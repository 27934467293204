import { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";


export const DatePickerInput = ({ ...props }) => {
    const isRange = props.selectsRange ? true : false;
    const [field] = useField(props);
    const { setFieldValue } = useFormikContext();

    /**
     * setup range support
     */
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        if (isRange) {
            setFieldValue(props.name, [(startDate && startDate.toISOString()) || null, (endDate && endDate.toISOString()) || null]);
        }
    }, [startDate, endDate, isRange, props.name, setFieldValue]);

    if (isRange) {
        return (
            <DatePicker
                {...field}
                {...props}
                selected={startDate}
                onChange={dates => {
                    const [start, end] = dates;
                    setStartDate(start);
                    setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
            />
        );
    } else {
        const adjustedValue = typeof field.value === 'string' ? new Date(field.value) : field.value;
        return (
            <DatePicker
                {...field}
                {...props}
                value={adjustedValue}
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {
                    if (val) {
                        if (field.name != 'timeOfLoss') {
                            setFieldValue(field.name, val.toLocaleDateString());
                        } else {
                            setFieldValue(field.name, val);
                        }
                    } else {
                        setFieldValue(field.name, '');
                    }
                }}
            />
        );
    }
};