import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../../../components/form-footer';
import IntakeInsuranceInvestigationPersonalHomeownersRentersDetailsFields from '../../../../../components/intake/insurance-investigation/personal/homeowners-renters/details-fields';
import InsuranceInvestigationPersonalHomeownersRentersDetailsSchema from '../../../../../validation-schemas/insurance-investigation-personal-homeowners-renters-details-schema';
import { updateActiveFormInsuranceInvestigationPersonalHomeownersRentersDetails, selectActiveFormInsuranceInvestigationPersonalHomeownersRentersDetails } from '../../../../../reducers/active-form-slice';


const IntakeInsuranceInvestigationPersonalHomeownersRentersDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const insuranceInvestigationPersonalHomeownersRentersDetails = useSelector(selectActiveFormInsuranceInvestigationPersonalHomeownersRentersDetails);
    const hasValues = Object.keys(insuranceInvestigationPersonalHomeownersRentersDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormInsuranceInvestigationPersonalHomeownersRentersDetails(values));
        navigate("/intake/insurance-investigation-personal-homeowners-renters/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        type: '',
        dateOfLoss: '',
        insuredName: '',
        insuredAddress: '',
        insuredAddress2: '',
        insuredCity: '',
        insuredStateProvince: '',
        insuredZip: '',
        insuredCountry: '',
        insuredDob: '',
        insuredSsn: '',
        homeownerRentersName: '',
        lossAddress: '',
        lossAddress2: '',
        lossCity: '',
        lossState: '',
        lossZip: '',
        lossCountry: '',
        insuranceCompany: '',
        Policy: '',
        Claim: '',
        locateAddress: '',
        locateAddress2: '',
        locateCity: '',
        locateStateProvince: '',
        locateZip: '',
        locateCountry: ''
    };


    return (
        <div className="page intake">
            <h2>Insured's Details</h2>

            <Formik
                initialValues={hasValues ? insuranceInvestigationPersonalHomeownersRentersDetails : initialValues}
                validationSchema={InsuranceInvestigationPersonalHomeownersRentersDetailsSchema.fields.insuranceInvestigationPersonalHomeownersRentersDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeInsuranceInvestigationPersonalHomeownersRentersDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`30`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInsuranceInvestigationPersonalHomeownersRentersDetails;