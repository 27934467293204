import * as Yup from 'yup';


const InsuranceInvestigationLocateAdditionalInformationSchema = Yup.object().shape({
    insuranceInvestigationLocateAdditionalInformation: Yup.object().shape({
        letterOfAcknowledgement: Yup.string()
            .required('Required'),
    })
});

export default InsuranceInvestigationLocateAdditionalInformationSchema;