import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import RoleServices from '../services/role-service';


export const fetchRoles = createAsyncThunk('roles/fetchRoles', async (payload) => {
    const data = await RoleServices.GetRoles();
    return data;
});

export const updateNotePermissionLevel = createAsyncThunk('roles/updateNotePermissionLevel', async (payload) => {
    var updatedPayload = Object.values(payload);
    const data = await RoleServices.UpdateNotePermissionLevel(updatedPayload);
    return data;
});

export const updateAttachmentPermissionLevel = createAsyncThunk('roles/updateAttachmentPermissionLevel', async (payload) => {
    var updatedPayload = Object.values(payload);
    const data = await RoleServices.UpdateAttachmentPermissionLevel(updatedPayload);
    return data;
});


export const rolesSlice = createSlice({
    name: 'roles',

    initialState: {
        roles: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetRolesStatus: (state) => {
            state.status = 'idle';
        },
    },

    extraReducers: {
        [fetchRoles.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchRoles.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.roles = action.payload;
        },
        [fetchRoles.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [updateNotePermissionLevel.pending]: (state, action) => {
            state.status = 'loading';
        },
        [updateNotePermissionLevel.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.lawFirmDetails = action.payload;
        },
        [updateNotePermissionLevel.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [updateAttachmentPermissionLevel.pending]: (state, action) => {
            state.status = 'loading';
        },
        [updateAttachmentPermissionLevel.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.lawFirmDetails = action.payload;
        },
        [updateAttachmentPermissionLevel.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetRolesStatus,
} = rolesSlice.actions;

export default rolesSlice.reducer;


export const selectAllRoles = state => {
    return state.roles.roles;
};