import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OrdersServices from '../services/orders-service';


export const getGlucroftUsersForAssignment = createAsyncThunk('glucroftUsersToAssign/getGlucroftUsersForAssignment', async (payload) => {
    const data = OrdersServices.GetGlucroftUsersForAssignment(payload);
    return data;
});



export const glucroftUsersToAssignSlice = createSlice({
    name: 'glucroftUsersToAssign',

    initialState: {
        glucroftUsersToAssign: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetGlucroftUsersToAssignStatus: (state) => {
            state.status = 'idle';
        },
    },

    extraReducers: {
        [getGlucroftUsersForAssignment.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getGlucroftUsersForAssignment.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.glucroftUsersToAssign = action.payload;
        },
        [getGlucroftUsersForAssignment.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetGlucroftUsersToAssignStatus,
} = glucroftUsersToAssignSlice.actions;

export default glucroftUsersToAssignSlice.reducer;


export const selectAllGlucroftUsersForAssigment = state => {
    return state.glucroftUsersToAssign.glucroftUsersToAssign;
};