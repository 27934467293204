import { Field } from "formik";
import { stateProvince } from "../../utility/state-province";
import StateProvinceDropdown from "../stateprovinceDropdown";

const IntakeGeneralInformationFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="firstNameCaseManager">First Name (Case Manager) <span className="required">*</span></label>
                    <Field id="firstNameCaseManager" name={nested ? 'generalInformation.firstNameCaseManager' : 'firstNameCaseManager'} />

                    {errors && errors.firstNameCaseManager && touched && touched && touched.firstNameCaseManager ? (
                        <div className="form-error">{errors.firstNameCaseManager}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastNameCaseManager">Last Name (Case Manager) <span className="required">*</span></label>
                    <Field id="lastNameCaseManager" name={nested ? 'generalInformation.lastNameCaseManager' : 'lastNameCaseManager'} />

                    {errors && errors.lastNameCaseManager && touched && touched.lastNameCaseManager ? (
                        <div className="form-error">{errors.lastNameCaseManager}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="emailPrimary">Email (Primary) <span className="required">*</span></label>
                    <Field id="emailPrimary" name={nested ? 'generalInformation.emailPrimary' : 'emailPrimary'} type="email" />

                    {errors && errors.emailPrimary && touched && touched.emailPrimary ? (
                        <div className="form-error">{errors.emailPrimary}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="emailSecondary">Email (Secondary)</label>
                    <Field id="emailSecondary" name={nested ? 'generalInformation.emailSecondary' : 'emailSecondary'} type="email" />

                    {errors && errors.emailSecondary && touched && touched.emailSecondary ? (
                        <div className="form-error">{errors.emailSecondary}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="phonePrimary">Phone # (Primary) <span className="required">*</span></label>
                    <Field id="phonePrimary" name={nested ? 'generalInformation.phonePrimary' : 'phonePrimary'} type="phone" />

                    {errors && errors.phonePrimary && touched && touched.phonePrimary ? (
                        <div className="form-error">{errors.phonePrimary}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="phoneSecondary">Phone # (Secondary)</label>
                    <Field id="phoneSecondary" name={nested ? 'generalInformation.phoneSecondary' : 'phoneSecondary'} type="phone" />

                    {errors && errors.phoneSecondary && touched && touched.phoneSecondary ? (
                        <div className="form-error">{errors.phoneSecondary}</div>
                    ) : null}
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="lawFirmName">Law Firm Name <span className="required">*</span></label>
                    <Field id="lawFirmName" name={nested ? 'generalInformation.lawFirmName' : 'lawFirmName'} />

                    {errors && errors.lawFirmName && touched && touched.lawFirmName ? (
                        <div className="form-error">{errors.lawFirmName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address">Address</label>
                    <Field id="address" name={nested ? 'generalInformation.address' : 'address'} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address2">Address 2</label>
                    <Field id="address2" name={nested ? 'generalInformation.address2' : 'address2'} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="city">City</label>
                    <Field id="city" name={nested ? 'generalInformation.city' : 'city'} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? 'generalInformation.stateProvince' : 'stateProvince'} id="stateProvince" disabled={false} className={`${(values.stateProvince && (values.stateProvince.length <= 0 || values.stateProvince === 'Make a selection')) ? 'placeholder' : ''}`}/>

                <div className="form-field-wrapper">
                    <label htmlFor="zip">Zip</label>
                    <Field id="zip" name={nested ? 'generalInformation.zip' : 'zip'} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="country">Country</label>
                    <Field id="country" name={nested ? 'generalInformation.country' : 'country'} />
                </div>
            </div>

        </div>
    );
};

export default IntakeGeneralInformationFields;