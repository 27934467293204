import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FileUpload from '../services/files-upload-service';


export const uploadAttachments = createAsyncThunk('attachments/uploadAttachments', async (payload) => {

    const response = await FileUpload.Upload(payload.files, payload.orderSummaryId, payload.portalUserId);
    return response;
});

export const deleteAttachments = createAsyncThunk('attachments/deleteAttachments', async (payload) => {
    const response = await FileUpload.Delete(payload);
    return response.data;
});

export const attachmentsSlice = createSlice({
    name: 'attachments',

    initialState: {
        attachments: [],
        status: 'idle',
        error: null,
        intakeAttachments_additional: [],
        intakeAttachments_specific: [],
        intakeAttachmentsAdded_additional: false,
        intakeAttachmentsDeleted_additional: false,
        intakeAttachmentsAdded_specific: false,
        intakeAttachmentsDeleted_specific: false
    },

    reducers: {
        resetAttachmentsAfterSubmission: (state) => {
            state.attachments = [];
            state.status = 'idle';
            state.error = null;
            state.intakeAttachments_additional = [];
            state.intakeAttachments_specific = [];
            state.intakeAttachmentsAdded_additional = false;
            state.intakeAttachmentsDeleted_additional = false;
            state.intakeAttachmentsAdded_specific = false;
            state.intakeAttachmentsDeleted_specific = false;
        },
        resetAttachmentsStatus: (state) => {
            state.status = 'idle';
        },
        resetAttachments: (state) => {
            state.attachments = [];
        },
        setIntakeAttachments: (state, action) => {

       //     state.intakeAttachments = action.payload;
            if (action.payload.added) {
                state[`intakeAttachments_${action.payload.intakeStep}`] = state[`intakeAttachments_${action.payload.intakeStep}`].concat(action.payload.files);
                state[`intakeAttachmentsAdded_${action.payload.intakeStep}`] = true;
            } else {
                state[`intakeAttachments_${action.payload.intakeStep}`] = action.payload.files;
                state[`intakeAttachmentsDeleted${action.payload.intakeStep}`] = true;
            }
        },
        resetIntakeAttachmentsAdded: (state, action) => {
            state[`intakeAttachmentsAdded_${action.payload.intakeStep}`] = false;
        },
        resetIntakeAttachmentsDeleted: (state, action) => {
            state[`intakeAttachmentsDeleted_${action.payload.intakeStep}`] = false;
        },
        setIntakeAttachmentsDeleted: (state, action) => {
            state[`intakeAttachmentsDeleted_${action.payload.intakeStep}`] = true;
        }
    },

    extraReducers: {
        [uploadAttachments.pending]: (state, action) => {
            state.status = 'loading';
        },
        [uploadAttachments.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.attachments = state.attachments.concat(action.payload);
        },
        [uploadAttachments.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [deleteAttachments.pending]: (state, action) => {
            state.status = 'loading';
        },
        [deleteAttachments.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.attachments = [];
        },
        [deleteAttachments.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    }
});


export const {
    resetAttachmentsAfterSubmission,
    resetAttachmentsStatus,
    resetAttachments,
    setIntakeAttachments,
    resetIntakeAttachmentsAdded,
    resetIntakeAttachmentsDeleted,
    setIntakeAttachmentsDeleted
} = attachmentsSlice.actions;


export default attachmentsSlice.reducer;


export const selectAllAttchments = state => {
    return state.attachments;
};