import * as Yup from 'yup';
import { phoneExpression } from '../utility/phone-expression';


const BackgroundScreeningSocialMediaSchema = Yup.object().shape({
    backgroundScreeningSocialMedia: Yup.object().shape({
        socialMediaFirstName: Yup.string()
            .required('Required'),
        socialMediaLastName: Yup.string()
            .required('Required'),
        socialMediaEmail: Yup.string()
            .email('Invalid email'),
        socialMediaPhone: Yup.string()
            .matches(phoneExpression, 'Invalid phone number'),
    })
});

export default BackgroundScreeningSocialMediaSchema;