import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LawFirmsService from '../services/law-firms-service';


export const sendInvite = createAsyncThunk('linkInvite/sendInvite', async (payload) => {
    const response = await LawFirmsService.SendInvite(payload);
    return response;
});


export const linkInviteSlice = createSlice({
    name: 'linkInvite',

    initialState: {
        linkInvite: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetLinkInviteStatus: (state) => {
            state.status = 'idle';
        },
    },

    extraReducers: {
        [sendInvite.pending]: (state, action) => {
            state.status = 'loading';
        },
        [sendInvite.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.linkInvite = action.payload;
        },
        [sendInvite.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetLinkInviteStatus,
} = linkInviteSlice.actions;

export default linkInviteSlice.reducer;