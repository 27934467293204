import { useCallback, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import FormFooter from '../../components/form-footer';
import IntakeAdditionalInformationFields from '../../components/intake/additional-information-fields';
import { selectActiveFormAdditionalInformation, updateActiveFormAdditionalInformation, selectActiveFormInvestigationType, selectActiveFormBackgroundScreeningDetails, selectActiveFormInsuranceClaimHistoryDetails, selectActiveFormInsuranceInvestigationBusinessDetails } from '../../reducers/active-form-slice';
import { resetAdditionalInformationFilesStatus, selectAllAdditionalInformationFiles, uploadAdditionalInformationFiles, deleteAdditionalInformationFiles } from '../../reducers/additional-information-files-slice';


const IntakeAdditionalInformation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const additionalInformation = useSelector(selectActiveFormAdditionalInformation);
    const hasValues = Object.keys(additionalInformation).length > 0 ? true : false;


    /**
     * decide which path to take based on the
     * chosen investigation type
     */
    const investigationType = useSelector(selectActiveFormInvestigationType);
    const backgroundScreeningDetails = useSelector(selectActiveFormBackgroundScreeningDetails);
    const insuranceClaimHistoryDetails = useSelector(selectActiveFormInsuranceClaimHistoryDetails);
    const insuranceInvestigationBusinessDetails = useSelector(selectActiveFormInsuranceInvestigationBusinessDetails);

    const choosePath = useCallback(() => {
        switch (investigationType[1]) {
            case "6859dfec-df01-492f-8def-50bb130d2740":
                return "/intake/asset-locate/request";
            case "b7fdc4fd-0629-4d64-8751-65080436b4ed":
                if (backgroundScreeningDetails.socialMedia === "Yes") {
                    return "/intake/background-screening/social-media";
                } else {
                    return "/intake/background-screening/details";
                }
            case "05a7fe17-8fb5-442b-aaa0-d9c99476228c":
                return "/intake/business-due-diligence/details";
            case "112f034e-c54b-4307-bc1d-ce68d3a69fe7":
                return "/intake/dmv/details";
            case "fc25e0ba-f2f1-41e9-afc8-2da980ed53fe":
                if (insuranceClaimHistoryDetails.insuranceClaimHistoryDetails === "No") {
                    return "/intake/insurance-claim-history/subject";
                } else {
                    return "/intake/insurance-claim-history/details";
                }
            case "cbd61d9e-7c34-4bbb-af1f-2cbf84321034":
                return "/intake/insurance-investigation-business/additional-information";
            case "dd7b99f9-5394-4d46-87f3-3a0804a8738a":
                return "/intake/insurance-investigation-locate/additional-information";
            case "3fe7eb42-7b28-4146-8fdd-f4012ce62bc5":
                return "/intake/insurance-investigation-personal-auto-umbrella/additional-information";
            case "b03f65f3-f6c5-42b6-af5b-c34a958bc6ec":
                return "/intake/insurance-investigation-personal-homeowners-renters/additional-information";
            case "77cf5024-fffe-48ac-ade7-a4ca318b1290":
                return "/intake/scene-investigation/details";
            case "8327fb74-d3ff-4561-b189-8e80fde1b3af":
                return "/intake/skip-trace/details";
            case "918a0ae1-4e91-49a2-a595-190f6b87e8dc":
                return "/intake/subpoena-process-serving/details";
            case "c7537487-e799-49f0-85b9-13c07170ba20":
                return "/intake/switrs/details";
            case "26a9c1ea-45a1-4181-8665-9cdc33721f71":
                return "/intake/traffic-collisions-report-retrieval/details";
            case "3a25f588-0e8f-451d-978d-711502131631":
                return "/intake/witness-defendant-statement/info";
            default:
                return "/intake/404";
        }
    }, [investigationType, backgroundScreeningDetails, insuranceClaimHistoryDetails, insuranceInvestigationBusinessDetails]);


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        const path = choosePath();
        navigate(path);
    };


    /**
     * handle the file upload & store returned info in state
     */
    const additionalInformationFiles = useSelector(selectAllAdditionalInformationFiles);
    const additionalInformationFilesStatus = useSelector(state => state.additionalInformationFiles.status);
    const formRef = useRef();

    const handleUpload = (files) => {
        dispatch(uploadAdditionalInformationFiles(files));
    };

    const handleUploadedRemoval = (guid) => {
        dispatch(deleteAdditionalInformationFiles(guid));
    };

    useEffect(() => {
        if (additionalInformationFilesStatus === "succeeded") {
            formRef.current.setFieldValue('files', additionalInformationFiles.additionalInformationFiles);
            dispatch(resetAdditionalInformationFilesStatus());
        }
    }, [additionalInformationFiles, additionalInformationFilesStatus, dispatch]);


    /**
     * submit form
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormAdditionalInformation(values));
        navigate("/intake/confirmation");
    };


    /**
     * setup the initial values & validation
     */
    const initialValues = {
        additionalInformation: '',
        files: [],
    };

    return (
        <div className="page intake">
            {(additionalInformationFilesStatus === 'loading') &&
                <div className="loader-container">
                    <BeatLoader color="#00818C" />
                </div>
            }

            <h2>Additional Information</h2>

            <Formik
                innerRef={formRef}
                initialValues={hasValues ? additionalInformation : initialValues}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <IntakeAdditionalInformationFields
                            handleUpload={handleUpload}
                            handleUploadedRemoval={handleUploadedRemoval}
                            uploadedFiles={additionalInformationFiles.additionalInformationFiles}
                            uploadStatus={additionalInformationFilesStatus}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={false}
                            showNext={true}
                            progress={`90`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeAdditionalInformation;