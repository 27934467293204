import { Field } from "formik";


const IntakeAssetLocateTypeFields = ({ errors, touched, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <h3>Requesting Search on a... (Check only one)</h3>

            <div className="asset-locate-type">
                <div role="group" aria-labelledby="assetLocateType-radio-group" className="form-check-inline">
                    <div className="form-check">
                        <Field type="radio" name={nested ? 'assetLocateType.assetLocateType' : 'assetLocateType'} value="Subject/Target" id="Subject/Target" />
                        <label htmlFor="Subject/Target">Subject/Target</label>
                    </div>
                    <div className="form-check">
                        <Field type="radio" name={nested ? "assetLocateType.assetLocateType" : "assetLocateType"} value="Business - Open and Active" id="Business - Open and Active" />
                        <label htmlFor="Business - Open and Active">Business - Open and Active</label>
                    </div>
                    <div className="form-check">
                        <Field type="radio" name={nested ? "assetLocateType.assetLocateType" : "assetLocateType"} value="Business - Closed or Suspended" id="Business - Closed or Suspended" />
                        <label htmlFor="Business - Closed or Suspended">Business - Closed or Suspended</label>
                    </div>
                </div>

                {errors && errors.assetLocateType && touched && touched.assetLocateType ? (
                    <div className="form-error">{errors.assetLocateType}</div>
                ) : null}
            </div>
        </div>
    );
}

export default IntakeAssetLocateTypeFields;