import * as Yup from 'yup';


const AssetLocateRequestSchema = Yup.object().shape({
    assetLocateRequest: Yup.object().shape({
        assetLocateRequest: Yup.array()
            .required('Required'),
    })
});

export default AssetLocateRequestSchema;