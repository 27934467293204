import { Field } from "formik";
import { stateProvince } from "../../../../../utility/state-province";
import { DatePickerInput } from "../../../../datepicker-input";
import StateProvinceDropdown from "../../../../stateprovinceDropdown";

const IntakeInsuranceInvestigationPersonalAutoUmbrellaDetailsFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <div className="form-row three-col-reversed no-spacing no-border">
                <div className="form-field-wrapper">
                    <label className="left-justified" htmlFor="insuredType">Type <span className="required">*</span></label>

                    <div role="group" aria-labelledby="insuredType-radio-group" className="form-check-inline left-justified">
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredType" : "insuredType"} value="Auto" id="Auto" />
                            <label htmlFor="Auto">Auto</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredType" : "insuredType"} value="Umbrella" id="Umbrella" />
                            <label htmlFor="Umbrella">Umbrella</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredType" : "insuredType"} value="Auto & Umbrella" id="Auto & Umbrella" />
                            <label htmlFor="Auto & Umbrella">Auto/Umbrella</label>
                        </div>
                    </div>

                    {errors && errors.insuredType && touched && touched.insuredType ? (
                        <div className="form-error">{errors.insuredType}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="dateOfLoss">Date of Loss <span className="required">*</span></label>
                    <DatePickerInput name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.dateOfLoss" : "dateOfLoss"} placeholderText="Select date" />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="insuredName">Insured's Name <span className="required">*</span></label>
                    <Field id="insuredName" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredName" : "insuredName"} />

                    {errors && errors.insuredName && touched && touched.insuredName ? (
                        <div className="form-error">{errors.insuredName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredAddress">Address</label>
                    <Field id="insuredAddress" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredAddress" : "insuredAddress"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredAddress2">Address 2</label>
                    <Field id="insuredAddress2" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredAddress2" : "insuredAddress2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredCity">City</label>
                    <Field id="insuredCity" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredCity" : "insuredCity"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredStateProvince" : "insuredStateProvince"} id="insuredStateProvince" disabled={false} className={`${(values.insuredStateProvince && (values.insuredStateProvince.length <= 0 || values.insuredStateProvince === 'Make a selection')) ? 'placeholder' : ''}`}/> 


                <div className="form-field-wrapper">
                    <label htmlFor="insuredZip">Zip</label>
                    <Field id="insuredZip" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredZip" : "insuredZip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredCountry">Country</label>
                    <Field id="insuredCountry" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredCountry" : "insuredCountry"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredDob">Date of Birth</label>
                    <DatePickerInput name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredDob" : "insuredDob"} placeholderText="Select date" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredSsn">SSN</label>
                    <Field id="insuredSsn" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredSsn" : "insuredSsn"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredVin">VIN</label>
                    <Field id="insuredVin" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredVin" : "insuredVin"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredLicensePlate">License Plate</label>
                    <Field id="insuredLicensePlate" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.insuredLicensePlate" : "insuredLicensePlate"} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="ownersName">Owners's Name</label>
                    <Field id="ownersName" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.ownersName" : "ownersName"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="ownersAddress">Address</label>
                    <Field id="ownersAddress" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.ownersAddress" : "ownersAddress"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="ownersAddress2">Address 2</label>
                    <Field id="ownersAddress2" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.ownersAddress2" : "ownersAddress2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="ownersCity">City</label>
                    <Field id="ownersCity" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.ownersCity" : "ownersCity"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.ownersStateProvince" : "ownersStateProvince"} id="ownersStateProvince" disabled={false} className={`${(values.ownersStateProvince && (values.ownersStateProvince.length <= 0 || values.ownersStateProvince === 'Make a selection')) ? 'placeholder' : ''}`}/> 

                <div className="form-field-wrapper">
                    <label htmlFor="ownersZip">Zip</label>
                    <Field id="ownersZip" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.ownersZip" : "ownersZip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="ownersCountry">Country</label>
                    <Field id="ownersCountry" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.ownersCountry" : "ownersCountry"} />
                </div>

                <div className="form-field-wrapper">
                    <label className="left-justified" htmlFor="ownerIsDriver">Is the Registered Owner also the Driver? <span className="required">*</span></label>

                    <div role="group" aria-labelledby="ownerIsDriver-radio-group" className="form-check-inline left-justified">
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.ownerIsDriver" : "ownerIsDriver"} value="Yes" id="ownerIsDriver-Yes" />
                            <label htmlFor="ownerIsDriver-Yes">Yes</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationPersonalAutoUmbrellaDetails.ownerIsDriver" : "ownerIsDriver"} value="No" id="ownerIsDriver-No" />
                            <label htmlFor="ownerIsDriver-No">No</label>
                        </div>
                    </div>

                    {errors && errors.ownerIsDriver && touched && touched.ownerIsDriver ? (
                        <div className="form-error">{errors.ownerIsDriver}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default IntakeInsuranceInvestigationPersonalAutoUmbrellaDetailsFields;