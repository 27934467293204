import BaseAPI from '../utility/fetch-wrapper';

class FormService {
    static SubmitForm = async payload => {
        const data = await BaseAPI.postRequest(`orders`, payload);

        return data;
    };

    static SendEmail = async payload => {
        const data = await BaseAPI.postRequest(`sendgrid/sendemail`, payload);
        return data;
    };
}


export default FormService;