import { Fragment, useEffect, useState, useCallback } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import BeatLoader from 'react-spinners/BeatLoader';
import ArchiveModal from '../components/modals/archive';
import StatusModal from '../components/modals/status';
import AssignModal from '../components/modals/assign';
import IcoArrow from '../assets/ico-arrow';
import CustomLoader from '../components/custom-loader';
import { updateStatus, updateAssignedUser } from '../reducers/orders-slice';
import { fetchCaseById, resetStatus, resetCaseOrders, selectCase } from '../reducers/case-details-slice';
import FilterMethods from '../utility/filter-methods';
import { submitToBilling } from '../reducers/order-details-slice';
import SubmitToBillingModal from '../components/modals/submit-to-billing';
import { selectActiveFeatures } from '../reducers/auth-slice';
import IcoMail from '../assets/ico-mail';



const CaseDetails = () => {
    const navigate = useNavigate();
    const [caseLoaded, setCaseLoaded] = useState(false);
    // eslint-disable-next-line
    const [selectedRows, setSelectedRows] = useState([]);
    const activeFeatures = useSelector(selectActiveFeatures);
    const [showDropdown, setShowDropdown] = useState(false);
    const [clearRows, setClearRows] = useState(false);
    //setting visible orders for lazy loading
    // const [visibleOrders, setVisibleOrders] = useState(15);

    /**
     * get case details
     */
    let { caseNumber } = useParams();
    const dispatch = useDispatch();
    const caseDetails = useSelector(selectCase);
    const caseStatus = useSelector(state => state.caseDetails.status);

    //loads variable that contains session storage item activeFilters
    const loadedFilters = JSON.parse(window.sessionStorage.getItem('activeCaseFilters'));

    // fetch case details on each initial render
    useEffect(() => {
        if (caseStatus === 'idle' && !caseLoaded) {
            dispatch(resetCaseOrders());
            dispatch(fetchCaseById(caseNumber));
        } else if (caseStatus === 'succeeded' && !caseLoaded) {
            // anything that needs to happen AFTER 
            // the data has loaded (e.g. removing loading indicator)
            setCaseLoaded(true);
        }
    }, [dispatch, caseNumber, caseStatus, caseLoaded]);

    // set when case details have been loaded
    useEffect(() => {
        if (caseLoaded) {
            dispatch(resetStatus());
        }
    }, [dispatch, caseLoaded]);

        //function to handle lazy loading of more orders
        // const loadMoreOrders = useCallback(() => {
        //     if (visibleOrders < caseDetails.length) {
        //         setVisibleOrders(visibleOrders + 25); // Load 25 more orders
        //     }
        // }, [visibleOrders, caseDetails]);
    
    // function to handle loading more orders once handleScroll breakpoint has been hit
        // const handleScroll = useCallback(() => {
        //     const tableContainer = document.getElementById('table-container-case'); 
        //     if (tableContainer) {
        //       const tableHeight = tableContainer.clientHeight;
        //       const tableContentHeight = tableContainer.scrollHeight;
          
        //       if (tableContainer.scrollTop >= tableContentHeight - tableHeight - 25 && visibleOrders < caseDetails.length) {
        //         loadMoreOrders();
        //       }
        //     }
        //   }, [loadMoreOrders, visibleOrders, caseDetails]);
    
    //setting an eventListener to be called when handleScroll breakpoint is detected
    // useEffect(() => {
    //     const tableContainer = document.getElementById('table-container-case'); 
    //     if (tableContainer) {
    //       tableContainer.addEventListener('scroll', handleScroll);
    //     }
    
    //     return () => {
    //       if (tableContainer) {
    //         tableContainer.removeEventListener('scroll', handleScroll);
    //       }
    //     };
    //   }, [handleScroll]);


    /**
     * handle filters functionality
     */
    const [appliedFilters, setApplyFilters] = useState([]);
    const [filters, setFilters] = useState([]);
    const [displayedFilters, setDisplayedFilters] = useState("");

        //will populate the filters with the filters object in session storage if the object is not null, else it will set everything to empty
        useEffect(() => {
            if (loadedFilters != null && caseStatus === 'idle' && !caseLoaded) {
                setFilters(loadedFilters);
                setDisplayedFilters(FilterMethods.concatFilters(loadedFilters));
                setApplyFilters(loadedFilters);
            }
        }, [loadedFilters, caseStatus, caseLoaded])
    
        // if the toggle for show dropdown is true then it will load checked filters, breaking it out into a use effect fixed
        // issue of not loading on the first click since the function was running before the dropdown opened
        useEffect(() => {
            if (showDropdown && loadedFilters != null) {
                FilterMethods.applyLoadedFilters(loadedFilters);
            }
        }, [showDropdown, loadedFilters]);

    const handleApplyFilters = (e) => {
        e.preventDefault();
        setDisplayedFilters(FilterMethods.concatFilters(filters));
        setApplyFilters(filters);
         //sets session storage variable to active filters checked
         window.sessionStorage.setItem("activeCaseFilters", JSON.stringify(filters));
        setShowDropdown(false);
        // setVisibleOrders(15);
    }

    const handleClearFilters = (e) => {
        e.preventDefault();
        FilterMethods.clearFilters();
        setFilters([]);
        setApplyFilters([]);
        setDisplayedFilters("");
        setShowDropdown(false);
        //sets session storage variable to null
        window.sessionStorage.setItem("activeCaseFilters", null);
        // setVisibleOrders(15);
    };

    // fetch case again after update
    const fetchCaseAfterDispatch = (dispatchFunction) => {
        dispatchFunction.then(() => {
            dispatch(resetCaseOrders());
            dispatch(fetchCaseById(caseNumber));
        })
        // setVisibleOrders(15);
    }


    /**
     * handle archive functionality
     */
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const handleCloseArchiveModal = () => setShowArchiveModal(false);
    const handleShowArchiveModal = () => setShowArchiveModal(true);

    const handleArchiveButton = (e) => {
        e.preventDefault();
        if (selectedRows.length > 0) {
            const dispatchFunc = dispatch(updateStatus({ orderIds: selectedRows, status: "Archived" }));
            fetchCaseAfterDispatch(dispatchFunc);
        }
        handleCloseArchiveModal();
        setClearRows(!clearRows);
        setSelectedRows([]);
        // setVisibleOrders(15);
    };

    /**
     * handle status functionality
     */
    const [showStatusModal, setShowStatusModal] = useState(false);
    const handleCloseStatusModal = () => setShowStatusModal(false);
    const handleShowStatusModal = () => setShowStatusModal(true);

    // eslint-disable-next-line
    const [status, setStatus] = useState("");
    const handleStatusChange = (e) => {
        e.preventDefault();
        setStatus(e.target.value);
    };

    const handleStatusButton = (e) => {
        e.preventDefault();
        if (selectedRows.length > 0) {
            const dispatchFunc = dispatch(updateStatus({ orderIds: selectedRows, status }));
            fetchCaseAfterDispatch(dispatchFunc);
        }
        handleCloseStatusModal();
        // setVisibleOrders(15);
    };

    /**
     * handle assign functionality
     */
    const [showAssignModal, setShowAssignModal] = useState(false);
    const handleCloseAssignModal = () => setShowAssignModal(false);
    const handleShowAssignModal = () => setShowAssignModal(true);

    // eslint-disable-next-line
    const [user, setUser] = useState("");
    const handleUserChange = (e) => {
        e.preventDefault();
        setUser(e.target.value);
    };

    const handleAssignButton = (e) => {
        e.preventDefault();
        if (selectedRows.length > 0 && user !== "Select a User") {
            const dispatchFunc = dispatch(updateAssignedUser({ orderIds: selectedRows, user }));
            fetchCaseAfterDispatch(dispatchFunc);
        }
        handleCloseAssignModal();
        // setVisibleOrders(15);
    };

    /**
     * handle changing of sleected rows in the table
     */
    const handleSelectedRowsChange = ({ selectedRows }) => {

        //Store selected Order Ids in state
        if (selectedRows.length > 0) {
            var orderIds = [];
            selectedRows.forEach(x => {
                orderIds.push(x.id);
            })
            setSelectedRows(orderIds);
        } else {
            setClearRows(!clearRows);
            setSelectedRows([]);
        }
    };


    /**
     * handle submit to billing
     */
    const [showSubmitToBillingModal, setShowSubmitToBillingModal] = useState(false);

    const handleShowSubmitToBillingModal = () => {
        setShowSubmitToBillingModal(true);
    };

    const handleHideSubmitToBillingModal = () => {
        setShowSubmitToBillingModal(false);
    };

    const handleSubmitButton = async (values) => {
        handleHideSubmitToBillingModal();
        var subjectLine = "Sent to Billing"
        var orderCodes = values.orderCode;
        var orderIds = selectedRows;
        await dispatch(submitToBilling({ subjectLine, orderCodes, orderIds }));
        setCaseLoaded(false);
        dispatch(resetStatus());
        navigate('/orders');
    };

    /**
     * setup case table
     */
    const columns = [
        {
            name: <span className="custom-table-header" title="Order">Order</span>,
            selector: row => <Link to={`/orders/order-details/${row.id}`}>{row.orderNumber}</Link>,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Law Firm">Law Firm</span>,
            selector: row => row.firm,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Case Manager">Case Manager</span>,
            selector: row => row.assignedFirm,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Case Name">Case Name</span>,
            selector: row => row.caseName,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Investigation Type">Investigation Type</span>,
            selector: row => row.investigationType,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Assigned (G.I.)">Assigned (G.I.)</span>,
            selector: row => row.assignedGI,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Status">Status</span>,
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Submit Date',
            selector: row => row.start,
            cell: row => <span key={`startDate${row.id}`}>{new Date(row.start.endsWith('Z') ? row.start : row.start+"Z").toLocaleDateString()}</span>,
            sortable: true,
        },
        {
            name: 'Last Edit',
            selector: row => row.lastEdit,
            cell: row => <span key={`editDate${row.id}`}>{new Date(row.lastEdit.endsWith('Z') ? row.lastEdit : row.lastEdit+"Z").toLocaleDateString()}</span>,
            sortable: true,
        },
        {
            name: 'Notification',
            selector: row => row.attention,
            cell: row => ( row.attentionMessage != null && !activeFeatures.includes('f_InternalInvestigator_AssignRoles') && !activeFeatures.includes('f_FieldInvestigator_AssignRoles') ? <Link className='button note' to={`/orders/order-details/${row.id}#notifications`} title ={row.attentionMessage}><IcoMail /></Link> : null),
            sortable: true,
        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.colorCode === 1,
            style: {
                //Red -> Aged Out
                backgroundColor: '#FFC5B4',
            },
        },
        {
            when: row => row.colorCode === 2,
            style: {
                //Yellow -> Waiting for Client
                backgroundColor: '#FCFAD5',
            },
        },
        {
            when: row => row.attention > 0 && !activeFeatures.includes('f_InternalInvestigator_AssignRoles') && !activeFeatures.includes('f_FieldInvestigator_AssignRoles'),
            style: {
                //If a row needs attention bold the font
                fontWeight: 600
            }
        }
    ];

    const paginationComponentOptions = {
        noRowsPerPage: true,
    };

    // declaring filterdData in a variable so a slice can be made of only the visible orders on screen but its still filtering all orders which are loaded initially
    const filteredData = FilterMethods.filteredData(caseDetails, appliedFilters)

    //function showing order totals below table
    const caseOrderTotal = () => {
        const orderCount = filteredData ? filteredData.length : caseDetails.length;

        return (
            <p>{orderCount} orders</p>
        )

    }


    return (
        <Fragment>
            <div className="page">
                {(caseStatus === 'loading') &&
                    <div className="loader-container">
                        <BeatLoader color="#00818C" />
                    </div>
                }

                <div className="page-header page-header-sticky"> 
                    <div className="page-header-title">
                        <h1>CASE: <small>{caseLoaded && caseDetails && caseDetails.length > 0 ? caseDetails[0].caseName : ""}</small></h1>
                        <div className="page-header-title-filters">{displayedFilters}</div>
                    </div>

                    <div className="page-header-actions">
                        <Dropdown className="filter" align="end" show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
                            <Dropdown.Toggle id="dropdown-filter" className="button primary filter">
                                Filter <IcoArrow />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Header className="center">
                                    <button className="button primary" onClick={handleApplyFilters}>Apply</button>
                                    <button className="button outlined primary" onClick={handleClearFilters}>Clear</button>
                                </Dropdown.Header>

                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Law Firm</Accordion.Header>
                                        <Accordion.Body>
                                            {[...new Set(caseDetails.map(order => order.firm))].map((firm, i) => (
                                                <Form.Check type={'checkbox'} name={`firm`} id={`lf${i}`} key={`lf${i}`}>
                                                    <Form.Check.Input type={'checkbox'} onClick={e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "firm", filters))} />
                                                    <Form.Check.Label id={`lf${i}-label`}>{firm}</Form.Check.Label>
                                                </Form.Check>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Case Manager</Accordion.Header>
                                        <Accordion.Body>
                                            {[...new Set(caseDetails.map(order => order.assignedFirm))].map((assignedFirm, i) => (
                                                <Form.Check type={'checkbox'} name={`assignedFirm`} id={`af${i}`} key={`af${i}`}>
                                                    <Form.Check.Input type={'checkbox'} onClick={e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "assignedFirm", filters))} />
                                                    <Form.Check.Label id={`af${i}-label`}>{assignedFirm}</Form.Check.Label>
                                                </Form.Check>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Investigation Type</Accordion.Header>
                                        <Accordion.Body>
                                            {[...new Set(caseDetails.map(order => order.investigationType))].map((investigationType, i) => (
                                                <Form.Check type={'checkbox'} name={`investigation-type`} id={`it${i}`} key={`it${i}`}>
                                                    <Form.Check.Input onClick={e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "investigationType", filters))} />
                                                    <Form.Check.Label id={`it${i}-label`}>{investigationType}</Form.Check.Label>
                                                </Form.Check>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Assigned (G.I.)</Accordion.Header>
                                        <Accordion.Body>
                                            {[...new Set(caseDetails.map(order => order.assignedGI))].map((assignedGI, i) => (
                                                <Form.Check type={'checkbox'} name={`assignedGI`} id={`agi${i}`} key={`agi${i}`}>
                                                    <Form.Check.Input type={'checkbox'} onClick={e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "assignedGI", filters))} />
                                                    <Form.Check.Label id={`agi${i}-label`}>{assignedGI}</Form.Check.Label>
                                                </Form.Check>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Status</Accordion.Header>
                                        <Accordion.Body>
                                            {[...new Set(caseDetails.map(order => order.status))].map((status, i) => (
                                                <Form.Check type={'checkbox'} name={`status`} id={`s${i}`} key={`s${i}`}>
                                                    <Form.Check.Input type={'checkbox'} onClick={e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "status", filters))} />
                                                    <Form.Check.Label id={`s${i}-label`}>{status}</Form.Check.Label>
                                                </Form.Check>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>Aging Date</Accordion.Header>
                                        <Accordion.Body>
                                            <Form.Check type={'checkbox'} name={`agingDate`} id={`ad0`}>
                                                <Form.Check.Input type={'checkbox'} onClick={e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "colorCode", filters))} />
                                                <Form.Check.Label id={`ad0-label`}>Cleared</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check type={'checkbox'} name={`agingDate`} id={`ad1`}>
                                                <Form.Check.Input type={'checkbox'} onClick={e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "colorCode", filters))} />
                                                <Form.Check.Label id={`ad1-label`}>Aged Out</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check type={'checkbox'} name={`agingDate`} id={`ad2`}>
                                                <Form.Check.Input type={'checkbox'} onClick={e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "colorCode", filters))} />
                                                <Form.Check.Label id={`ad2-label`}>Waiting on Client</Form.Check.Label>
                                            </Form.Check>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown className="new" align="end">
                            <Dropdown.Toggle id="dropdown-add" className="button secondary new">
                                + Add New
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="/admin/law-firm">Law Firm</Dropdown.Item>
                                <Dropdown.Item href="/admin/case-manager">Case Manager</Dropdown.Item>
                                <Dropdown.Item href="/admin/glucroft-user">Glucroft User</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown className="action" align="end">
                            <Dropdown.Toggle id="dropdown-action" className="button tertiary action">
                                Choose an Actions <IcoArrow />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {activeFeatures.includes('f_Archive') && <Dropdown.Item onClick={handleShowArchiveModal}>Archive</Dropdown.Item>}
                                <Dropdown.Item onClick={handleShowStatusModal}>Update Status</Dropdown.Item>
                                <Dropdown.Item onClick={handleShowAssignModal}>Assign</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {activeFeatures.includes('f_SubmitBilling') && <button className="button outlined secondary" type="button" onClick={handleShowSubmitToBillingModal}>Submit to Billing</button>}
                    </div>
                </div>


                <div className='page-table-container' id="table-container-case">
                    <div className="page-body no-padding">
                        <DataTable
                            paginationComponentOptions={paginationComponentOptions}
                            columns={columns}
                            // data={filteredData.slice(0, visibleOrders)}
                            data={filteredData}
                            progressPending={caseStatus === 'loading'}
                            progressComponent={<CustomLoader />}
                            conditionalRowStyles={conditionalRowStyles}
                            selectableRows
                            onSelectedRowsChange={handleSelectedRowsChange}
                            clearSelectedRows={clearRows}

                        />
                    </div>
                </div>
                 <div>
                    {caseOrderTotal()}
                 </div>
            </div>

            {showArchiveModal &&
                <ArchiveModal
                    showArchiveModal={showArchiveModal}
                    handleCloseArchiveModal={handleCloseArchiveModal}
                    handleArchiveButton={handleArchiveButton}
                />
            }

            {showStatusModal &&
                <StatusModal
                    showStatusModal={showStatusModal}
                    handleCloseStatusModal={handleCloseStatusModal}
                    handleStatusButton={handleStatusButton}
                    handleStatusChange={handleStatusChange}
                />
            }

            {showAssignModal &&
                <AssignModal
                    showAssignModal={showAssignModal}
                    handleCloseAssignModal={handleCloseAssignModal}
                    handleAssignButton={handleAssignButton}
                    handleUserChange={handleUserChange}
                    orderIds={selectedRows}
                />
            }

            {showSubmitToBillingModal &&
                <SubmitToBillingModal
                    showSubmitToBillingModal={showSubmitToBillingModal}
                    handleHideSubmitToBillingModal={handleHideSubmitToBillingModal}
                    handleSubmitButton={handleSubmitButton}
                />
            }
        </Fragment>
    );
}

export default CaseDetails;