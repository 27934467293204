import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ReportService from '../services/report-service';


export const generateReport = createAsyncThunk('report/generateReport', async (payload) => {
    const response = await ReportService.GenerateReport(payload);
    return response;
});


export const reportSlice = createSlice({
    name: 'report',

    initialState: {
        report: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetReportStatus: (state) => {
            state.status = 'idle';
        },
    },

    extraReducers: {
        [generateReport.pending]: (state, action) => {
            state.status = 'loading';
        },
        [generateReport.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.report = action.payload;
        },
        [generateReport.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetReportStatus,
} = reportSlice.actions;

export default reportSlice.reducer;