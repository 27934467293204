import { useEffect, useState } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import BeatLoader from 'react-spinners/BeatLoader';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DatePickerInput } from "./datepicker-input";
import IcoAdd from '../assets/ico-add.svg';
import { fetchLawFirms, resetLawFirmsStatus, selectAllLawFirms } from "../reducers/law-firms-slice";
import { resetCasesStatus, fetchCases, selectAllCases } from "../reducers/cases-slice";
import { fetchCaseManagersByFirm, resetCaseManagersStatus, fetchCaseManagers, selectAllCaseManagers } from '../reducers/case-managers-slice';
import { fetchGlucroftUsers, resetGlucroftUsersStatus, selectAllGlucroftUsers } from '../reducers/glucroft-users-slice';
import { fetchInvestigationTypes, resetInvestigationTypesStatus, selectAllInvestigationTypes } from '../reducers/investigation-type-slice';
import { generateReport } from "../reducers/report-slice";


const Reports = ({ closeFlyout }) => {
    const dispatch = useDispatch();

    /**
     * fetch law firms
     */
    const [lawFirmsLoaded, setLawFirmsLoaded] = useState(false);
    const lawFirms = useSelector(selectAllLawFirms);
    const lawFirmsStatus = useSelector(state => state.lawFirms.status);
    const [selectedLawFirm, setSelectedLawFirm] = useState([]);

    // need to add an option for all firms in typeahead
    let lawFirmsWithAllOption = [{ firm: 'All Firms' }];
    lawFirmsWithAllOption.push(...lawFirms);

    // fetch law firms on each initial render
    useEffect(() => {
        if (lawFirmsStatus === 'idle' && !lawFirmsLoaded) {
            dispatch(fetchLawFirms());
        } else if (lawFirmsStatus === 'succeeded' && !lawFirmsLoaded) {
            // anything that needs to happen AFTER 
            // the data has loaded (e.g. removing loading indicator)
            setLawFirmsLoaded(true);
        }
    }, [dispatch, lawFirmsStatus, lawFirmsLoaded]);

    // set when law firms details have been loaded
    useEffect(() => {
        if (lawFirmsLoaded) {
            dispatch(resetLawFirmsStatus());
        }
    }, [dispatch, lawFirmsLoaded]);

    // setting up cases for later use
    const cases = useSelector(selectAllCases);
    const casesStatus = useSelector(state => state.cases.status);    

    // setting up case managers for later use
    const caseManagers = useSelector(selectAllCaseManagers);
    const caseManagersStatus = useSelector(state => state.caseManagers.status);


    // setting up glucroft users for later use
    const glucroftUsers = useSelector(selectAllGlucroftUsers);
    const glucroftUsersStatus = useSelector(state => state.glucroftUsers.status);


    // setting up investigation types for later use
    const investigationTypes = useSelector(selectAllInvestigationTypes);
    const investigationTypesStatus = useSelector(state => state.investigationTypes.status);



    /**
     * request and download the report
     */
    const reportStatus = useSelector(state => state.report.status);
    const handleRunReport = async (values) => {
        var filterValues = values.filters.map((x,i) => (x.type.includes("Date") || x.type.includes("Edit")) ? x.filter.join() : x.filter);
        values.filters.map((x,i) => x.filter = filterValues[i]);
        var data = await dispatch(generateReport(values));
        var dataArr = base64ToArrayBuffer(data.payload);
        var date = new Date().toLocaleDateString();
        saveByteArray(date+"-REPORT.CSV", dataArr);
        closeFlyout();
    };

    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
           var ascii = binaryString.charCodeAt(i);
           bytes[i] = ascii;
        }
        return bytes;
     }

     function saveByteArray(reportName, byte) {
        var blob = new Blob([byte], {type: "text/csv"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    /**
     * sending the invite
     */

    /**
     * add another email input to form
     */
    const handleAddNew = arrayHelpers => {
        arrayHelpers.push({ type: '', filter: '' });
    };


    /**
     * setup the initial values & validation
     */
    const initialValues = {
        lawFirm: [],
        filters: [
            {
                type: '',
                filter: '',
            },
        ],
    };

    const validationSchema = Yup.object().shape({
        lawFirm: Yup.array()
            .min(1, "Required")
            .required('Required'),
        filters: Yup.array()
            .of(
                Yup.object().shape({
                    type: Yup.string()
                        .required('Required'),
                    filter: Yup.object().when('type', {
                        is: (val) => (val === "Submit Date" || val === "Last Edit"),
                        then: () => Yup.array()
                            .of(
                                Yup.string()
                                    .required('Required')
                                    .typeError('Start and end date required')
                            ),
                        otherwise: () => Yup.string()
                            .required('Required')
                    }),
                })
            )
            .required('Must have filters')
            .min(1, 'Minimum of 1 filter'),
    });


    /**
     * return the specific input based
     * on the filter type chosen
     */
    const getFilterInput = (filterType, values, index) => {
        switch (filterType) {
            //case 'Order':
            case 'Case Name':
                if (!!cases && casesStatus === 'idle' && cases.length === 0) {
                    dispatch(fetchCases());
                } else if (casesStatus === 'succeeded' && cases.length > 0) {              
                    dispatch(resetCasesStatus());
                }

                return (
                    <Field name={`filters.${index}.filter`} as="select">
                        <option value="" defaultValue>Select a case</option>
                        {selectedLawFirm != null && selectedLawFirm[0].firm != "All Firms" && selectedLawFirm.length > 0 ? (
                            cases.filter(x => x.orgId == selectedLawFirm[0].id).map((caseSummary, index) => {
                                return <option key={`case-manager-${index}`} value={caseSummary.id}>{`${caseSummary.caseName}`}</option>
                            })
                        ) : cases.map((caseSummary, index) => {
                            return <option key={`case-manager-${index}`} value={caseSummary.id}>{`${caseSummary.caseName}`}</option>
                        })}
                    </Field>
                );
            case 'Investigation Type':
                if (!!investigationTypes && investigationTypesStatus === 'idle' && investigationTypes.length === 0) {
                    dispatch(fetchInvestigationTypes());
                } else if (investigationTypesStatus === 'succeeded' && investigationTypes.length > 0) {
                    dispatch(resetInvestigationTypesStatus());
                }

                return (
                    <Field name={`filters.${index}.filter`} as="select">
                        <option value="" defaultValue>Select an investigation type</option>

                        {investigationTypes.map((investigationType, index) => {
                            return <option key={`investigation-type-${index}`} value={investigationType.name}>{investigationType.name}</option>
                        })}
                    </Field>
                );
            case 'Assigned G.I.':
                if (!!glucroftUsers && glucroftUsersStatus === 'idle' && glucroftUsers.length === 0) {
                    dispatch(fetchGlucroftUsers());
                } else if (glucroftUsersStatus === 'succeeded' && glucroftUsers.length > 0) {
                    dispatch(resetGlucroftUsersStatus());
                }

                return (
                    <Field name={`filters.${index}.filter`} as="select">
                        <option value="" defaultValue>Select a Glucroft investigator</option>

                        {glucroftUsers.map((glucroftUser, index) => {
                            return <option key={`glucroft-user-${index}`} value={glucroftUser.id}>{`${glucroftUser.firstName} ${glucroftUser.lastName}`}</option>
                        })}
                    </Field>
                );
            case 'Assigned Firm':
                if (!!caseManagers && caseManagersStatus === 'idle' && caseManagers.length === 0) {
                    dispatch(fetchCaseManagers());
                } else if (caseManagersStatus === 'succeeded' && caseManagers.length > 0) {              
                    dispatch(resetCaseManagersStatus());
                }

                return (
                    <Field name={`filters.${index}.filter`} as="select">
                        <option value="" defaultValue>Select a case manager</option>
                        {selectedLawFirm != null && selectedLawFirm.length > 0 ? (
                            caseManagers.filter(x => x.lawFirmId == selectedLawFirm[0].id).map((caseManager, index) => {
                                return <option key={`case-manager-${index}`} value={caseManager.id}>{`${caseManager.firstName} ${caseManager.lastName}`}</option>
                            })
                        ) : caseManagers.map((caseManager, index) => {
                            return <option key={`case-manager-${index}`} value={caseManager.id}>{`${caseManager.firstName} ${caseManager.lastName}`}</option>
                        })}
                    </Field>
                );
            case 'Status':
                return (
                    <Field name={`filters.${index}.filter`} as="select">
                        <option value="" defaultValue>Select a status</option>
                        <option value="New">New</option>
                        <option value="In Review">In Review</option>
                        <option value="Waiting on Client">Waiting on Client</option>
                        <option value="In Process">In Process</option>
                        <option value="Report Ordered">Report Ordered</option>
                        <option value="Report Recieved">Report Recieved</option>
                        <option value="Assigned">Assigned</option>
                        <option value="QA">QA</option>
                        <option value="Sent to Client">Sent to Client</option>
                        <option value="In Billing">In Billing</option>
                        <option value="Complete">Complete</option>
                    </Field>
                );
            case 'Submit Date':
                return (
                    <DatePickerInput name={`filters.${index}.filter`} placeholderText="Select date" selectsRange />
                );
            case 'Last Edit':
                return (
                    <DatePickerInput name={`filters.${index}.filter`} placeholderText="Select date" selectsRange />
                );
            default:
                break;
        }
    };


    return (
        <div className="flyout">
            {(lawFirmsStatus === 'loading' || reportStatus === 'loading' || caseManagersStatus === 'loading' || glucroftUsersStatus === 'loading' || investigationTypesStatus === 'loading') &&
                <div className="loader-container">
                    <BeatLoader color="#00818C" />
                </div>
            }

            <h2>Report Filters</h2>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleRunReport}
                enableReinitialize={true}
            >
                {({ errors, dirty, isValid, touched, values, resetForm, setFieldValue }) => (
                    <Form>
                        <div className="form-field-wrapper">
                            <Typeahead
                                id="lawFirm"
                                labelKey="firm"
                                onChange={(val) => {setFieldValue('lawFirm', val); setSelectedLawFirm(val);}}
                                options={lawFirmsWithAllOption}
                                placeholder="Select a firm"
                                selected={values.lawFirm}
                            />

                            {errors && errors.lawFirm && touched && touched.lawFirm ? (
                                <div className="form-error">{errors.lawFirm}</div>
                            ) : null}
                        </div>

                        <FieldArray
                            name="filters"
                            render={arrayHelpers => (
                                <div>
                                    {values.filters.map((filter, index) => (
                                        <div key={index} className="form-field-wrapper">
                                            <Field name={`filters.${index}.type`} as="select">
                                                <option value="" defaultValue>Select a filter</option>

                                                {/* <option value='Order'>Order</option> */}
                                                <option value='Case Name'>Case Name</option>
                                                <option value='Investigation Type'>Investigation Type</option>
                                                <option value='Assigned G.I.'>Assigned (G.I.)</option>
                                                {(values.lawFirm.length > 0 && values.lawFirm[0].firm !== "All Firms") && <option value='Assigned Firm'>Assigned (Firm)</option>}
                                                <option value='Status'>Status</option>
                                                <option value='Submit Date'>Submit Date</option>
                                                <option value='Last Edit'>Last Edit</option>
                                            </Field>

                                            {errors && errors.filters && errors.filters[index] && errors.filters[index].type && touched && touched.filters && touched.filters[index] && touched.filters[index].type ? (
                                                <div className="form-error">{errors.filters[index].type}</div>
                                            ) : null}

                                            {getFilterInput(values.filters[index].type, values, index)}

                                            {values.filters[index].type && errors && errors.filters && errors.filters[index] && errors.filters[index].filter && touched && touched.filters && touched.filters[index] && touched.filters[index].filter ? (
                                                <div className="form-error">{typeof errors.filters[index].filter === 'object' ? errors.filters[index].filter[0] : errors.filters[index].filter}</div>
                                            ) : null}
                                        </div>
                                    ))}

                                    <button
                                        type="button"
                                        className="btn-add"
                                        onClick={() => handleAddNew(arrayHelpers)}
                                    >
                                        <img src={IcoAdd} alt="+" /> Add Another
                                    </button>

                                    <div className="flyout-buttons">
                                        <button className="button tertiary" type="submit">Run Report</button>
                                        <button className="button outlined secondary" type="button" onClick={resetForm}>Clear Filters</button>
                                    </div>
                                </div>
                            )}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};


export default Reports;