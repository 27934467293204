import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import CustomLoader from './custom-loader';
import Accordion from 'react-bootstrap/Accordion';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import IcoArrow from '../assets/ico-arrow';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FilterMethods from '../utility/filter-methods';

const LawFirmsTable = ({ lawFirms, progressPending, lawFirmsAccordion }) => {
    const navigate = useNavigate();
    /**
     * setup law frims columns
     */
    const columns = [
        {
            name: <span className="custom-table-header" title="Firm">Firm</span>,
            selector: row => row.firm,
            cell: row => <Link to={`/admin/law-firm/${row.id}`}>{row.firm}</Link>,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Address">Address</span>,
            selector: row => row.address,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Phone">Phone</span>,
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Primary Name">Primary Name</span>,
            selector: row => row.primaryName,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Primary Email">Primary Email</span>,
            selector: row => row.primaryEmail,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Primary Phone">Primary Phone</span>,
            selector: row => row.primaryPhone,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Billing Name">Billing Name</span>,
            selector: row => row.billingName,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Billing Email">Billing Email</span>,
            selector: row => row.billingEmail,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Billing Phone">Billing Phone</span>,
            selector: row => row.billingPhone,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Referred By">Referred By</span>,
            selector: row => row.referredBy,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Status">Status</span>,
            selector: row => row.status,
            sortable: true,
        },
    ];

    const paginationComponentOptions = {
        noRowsPerPage: true,
    };

    // law firm
    const addNewLawFirm = () => {
        navigate('/admin/law-firm');
    };

    /**
     * handle filters functionality
     */
    const [appliedFilters, setApplyFilters] = useState([]);    
    const [filters, setFilters] = useState([]);
    const [displayedFilters, setDisplayedFilters] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);

    const handleApplyFilters = (e)  => {
        e.preventDefault();
        setDisplayedFilters(FilterMethods.concatFilters(filters));
        setApplyFilters(filters);
        setShowDropdown(false);
    }

    const handleClearFilters = (e) => {
        e.preventDefault();
        FilterMethods.clearFilters();
        setFilters([]);
        setApplyFilters([]);
        setDisplayedFilters("");
        setShowDropdown(false);
    };

    return (
        <Accordion.Item eventKey="0">
        <div className="page-header">
            <Accordion.Header as="div">
                <div className="page-header-title">
                    <h2 className={`mb-0 ${lawFirmsAccordion ? 'active' : ''}`}>Law Firms</h2>
                </div>
            </Accordion.Header>
            {lawFirmsAccordion &&
                <div className="page-header-title-filters">{displayedFilters}</div>
            }
            {lawFirmsAccordion &&
                <div className="page-header-actions">
                    <Dropdown className="filter" align="end" show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
                        <Dropdown.Toggle id="dropdown-filter" className="button primary filter">
                            Filter <IcoArrow />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Header className="center">
                                <button className="button primary" onClick={handleApplyFilters}>Apply</button>
                                <button className="button outlined primary" onClick={handleClearFilters}>Clear</button>
                            </Dropdown.Header>

                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Law Firm Name</Accordion.Header>
                                    <Accordion.Body>
                                        {[...new Set(lawFirms.map(data => data.firm))].map((firm, i) => (
                                            <Form.Check type={'checkbox'} name={`firm`} id={`lf${i}`}>
                                                <Form.Check.Input type={'checkbox'} onClick= {e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "firm", filters))}/>
                                                <Form.Check.Label id={`lf${i}-label`}>{firm}</Form.Check.Label>
                                           </Form.Check>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Status</Accordion.Header>
                                    <Accordion.Body>
                                        {[...new Set(lawFirms.map(data => data.status))].map((status, i) => (
                                            <Form.Check type={'checkbox'} name={`status`} id={`s${i}`}>
                                                <Form.Check.Input type={'checkbox'} onClick= {e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "status", filters))}/>
                                                <Form.Check.Label id={`s${i}-label`}>{status}</Form.Check.Label>
                                            </Form.Check>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Dropdown.Menu>
                    </Dropdown>
                    <button className="button secondary" type="button" onClick={addNewLawFirm}>+ Add New</button>
                </div>
            }
            </div>
            <Accordion.Body>
                <div className="page-body mt-4">
                <div className="custom-table">
                    <DataTable
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        columns={columns}
                        data={FilterMethods.filteredData(lawFirms, appliedFilters)}
                        progressPending={progressPending}
                        progressComponent={<CustomLoader />}
                    />
                </div>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
};

export default LawFirmsTable;