import * as Yup from 'yup';


const AssetLocateDetailsPersonSchema = Yup.object().shape({
    assetLocateDetails: Yup.object().shape({
        firstName: Yup.string()
            .required('Required'),
        lastName: Yup.string()
            .required('Required'),
        address: Yup.string()
            .required('Required'),
        city: Yup.string()
            .required('Required'),
        stateProvince: Yup.string()
            .required('Required'),
        zip: Yup.string()
            .required('Required'),
        country: Yup.string()
            .required('Required'),
        dob: Yup.string()
            .required('Required'),
    })
});

export default AssetLocateDetailsPersonSchema;