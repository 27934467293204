import BaseAPI from '../utility/fetch-wrapper';

class RoleServices {
    static GetRoles = async successFunc => {
        const data = await BaseAPI.getRequest(`misc/permissions`);
        return data;
    };

    static UpdateNotePermissionLevel = async (payload) => {
        const data = await BaseAPI.putRequest(`users/noteaccess`, payload);
        return data;
    }

    static UpdateAttachmentPermissionLevel = async (payload) => {
        const data = await BaseAPI.putRequest(`users/attachmentaccess`, payload);
        return data;
    }
}

export default RoleServices;