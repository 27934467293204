import { lawFirms, lawFirm123 } from '../placeholder-data';
import FormService from '../services/form-service';
import BaseAPI from '../utility/fetch-wrapper';

class LawFirmsServices {
    static GetLawFirms = async successFunc => {
        const data = await BaseAPI.getRequest(`organizations`);
        return data;
    };

    static GetLawFirmById = async payload => {
        const data = await BaseAPI.getRequest(`organizations/${payload}`);
        return data;
    };

    static UpdateLawFirm = async payload => {
        const data = await BaseAPI.putRequest(`organizations/${payload.id}`, payload)
        return data;
    };

    static CreateLawFirm = async payload => {
        const data = await BaseAPI.postRequest(`organizations`, payload)
        return data;
    };
    
    static SendInvite = async payload => {

        const emailRequest =
        {
            //Hard coded email right now
            emailRecipients : payload.emails,
            subjectLine : "Glucroft Order Invitation",
            emailBody : `Please use the link below to start an investigation order. ${window.location.origin}/intake?firm=${payload.lawFirm[0].id}`
        };
        return await FormService.SendEmail(emailRequest);
    };
}


export default LawFirmsServices;