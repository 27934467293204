import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import CustomLoader from './custom-loader';
import Accordion from 'react-bootstrap/Accordion';
import Dropdown from 'react-bootstrap/Dropdown';
// import Form from 'react-bootstrap/Form';
import BootstrapForm from "react-bootstrap/Form"
import { Formik, Field, Form } from "formik";
import IcoArrow from '../assets/ico-arrow';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import FilterMethods from '../utility/filter-methods';
import { FormCheck, Modal } from 'react-bootstrap';
import { updateNotePermissionLevel, selectAllRoles, resetRolesStatus, fetchRoles, updateAttachmentPermissionLevel }  from '../reducers/roles-slice';
import { useDispatch, useSelector } from "react-redux";

const RolesTable = ({ roles, progressPending, rolesInitialValues, rolesAccordion }) => {
    const dispatch = useDispatch();
    const roleStatus = useSelector(state => state.roles.status);
    const [showResultModal, setResultModal] = useState(false);

    const handleShowResultModal = () => {
        setResultModal(!showResultModal);
    };

    const handleHideShowResultModal = () => {
        setResultModal(false);
        dispatch(resetRolesStatus());
    };

    const SaveChanges = async (values) => {
        await dispatch(updateAttachmentPermissionLevel(values));
        await dispatch(updateNotePermissionLevel(values));
        handleShowResultModal();
    }

    const handleChange = (event, setFieldValue) => {
        const name = event.target.name;
        setFieldValue(event.target.name, );
      };

    return (
        <Formik
            onSubmit={SaveChanges}
            initialValues={ rolesInitialValues }
            enableReinitialize={true}
            // validator={() => ({})}
        >
        {({ errors, dirty, isValid, touched, values, setFieldValue, submitForm }) => (
            <Form>
                <Accordion.Item eventKey="2">
                    <div className="page-header" as="div">
                        <Accordion.Header>
                            <div className="page-header-title">
                                <h2 className={`mb-0 ${rolesAccordion ? 'active' : ''}`}>Roles</h2>
                            </div>
                        </Accordion.Header>
                        {rolesAccordion &&
                            <div className="page-header-actions">
                                <button className="form-submit" type="submit">Save Changes</button>
                            </div>
                        }
                    </div>
                    <Accordion.Body>
                        {roles.length > 0 &&
                            <div className="page-body mt-4">
                                {roles.map((role,i) => (
                                    <div role="group" name={role.title} id={`${role.id}`} key={`r${i}`} className="form-check-inline">
                                        <div id={`p${i}-label`}>{role.title}</div>
                                        <div className="form-check">
                                            Internal Notes
                                            <Field 
                                                style={{"marginLeft":"10px"}}
                                                type="radio"
                                                id={`f_InternalNotes-${role.id}`}
                                                name={`f_InternalNotes-${role.id}`}
                                                value={`f_InternalNotes-${role.id}`}
                                                onClick={event => handleChange(event, setFieldValue)} // Pass setFieldValue here
                                            />
                                            External Notes
                                            <Field 
                                                style={{"marginLeft":"10px"}}
                                                type="radio" 
                                                id={`f_ExternalNotes-${role.id}`}
                                                name={`f_ExternalNotes-${role.id}`} 
                                                value={`f_ExternalNotes-${role.id}`}
                                                onClick={event => handleChange(event, setFieldValue)} // Pass setFieldValue here
                                            />
                                            Internal Attachments
                                            <Field 
                                                type="radio" 
                                                id={`f_InternalAttachments-${role.id}`}
                                                name={`f_InternalAttachments-${role.id}`} 
                                                value={`f_InternalAttachments-${role.id}`}
                                                onClick={event => handleChange(event, setFieldValue)} // Pass setFieldValue here
                                            />
                                            External Attachments
                                            <Field 
                                                type="radio" 
                                                id={`f_ExternalAttachments-${role.id}`}
                                                name={`f_ExternalAttachments-${role.id}`} 
                                                value={`f_ExternalAttachments-${role.id}`}
                                                onClick={event => handleChange(event, setFieldValue)} // Pass setFieldValue here
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </Accordion.Body>
                </Accordion.Item>

                {showResultModal &&
                    <Modal
                        backdrop="static"
                        show={showResultModal}
                        onHide={handleHideShowResultModal}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{roleStatus == 'succeeded' ? 'Roles Updated' : 'Failed to Update Roles'}</Modal.Title>
                        </Modal.Header>
                    </Modal>
            }
            </Form>
        )}
        </Formik>
    );
};

export default RolesTable;