import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CaseManagersServices from '../services/case-managers-service';


export const fetchCaseManagerById = createAsyncThunk('caseManagerDetails/fetchCaseManagerById', async (payload) => {
    const response = await CaseManagersServices.GetCaseManagerById(payload);
    return response;
});

export const updateCaseManager = createAsyncThunk('caseManagerDetails/updateCaseManager', async (payload) => {
    const response = await CaseManagersServices.UpdateCaseManager(payload);
    return response;
});

export const createCaseManager = createAsyncThunk('caseManagerDetails/createCaseManager', async (payload) => {
    const response = await CaseManagersServices.CreateCaseManager(payload);
    return response;
});

export const caseManagerDetailsSlice = createSlice({
    name: 'caseManagerDetails',

    initialState: {
        caseManagerDetails: {},
        status: 'idle',
        error: null,
    },

    reducers: {
        resetCaseManagerDetailsStatus: (state) => {
            state.status = 'idle';
        },
    },

    extraReducers: {
        [fetchCaseManagerById.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCaseManagerById.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.caseManagerDetails = action.payload;
        },
        [fetchCaseManagerById.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [updateCaseManager.pending]: (state, action) => {
            state.status = 'loading';
        },
        [updateCaseManager.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.caseManagerDetails = action.payload;
        },
        [updateCaseManager.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetCaseManagerDetailsStatus,
} = caseManagerDetailsSlice.actions;

export default caseManagerDetailsSlice.reducer;


export const selectCaseManager = state => {
    return state.caseManagerDetails.caseManagerDetails;
};