import * as Yup from 'yup';


const InsuranceInvestigationPersonalHomeownersRentersAdditionalInformationSchema = Yup.object().shape({
    insuranceInvestigationPersonalHomeownersRentersAdditionalInformation: Yup.object().shape({
        letterOfAcknowledgement: Yup.string()
            .required('Required'),
    })
});

export default InsuranceInvestigationPersonalHomeownersRentersAdditionalInformationSchema;