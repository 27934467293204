import * as Yup from 'yup';


const InvestigationTypeSchema = Yup.object().shape({
    investigation: Yup.object().shape({
        investigationType: Yup.string()
            .required('Required'),
        firstNameClient: Yup.string()
            .required('Required'),
        lastNameClient: Yup.string()
            .required('Required'),
    })
});

export default InvestigationTypeSchema;