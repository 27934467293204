import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OrdersServices from '../services/orders-service';


export const fetchOrders = createAsyncThunk('orders/fetchOrders', async () => {
        const data = await OrdersServices.GetOrders()
        return data;      
    });

export const updateStatus = createAsyncThunk('orders/updatestatus', async (payload) => {
    const data = await OrdersServices.UpdateStatus(payload);
    return data;
});

export const updateAssignedUser = createAsyncThunk('orders/updateAssignedUser', async (payload) => {
    const data = await OrdersServices.AssignGlucroftUser(payload);
    return data;
});

const initialState = {
    orders: [],
    status: 'idle',
    error: null  
}

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.status = 'idle';
        },
        resetOrders: () => initialState
    },


    extraReducers: {
        [fetchOrders.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchOrders.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.orders = state.orders.concat(action.payload);
        },
        [fetchOrders.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    }
});



export const {
    resetStatus,
    resetOrders,
} = ordersSlice.actions;

export default ordersSlice.reducer;


export const selectAllOrders = state => {
    return state.orders.orders;
};