import { Form, Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from "../../../components/form-footer";
import { selectActiveFormWitnessDefendantStatementDetails, selectActiveFormWitnessDefendantStatementWitnessDetails, updateWitnessDefendantStatementWitnessDetails } from "../../../reducers/active-form-slice";
import WitnessDefendantStatementWitnessDetailsSchema from "../../../validation-schemas/witness-defendant-statement-witness-details";
import IntakeWitnessDefendantStatementWitnessDetailsFields from "../../../components/intake/witness-defendant-statement/witness-info-fields";


const IntakeWitnessDefendantStatementWitnessInfo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const witnessDefendantStatementDetails = useSelector(selectActiveFormWitnessDefendantStatementDetails);
    const witnessDefendantStatementWitnessDetails = useSelector(selectActiveFormWitnessDefendantStatementWitnessDetails);
    const hasValues = Object.keys(witnessDefendantStatementWitnessDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        switch (witnessDefendantStatementDetails.statementType) {
            case "Both":
                navigate("/intake/witness-defendant-statement/defendant-info");
                break;
            default:
                navigate("/intake/witness-defendant-statement/details");
                break;
        }
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateWitnessDefendantStatementWitnessDetails(values));

        navigate("/intake/witness-defendant-statement/fault-info");
    };


    /**
     * setup the initial values
     */
    const witnessInitialValue = {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        stateProvince: '',
        zip: '',
        county: '',
    };

    const initialValues = {
        witnesses: [
            witnessInitialValue
        ],
    };


    return (
        <div className="page intake">
            <h2>Witness AND/OR Defendant Statement Details</h2>

            <Formik
                initialValues={hasValues ? witnessDefendantStatementWitnessDetails : initialValues}
                validationSchema={WitnessDefendantStatementWitnessDetailsSchema.fields.witnessDefendantStatementWitnessDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeWitnessDefendantStatementWitnessDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                            initialValues={witnessInitialValue}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`40`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeWitnessDefendantStatementWitnessInfo;