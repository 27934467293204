import BaseAPI from '../utility/fetch-wrapper';


class GlucroftUsersServices {
    static GetGlucroftUsers = async successFunc => {
        const data = await BaseAPI.getRequest(`users/glucroftusers`);
        return data;
    };

    static GetGlucroftUserById = async payload => {
        const data = await BaseAPI.getRequest(`users/glucroftusers/${payload}`);
        return data;
    };

    static UpdateGlucroftUser = async (payload) => {
        const data = await BaseAPI.putRequest(`users/glucroftusers/${payload.id}`, payload)
        return data;
    };

    static CreateGlucroftUser = async payload => {
        const data = await BaseAPI.postRequest(`users/glucroftusers`, payload);
        return data;
    };

    
}


export default GlucroftUsersServices;