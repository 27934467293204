import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormFooter from '../../../components/form-footer';
import IntakeInsuranceClaimHistoryDetailsFields from '../../../components/intake/insurance-claim-history/details-fields';
import InsuranceClaimHistoryDetailsSchema from '../../../validation-schemas/insurance-claim-history-details-schema';
import { selectActiveFormInsuranceClaimHistoryDetails, updateActiveFormInsuranceClaimHistoryDetails, resetActiveFormClientInfo } from '../../../reducers/active-form-slice';


const IntakeInsuranceClaimHistoryDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    /**
     * get current form data
     */
    const insuranceClaimHistoryDetails = useSelector(selectActiveFormInsuranceClaimHistoryDetails);
    const hasValues = Object.keys(insuranceClaimHistoryDetails).length > 0 ? true : false;

    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {        
        dispatch(updateActiveFormInsuranceClaimHistoryDetails(values));
        navigate("/intake/insurance-claim-history/subject");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        insuranceClaimHistoryDetails: '',
    };


    return (
        <div className="page intake">
            <h2>Insurance Claim History Details</h2>

            <Formik
                initialValues={hasValues ? insuranceClaimHistoryDetails : initialValues}
                validationSchema={InsuranceClaimHistoryDetailsSchema.fields.insuranceClaimHistoryDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched }) => (
                    <Form>
                        <IntakeInsuranceClaimHistoryDetailsFields
                            errors={errors}
                            touched={touched}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`30`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInsuranceClaimHistoryDetails;