import LeftNav from "../left-nav";
import Header from "../header";
import Breadcrumbs from "../breadcrumbs";


const Error = () => {
    return (
        <div className='layout'>
            <LeftNav />

            <div className='layout-content'>
                <Header />

                <Breadcrumbs />

                <div className='layout-content-outlet'>
                    <h1 className="close-text">An error has occured.</h1>
                </div>
            </div>
        </div>
    );
}

export default Error;