import { Field } from "formik";
import { stateProvince } from '../../../../utility/state-province';
import { DatePickerInput } from '../../../../components/datepicker-input';
import StateProvinceDropdown from "../../../stateprovinceDropdown";


const IntakeAssetLocateDetailsPersonFields = ({ errors, touched, values, assetLocateType, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <h3>{assetLocateType}</h3>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="firstName">First Name <span className="required">*</span></label>
                    <Field id="firstName" name={nested ? 'assetLocateDetails.firstName' : 'firstName'} />

                    {errors && errors.firstName && touched && touched.firstName ? (
                        <div className="form-error">{errors.firstName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastName">Last Name <span className="required">*</span></label>
                    <Field id="lastName" name={nested ? 'assetLocateDetails.lastName' : 'lastName'} />

                    {errors && errors.lastName && touched && touched.lastName ? (
                        <div className="form-error">{errors.lastName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address">Address <span className="required">*</span></label>
                    <Field id="address" name={nested ? 'assetLocateDetails.address' : 'address'} />

                    {errors && errors.address && touched && touched.address ? (
                        <div className="form-error">{errors.address}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address2">Address 2</label>
                    <Field id="address2" name={nested ? 'assetLocateDetails.address2' : 'address2'} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="city">City <span className="required">*</span></label>
                    <Field id="city" name={nested ? 'assetLocateDetails.city' : 'city'} />

                    {errors && errors.city && touched && touched.city ? (
                        <div className="form-error">{errors.city}</div>
                    ) : null}
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? 'assetLocateDetails.stateProvince' : 'stateProvince'} id="stateProvince" disabled={false} required={true}>
                    {errors && errors.stateProvince && touched && touched.stateProvince ? (
                            <div className="form-error">{errors.stateProvince}</div>
                        ) : null}
                </StateProvinceDropdown>


                <div className="form-field-wrapper">
                    <label htmlFor="zip">Zip <span className="required">*</span></label>
                    <Field id="zip" name={nested ? 'assetLocateDetails.zip' : 'zip'} />

                    {errors && errors.zip && touched && touched.zip ? (
                        <div className="form-error">{errors.zip}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="country">Country <span className="required">*</span></label>
                    <Field id="country" name={nested ? 'assetLocateDetails.country' : 'country'} />

                    {errors && errors.country && touched && touched.country ? (
                        <div className="form-error">{errors.country}</div>
                    ) : null}
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="ssn">Social Security #</label>
                    <Field id="ssn" name="ssn" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="dob">Date of Birth <span className="required">*</span></label>
                    <DatePickerInput name={nested ? 'assetLocateDetails.dob' : 'dob'} placeholderText="Select date" />

                    {errors && errors.dob && touched && touched.dob ? (
                        <div className="form-error">{errors.dob}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default IntakeAssetLocateDetailsPersonFields;