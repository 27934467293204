import { useEffect, useState } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import BeatLoader from 'react-spinners/BeatLoader';
import { Typeahead } from 'react-bootstrap-typeahead';
import IcoAdd from '../assets/ico-add.svg';
import { fetchLawFirms, resetLawFirmsStatus, selectAllLawFirms } from "../reducers/law-firms-slice";
import { sendInvite } from "../reducers/link-invite-slice";


const LinkShare = ({ closeFlyout }) => {
    const dispatch = useDispatch();


    /**
     * fetch law firms
     */
    const [lawFirmsLoaded, setLawFirmsLoaded] = useState(false);
    const lawFirms = useSelector(selectAllLawFirms);
    const lawFirmsStatus = useSelector(state => state.lawFirms.status);

    // fetch law firms on each initial render
    useEffect(() => {
        if (lawFirmsStatus === 'idle' && !lawFirmsLoaded) {
            dispatch(fetchLawFirms());
        } else if (lawFirmsStatus === 'succeeded' && !lawFirmsLoaded) {
            // anything that needs to happen AFTER 
            // the data has loaded (e.g. removing loading indicator)
            setLawFirmsLoaded(true);
        }
    }, [dispatch, lawFirmsStatus, lawFirmsLoaded]);

    // set when law firms details have been loaded
    useEffect(() => {
        if (lawFirmsLoaded) {
            dispatch(resetLawFirmsStatus());
        }
    }, [dispatch, lawFirmsLoaded]);


    /**
     * sending the invite
     */
    const linkInviteStatus = useSelector(state => state.linkInvite.status);
    const handleSendInvite = async (values) => {
        await dispatch(sendInvite(values));
        closeFlyout();
    };


    /**
     * add another email input to form
     */
    const handleAddNew = arrayHelpers => {
        arrayHelpers.push('');
    };


    /**
     * setup the initial values & validation
     */
    const initialValues = {
        lawFirm: [],
        emails: [''],
    };

    const validationSchema = Yup.object().shape({
        lawFirm: Yup.array()
            .min(1, "Required")
            .required('Required'),
        emails: Yup.array()
            .of(
                Yup.string()
                    .email('Invalid email')
                    .required('Required')
            )
    });


    return (
        <div className="flyout">
            {(lawFirmsStatus === 'loading' || linkInviteStatus === 'loading') &&
                <div className="loader-container">
                    <BeatLoader color="#00818C" />
                </div>
            }

            <h2>Link to Invite</h2>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSendInvite}
                enableReinitialize={true}
            >
                {({ errors, dirty, isValid, touched, values, resetForm, setFieldValue }) => (
                    <Form>
                        <div className="form-field-wrapper">
                            <Typeahead
                                id="lawFirm"
                                labelKey="firm"
                                onChange={(val) => setFieldValue('lawFirm', val)}
                                options={lawFirms}
                                placeholder="Select a firm"
                                selected={values.lawFirm}
                            />

                            {errors && errors.lawFirm && touched && touched.lawFirm ? (
                                <div className="form-error">{errors.lawFirm}</div>
                            ) : null}
                        </div>

                        <FieldArray
                            name="emails"
                            render={arrayHelpers => (
                                <div>
                                    {values.emails.map((email, index) => (
                                        <div key={index} className="form-field-wrapper">
                                            <Field name={`emails.${index}`} placeholder="Email" />

                                            {errors && errors.emails && errors.emails[index] && touched && touched.emails && touched.emails[index] ? (
                                                <div className="form-error">{errors.emails[index]}</div>
                                            ) : null}
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        className="btn-add"
                                        onClick={() => handleAddNew(arrayHelpers)}
                                    >
                                        <img src={IcoAdd} alt="+" /> Add Another
                                    </button>

                                    <div className="flyout-buttons">
                                        <button className="button tertiary" type="submit">Send Invite</button>
                                        <button className="button outlined secondary" type="button" onClick={resetForm}>Clear</button>
                                    </div>
                                </div>
                            )}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};


export default LinkShare;