//import { orders, order3452 } from '../placeholder-data';
import BaseAPI from '../utility/fetch-wrapper';
 
class OrdersServices {
    static GetOrders = async successFunc => {
        const data = await BaseAPI.getRequest(`orders`);
        return data;
    };

    static GetNotifications = async successFunc => {
        const data = await BaseAPI.getRequest(`orders/notifications`);
        return data;
    };

    static GetAllNotifications = async (payload) => {
        const data = await BaseAPI.getRequest(`orders/GetAllNotifications?guid=${payload}`);
        return data;
    };

    static GetOrderById = async (payload) => {
        const data = await BaseAPI.getRequest(`orders/${payload}`);
        return data;
    };

    static UpdateOrder = async (payload) => {
        const data = await BaseAPI.putRequest(`orders/${payload.order.id}`, payload);
        return data;
    }

    static UpdateStatus = async (payload) => {
        const data = await BaseAPI.putRequest(`orders/updatestatus/${payload.status}`, payload.orderIds);
        return data;
    }

    static AssignGlucroftUser = async (payload) => {
        const data = await BaseAPI.putRequest(`orders/updateassigneduser/${payload.user}`, payload.orderIds);
        return data;
    };

    static GetGlucroftUsersForAssignment = async (payload) => {
        const data = await BaseAPI.postRequest(`orders/usersforassignment`, payload);
        return data
    }

    static SubmitToBilling = async (payload) => {
        const data = await BaseAPI.postRequest(`sendgrid/sendtobilling`, payload);
        return data;
    }

    static GetNotified = async payload => {
        const data = await BaseAPI.getRequest(`orders/GetUserIsNotified?guid=${payload}`);
        return data;
    }

    static DeleteNotification = async (payload) => {
        const data = await BaseAPI.postRequest(`orders/DeleteNotification?guid=${payload}`);
        return data;
    };

    static PostNewNotificationFromExisting = async (payload) => {
        const data = await BaseAPI.postRequest(`orders/PostNewNotificationFromExisting`, payload);
        return data;
    };

    static ChangeAttachmentTypeExternal = async payload => {
  
        const data = await BaseAPI.postRequest(`orders/ChangeAttachmentType?external=true&fileid=${payload.fileid}&orderId=${payload.orderId}`);
        return data;
    }

    static ChangeAttachmentTypeInternal = async payload => {
        const data = await BaseAPI.postRequest(`orders/ChangeAttachmentType?external=false&fileid=${payload.fileid}&orderId=${payload.orderId}`);
        return data;
    }

    static ChangeNoteType = async payload => {
        const data = await BaseAPI.postRequest(`orders/ChangeNoteType?noteId=${payload}`);
    }

}

export default OrdersServices;