import { mapNamesToLabels } from './map-fieldsNames-labels';
class ParseOrderDetailsChangeLog {

    /**
     * This function is for identifying the differences if the "different" key has an array or object as the value
     */
    static differenceHelper =  (orgDifference, upDifference, originalOrderDetailsObject, updatedOrderDetailsObject, str) => {
        if (Array.isArray(orgDifference))
        {
            for (var i = 0; i < orgDifference.length; i++)
            {
                str = this.differenceHelper(orgDifference[i], upDifference[i], orgDifference, upDifference, str)
            }

        }
        else if (typeof orgDifference === 'object')
        {
            Object.keys(orgDifference).forEach(e => {
                str = this.differenceHelper(orgDifference[e], upDifference[e], e, e, str);
            });
        }
        else if (orgDifference != upDifference)
        {
            const label = mapNamesToLabels(originalOrderDetailsObject);
            str = str + label + ", "
        }

        return str;
    }

    /**
     * This function is for identifying objects and array inside the details
     */
    static keyHelper = (originalOrderDetailsObject, key, str) => {
        // check if key is array
        if (Array.isArray(key))
        {
            key.forEach(s => {
                str = this.keyHelper(key, s, str);
            });
        }
        // check if key is an object
        else if (key != null && typeof key === 'object')
        {
            Object.keys(key).forEach(e => {
                str = this.keyHelper(originalOrderDetailsObject, key[e], str);
            });
        }
        // otherwise just add to string
        else {
            str = str + key + ","
        }
        
        
        return str;
    }

    static parsedOrderDetailsChangeLog = (originalOrderDetails, updatedOrderDetails) => {
        let originalOrderDetailsObject = JSON.parse(originalOrderDetails);
        let originalOrderDetailsObjectCopy = JSON.parse(originalOrderDetails);
        let updatedOrderDetailsObject = JSON.parse(updatedOrderDetails);
        let updatedOrderDetailsObjectCopy = JSON.parse(updatedOrderDetails);

        /**
         * Original details
         */
        Object.keys(originalOrderDetailsObject).forEach(e => {
            let str = "";
            str = this.keyHelper(originalOrderDetailsObject, originalOrderDetailsObject[e], str);
            if (str.length > 1)
            {
                str = str.substring(0, str.length-1);
            }
            originalOrderDetailsObject[e] = str;
            
        })

        /**
         * Updated details
         */
        Object.keys(updatedOrderDetailsObject).forEach(e => {

            let str = "";
            str = this.keyHelper(updatedOrderDetailsObject, updatedOrderDetailsObject[e], str);
            if (str.length > 1)
            {
                str = str.substring(0, str.length-1);
            }
            updatedOrderDetailsObject[e] = str;
        })

        var differences = Object.keys(originalOrderDetailsObject).filter(k => originalOrderDetailsObject[k] !== updatedOrderDetailsObject[k])
        var tempArr = [];
        // this is to check if the "difference" key is actually an array or object
        differences.forEach(x => {
            if (Array.isArray(originalOrderDetailsObjectCopy[x]) || typeof originalOrderDetailsObjectCopy[x] === 'object')
            {
                // if it is, then get the specific field 
                var str = "";
                str = this.differenceHelper(originalOrderDetailsObjectCopy[x], updatedOrderDetailsObjectCopy[x], originalOrderDetailsObjectCopy, updatedOrderDetailsObjectCopy, str);
                if (str.length > 1)
                {
                    str = str.substring(0, str.length-2);
                    let regexp = /^[A-Z]/;
                    // capitalize first letter
                    if (!regexp.test(x))
                    {
                        x = x.charAt(0).toUpperCase() + x.slice(1);
                    }
                    if (x === 'Witnesses')
                    {
                        x = 'Witness\'s'
                    }
                    if (x === 'Defendants')
                    {
                        x = 'Defendant\'s'
                    }
                    tempArr.push(x + ": " + str);

                }
            }
            else 
            {
                tempArr.push(x);
            }
        });
        return tempArr;

    };

    // static difference(object, base) {
    //     function changes(object, base) {
    //         return _.transform(object, function(result, value, key) {
    //             if (!_.isEqual(value, base[key])) {
    //                 result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
    //             }
    //         });
    //     }
    //     return changes(object, base);
    // }
};

export default ParseOrderDetailsChangeLog;