import { Fragment } from "react";
import { Field, FieldArray } from "formik";
import { DatePickerInput } from "../../datepicker-input";
import { stateProvince } from "../../../utility/state-province";
import StateProvinceDropdown from "../../stateprovinceDropdown";


const IntakeWitnessDefendantStatementWitnessDetailsFields = ({ errors, touched, values, nested, initialValues }) => {
    /**
     * add another witness to form
     */
    const handleAddNew = arrayHelpers => {
        arrayHelpers.push(initialValues);
    };


    /**
     * remove witness
     */
    const handleRemove = (arrayHelpers, index) => {
        arrayHelpers.remove(index)
    };


    return (
        <div className="form-wrapper general-information">
            <h3>Witness Information <small>* Fill with N/A if not applicable on required fields</small></h3>

            <FieldArray
                name={nested ? 'witnessDefendantStatementWitnessDetails.witnesses' : 'witnesses'}
                render={arrayHelpers => (
                    <Fragment>
                        {values.witnesses.map((witness, index) => (
                            <div key={`defendant-${index}`} className="repeater">
                                <div className="form-row no-border">
                                    <div className="form-field-wrapper">
                                        <label htmlFor="firstName">First Name <span className="required">*</span></label>
                                        <Field id="firstName" name={nested ? `witnessDefendantStatementWitnessDetails.witnesses.${index}.firstName` : `witnesses.${index}.firstName`} />

                                        {errors && errors.witnesses && errors.witnesses[index] && errors.witnesses[index].firstName && touched && touched.witnesses && touched.witnesses[index] && touched.witnesses[index].firstName ? (
                                            <div className="form-error">{errors.witnesses[index].firstName}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="lastName">Last Name <span className="required">*</span></label>
                                        <Field id="lastName" name={nested ? `witnessDefendantStatementWitnessDetails.witnesses.${index}.lastName` : `witnesses.${index}.lastName`} />

                                        {errors && errors.witnesses && errors.witnesses[index] && errors.witnesses[index].lastName && touched && touched.witnesses && touched.witnesses[index] && touched.witnesses[index].lastName ? (
                                            <div className="form-error">{errors.witnesses[index].lastName}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="dateOfBirth">Date of Birth</label>
                                        <DatePickerInput name={nested ? `witnessDefendantStatementWitnessDetails.witnesses.${index}.dateOfBirth` : `witnesses.${index}.dateOfBirth`} placeholderText="Select date" />
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="phone">Phone # <span className="required">*</span></label>
                                        <Field id="phone" name={nested ? `witnessDefendantStatementWitnessDetails.witnesses.${index}.phone` : `witnesses.${index}.phone`} />

                                        {errors && errors.witnesses && errors.witnesses[index] && errors.witnesses[index].phone && touched && touched.witnesses && touched.witnesses[index] && touched.witnesses[index].phone ? (
                                            <div className="form-error">{errors.witnesses[index].phone}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="address1">Address 1</label>
                                        <Field id="address1" name={nested ? `witnessDefendantStatementWitnessDetails.witnesses.${index}.address1` : `witnesses.${index}.address1`} />
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="address2">Address 2</label>
                                        <Field id="address2" name={nested ? `witnessDefendantStatementWitnessDetails.witnesses.${index}.address2` : `witnesses.${index}.address2`} />
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="city">City</label>
                                        <Field id="city" name={nested ? `witnessDefendantStatementWitnessDetails.witnesses.${index}.city` : `witnesses.${index}.city`} />
                                    </div>
                                    
                                    <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? `witnessDefendantStatementWitnessDetails.witnesses.${index}.stateProvince` : `witnesses.${index}.stateProvince`} id="stateProvince"/>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="zip">Zip</label>
                                        <Field id="zip" name={nested ? `witnessDefendantStatementWitnessDetails.witnesses.${index}.zip` : `witnesses.${index}.zip`} />
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="county">Country</label>
                                        <Field id="county" name={nested ? `witnessDefendantStatementWitnessDetails.witnesses.${index}.county` : `witnesses.${index}.county`} />
                                    </div>
                                </div>


                                {index > 0 && <div className="buttons-right">
                                    <button
                                        type="button"
                                        className="button tertiary small"
                                        onClick={() => handleRemove(arrayHelpers, index)}
                                    >
                                        - Remove Witness
                                    </button>
                                </div>}
                            </div>
                        ))}

                        <div className="buttons-centered">
                            <button
                                type="button"
                                className="button tertiary"
                                onClick={() => handleAddNew(arrayHelpers)}
                            >
                                + Add Another Witness
                            </button>
                        </div>

                        {errors && errors.witnesses && typeof errors.witnesses === 'string' ? (
                            <div className="form-error">{errors.witnesses}</div>
                        ) : null}
                    </Fragment>
                )}
            />
        </div>
    );
};

export default IntakeWitnessDefendantStatementWitnessDetailsFields;