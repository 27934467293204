import * as Yup from 'yup';


const TrafficCollisionsReportRetrievalDetailsSchema = Yup.object().shape({
    trafficCollisionsReportRetrievalDetails: Yup.object().shape({
        policeDept: Yup.string()
            .required('Required'),
        dateOfLoss: Yup.string()
            .required('Required')
            .typeError('Required'),
        timeOfLoss: Yup.string()
            .required('Required')
            .typeError('Required'),
        lossLocation: Yup.string()
            .required('Required'),
        vehicle1: Yup.string()
            .required('Required'),
        files: Yup.array()
            .min(1, 'Required'),
    })
});

export default TrafficCollisionsReportRetrievalDetailsSchema;