import { selectPortalUser } from '../reducers/auth-slice';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';


const LoginRedirector = () => {

    const portalUser = useSelector(selectPortalUser);

    if (portalUser.status === "rejected") {

        return <Navigate to="/portal" />
    }
    if (portalUser && portalUser.userType === "glucroft" && portalUser.activeFeatures.includes("f_HomeOrders")) {

        return <Navigate to="/orders" />
    }
    else if (portalUser && portalUser.userType === "glucroft" && !portalUser.activeFeatures.includes("f_HomeOrders")) {

        return <Navigate to="/portal" />
    }
    else if (portalUser && portalUser.userType === "portal") {

        return <Navigate to="/portal" />
    }

    return null
}

export default LoginRedirector;