import * as Yup from 'yup';
import { phoneExpression } from '../utility/phone-expression';


const WitnessDefendantStatementDefendantDetailsSchema = Yup.object().shape({
    witnessDefendantStatementDefendantDetails: Yup.object().shape({
        defendants: Yup.array()
            .of(
                Yup.object().shape({
                    firstName: Yup.string()
                        .required('Required'),
                    lastName: Yup.string()
                        .required('Required'),
                    phone: Yup.string()
                        .matches(phoneExpression, 'Invalid phone number')
                        .required('Required'),
                    directionOfTravel: Yup.string()
                        .required('Required'),
                    streetOfTravel: Yup.string()
                        .required('Required'),
                    defendantVehicleDescription: Yup.string()
                        .required('Required'),
                })
            )
            .required('Must have at least one defendat')
            .min(1, 'Minimum of 1 defendant'),
    })
});


export default WitnessDefendantStatementDefendantDetailsSchema;