
class BaseAPI {

    static setToken = (newToken) => {

        localStorage.setItem('gip_token', newToken);

    }
    
    static getToken = () => {

        return localStorage.getItem('gip_token');

    }

    static handleResponse = (response) => {
        if (response.headers.has('gluinv-validUntil')) {
            localStorage.setItem('gluinv-validUntil', response.headers.get('gluinv-validUntil'));
            dispatchEvent(new Event('gluinv-validUntil'));
            return response.text().then(txt => {
                const data = txt && JSON.parse(txt);
                
                if (!response.ok) {
                    const error = data;
                    return Promise.reject(error);
                }

                return data;
            });            
        }
    }

    static get_API_BASE_URL = () => {
        if (window.location.host.indexOf('fd-integration') > -1)
            return process.env.REACT_APP_API_URL_INTEGRATION;
        else
            return process.env.REACT_APP_API_URL;
    }


    static getRequest = async (endpoint, method = 'GET', token='') => {

        let headers = {
            'Content-type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + ((token) ? token : this.getToken())
        };

        const request = new Request(`${this.get_API_BASE_URL() + endpoint}`, {
            //    credentials: "include",
            method: method,
            headers: headers
        });

        return await fetch(request).then(this.handleResponse);
    }

    static postRequest = async (endpoint, requestObject, token='') => {

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + ((token) ? token : this.getToken())
        };

        const request = new Request(`${this.get_API_BASE_URL() + endpoint}`, {
            //    credentials: "include",
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestObject)
        });

        return await fetch(request).then(this.handleResponse);
    }


    static putRequest = async (endpoint, requestObject, token='') => {

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + ((token) ? token : this.getToken())
        };

        const request = new Request(`${this.get_API_BASE_URL() + endpoint}`, {
            //    credentials: "include",
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(requestObject)
        });

        return await fetch(request).then(this.handleResponse);
    }


    static uploadFile = async (endpoint, requestObject) => {

        let headers = {
            'Accept': 'application/json',
      //      'Content-type': 'multipart/form-data'
        };

        const request = new Request(`${this.get_API_BASE_URL() + endpoint}`, {
            //    credentials: "include",
            method: 'POST',
            headers: headers,
            body: requestObject
        });

        return await fetch(request).then(this.handleResponse);
    }

    static requestReport = async (endpoint, requestObject) => {

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=utf-8'
        };

        const request = new Request(`${this.get_API_BASE_URL() + endpoint}`, {
            //    credentials: "include",
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestObject)
        });

        return await fetch(request).then(this.handleResponse);
    }

    static downloadFile = async (endpoint) => {
        var url = this.get_API_BASE_URL() + endpoint;
            var downloadWindow = window.open(url, '_self');
            if (downloadWindow != null) {
                downloadWindow.focus();
            }
    }
};

export default BaseAPI;