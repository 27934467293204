import * as Yup from 'yup';


const BusinessDueDiligenceDetailsSchema = Yup.object().shape({
    businessDueDiligenceDetails: Yup.object().shape({
        businessName: Yup.string()
            .required('Required'),
        ownersName: Yup.string()
            .required('Required'),
    })
});

export default BusinessDueDiligenceDetailsSchema;