import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAdUser } from '../../reducers/auth-slice';


const IntakeSuccess = () => {
    const navigate = useNavigate();
    const adUser = useSelector(selectAdUser);

    /**
     * handle the close button
     */
    const handleClose = () => {
        if (adUser && adUser.adUser) {
            navigate("/");    
        } else {
            window.location.href = "https://glucroftinvestigations.com/"
        }
    };


    /**
     * handle the add new
     */
    const handleAddNew = () => {
        navigate("/intake/general-information");
    };


    return (
        <div className="page">
            <div className="success">
                <div className="left">
                    <h2>Success!</h2>
                </div>
                <div className="right">
                    <button className="button primary" type="button" onClick={handleClose}>Close</button>
                    <button className="button tertiary" type="button" onClick={handleAddNew}>+ Add New Order</button>
                </div>
            </div>
            <div>
                <h3>We have received your submission; thank you! We will turn around your order as quickly as possible.</h3>
                <h5>Please Note: Although we can investigate policy coverage for USAA, we are now unable to guarantee the result is consistent with the date of loss.</h5>
            </div>
        </div>
    );
};

export default IntakeSuccess;