import * as Yup from 'yup';


const InsuranceInvestigationPersonalHomeownersRentersDetailsSchema = Yup.object().shape({
    insuranceInvestigationPersonalHomeownersRentersDetails: Yup.object().shape({
        insuredType: Yup.string()
            .required('Required'),
        insuranceCompany: Yup.string()
            .required('Required'),
        dateOfLoss: Yup.string()
            .required('Required')
            .typeError('Required')
    })
});

export default InsuranceInvestigationPersonalHomeownersRentersDetailsSchema;