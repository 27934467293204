import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FileUpload from '../services/files-upload-service';


export const uploadTrafficCollisionsReportRetrievalFiles = createAsyncThunk('trafficCollisionsReportRetrievalFiles/UploadTrafficCollisionsReportRetrievalFiles', async (payload) => {
    const response = await FileUpload.Upload(payload);
    return response.data;
});

export const deleteTrafficCollisionsReportRetrievalFiles = createAsyncThunk('trafficCollisionsReportRetrievalFiles/DeleteTrafficCollisionsReportRetrievalFiles', async (guid) => {
    const response = await FileUpload.Delete(guid);
    return response.data;
});

export const trafficCollisionsReportRetrievalFilesSlice = createSlice({
    name: 'trafficCollisionsReportRetrievalFiles',

    initialState: {
        trafficCollisionsReportRetrievalFiles: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetTrafficCollisionsReportRetrievalFilesStatus: (state) => {
            state.status = 'idle';
        },
        resetTrafficCollisionsReportRetrievalFiles: (state) => {
            state.trafficCollisionsReportRetrievalFiles = [];
        },
    },

    extraReducers: {
        [uploadTrafficCollisionsReportRetrievalFiles.pending]: (state, action) => {
            state.status = 'loading';
        },
        [uploadTrafficCollisionsReportRetrievalFiles.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.trafficCollisionsReportRetrievalFiles = state.trafficCollisionsReportRetrievalFiles.concat(action.payload);
        },
        [uploadTrafficCollisionsReportRetrievalFiles.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [deleteTrafficCollisionsReportRetrievalFiles.pending]: (state, action) => {
            state.status = 'loading';
        },
        [deleteTrafficCollisionsReportRetrievalFiles.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.trafficCollisionsReportRetrievalFiles = [];
        },
        [deleteTrafficCollisionsReportRetrievalFiles.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    }
});


export const {
    resetTrafficCollisionsReportRetrievalFilesStatus,
    resetTrafficCollisionsReportRetrievalFiles,
} = trafficCollisionsReportRetrievalFilesSlice.actions;


export default trafficCollisionsReportRetrievalFilesSlice.reducer;


export const selectAllTrafficCollisionsReportRetrievalFiles = state => {
    return state.trafficCollisionsReportRetrievalFiles;
};