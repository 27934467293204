import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../../../components/form-footer';
import IntakeInsuranceInvestigationPersonalAutoUmbrellaDetailsFields from '../../../../../components/intake/insurance-investigation/personal/auto-umbrella/details-fields';
import InsuranceInvestigationPersonalAutoUmbrellaDetailsSchema from '../../../../../validation-schemas/insurance-investigation-personal-auto-umbrella-details-schema';
import { updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaDetails, selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaDetails } from '../../../../../reducers/active-form-slice';


const IntakeInsuranceInvestigationPersonalAutoUmbrellaDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const insuranceInvestigationPersonalAutoUmbrella = useSelector(selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaDetails);
    const hasValues = Object.keys(insuranceInvestigationPersonalAutoUmbrella).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaDetails(values));
        navigate("/intake/insurance-investigation-personal-auto-umbrella/drivers-details");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        type: '',
        dateOfLoss: '',
        insuredName: '',
        insuredAddress: '',
        insuredAddress2: '',
        insuredCity: '',
        insuredStateProvince: '',
        insuredZip: '',
        insuredCountry: '',
        insuredDob: '',
        insuredSsn: '',
        insuredVin: '',
        insuredLicensePlate: '',
        ownersName: '',
        ownersAddress: '',
        ownersAddress2: '',
        ownersCity: '',
        ownersStateProvince: '',
        ownersZip: '',
        ownersCountry: '',
        ownerIsDriver: '',
        locateAddress: '',
        locateAddress2: '',
        locateCity: '',
        locateStateProvince: '',
        locateZip: '',
        locateCountry: ''
    };


    return (
        <div className="page intake">
            <h2>Insured's Details</h2>

            <Formik
                initialValues={hasValues ? insuranceInvestigationPersonalAutoUmbrella : initialValues}
                validationSchema={InsuranceInvestigationPersonalAutoUmbrellaDetailsSchema.fields.insuranceInvestigationPersonalAutoUmbrellaDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeInsuranceInvestigationPersonalAutoUmbrellaDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`25`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInsuranceInvestigationPersonalAutoUmbrellaDetails;