import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FileUpload from '../services/files-upload-service';


export const uploadAdditionalInformationFiles = createAsyncThunk('additionalInformationFiles/UploadAdditionalInformationFiles', async (payload) => {
    const response = await FileUpload.Upload(payload);
    return response.data;
});

export const deleteAdditionalInformationFiles = createAsyncThunk('additionalInformationFiles/DeleteAdditionalInformationFiles', async (payload) => {
    const response = await FileUpload.Upload(payload);
    return response.data;
});

export const additionalInformationFilesSlice = createSlice({
    name: 'additionalInformationFiles',

    initialState: {
        additionalInformationFiles: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetAdditionalInformationFilesStatus: (state) => {
            state.status = 'idle';
        },
        resetAdditionalInformationFiles: (state) => {
            state.additionalInformationFiles = [];
        },
    },

    extraReducers: {
        [uploadAdditionalInformationFiles.pending]: (state, action) => {
            state.status = 'loading';
        },
        [uploadAdditionalInformationFiles.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.additionalInformationFiles = state.additionalInformationFiles.concat(action.payload);
        },
        [uploadAdditionalInformationFiles.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [deleteAdditionalInformationFiles.pending]: (state, action) => {
            state.status = 'loading';
        },
        [deleteAdditionalInformationFiles.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.additionalInformationFiles = [];
        },
        [deleteAdditionalInformationFiles.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    }
});


export const {
    resetAdditionalInformationFilesStatus,
    resetAdditionalInformationFiles,
} = additionalInformationFilesSlice.actions;


export default additionalInformationFilesSlice.reducer;


export const selectAllAdditionalInformationFiles = state => {
    return state.additionalInformationFiles;
};