export const mapFieldIds = (name, details, index) => {
    switch (name) {
        case "orderNumber":
            return details.orderId;
        case "caseName":
            return details.caseId;
        case "managerAssigned":
            return details.managerAssignedId;
        case "glucroftAssigned":
            return details.glucroftAssignedId;
        case "lawFirm":
            return details.lawFirmId;
        default:
            return `${name}-${index}`;
    }
};