import { Fragment } from "react";
import { Field } from "formik";
import { stateProvince } from "../../../utility/state-province";
import { DatePickerInput } from "../../datepicker-input";
import StateProvinceDropdown from "../../stateprovinceDropdown";

const IntakeInsuranceClaimHistorySubjectFields = ({ errors, touched, values, nested }) => {
    return (
        <Fragment>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="firstName">First Name <span className="required">*</span></label>
                    <Field id="firstName" name={nested ? "insuranceClaimHistorySubject.firstName" : "firstName"} />
                    {errors && errors.firstName && touched && touched.firstName ? (
                        <div className="form-error">{errors.firstName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastName">Last Name <span className="required">*</span></label>
                    <Field id="lastName" name={nested ? "insuranceClaimHistorySubject.lastName" : "lastName"} />

                    {errors && errors.lastName && touched && touched.lastName ? (
                        <div className="form-error">{errors.lastName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="email">Email</label>
                    <Field id="email" name={nested ? "insuranceClaimHistorySubject.email" : "email"} type="email" />

                    {errors && errors.email && touched && touched.email ? (
                        <div className="form-error">{errors.email}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="phone">Phone #</label>
                    <Field id="phone" name={nested ? "insuranceClaimHistorySubject.phone" : "phone"} type="phone" />

                    {errors && errors.phone && touched && touched.phone ? (
                        <div className="form-error">{errors.phone}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address">Address</label>
                    <Field id="address" name={nested ? "insuranceClaimHistorySubject.address" : "address"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address2">Address 2</label>
                    <Field id="address2" name={nested ? "insuranceClaimHistorySubject.address2" : "address2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="city">City</label>
                    <Field id="city" name={nested ? "insuranceClaimHistorySubject.city" : "city"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "insuranceClaimHistorySubject.stateProvince" : "stateProvince"} id="stateProvince" disabled={false} />

                <div className="form-field-wrapper">
                    <label htmlFor="zip">Zip</label>
                    <Field id="zip" name={nested ? "insuranceClaimHistorySubject.zip" : "zip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="country">Country</label>
                    <Field id="country" name={nested ? "insuranceClaimHistorySubject.country" : "country"} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="dob">Date of Birth</label>
                    <DatePickerInput name={nested ? "insuranceClaimHistorySubject.dob" : "dob"} placeholderText="Select date" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="ssn">SSN</label>
                    <Field id="ssn" name={nested ? "insuranceClaimHistorySubject.ssn" : "ssn"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="vin">VIN</label>
                    <Field id="vin" name={nested ? "insuranceClaimHistorySubject.vin" : "vin"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="licensePlate">License Plate</label>
                    <Field id="licensePlate" name={nested ? "insuranceClaimHistorySubject.licensePlate" : "licensePlate"} />
                </div>
            </div>
        </Fragment>
    );
};

export default IntakeInsuranceClaimHistorySubjectFields;