import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import BeatLoader from 'react-spinners/BeatLoader';
import { fetchCaseManagers, resetCaseManagersStatus, selectAllCaseManagers } from '../../reducers/case-managers-slice';
import CaseManagersTable from '../../components/case-managers-table';
import { fetchLawFirms, resetLawFirmsStatus, selectAllLawFirms } from '../../reducers/law-firms-slice';
import LawFirmsTable from '../../components/law-firms-table';
import { fetchGlucroftUsers, resetGlucroftUsersStatus, selectAllGlucroftUsers } from '../../reducers/glucroft-users-slice';
import GlucroftUsersTable from '../../components/glucroft-users-table';
import RolesTable from '../../components/roles-table';
import { selectActiveFeatures } from '../../reducers/auth-slice';
import { fetchRoles, resetRolesStatus, selectAllRoles } from '../../reducers/roles-slice';
import { permissionLevels } from '../../utility/permission-levels-ids';

const Admin = () => {
    const dispatch = useDispatch();
    const [caseManagersAccordion, setCaseManagersAccordion] = useState(false);
    const [lawFirmsAccordion, setLawFirmsAccordion] = useState(false);
    const [glucroftUsersAccordion, setGlucroftUsersAccordion] = useState(false);
    const [rolesAccordion, setRolesAccordion] = useState(false);
    const activeFeatures = useSelector(selectActiveFeatures);

    /**
     * fetch case managers
     */
    const [caseManagersLoaded, setCaseManagersLoaded] = useState(false);
    const caseManagers = useSelector(selectAllCaseManagers);
    const caseManagersStatus = useSelector(state => state.caseManagers.status);

    // fetch case managers on each initial render
    useEffect(() => {
        if (caseManagersStatus === 'idle' && !caseManagersLoaded) {
            dispatch(fetchCaseManagers());
        } else if (caseManagersStatus === 'succeeded' && !caseManagersLoaded) {
            // anything that needs to happen AFTER 
            // the data has loaded (e.g. removing loading indicator)
            setCaseManagersLoaded(true);
        }
    }, [dispatch, caseManagersStatus, caseManagersLoaded]);

    // if a user has been updated, rerender
    useEffect(() => {
        if (caseManagersStatus === 'idle') {
            dispatch(fetchCaseManagers());
        } 
    }, [dispatch, caseManagersStatus]);

    // set when case managers details have been loaded
    useEffect(() => {
        if (caseManagersLoaded) {
            dispatch(resetCaseManagersStatus());
        }
    }, [dispatch, caseManagersLoaded]);

    
    /**
     * fetch law firms
     */
    const [lawFirmsLoaded, setLawFirmsLoaded] = useState(false);
    const lawFirms = useSelector(selectAllLawFirms);
    const lawFirmsStatus = useSelector(state => state.lawFirms.status);

    // fetch law firms on each initial render
    useEffect(() => {
        if (lawFirmsStatus === 'idle' && !lawFirmsLoaded) {
            dispatch(fetchLawFirms());
        } else if (lawFirmsStatus === 'succeeded' && !lawFirmsLoaded) {
            // anything that needs to happen AFTER 
            // the data has loaded (e.g. removing loading indicator)
            setLawFirmsLoaded(true);
        }
    }, [dispatch, lawFirmsStatus, lawFirmsLoaded]);

    // if a user has been updated, rerender
    useEffect(() => {
        if (lawFirmsStatus === 'idle') {
            dispatch(fetchLawFirms());
        } 
    }, [dispatch, lawFirmsStatus]);

    // set when law firms details have been loaded
    useEffect(() => {
        if (lawFirmsLoaded) {
            dispatch(resetLawFirmsStatus());
        }
    }, [dispatch, lawFirmsLoaded]);


    /**
     * fetch glucroft users
     */
    const [glucroftUsersLoaded, setGlucroftUsersLoaded] = useState(false);
    const glucroftUsers = useSelector(selectAllGlucroftUsers);
    const glucroftUsersStatus = useSelector(state => state.glucroftUsers.status);

    // fetch glucroft users on each initial render
    useEffect(() => {
        if (glucroftUsersStatus === 'idle' && !glucroftUsersLoaded) {
            dispatch(fetchGlucroftUsers());
        } else if (glucroftUsersStatus === 'succeeded' && !glucroftUsersLoaded) {
            // anything that needs to happen AFTER 
            // the data has loaded (e.g. removing loading indicator)
            setGlucroftUsersLoaded(true);
        }
    }, [dispatch, glucroftUsersStatus, glucroftUsersLoaded]);

    // if a user has been updated, rerender
    useEffect(() => {
        if (glucroftUsersStatus === 'idle') {
            dispatch(fetchGlucroftUsers());
        } 
    }, [dispatch, glucroftUsersStatus]);

    // set when glucroft users details have been loaded
    useEffect(() => {
        if (glucroftUsersLoaded) {
            dispatch(resetGlucroftUsersStatus());
        }
    }, [dispatch, glucroftUsersLoaded]);

    /**
     * fetch roles
     */
    const [rolesLoaded, setRolesLoaded] = useState(false);
    const roles = useSelector(selectAllRoles);
    const [rolesInitialValues, setRolesInitialValues] = useState({});
    const rolesStatus = useSelector(state => state.roles.status);

    // fetch roles on each initial render
    useEffect(() => {
        if (rolesStatus === 'idle' && !rolesLoaded) {
            dispatch(fetchRoles());
        } else if (rolesStatus === 'succeeded' && !rolesLoaded) {
            // anything that needs to happen AFTER 
            // the data has loaded (e.g. removing loading indicator)
            var permissions = {};
            roles.forEach((role,i) => {
                var internalNotePropName = `f_InternalNotes-${role.id}`;
                var externalNotePropName = `f_ExternalNotes-${role.id}`;
                var internalAttachmentPropName = `f_InternalAttachments-${role.id}`;
                var externalAttachmentPropName = `f_ExternalAttachments-${role.id}`;


                if(role.useInternalNotes)
                    permissions[internalNotePropName] = internalNotePropName;
                if(role.useExternalNotes)
                    permissions[externalNotePropName] = externalNotePropName;
                if(role.useInternalAttachments)
                    permissions[internalAttachmentPropName] = internalAttachmentPropName;
                if(role.useExternalAttachments)
                    permissions[externalAttachmentPropName] = externalAttachmentPropName;
            });
            setRolesInitialValues(permissions);
            setRolesLoaded(true);
        }
    }, [dispatch, rolesStatus, rolesLoaded]);

    // set when roles have been loaded
    useEffect(() => {
        if (rolesLoaded) {
            dispatch(resetRolesStatus());
        }
    }, [dispatch, rolesLoaded]);

    return (
        <Fragment>
            {activeFeatures.includes('f_Admin_CaseMgr') &&
                <div className="page mb-4">
                    {(caseManagersStatus === 'loading') &&
                        <div className="loader-container">
                            <BeatLoader color="#00818C" />
                        </div>
                    }

                    <Accordion onSelect={(e) => { setCaseManagersAccordion(!!e ? true : false) }}>
                        <CaseManagersTable
                            caseManagers={caseManagers}
                            progressPending={caseManagersStatus === 'loading'}
                            caseManagersAccordion = {caseManagersAccordion}
                        />
                    </Accordion>
                </div>
            }

            {activeFeatures.includes('f_Admin_Firm') &&
                <div className="page mb-4">
                    {(lawFirmsStatus === 'loading') &&
                        <div className="loader-container">
                            <BeatLoader color="#00818C" />
                        </div>
                    }

                    <Accordion onSelect={(e) => { setLawFirmsAccordion(!!e ? true : false) }}>
                        <LawFirmsTable
                            lawFirms={lawFirms}
                            progressPending={lawFirmsStatus === 'loading'}
                            lawFirmsAccordion = {lawFirmsAccordion}
                        />
                    </Accordion>
                </div>
            }

            {activeFeatures.includes('f_Admin_Users') &&
                <div className="page mb-4">
                    {(glucroftUsersStatus === 'loading') &&
                        <div className="loader-container">
                            <BeatLoader color="#00818C" />
                        </div>
                    }

                    <Accordion onSelect={(e) => { setGlucroftUsersAccordion(!!e ? true : false) }}>
                        <GlucroftUsersTable
                            glucroftUsers={glucroftUsers}
                            progressPending={glucroftUsersStatus === 'loading'}
                            glucroftUsersAccordion={glucroftUsersAccordion}
                        />
                    </Accordion>
                </div>
            }

            {(activeFeatures.includes('f_Admin_Permissions') || activeFeatures.includes('f_Manager_Permissions')) &&
                <div className="page mb-4">
                    {(rolesStatus === 'loading') &&
                        <div className="loader-container">
                            <BeatLoader color="#00818C" />
                        </div>
                    }

                    <Accordion onSelect={(e) => { setRolesAccordion(!!e ? true : false) }}>
                        <RolesTable
                            roles={!activeFeatures.includes('f_Admin_Permissions') ? roles.filter((role) => role.id != permissionLevels.Administrator) : roles}
                            progressPending={rolesStatus === 'loading'}
                            rolesAccordion={rolesAccordion}
                            rolesInitialValues={rolesInitialValues}
                        />
                    </Accordion>
                </div>
            }
        </Fragment>
    );
}

export default Admin;