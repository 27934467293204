import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import BeatLoader from 'react-spinners/BeatLoader';
import { fetchGlucroftById, resetGlucroftDetailsStatus, selectGlucroftUser, updateGlucroftUser, createGlucroftUser, fetchRoles } from "../../reducers/glucroft-user-details-slice";
import { selectActiveFeatures } from '../../reducers/auth-slice';
import { permissionLevels } from '../../utility/permission-levels-ids';
import { fetchGlucroftUsers, resetGlucroftUsersStatus } from "../../reducers/glucroft-users-slice";

const GlucroftUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [permissionOptions, setPermissionOptions] = useState([]);
    const [enableEdit, setEnableEdit] = useState(true);
    const activeFeatures = useSelector(selectActiveFeatures);
    

    /**
     * get glucroft user details
     */
    const { id } = useParams();
    const [detailsLoaded, setDetailsLoaded] = useState(false);
    const glucroftUser = useSelector(selectGlucroftUser);
    const glucroftUserStatus = useSelector(state => state.glucroftUserDetails.status);


    // fetch case manager details on each initial render
    useEffect(() => {
        const handleRoles = async () => {
            var roles = await dispatch(fetchRoles());
            var filteredRoles = filterRolesAvailable(roles.payload);

            const options = [];
            filteredRoles.forEach(element => {
                options.push(
                    <option key={element.id} value={element.id}>
                        {element.title}
                    </option>
                )
            });
            setPermissionOptions(options);
        }
        handleRoles();
        if (id) {
            if (glucroftUserStatus === 'idle' && !detailsLoaded) {
                dispatch(fetchGlucroftById(id));
            } 
            else if (glucroftUserStatus === 'succeeded' && !detailsLoaded) {
                // anything that needs to happen AFTER 
                // the data has loaded (e.g. removing loading indicator)
                setDetailsLoaded(true);
            }
        }
    }, [dispatch, id, glucroftUserStatus, detailsLoaded]);

    // set when case manager details have been loaded
    useEffect(() => {
        if (detailsLoaded) {
            setEnableEdit(enableEditIfUserIsPermitted(glucroftUser));
            dispatch(resetGlucroftDetailsStatus());
        }
        else {
            dispatch(fetchGlucroftById(id));
        }
    }, [dispatch, detailsLoaded]);

    const filterRolesAvailable = (roles) => {
        var glucroftRoles = roles.filter(element => element.id != permissionLevels.CaseManagerLevel2 && element.id != permissionLevels.CaseManagerLevel1);
        if (id && glucroftUser.permissionLevel == permissionLevels.Administrator) return glucroftRoles;
        if (activeFeatures.includes('f_Admin_AssignRoles')) return glucroftRoles;
        if (activeFeatures.includes('f_Manager_AssignRoles')) return glucroftRoles.filter(element => element.title != 'Administrator');
        if (activeFeatures.includes('f_OrderCreator_AssignRoles')) return glucroftRoles.filter(element => element.title != 'Administrator' || element.title != 'Manager');
        if (activeFeatures.includes('f_Accounting_AssignRoles')) return glucroftRoles.filter(element => element.title != 'Administrator' || element.title != 'Manager' || element.title != 'Order Creator');
        if (activeFeatures.includes('f_FieldInvestigatorManager_AssignRoles')) return glucroftRoles.filter(element => element.title != 'Administrator' || element.title != 'Manager' || element.title != 'Order Creator' || element.title != 'Accounting');
        if (activeFeatures.includes('f_FieldInvestigator_AssignRoles')) return glucroftRoles.filter(element => element.title != 'Administrator' || element.title != 'Manager' || element.title != 'Order Creator' || element.title != 'Accounting' || element.title != 'Field Investigator Manager');
        if (activeFeatures.includes('f_InternalInvestigator_AssignRoles')) return glucroftRoles.filter(element => element.title != 'Administrator' || element.title != 'Manager' || element.title != 'Order Creator' || element.title != 'Accounting'|| element.title != 'Field Investigator' || element.title != 'Field Investigator Manager');
        else {return glucroftRoles.filter(element => element.title == 'Portal User')}
    };

    const enableEditIfUserIsPermitted = (glucroftUser) => {
        if(glucroftUser.permissionLevel == permissionLevels.Administrator) {
            return activeFeatures.includes('f_Admin_AssignRoles')
        }
        else return true;
    };

    /**
     * setup the initial values & validation
     */
    const initialValues = {
        id: '',
        firstName: '',
        lastName: '',
        employeeNumber: '',
        employeeId: '',
        email: '',
        phone: '',
        permissionLevel: '',
        status: '',
        notes: '',
        notifyUser: 0
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('Required'),
        lastName: Yup.string()
            .required('Required'),
        employeeNumber: Yup.string()
            .required('Required'),
        employeeId: Yup.string()
            .required('Required'),
        email: Yup.string()
            .required('Required')
            .email('Invalid email'),
        phone: Yup.string()
            .required('Required'),
        permissionLevel: Yup.string()
            .required('Required'),
        status: Yup.string()
            .required('Required'),
    });


    /**
     * handle form submission
     */
    const handleSubmit = async (values) => {
        if (values.id.length === 0) {
            await dispatch(createGlucroftUser(values))
        }
        else {
            await dispatch(updateGlucroftUser(values));
        }
        await dispatch(resetGlucroftUsersStatus());
        await dispatch(fetchGlucroftUsers());
        navigate("/admin");
    };


    /**
     * handle canceling
     */
    const handleCancel = () => {
        navigate('/admin');
    };

    return (
        <Formik
            initialValues={detailsLoaded ? glucroftUser : initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ errors, dirty, isValid, touched, submitForm, values }) => (
                <Fragment>
                    <div className="page">
                        {(glucroftUserStatus === 'loading') &&
                            <div className="loader-container">
                                <BeatLoader color="#00818C" />
                            </div>
                        }

                        <div className="page-header">
                            <div className="page-header-title">
                                <h1 className="mb-0">Glucroft User</h1>

                                {detailsLoaded && <div className="page-header-title-filters">{glucroftUser.firstName} {glucroftUser.lastName}</div>}
                            </div>

                            <div className="page-header-actions">
                                <button className="form-submit button tertiary" type="submit" onClick={submitForm} disabled={!((dirty || detailsLoaded) && isValid && enableEdit)}>Save</button>
                                <button className="button outlined secondary" type="button" onClick={handleCancel}>Cancel</button>
                            </div>
                        </div>

                        <div className="page-body mt-4">
                            <Form>
                                <div className="form-row">
                                    <div className="form-field-wrapper">
                                        <label htmlFor="firstName">First Name <span className="required">*</span></label>
                                        <Field id="firstName" name="firstName" />

                                        {errors && errors.firstName && touched && touched.firstName ? (
                                            <div className="form-error">{errors.firstName}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="lastName">Last Name <span className="required">*</span></label>
                                        <Field id="lastName" name="lastName" />

                                        {errors && errors.lastName && touched && touched.lastName ? (
                                            <div className="form-error">{errors.lastName}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="employeeNumber">Employee # <span className="required">*</span></label>
                                        <Field id="employeeNumber" name="employeeNumber" />

                                        {errors && errors.employeeNumber && touched && touched.employeeNumber ? (
                                            <div className="form-error">{errors.employeeNumber}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="employeeId">Employee ID <span className="required">*</span></label>
                                        <Field id="employeeId" name="employeeId" />

                                        {errors && errors.employeeId && touched && touched.employeeId ? (
                                            <div className="form-error">{errors.employeeId}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="email">Email <span className="required">*</span></label>
                                        <Field id="email" name="email" />

                                        {errors && errors.email && touched && touched.email ? (
                                            <div className="form-error">{errors.email}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="phone">Phone <span className="required">*</span></label>
                                        <Field id="phone" name="phone" />

                                        {errors && errors.phone && touched && touched.phone ? (
                                            <div className="form-error">{errors.phone}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="permissionLevel">Role Type <span className="required">*</span></label>
                                        <Field id="permissionLevel" name="permissionLevel" as="select">
                                            <option value="" defaultValue>Select Role Type</option>
                                            {permissionOptions}
                                        </Field>

                                        {errors && errors.permissionLevel && touched && touched.permissionLevel ? (
                                            <div className="form-error">{errors.permissionLevel}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="status">Status <span className="required">*</span></label>
                                        <Field id="status" name="status" as="select">
                                            <option value="" defaultValue>Make a selection</option>
                                            <option value="Enabled">Enabled</option>
                                            <option value="Disabled">Disabled</option>
                                        </Field>

                                        {errors && errors.status && touched && touched.status ? (
                                            <div className="form-error">{errors.status}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="notes">Notes</label>
                                        <Field id="notes" name="notes" as="textarea" rows="5" />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Fragment>
            )}
        </Formik>
    );
};

export default GlucroftUser;