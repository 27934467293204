import { useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import IntakeWitnessDefendantStatementDetailsFaultInfoFields from '../../../components/intake/witness-defendant-statement/fault-info-fields';
import FormFooter from '../../../components/form-footer';
import { selectActiveFormWitnessDefendantStatementDetails, selectActiveFormWitnessDefendantStatementFaultInfo, updateWitnessDefendantStatementFaultInfo } from '../../../reducers/active-form-slice';
import { deleteFaultInfoFiles, resetFaultInfoFilesStatus, selectAllFaultInfoFiles, uploadFaultInfoFiles } from '../../../reducers/fault-info-files-slice';


const IntakeWitnessDefendantStatementFaultInfo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const witnessDefendantStatementDetails = useSelector(selectActiveFormWitnessDefendantStatementDetails);
    const witnessDefendantStatementDetailsFaultInfo = useSelector(selectActiveFormWitnessDefendantStatementFaultInfo);
    const hasValues = Object.keys(witnessDefendantStatementDetailsFaultInfo).length > 0 ? true : false;


    /**
     * handle the file upload & store returned info in state
     */
    const faultInfoFiles = useSelector(selectAllFaultInfoFiles);
    const faultInfoFilesStatus = useSelector(state => state.faultInfoFiles.status);
    const formRef = useRef();

    const handleUpload = (files) => {
        dispatch(uploadFaultInfoFiles(files));
    };

    const handleUploadedRemoval = (guid) => {
        dispatch(deleteFaultInfoFiles(guid));
    };

    useEffect(() => {
        if (faultInfoFilesStatus === "succeeded") {
            formRef.current.setFieldValue('files', faultInfoFiles.faultInfoFiles);
            dispatch(resetFaultInfoFilesStatus());
        }
    }, [faultInfoFiles, faultInfoFilesStatus, dispatch]);


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        switch (witnessDefendantStatementDetails.statementType) {
            case "Both":
            case "Witness":
                navigate("/intake/witness-defendant-statement/witness-info");
                break;
            case "Defendant":
                navigate("/intake/witness-defendant-statement/defendant-info");
                break;
        }
    };


    /**
     * update active form & continue
    */
    const handleSubmit = (values) => {
        dispatch(updateWitnessDefendantStatementFaultInfo(values));
        navigate("/intake/witness-defendant-statement/info");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        acceptedLiability: '',
        files: [],
    };


    return (
        <div className="page intake">
            {(faultInfoFilesStatus === 'loading') &&
                <div className="loader-container">
                    <BeatLoader color="#00818C" />
                </div>
            }

            <h2>Witness AND/OR Defendant Statement Details</h2>

            <Formik
                innerRef={formRef}
                initialValues={hasValues ? witnessDefendantStatementDetailsFaultInfo : initialValues}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <IntakeWitnessDefendantStatementDetailsFaultInfoFields
                            handleUpload={handleUpload}
                            handleUploadedRemoval={handleUploadedRemoval}
                            uploadedFiles={faultInfoFiles.faultInfoFiles}
                            uploadStatus={faultInfoFilesStatus}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={false}
                            showNext={true}
                            progress={`40`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeWitnessDefendantStatementFaultInfo;