import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FileUpload from '../services/files-upload-service';


export const uploadSWITRSDetailsFiles = createAsyncThunk('switrsDetailsFiles/UploadSWITRSDetailsFiles', async (payload) => {
    const response = await FileUpload.Upload(payload);
    return response.data;
});

export const deleteSWITRSDetailsFiles = createAsyncThunk('switrsDetailsFiles/DeleteSWITRSDetailsFiles', async (payload) => {
    const response = await FileUpload.Upload(payload);
    return response.data;
});

export const switrsDetailsFilesSlice = createSlice({
    name: 'switrsDetailsFiles',

    initialState: {
        switrsDetailsFiles: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetSWITRSDetailsFilesStatus: (state) => {
            state.status = 'idle';
        },
        resetSWITRSDetailsFiles: (state) => {
            state.switrsDetailsFiles = [];
        },
    },

    extraReducers: {
        [uploadSWITRSDetailsFiles.pending]: (state, action) => {
            state.status = 'loading';
        },
        [uploadSWITRSDetailsFiles.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.switrsDetailsFiles = state.switrsDetailsFiles.concat(action.payload);
        },
        [uploadSWITRSDetailsFiles.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [deleteSWITRSDetailsFiles.pending]: (state, action) => {
            state.status = 'loading';
        },
        [deleteSWITRSDetailsFiles.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.switrsDetailsFiles = [];
        },
        [deleteSWITRSDetailsFiles.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    }
});


export const {
    resetSWITRSDetailsFilesStatus,
    resetSWITRSDetailsFiles,
} = switrsDetailsFilesSlice.actions;


export default switrsDetailsFilesSlice.reducer;


export const selectAllSWITRSDetailsFiles = state => {
    return state.switrsDetailsFiles;
};