import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IntakeWitnessDefendantStatementDetailsFields from '../../../components/intake/witness-defendant-statement/details-fields';
import FormFooter from '../../../components/form-footer';
import { selectActiveFormWitnessDefendantStatementDetails, updateWitnessDefendantStatementDetails } from '../../../reducers/active-form-slice';
import WitnessDefendantStatementDetailsSchema from '../../../validation-schemas/witness-defendant-statement-details';


const IntakeWitnessDefendantStatementDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const witnessDefendantStatementDetails = useSelector(selectActiveFormWitnessDefendantStatementDetails);
    const hasValues = Object.keys(witnessDefendantStatementDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateWitnessDefendantStatementDetails(values));

        switch (values.statementType) {
            case "Defendant":
            case "Both":
                navigate("/intake/witness-defendant-statement/defendant-info");
                break;
            case "Witness":
            default:
                navigate("/intake/witness-defendant-statement/witness-info");
                break;
        }
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        dateOfLoss: '',
        timeOfLoss: '',
        locationOfLoss: '',
        factsOfLoss: '',
        specificQuestions: '',
        directionOfTravel: '',
        streetOfTravel: '',
        vehicleDescription: '',
        pedestrianIdentifiers: '',
        statementType: '',
    };


    return (
        <div className="page intake">
            <h2>Witness AND/OR Defendant Statement Details</h2>

            <Formik
                initialValues={hasValues ? witnessDefendantStatementDetails : initialValues}
                validationSchema={WitnessDefendantStatementDetailsSchema.fields.witnessDefendantStatementDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeWitnessDefendantStatementDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`25`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeWitnessDefendantStatementDetails;