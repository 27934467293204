import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../components/form-footer';
import IntakeSceneInvestigationDetailsFields from '../../../components/intake/scene-investigation/details-fields';
import SceneInvestigationDetailsSchema from '../../../validation-schemas/scene-investigation-details-schema';
import { updateActiveFormSceneInvestigationDetails, selectActiveFormSceneInvestigationDetails } from '../../../reducers/active-form-slice';


const IntakeSceneInvestigationDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const sceneInvestigationDetails = useSelector(selectActiveFormSceneInvestigationDetails);
    const hasValues = Object.keys(sceneInvestigationDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormSceneInvestigationDetails(values));
        navigate("/intake/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        dateOfLoss: '',
        timeOfLoss: '',
        surveillanceType: '',
        investigationSubject: '',
        investigationLocation: '',
        subjectVehicleDescription: '',
        subjectVehiclelicensePlate: '',
        factsOfLoss: '',
    };


    return (
        <div className="page intake">
            <h2>Scene Investigation Details</h2>

            <Formik
                initialValues={hasValues ? sceneInvestigationDetails : initialValues}
                validationSchema={SceneInvestigationDetailsSchema.fields.sceneInvestigationDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched }) => (
                    <Form>
                        <IntakeSceneInvestigationDetailsFields
                            errors={errors}
                            touched={touched}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`50`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeSceneInvestigationDetails;