import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FileUpload from '../services/files-upload-service';


export const uploadFaultInfoFiles = createAsyncThunk('faultInfoFiles/UploadFaultInfoFiles', async (payload) => {
    const response = await FileUpload.Upload(payload);
    return response.data;
});

export const deleteFaultInfoFiles = createAsyncThunk('faultInfoFiles/DeleteFaultInfoFiles', async (payload) => {
    const response = await FileUpload.Upload(payload);
    return response.data;
});

export const faultInfoFilesSlice = createSlice({
    name: 'faultInfoFiles',

    initialState: {
        faultInfoFiles: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetFaultInfoFilesStatus: (state) => {
            state.status = 'idle';
        },
        resetFaultInfoFiles: (state) => {
            state.faultInfoFiles = [];
        },
    },

    extraReducers: {
        [uploadFaultInfoFiles.pending]: (state, action) => {
            state.status = 'loading';
        },
        [uploadFaultInfoFiles.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.faultInfoFiles = state.faultInfoFiles.concat(action.payload);
        },
        [uploadFaultInfoFiles.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [deleteFaultInfoFiles.pending]: (state, action) => {
            state.status = 'loading';
        },
        [deleteFaultInfoFiles.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.faultInfoFiles = [];
        },
        [deleteFaultInfoFiles.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    }
});


export const {
    resetFaultInfoFilesStatus,
    resetFaultInfoFiles,
} = faultInfoFilesSlice.actions;


export default faultInfoFilesSlice.reducer;


export const selectAllFaultInfoFiles = state => {
    return state.faultInfoFiles;
};