import { Field } from "formik";
import { stateProvince } from "../../../utility/state-province";
import StateProvinceDropdown from "../../stateprovinceDropdown";


const IntakeBusinessDueDiligenceDetailsFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <h3>Business</h3>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="businessName">Business Name <span className="required">*</span></label>
                    <Field id="businessName" name={nested ? "businessDueDiligenceDetails.businessName" : "businessName"} />

                    {errors && errors.businessName && touched && touched.businessName ? (
                        <div className="form-error">{errors.businessName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="ownersName">Owners Name<span className="required">*</span></label>
                    <Field id="ownersName" name={nested ? "businessDueDiligenceDetails.ownersName" : "ownersName"} />

                    {errors && errors.ownersName && touched && touched.ownersName ? (
                        <div className="form-error">{errors.ownersName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="businessAddress">Business Address</label>
                    <Field id="businessAddress" name={nested ? "businessDueDiligenceDetails.businessAddress" : "businessAddress"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="businessAddress2">Business Address 2</label>
                    <Field id="businessAddress2" name={nested ? "businessDueDiligenceDetails.businessAddress2" : "businessAddress2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="city">City</label>
                    <Field id="city" name={nested ? "businessDueDiligenceDetails.city" : "city"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "businessDueDiligenceDetails.stateProvince" : "stateProvince"} id="stateProvince" disabled={false}/>

                <div className="form-field-wrapper">
                    <label htmlFor="zip">Zip</label>
                    <Field id="zip" name={nested ? "businessDueDiligenceDetails.zip" : "zip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="country">Country</label>
                    <Field id="country" name={nested ? "businessDueDiligenceDetails.country" : "country"} />
                </div>
            </div>
        </div>
    );
};

export default IntakeBusinessDueDiligenceDetailsFields;