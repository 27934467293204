import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GlucroftUsersService from '../services/glucroft-users-service';


export const fetchGlucroftUsers = createAsyncThunk('glucroftUsers/fetchGlucroftUsers', async (payload) => {
    const data = await GlucroftUsersService.GetGlucroftUsers();
    return data;
});

export const glucroftUsersSlice = createSlice({
    name: 'glucroftUsers',

    initialState: {
        glucroftUsers: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetGlucroftUsersStatus: (state) => {
            state.status = 'idle';
        },
    },

    extraReducers: {
        [fetchGlucroftUsers.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchGlucroftUsers.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.glucroftUsers = action.payload;
        },
        [fetchGlucroftUsers.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetGlucroftUsersStatus,
} = glucroftUsersSlice.actions;

export default glucroftUsersSlice.reducer;


export const selectAllGlucroftUsers = state => {
    return state.glucroftUsers.glucroftUsers;
};