import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CaseManagersServices from '../services/case-managers-service';


export const fetchCaseManagers = createAsyncThunk('caseManagers/fetchCaseManagers', async (payload) => {
    const data = await CaseManagersServices.GetCaseManagers();
    return data;
});

export const fetchCaseManagersByFirm = createAsyncThunk('caseManagers/fetchCaseManagersByFirm', async (payload) => {
    const response = await CaseManagersServices.GetCaseManagersByFirm(payload);
    return response.data;
});


export const caseManagersSlice = createSlice({
    name: 'caseManagers',

    initialState: {
        caseManagers: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetCaseManagersStatus: (state) => {
            state.status = 'idle';
        },
    },

    extraReducers: {
        [fetchCaseManagers.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCaseManagers.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.caseManagers = action.payload;
        },
        [fetchCaseManagers.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [fetchCaseManagersByFirm.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCaseManagersByFirm.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.caseManagers = action.payload;
        },
        [fetchCaseManagersByFirm.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetCaseManagersStatus,
} = caseManagersSlice.actions;

export default caseManagersSlice.reducer;


export const selectAllCaseManagers = state => {
    return state.caseManagers.caseManagers;
};