import { useState } from 'react';
import DataTable from 'react-data-table-component';
import CustomLoader from './custom-loader';
import ShowNoteModal from './modals/show-note';
import FilterMethods from '../utility/filter-methods';
import IcoMail from '../assets/ico-mail';
import { useDispatch } from 'react-redux';
import { fetchOrderById } from '../reducers/order-details-slice';
import { fetchAllNotifications } from '../reducers/notifications-slice';

const OrderDetailsNotesTable = ({ notes, notifications, progressPending, activeFeatures, handleSelectedRowsChange, orderId }) => {
    
    const dispatch = useDispatch();

    const [showNoteModal, setShowNoteModal] = useState(false);
    const [selectedNote, setSelectedNote] = useState({});


    const handleShowNoteModal = row => {
        setSelectedNote(row);
        setShowNoteModal(!showNoteModal);
    };

    const handleHideNoteModal = async () => {
        setShowNoteModal(false);
        setSelectedNote({});
        await dispatch(fetchOrderById(orderId));
        await dispatch(fetchAllNotifications(orderId));
    };

    /**
     * setup notes columns
     */
    const columns = [
        {
            name: <span className="custom-table-header" title="From">From</span>,
            selector: row => row.from,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Tag">Tag</span>,
            selector: row => row.tag,
            cell: row => <span className={row.tag ? "tag-external" : "tag-internal"}>{row.tag ? "External" : "Internal"}</span>,
            sortable: true,
        },
        {
            id: 'date',
            name: <span className="custom-table-header" title="Date">Date</span>,
            selector: row => row.date,
            cell: row => <span>{new Date(row.date.endsWith('Z') ? row.date : row.date+"Z").toLocaleString()}</span>,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Message">Message</span>,
            selector: row => <div className="custom-table-link" onClick={() => handleShowNoteModal(row)}>{row.message}</div>,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Tag">Unread</span>,
            cell: row => ( notifications.find(x => x.noteID === row.id) ? <span className='button note'><IcoMail /></span> : <span></span>),
            sortable: true,
        },
    ];

    const paginationComponentOptions = {
        noRowsPerPage: true,
    };

    return (
        <div className="custom-table">
            <DataTable
                pagination
                paginationComponentOptions={paginationComponentOptions}
                columns={columns}
                data={notes}
                defaultSortFieldId="date"
                defaultSortAsc={false}
                progressPending={progressPending}
                progressComponent={<CustomLoader />}
                selectableRows
                selectableRowsSingle
                onSelectedRowsChange={handleSelectedRowsChange}
                notifications={notifications}
            />

            {showNoteModal &&
                <ShowNoteModal
                    showNoteModal={showNoteModal}
                    handleHideNoteModal={handleHideNoteModal}
                    note={selectedNote}
                />
            }
        </div>
    );
};

export default OrderDetailsNotesTable;