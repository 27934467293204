import * as Yup from 'yup';


const InsuranceInvestigationBusinessDetailsSchema = Yup.object().shape({
    insuranceInvestigationBusinessDetails: Yup.object().shape({
        insuredType: Yup.string()
            .required('Required'),
        insuredName: Yup.string()
            .required('Required'),
        ownerIsDriver: Yup.string()
            .required('Required'),
        dateOfLoss: Yup.string()
            .required('Required')
            .typeError('Required'),
    })
});

export default InsuranceInvestigationBusinessDetailsSchema;