const IcoDocument = () => {
  return (
    <svg id="pie-chart" xmlns="http://www.w3.org/2000/svg" width="36.057" height="36.057" viewBox="0 0 36.057 36.057">
      <g id="Icons_-_Navigation_icon_12_states_" data-name="Icons - Navigation icon (12 states)">
        <g id="ic_invoice">
          <path id="Path_379" data-name="Path 379" d="M14,3V9.742a1.686,1.686,0,0,0,1.686,1.686h6.742" transform="translate(8 1)" fill="none" stroke="current" strokeLinecap="round" strokeWidth="2" />
          <path id="Path_380" data-name="Path 380" d="M27.335,33.057H8.722A3.547,3.547,0,0,1,5,29.718V6.34A3.547,3.547,0,0,1,8.722,3H21.751l9.306,8.349V29.718A3.547,3.547,0,0,1,27.335,33.057Z" transform="translate(0 0)" fill="none" stroke="current" strokeLinecap="round" strokeWidth="2" />
          <line id="Line_12" data-name="Line 12" x2="8" transform="translate(10 11)" fill="none" stroke="current" strokeLinecap="round" strokeWidth="2" />
          <line id="Line_13" data-name="Line 13" x2="16" transform="translate(10 19)" fill="none" stroke="current" strokeLinecap="round" strokeWidth="2" />
          <line id="Line_14" data-name="Line 14" x2="16" transform="translate(10 27)" fill="none" stroke="current" strokeLinecap="round" strokeWidth="2" />
        </g>
      </g>
    </svg>
  );
}

export default IcoDocument;