const IcoMail = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="26" viewBox="0 0 32 26">
    <path id="Icon_ionic-md-mail" data-name="Icon ionic-md-mail" d="M30.75,5.625H5.25a3.009,3.009,0,0,0-3,3v18.75a3.009,3.009,0,0,0,3,3h25.5a3.009,3.009,0,0,0,3-3V8.625A3.009,3.009,0,0,0,30.75,5.625ZM30.375,12,18,20.25,5.625,12V9L18,17.25,30.375,9Z" transform="translate(-2.25 -5.625)" fill="#fff"/>
    </svg>
  )
};

export default IcoMail;

