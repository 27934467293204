import * as Yup from 'yup';


const WitnessDefendantStatementDetailsSchema = Yup.object().shape({
    witnessDefendantStatementDetails: Yup.object().shape({
        dateOfLoss: Yup.string()
            .required('Required'),
        timeOfLoss: Yup.string()
            .required('Required'),
        locationOfLoss: Yup.string()
            .required('Required'),
        factsOfLoss: Yup.string()
            .required('Required'),
        specificQuestions: Yup.string()
            .required('Required'),
        directionOfTravel: Yup.string()
            .required('Required'),
        streetOfTravel: Yup.string()
            .required('Required'),
        vehicleDescription: Yup.string()
            .required('Required'),
        pedestrianIdentifiers: Yup.string()
            .required('Required'),
        statementType: Yup.string()
            .required('Required'),
    })
});

export default WitnessDefendantStatementDetailsSchema;