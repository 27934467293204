import DataTable from 'react-data-table-component';
import CustomLoader from './custom-loader';
import FileUpload from '../services/files-upload-service';
import FilterMethods from '../utility/filter-methods';
import React from 'react';
import { useState } from 'react';
import Column from 'react-data-table-component'
import { postDeleteNotification } from '../reducers/notifications-slice';
import { useDispatch } from 'react-redux';
import { fetchAllNotifications } from '../reducers/notifications-slice';
import { fetchOrderById } from '../reducers/order-details-slice';
import IcoMail from '../assets/ico-mail';






const OrderDetailsAttachmentsTable = ({ attachments, notifications, progressPending, activeFeatures, selectedFile, setSelectedFile, handleSelectedRowsChange, orderId }) => {

    const dispatch = useDispatch();

    const handleSetSelectedFile = (e) => {
        e.preventDefault();
        setSelectedFile(e.value);
    };

    const [file, setFile] = useState(null);

    const deleteNotificationAndUploadFile = async (row) => {

        await dispatch(postDeleteNotification(row.id));

        await FileUpload.Download(row.id);

        await dispatch(fetchOrderById(orderId));
        await dispatch(fetchAllNotifications(orderId));
    }
    

    /**
     * setup attachments columns
     */
    const columns = [
        {
            name: <span className="custom-table-header" title="Type">Type</span>,
            selector: row => row.type,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Tag">Tag</span>,
            selector: row => row.tag,
            cell: row => <span className={row.tag ? "tag-external" : "tag-internal"}>{row.tag ? "External" : "Internal"}</span>,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Date">Date</span>,
            selector: row => row.date,
            cell: row => <span>{new Date(row.date.endsWith('Z') ? row.date : row.date+"Z").toLocaleString()}</span>,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="File Name">File Name</span>,
            cell: row => <div className="custom-table-link" onClick={async () => await deleteNotificationAndUploadFile(row)}>{row.name}</div>,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Tag">Unread</span>,
            cell: row => ( notifications.find(x => x.docID === row.id) ? <span className='button note'><IcoMail /></span> : <span></span>),
            sortable: true,
        },
    ];

    const paginationComponentOptions = {
        noRowsPerPage: true,
    };

    // const handleSelectedRowsChange = (e) => {
    //     // setSelectedFile(selectedRows.id);
    // };

    return (
        <div className="custom-table">
            <DataTable 
                value= {attachments} 
                pagination
                paginationComponentOptions={paginationComponentOptions}
                columns={columns}
                data={attachments}
                progressPending={progressPending}
                progressComponent={<CustomLoader />}
                selectableRows
                selectableRowsSingle
                onSelectedRowsChange={handleSelectedRowsChange}
                notifications={notifications}
            >
            </DataTable>
        </div>
    );
};

export default OrderDetailsAttachmentsTable;