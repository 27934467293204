import { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import FormFooter from '../../../components/form-footer';
import IntakeTrafficCollisionsReportRetrievalDetailsFields from '../../../components/intake/traffic-collisions-report-retrieval/details-fields';
import TrafficCollisionsReportRetrievalDetailsSchema from '../../../validation-schemas/traffic-collisions-report-retrieval-details-schema';
import { selectAllAttchments, resetIntakeAttachmentsAdded } from '../../../reducers/attachments-slice';
import { selectActiveFormTrafficCollisionsReportRetrievalDetails, updateTrafficCollisionsReportRetrievalDetails } from '../../../reducers/active-form-slice';
import { resetTrafficCollisionsReportRetrievalFilesStatus, selectAllTrafficCollisionsReportRetrievalFiles, uploadTrafficCollisionsReportRetrievalFiles, deleteTrafficCollisionsReportRetrievalFiles } from '../../../reducers/traffic-collisions-report-retrieval-files-slice';


const IntakeTrafficCollisionsReportRetrievalDetails = (intakeStep = null) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allAttachments = useSelector(selectAllAttchments);
    const specificAttachments = allAttachments.intakeAttachments_specific.slice();
    const specificedAttachmentsStatus = useSelector(state => allAttachments.intakeAttachmentsAdded_specific);


    /**
     * get current form values
     */
    const trafficCollisionsReportRetrievalDetails = useSelector(selectActiveFormTrafficCollisionsReportRetrievalDetails);
    const hasValues = Object.keys(trafficCollisionsReportRetrievalDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * handle the file upload
     */
    const trafficCollisionsReportRetrievalFiles = useSelector(selectAllTrafficCollisionsReportRetrievalFiles);
    const trafficCollisionsReportRetrievalFilesStatus = useSelector(state => state.trafficCollisionsReportRetrievalFiles.status);
    const formRef = useRef();

    const handleUpload = (files) => {
        dispatch(uploadTrafficCollisionsReportRetrievalFiles(files));
    };

    const handleUploadedRemoval = (guid) => {
        dispatch(deleteTrafficCollisionsReportRetrievalFiles(guid));
    };

    useEffect(() => {
        if (trafficCollisionsReportRetrievalFilesStatus === "succeeded") {
            formRef.current.setFieldValue('files', trafficCollisionsReportRetrievalFiles.trafficCollisionsReportRetrievalFiles);
            dispatch(resetTrafficCollisionsReportRetrievalFilesStatus());
        }
    }, [trafficCollisionsReportRetrievalFiles, trafficCollisionsReportRetrievalFilesStatus, dispatch]);

    useEffect(() => {
        if (specificedAttachmentsStatus === true) {
            formRef.current.setFieldValue('files', specificAttachments);
            dispatch(resetIntakeAttachmentsAdded({intakeStep}));
        }
    }, [specificAttachments, specificedAttachmentsStatus, dispatch]);

    /**
     * submit form
     */
    const handleSubmit = (values) => {
        dispatch(updateTrafficCollisionsReportRetrievalDetails(values));
        navigate('/intake/additional-information');
    };

    /**
     * setup the initial values
     */
    const initialValues = {
        policeDept: '',
        dateOfLoss: '',
        timeOfLoss: '',
        clientDob: '',
        clientDriversLicenseNumber: '',
        reportNumber: '',
        lossLocation: '',
        City: '',
        vehicle1: '',
        licensePlate1: '',
        vehicle2: '',
        licensePlate2: '',
        vehicle3: '',
        licensePlate3: '',
        files: [],
    };


    return (
        <div className="page intake">
            {(trafficCollisionsReportRetrievalFilesStatus === 'loading') &&
                <div className="loader-container">
                    <BeatLoader color="#00818C" />
                </div>
            }

            <h2>Traffic Collisions Report Retrieval Details</h2>

            <Formik
                innerRef={formRef}
                initialValues={hasValues ? trafficCollisionsReportRetrievalDetails : initialValues}
                validationSchema={TrafficCollisionsReportRetrievalDetailsSchema.fields.trafficCollisionsReportRetrievalDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched }) => (
                    <Form>
                        <IntakeTrafficCollisionsReportRetrievalDetailsFields
                            handleUpload={handleUpload}
                            handleUploadedRemoval={handleUploadedRemoval}
                            uploadedFiles={trafficCollisionsReportRetrievalFiles.trafficCollisionsReportRetrievalFiles}
                            uploadStatus={trafficCollisionsReportRetrievalFilesStatus}
                            errors={errors}
                            touched={touched}
                            specificFiles = {specificAttachments}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`50`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeTrafficCollisionsReportRetrievalDetails;