export const orderTypes = 
{
    AL: "6859dfec-df01-492f-8def-50bb130d2740",
    BSMS: "b7fdc4fd-0629-4d64-8751-65080436b4ed",
    DD: "05a7fe17-8fb5-442b-aaa0-d9c99476228c",
    DMV: "112f034e-c54b-4307-bc1d-ce68d3a69fe7",
    ISO: "fc25e0ba-f2f1-41e9-afc8-2da980ed53fe",
    PLB: "cbd61d9e-7c34-4bbb-af1f-2cbf84321034",
    PLL: "dd7b99f9-5394-4d46-87f3-3a0804a8738a",
    PLPAU: "3fe7eb42-7b28-4146-8fdd-f4012ce62bc5",
    PLPHR: "b03f65f3-f6c5-42b6-af5b-c34a958bc6ec",
    SI: "77cf5024-fffe-48ac-ade7-a4ca318b1290",
    ST: "8327fb74-d3ff-4561-b189-8e80fde1b3af",
    SERVE: "918a0ae1-4e91-49a2-a595-190f6b87e8dc",
    SWITRS: "c7537487-e799-49f0-85b9-13c07170ba20",
    TCR: "26a9c1ea-45a1-4181-8665-9cdc33721f71",
    DWS: "3a25f588-0e8f-451d-978d-711502131631"
}