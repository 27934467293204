import { Field } from "formik";
import FileInput from "../../file-input";


const IntakeSWITRSDetailsFields = ({ handleUpload, handleUploadedRemoval, uploadedFiles, uploadStatus, nested }) => {
    return (
        <div className="form-wrapper additional-information">
            <div className="form-row single-col">
                <div className="form-field-wrapper">
                    <label htmlFor="location">Location of Accident or Intersection</label>
                    <Field id="location" name={nested ? "switrsDetails.location" : "location"} />
                </div>
            </div>

            <div className="form-row single-col">
                <FileInput
                    uploadedFiles={uploadedFiles}
                    handleUpload={handleUpload}
                    handleUploadedRemoval={handleUploadedRemoval}
                    uploadStatus={uploadStatus}
                    isIntake={true}
                    intakeStep={'specific'}
                />

                <Field id="files" name={nested ? "switrsDetails.files" : "files"} type="hidden" />
            </div>
        </div>
    );
};

export default IntakeSWITRSDetailsFields;