import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../components/form-footer';
import IntakeBackgroundScreeningSocialMediaFields from '../../../components/intake/background-screening/social-media-fields';
import { updateActiveFormBackgroundScreeningSocialMedia, selectActiveFormBackgroundScreeningSocialMedia, selectActiveFormBackgroundScreeningDetails } from '../../../reducers/active-form-slice';
import BackgroundScreeningSocialMediaSchema from '../../../validation-schemas/background-screening-social-media-schema';

const IntakeBackgroundScreeningSocialMedia = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const backgroundScreeningSocialMedia = useSelector(selectActiveFormBackgroundScreeningSocialMedia);
    const backgroundScreeningSubject = useSelector(selectActiveFormBackgroundScreeningDetails);

    const hasValues = Object.keys(backgroundScreeningSocialMedia).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/background-screening/details");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormBackgroundScreeningSocialMedia(values));
        navigate("/intake/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        socialMediaFirstName: backgroundScreeningSubject.firstName,
        socialMediaLastName: backgroundScreeningSubject.lastName,
        socialMediaEmail: backgroundScreeningSubject.emailPrimary,
        socialMediaPhone: backgroundScreeningSubject.phonePrimary,
        socialMediaAddress: backgroundScreeningSubject.address,
        socialMediaAddress2: backgroundScreeningSubject.address2,
        socialMediaCity: backgroundScreeningSubject.city,
        socialMediaStateProvince: backgroundScreeningSubject.stateProvince,
        socialMediaZip: backgroundScreeningSubject.zip,
        socialMediaCountry: backgroundScreeningSubject.country,
        socialMediaLocations: '',
        socialMediaConnectionsAssociates: '',
        socialMediaConnectionsAssociatesLocations: '',
        socialMediaUsername: '',
        socialMediaVin: backgroundScreeningSubject.vin,
        socialMediaLicensePlate: backgroundScreeningSubject.licensePlate,
        socialMediaKeyword: '',
        socialMediaJob: backgroundScreeningSubject.jobs,
        socialMediaDob: backgroundScreeningSubject.dob,
        socialMediaAge: backgroundScreeningSubject.age,
        socialMediaSchool: '',
        socialMediaDateOfIncident: '',
    };


    return (
        <div className="page intake">
            <h2>Social Media Subject Search Details</h2>

            <Formik
                initialValues={hasValues ? backgroundScreeningSocialMedia : initialValues}
                validationSchema={BackgroundScreeningSocialMediaSchema.fields.backgroundScreeningSocialMedia}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeBackgroundScreeningSocialMediaFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`75`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeBackgroundScreeningSocialMedia;