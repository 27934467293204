import { Field } from "formik";

const InvestigationNameFields = ({ errors, touched, values, nested }) => {
    
    return (
            <div style={{alignItems: 'center', display: 'flex', width: '100%'}}>
                <div className="form-field-wrapper" style={{padding: '2rem', width: '100%'}}>
                    <label htmlFor="firstNameClient">First Name (Client) <span className="required">*</span></label>
                    <Field id="firstNameClient" name={nested ? 'investigationType.firstNameClient' : 'firstNameClient'} />

                    {errors && errors.firstNameClient && touched && touched.firstNameClient ? (
                        <div className="form-error">{errors.firstNameClient}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper" style={{padding: '2rem', width: '100%'}}>
                    <label htmlFor="lastNameClient">Last Name (Client) <span className="required">*</span></label>
                    <Field id="lastNameClient" name={nested ? 'investigationType.lastNameClient' : 'lastNameClient'} />

                    {errors && errors.lastNameClient && touched && touched.lastNameClient ? (
                        <div className="form-error">{errors.lastNameClient}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper" style={{padding: '2rem', width: '100%'}}>
                    <label htmlFor="fileNumberClient">File # (Client)</label>
                    <Field id="fileNumberClient" name={nested ? 'investigationType.fileNumberClient' : 'fileNumberClient'} />
                </div>
            </div>
    );
};

export default InvestigationNameFields;