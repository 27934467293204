import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../../components/form-footer';
import IntakeInsuranceInvestigationLocateDriversDetailsFields from '../../../../components/intake/insurance-investigation/locate/drivers-details-fields';
import InsuranceInvestigationLocateDriversDetailsSchema from '../../../../validation-schemas/insurance-investigation-locate-driver-details-schema';
import { updateActiveFormInsuranceInvestigationLocateDriversDetails, selectActiveFormInsuranceInvestigationLocateDriversDetails, selectActiveFormInsuranceInvestigationLocateDetails } from '../../../../reducers/active-form-slice';


const IntakeInsuranceInvestigationLocateDriversDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const insuranceInvestigationLocateDriversDetails = useSelector(selectActiveFormInsuranceInvestigationLocateDriversDetails);
    const hasValues = Object.keys(insuranceInvestigationLocateDriversDetails).length > 0 ? true : false;
    const { registeredOwnersName, registeredOwnersAddress, registeredOwnersAddress2, registeredOwnersCity, registeredOwnersCountry, registeredOwnersStateProvince, registeredOwnersZip, registeredOwnerIsDriver } = useSelector(selectActiveFormInsuranceInvestigationLocateDetails);


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/insurance-investigation-locate/details");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormInsuranceInvestigationLocateDriversDetails(values));
        navigate("/intake/insurance-investigation-locate/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        driversName: registeredOwnerIsDriver === 'Yes' ? registeredOwnersName : '',
        address: registeredOwnerIsDriver === 'Yes' ? registeredOwnersAddress : '',
        address2: registeredOwnerIsDriver === 'Yes' ? registeredOwnersAddress2 : '',
        city: registeredOwnerIsDriver === 'Yes' ? registeredOwnersCity : '',
        stateProvince: registeredOwnerIsDriver === 'Yes' ? registeredOwnersStateProvince : '',
        zip: registeredOwnerIsDriver === 'Yes' ? registeredOwnersZip : '',
        country: registeredOwnerIsDriver === 'Yes' ? registeredOwnersCountry : '',
        dob: '',
        driversLicensePlate: '',
        driversLicenseNumber: '',
        driversInsuranceCompany: '',
        policy: '',
        claim: '',
    };


    return (
        <div className="page intake">
            <h2>Driver's Details</h2>

            <Formik
                initialValues={hasValues ? insuranceInvestigationLocateDriversDetails : initialValues}
                validationSchema={InsuranceInvestigationLocateDriversDetailsSchema.fields.insuranceInvestigationLocateDriversDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeInsuranceInvestigationLocateDriversDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`60`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInsuranceInvestigationLocateDriversDetails;