import { Field } from "formik";
import { useState } from "react";
import { stateProvince } from '../../../utility/state-province';
import StateProvinceDropdown from "../../stateprovinceDropdown";

const IntakeAssetLocateRequestFields = ({ errors, touched, nested, values }) => {
    const [assetLocateRequestState, setAssetLocateRequestState] = useState(false);

    return (
        <div className="form-wrapper asset-locate-request">
            <h3>I am Requesting... (Check all that apply)</h3>

            <div className="asset-locate-type">
                <div role="group" aria-labelledby="assetLocate-type-radio-group" className="form-check-inline">
                    <div className="form-check">
                        <Field type="checkbox" name={nested ? 'assetLocateRequest.assetLocateRequest' : 'assetLocateRequest'} value="State Bank Located in the State of" id="State Bank Located in the State of" onClick={(e) => {setAssetLocateRequestState(!assetLocateRequestState)}}/>
                        <label htmlFor="State Bank Located in the State of">State Bank Located in the State of</label>
                    </div>
                    <div className="form-check">
                        <Field type="checkbox" name={nested ? 'assetLocateRequest.assetLocateRequest' : 'assetLocateRequest'} value="National Bank Locate" id="National Bank Locate" />
                        <label htmlFor="National Bank Locate">National Bank Locate</label>
                    </div>
                    <div className="form-check">
                        <Field type="checkbox" name={nested ? 'assetLocateRequest.assetLocateRequest' : 'assetLocateRequest'} value="National Bank and Brokerage Locate" id="National Bank and Brokerage Locate" />
                        <label htmlFor="National Bank and Brokerage Locate">National Bank and Brokerage Locate</label>
                    </div>
                    <div className="form-check">
                        <Field type="checkbox" name={nested ? 'assetLocateRequest.assetLocateRequest' : 'assetLocateRequest'} value="National Brokerage Locate Only" id="National Brokerage Locate Only" />
                        <label htmlFor="National Brokerage Locate Only">National Brokerage Locate Only</label>
                    </div>
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? 'assetLocateRequest.stateProvinceRequested' : 'stateProvinceRequested'} id="stateProvinceRequested" disabled={!assetLocateRequestState} required={true}/>
            </div>
        </div>
    );
};

export default IntakeAssetLocateRequestFields;