import { Field } from "formik";
import { stateProvince } from "../../../../../utility/state-province";
import { DatePickerInput } from "../../../../datepicker-input";
import StateProvinceDropdown from "../../../../stateprovinceDropdown";

const IntakeInsuranceInvestigationPersonalHomeownersRentersDetailsFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <div className="form-row">
                <div className="form-field-wrapper">
                    <label className="left-justified" htmlFor="insuredType">Type <span className="required">*</span></label>

                    <div role="group" aria-labelledby="insuredType-radio-group" className="form-check-inline single-col">
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuredType" : "insuredType"} value="Homeowners" id="homeowners" />
                            <label htmlFor="homeowners">Homeowners</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuredType" : "insuredType"} value="Renters" id="renters" />
                            <label htmlFor="renters">Renters</label>
                        </div>
                    </div>

                    {errors && errors.type && touched && touched.type ? (
                        <div className="form-error">{errors.type}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="dateOfLoss">Date of Loss <span className="required">*</span></label>
                    <DatePickerInput name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.dateOfLoss" : "dateOfLoss"} placeholderText="Select date" />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="insuredName">Insured's Name</label>
                    <Field id="insuredName" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuredName" : "insuredName"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredAddress">Address</label>
                    <Field id="insuredAddress" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuredAddress" : "insuredAddress"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredAddress2">Address 2</label>
                    <Field id="insuredAddress2" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuredAddress2" : "insuredAddress2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredCity">City</label>
                    <Field id="insuredCity" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuredCity" : "insuredCity"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuredStateProvince" : "insuredStateProvince"} id="insuredStateProvince" disabled={false} className={`${(values.insuredStateProvince && (values.insuredStateProvince.length <= 0 || values.insuredStateProvince === 'Make a selection')) ? 'placeholder' : ''}`}/>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredZip">Zip</label>
                    <Field id="insuredZip" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuredZip" : "insuredZip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredCountry">Country</label>
                    <Field id="insuredCountry" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuredCountry" : "insuredCountry"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredDob">Date of Birth</label>
                    <DatePickerInput name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuredDob" : "insuredDob"} placeholderText="Select date" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredSsn">SSN</label>
                    <Field id="insuredSsn" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuredSsn" : "insuredSsn"} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="homeownerRentersName">Homeowner/Renter's Name</label>
                    <Field id="homeownerRentersName" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.homeownerRentersName" : "homeownerRentersName"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lossAddress">Address of Loss</label>
                    <Field id="lossAddress" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.lossAddress" : "lossAddress"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lossAddress2">Address of Loss 2</label>
                    <Field id="lossAddress2" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.lossAddress2" : "lossAddress2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lossCity">City</label>
                    <Field id="lossCity" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.lossCity" : "lossCity"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.lossState" : "lossState"} id="lossState" disabled={false} className={`${(values.lossState && (values.lossState.length <= 0 || values.lossState === 'Make a selection')) ? 'placeholder' : ''}`}/>

                <div className="form-field-wrapper">
                    <label htmlFor="lossZip">Zip</label>
                    <Field id="lossZip" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.lossZip" : "lossZip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lossCountry">Country</label>
                    <Field id="lossCountry" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.lossCountry" : "lossCountry"} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="insuranceCompany">Insurance Company <span className="required">*</span></label>
                    <Field id="insuranceCompany" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.insuranceCompany" : "insuranceCompany"} />

                    {errors && errors.insuranceCompany && touched && touched.insuranceCompany ? (
                        <div className="form-error">{errors.insuranceCompany}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="policy">Policy #</label>
                    <Field id="policy" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.policy" : "policy"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="claim">Claim #</label>
                    <Field id="claim" name={nested ? "insuranceInvestigationPersonalHomeownersRentersDetails.claim" : "claim"} />
                </div>
            </div>
        </div>
    );
};

export default IntakeInsuranceInvestigationPersonalHomeownersRentersDetailsFields;