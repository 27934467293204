export const permissionLevels = 
    {
        Administrator: "225ad3a3-fc2c-4b3f-abbd-114d525e5dad",
        Manager: "4d58c0a2-f3af-420a-be58-28fa348f030e",
        Onboarding: "3b77074a-04ce-425d-b78a-084470474525",
        InternalInvestigator: "04cc5dd1-7fed-4aad-996b-313a55815508",
        FieldInvestigator: "615b25d4-161a-486b-8982-84e7ce1d50fd",
        FieldInvestigatorManager: "fee22dce-1905-43c8-ac9a-5d6502b06c09",
        PortalUser: "3ce0c271-a023-4c6c-955e-d023c8364d39",
        Accounting: "89ec3bc4-1b1b-4bab-88f8-dcc25c0db08b",
        OrderCreator: "b0f0a485-9cb1-4d7e-afa8-f92eedff3308",
        CaseManagerLevel1: "5f23d090-8c26-484d-abce-d3cdc80a33ae",
        CaseManagerLevel2: "73b6b576-b75b-4ca0-93ae-86cfe26d2209"
    }