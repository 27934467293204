export const billingCodes = [
    {
        code: "PL001",
        label: "Auto Insurance Policy Trace(s) Policy Limits*",
    },
    {
        code: "PL002",
        label: "Auto/Umbrella Combo Special (hit on umbrella $100/no hit - no fee)",
    },
    {
        code: "CPL001",
        label: "Commercial Policy Search",
    },
    {
        code: "HPL001",
        label: "Home/Property/Umbrella Insurance (unknown)",
    },
    {
        code: "HPL002",
        label: "Home/Property/Umbrella Insurance (known)",
    },
    {
        code: "RUSH",
        label: "Rush Fee",
    },
    {
        code: "PL004",
        label: "Search without a Defendant's Address",
    },
    {
        code: "PL003",
        label: "USAA",
    },
    {
        code: "LC001",
        label: "Locate Insurance Carrier with Limits",
    },
    {
        code: "NO001",
        label: "To Obtain Policy Limits with no Address and DOB",
    },
    {
        code: "LC002",
        label: "Locate Insurance Carrier Only",
    },
    {
        code: "NOLC001",
        label: "To Locate Insurance Carrier with Limits with Name Only",
    },
    {
        code: "NOLC002",
        label: "To Locate Insurance Carrier With Name Only",
    },
    {
        code: "PL005",
        label: "Policy Limit Search DOL 1-3 Years from today's date",
    },
    {
        code: "ISO",
        label: "Claim History Search (ISO)",
    },
    {
        code: "CANCEL",
        label: "Cancelation Fee",
    },
    {
        code: "",
        label: "Cancel - No Charge"
    },
    {
        code: "",
        label: "No Hit - No Charge"
    },
    {
        code: "",
        label: "No Hit - Charge"
    },
];