import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../../components/form-footer';
import IntakeInsuranceInvestigationBusinessDriversDetailsFields from '../../../../components/intake/insurance-investigation/business/drivers-details-fields';
import InsuranceInvestigationBusinessDriversDetailsSchema from '../../../../validation-schemas/insurance-investigation-business-driver-details-schema';
import { updateActiveFormInsuranceInvestigationBusinessDriversDetails, selectActiveFormInsuranceInvestigationBusinessDetails, selectActiveFormInsuranceInvestigationBusinessDriversDetails } from '../../../../reducers/active-form-slice';


const IntakeInsuranceInvestigationBusinessDriversDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const insuranceInvestigationBusinessDriversDetails = useSelector(selectActiveFormInsuranceInvestigationBusinessDriversDetails);
    const hasValues = Object.keys(insuranceInvestigationBusinessDriversDetails).length > 0 ? true : false;
    const { ownersName, ownersAddress, ownersAddress2, ownersCity, ownersCountry, ownersStateProvince, ownersZip, ownerIsDriver } = useSelector(selectActiveFormInsuranceInvestigationBusinessDetails);


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/insurance-investigation-business/details");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormInsuranceInvestigationBusinessDriversDetails(values));
        navigate("/intake/insurance-investigation-business/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        driversName: ownerIsDriver === 'Yes' ? ownersName : '',
        address: ownerIsDriver === 'Yes' ? ownersAddress : '',
        address2: ownerIsDriver === 'Yes' ? ownersAddress2 : '',
        city: ownerIsDriver === 'Yes' ? ownersCity : '',
        stateProvince: ownerIsDriver === 'Yes' ? ownersStateProvince : '',
        zip: ownerIsDriver === 'Yes' ? ownersZip : '',
        country: ownerIsDriver === 'Yes' ? ownersCountry : '',
        dob: '',
        driversLicensePlate: '',
        driversLicenseNumber: '',
        driversInsuranceCompany: '',
        policy: '',
        claim: '',
    };


    return (
        <div className="page intake">
            <h2>Driver's Details</h2>

            <Formik
                initialValues={hasValues ? insuranceInvestigationBusinessDriversDetails : initialValues}
                validationSchema={InsuranceInvestigationBusinessDriversDetailsSchema.fields.insuranceInvestigationBusinessDriversDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeInsuranceInvestigationBusinessDriversDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`70`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInsuranceInvestigationBusinessDriversDetails;