import BaseAPI from '../utility/fetch-wrapper';

class FileUpload {

    static Download = (fileId) => {
        BaseAPI.downloadFile(`fileupload/downloadFile?fileId=${fileId}`);
    }

    static Upload = async (payload, orderSummaryId, portalUserId) => {
        const uploadPromises = [];

        for (let index in payload) {
            let item = payload[index];

            const formData = new FormData();

            // Update the formData object 
            formData.append(
                "upload",
                item,
                item.name
            );

            // post file to storage
            await BaseAPI.uploadFile(`fileupload/uploadFile?orderId=${orderSummaryId}&portalUserId=${portalUserId}`, formData);

            // setTimeout(() => {uploadPromises.push(BaseAPI.uploadFile(`fileupload/uploadFile?orderId=${orderSummaryId}&portalUserId=${portalUserId}`, formData))}, 250);
        }

        // // return upload results when all files are uploaded
        // await Promise.all(uploadPromises, (res) => {
        //     console.log('all done', res);
        // })
        
        return await this.GetFiles(orderSummaryId);
    };

    static GetFiles = async (orderSummaryId) => {
        return BaseAPI.getRequest(`fileupload/getOrderFiles?orderSummaryId=${orderSummaryId}`);
    }

    static Delete = (fileId) => {
        BaseAPI.getRequest(`fileupload/deleteFile?fileId=${fileId}`);
    };

}


export default FileUpload;