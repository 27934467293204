import Modal from 'react-bootstrap/Modal';


const SubmissionFailureModal = ({ showSubmissionFailureModal, handleHideSubmissionFailureModal, failureMessage, continueOrder }) => {
    return (
        <Modal
            backdrop="static"
            show={showSubmissionFailureModal}
            onHide={handleHideSubmissionFailureModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Error in Submitting the Order</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="form-field-wrapper">
                    {failureMessage}
                </div>
            </Modal.Body>

            <Modal.Footer>
                {(failureMessage.includes("email") || failureMessage.includes("attachment")) && <button className="button tertiary" onClick={continueOrder}>Next</button>}
            </Modal.Footer>
        </Modal>
    );
};

export default SubmissionFailureModal;