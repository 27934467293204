import { Field } from "formik";
import FileInput from "../file-input";


const IntakeAdditionalInformationFields = ({ handleUpload, handleUploadedRemoval, uploadedFiles, uploadStatus, nested }) => {
    return (
        <div className="form-wrapper additional-information">
            <div className="form-row single-col">
                <div className="form-field-wrapper">
                    <label htmlFor="additionalInformation">Additional Information</label>
                    <Field id="additionalInformation" name={nested ? 'additionalInformation.additionalInformation' : 'additionalInformation'} as="textarea" rows="5" />
                </div>
            </div>

            <div className="form-row single-col">
                <FileInput
                    uploadedFiles={uploadedFiles}
                    handleUpload={handleUpload}
                    handleUploadedRemoval={handleUploadedRemoval}
                    uploadStatus={uploadStatus}
                    isIntake={true}
                    intakeStep={'additional'}
                />

                <Field id="files" ame={nested ? 'additionalInformation.files' : 'files'} type="hidden" />
            </div>
        </div>
    );
};

export default IntakeAdditionalInformationFields;