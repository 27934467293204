import ProgressBar from 'react-bootstrap/ProgressBar';
import IcoArrowRight from '../assets/ico-arrow-right.svg';
import IcoArrowLeft from '../assets/ico-arrow-left.svg';


const FormFooter = ({ disableNext, handlePrevious, progress, showNext, showPrevious }) => {
    return (
        <div className="form-footer">
            <div className="form-footer-left">
                {showPrevious &&
                    <button type="button" className="form-previous" onClick={handlePrevious}>
                        <img src={IcoArrowLeft} alt="<" /> Back
                    </button>
                }
            </div>
            <div className="form-footer-middle">
                <ProgressBar now={progress} />
            </div>
            <div className="form-footer-right">
                {showNext &&
                    <button type="submit" className="form-submit" disabled={disableNext}>
                        Next <img src={IcoArrowRight} alt=">" />
                    </button>
                }
            </div>
        </div>
    );
};

export default FormFooter;