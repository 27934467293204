import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import InvestigationTypesServices from '../services/investigation-type-service';


export const fetchInvestigationTypes = createAsyncThunk('investigationTypes/fetchInvestigationTypes', async () => {
    const response = await InvestigationTypesServices.GetInvestigationTypes();
    return response;
});


export const investigationTypesSlice = createSlice({
    name: 'investigationTypes',

    initialState: {
        investigationTypes: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetInvestigationTypesStatus: (state) => {
            state.status = 'idle';
        },
    },

    extraReducers: {
        [fetchInvestigationTypes.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchInvestigationTypes.fulfilled]: (state, action) => {
            var payload = action.payload;
            const sortedPayload = payload.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
            state.status = 'succeeded';
            state.investigationTypes = state.investigationTypes.concat(sortedPayload);
        },
        [fetchInvestigationTypes.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    }
});

export const {
    resetInvestigationTypesStatus,
} = investigationTypesSlice.actions;


export default investigationTypesSlice.reducer;


export const selectAllInvestigationTypes = state => {
    return state.investigationTypes.investigationTypes;
};