import * as Yup from 'yup';
import { phoneExpression } from '../utility/phone-expression';


const SkipTraceDetailsSchema = Yup.object().shape({
    skipTraceDetails: Yup.object().shape({
        firstName: Yup.string()
            .required('Required'),
        lastName: Yup.string()
            .required('Required'),
        phone: Yup.string()
            .matches(phoneExpression, 'Invalid phone number'),
    })
});

export default SkipTraceDetailsSchema;