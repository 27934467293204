import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LawFirmsService from '../services/law-firms-service';


export const fetchLawFirms = createAsyncThunk('lawFirms/fetchLawFirms', async (payload) => {
    const data = await LawFirmsService.GetLawFirms();
    return data;
});


export const lawFirmsSlice = createSlice({
    name: 'lawFirms',

    initialState: {
        lawFirms: [],
        status: 'idle',
        error: null,
    },

    reducers: {
        resetLawFirmsStatus: (state) => {
            state.status = 'idle';
        },
    },

    extraReducers: {
        [fetchLawFirms.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchLawFirms.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.lawFirms = action.payload;
        },
        [fetchLawFirms.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetLawFirmsStatus,
} = lawFirmsSlice.actions;

export default lawFirmsSlice.reducer;


export const selectAllLawFirms = state => {
    return state.lawFirms.lawFirms;
};