import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


const StatusModal = ({ showStatusModal, handleCloseStatusModal, handleStatusButton, handleStatusChange }) => {
    return (
        <Modal
            backdrop="static"
            show={showStatusModal}
            onHide={handleCloseStatusModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Update all selected orders' status to:</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p><strong>Status</strong></p>
                <Form.Select onChange={handleStatusChange}>
                    <option disabled selected>Select Status</option>
                    <option>Assigned</option>
                    <option>Canceled</option>
                    <option>Complete</option>
                    <option>In Billing</option>
                    <option>In Process</option>
                    <option>In Review</option>
                    <option>New</option>
                    <option>QA</option>
                    <option>Ready for Assignment</option>
                    <option>Report Ordered</option>
                    <option>Report Received</option>
                    <option>Sent to Client</option>
                    <option>Waiting on Client</option>
                </Form.Select>
            </Modal.Body>

            <Modal.Footer>
                <button className="button tertiary" onClick={handleStatusButton}>Update Status</button>
            </Modal.Footer>
        </Modal>
    );
};

export default StatusModal;