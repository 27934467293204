import * as Yup from 'yup';
import { phoneExpression } from '../../../utility/phone-expression';
import GeneralInformationSchema from '../../../validation-schemas/general-information-schema';


const BuildSchema = (investigationType) => {
    const GeneralInformationSchemaFields = GeneralInformationSchema.fields;

    switch (investigationType[1]) {
        case "6859dfec-df01-492f-8def-50bb130d2740":
            const AssetLocateRequestSchema = require('../../../validation-schemas/asset-locate-request-schema');
            const AssetLocateTypeSchema = require('../../../validation-schemas/asset-locate-type-schema');

            let AssetLocateRequestSchemaFields = Object.assign({}, AssetLocateRequestSchema.default.fields);
            let AssetLocateTypeSchemaFields = Object.assign({}, AssetLocateTypeSchema.default.fields);

            const AssetLocateSchema = {
                ...GeneralInformationSchemaFields,
                ...AssetLocateRequestSchemaFields,
                ...AssetLocateTypeSchemaFields,
                assetLocateDetails: Yup.object().when('assetLocateType.assetLocateType', (val) => {
                    if (val === "Subject/Target") {
                        return (
                            Yup.object().shape({
                                firstName: Yup.string()
                                    .required('Required'),
                                lastName: Yup.string()
                                    .required('Required'),
                                address: Yup.string()
                                    .required('Required'),
                                city: Yup.string()
                                    .required('Required'),
                                stateProvince: Yup.string()
                                    .required('Required'),
                                zip: Yup.string()
                                    .required('Required'),
                                country: Yup.string()
                                    .required('Required'),
                                dob: Yup.string()
                                    .required('Required'),
                            })
                        );
                    } else {
                        return (
                            Yup.object().shape({
                                businessName: Yup.string()
                                    .required('Required'),
                                address: Yup.string()
                                    .required('Required'),
                                city: Yup.string()
                                    .required('Required'),
                                stateProvince: Yup.string()
                                    .required('Required'),
                                zip: Yup.string()
                                    .required('Required'),
                                country: Yup.string()
                                    .required('Required'),
                            })
                        );
                    }
                }),
            };

            return AssetLocateSchema;
        case "b7fdc4fd-0629-4d64-8751-65080436b4ed":
            const BackgroundScreeningDetailsSchema = require('../../../validation-schemas/background-screening-details-schema');

            let BackgroundScreeningDetailsSchemaFields = Object.assign({}, BackgroundScreeningDetailsSchema.default.fields);

            const BackgroundScreeningSchema = {
                ...GeneralInformationSchemaFields,
                ...BackgroundScreeningDetailsSchemaFields,
                backgroundScreeningSocialMedia: Yup.object().when('backgroundScreeningDetails.socialMedia', (val) => {
                    if (val === "Yes") {
                        return (
                            Yup.object().shape({
                                socialMediaFirstName: Yup.string()
                                    .required('Required'),
                                socialMediaLastName: Yup.string()
                                    .required('Required'),
                                socialMediaEmail: Yup.string()
                                    .email('Invalid email'),
                                socialMediaPhone: Yup.string()
                                    .matches(phoneExpression, 'Invalid phone number'),
                                socialMediaZip: Yup.string()
                                    .required('Required'),
                                socialMediaCountry: Yup.string()
                                    .required('Required'),
                            })
                        );
                    } else {
                        Yup.object().shape({})
                    }
                }),
            }

            return BackgroundScreeningSchema;
        case "05a7fe17-8fb5-442b-aaa0-d9c99476228c":
            const BusinessDueDiligenceDetailsSchema = require('../../../validation-schemas/business-due-diligence-details-schema');

            let BusinessDueDiligenceDetailsSchemaFields = Object.assign({}, BusinessDueDiligenceDetailsSchema.default.fields);

            const BusinessDueDiligenceSchema = {
                ...GeneralInformationSchemaFields,
                ...BusinessDueDiligenceDetailsSchemaFields
            }

            return BusinessDueDiligenceSchema;
        case "112f034e-c54b-4307-bc1d-ce68d3a69fe7":
            const DmvDetailsSchema = require('../../../validation-schemas/dmv-details-schema');

            let DmvDetailsSchemaFields = Object.assign({}, DmvDetailsSchema.default.fields);

            const DMVSchema = {
                ...GeneralInformationSchemaFields,
                ...DmvDetailsSchemaFields
            }

            return DMVSchema;
        case "fc25e0ba-f2f1-41e9-afc8-2da980ed53fe":
            const InsuranceClaimHistoryDetailsSchema = require('../../../validation-schemas/insurance-claim-history-details-schema');

            let InsuranceClaimHistoryDetailsSchemaFields = Object.assign({}, InsuranceClaimHistoryDetailsSchema.default.fields);

            const InsuranceClaimHistoryISOSchema = {
                ...GeneralInformationSchemaFields,
                ...InsuranceClaimHistoryDetailsSchemaFields,
                insuranceClaimHistorySubject: Yup.object().when('insuranceClaimHistoryDetails.insuranceClaimHistoryDetails', (val) => {
                    if (val === "No") {
                        return (
                            Yup.object().shape({
                                firstName: Yup.string()
                                    .required('Required'),
                                lastName: Yup.string()
                                    .required('Required'),
                                email: Yup.string()
                                    .email('Invalid email'),
                                phone: Yup.string()
                                    .matches(phoneExpression, 'Invalid phone number'),
                            })
                        );
                    } else {
                        Yup.object().shape({})
                    }
                }),
            }

            return InsuranceClaimHistoryISOSchema;
        case "cbd61d9e-7c34-4bbb-af1f-2cbf84321034":
            const InsuranceInvestigationBusinessDetailsSchema = require('../../../validation-schemas/insurance-investigation-business-details-schema');
            const InsuranceInvestigationBusinessAdditionalInformationSchema = require('../../../validation-schemas/insurance-investigation-business-additional-information-schema');

            let InsuranceInvestigationBusinessDetailsSchemaFields = Object.assign({}, InsuranceInvestigationBusinessDetailsSchema.default.fields);
            let InsuranceInvestigationBusinessAdditionalInformationSchemaFields = Object.assign({}, InsuranceInvestigationBusinessAdditionalInformationSchema.default.fields);

            const InsuranceInvestigationBusinessSchema = {
                ...GeneralInformationSchemaFields,
                ...InsuranceInvestigationBusinessDetailsSchemaFields,
                ...InsuranceInvestigationBusinessAdditionalInformationSchemaFields,
                insuranceInvestigationBusinessDriversDetails: Yup.object().when('insuranceInvestigationBusinessDetails.ownerIsDriver', (val) => {
                    if (val === "No") {
                        return (
                            Yup.object().shape({
                                driversInsuranceCompany: Yup.string()
                                    .required('Required'),
                            })
                        );
                    } else {
                        Yup.object().shape({})
                    }
                }),
            }

            return InsuranceInvestigationBusinessSchema;
        case "dd7b99f9-5394-4d46-87f3-3a0804a8738a":
            const InsuranceInvestigationLocateDetailsSchema = require('../../../validation-schemas/insurance-investigation-locate-details-schema');
            const InsuranceInvestigationLocateAdditionalInformationSchema = require('../../../validation-schemas/insurance-investigation-locate-additional-information-schema');

            let InsuranceInvestigationLocateDetailsSchemaFields = Object.assign({}, InsuranceInvestigationLocateDetailsSchema.default.fields);
            let InsuranceInvestigationLocateAdditionalInformationSchemaFields = Object.assign({}, InsuranceInvestigationLocateAdditionalInformationSchema.default.fields);

            const InsuranceInvestigationLocateSchema = {
                ...GeneralInformationSchemaFields,
                ...InsuranceInvestigationLocateDetailsSchemaFields,
                ...InsuranceInvestigationLocateAdditionalInformationSchemaFields,
                insuranceInvestigationLocateDriversDetails: Yup.object().when('insuranceInvestigationLocateDetails.registeredOwnerIsDriver', (val) => {
                    if (val === "No") {
                        return (
                            Yup.object().shape({
                                driversInsuranceCompany: Yup.string()
                                    .required('Required'),
                            })
                        );
                    } else {
                        Yup.object().shape({})
                    }
                }),
            }

            return InsuranceInvestigationLocateSchema;
        case "3fe7eb42-7b28-4146-8fdd-f4012ce62bc5":
            const InsuranceInvestigationPersonalAutoUmbrellaDetailsSchema = require('../../../validation-schemas/insurance-investigation-personal-auto-umbrella-details-schema');
            const InsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationSchema = require('../../../validation-schemas/insurance-investigation-personal-auto-umbrella-addition-information-schema');

            let InsuranceInvestigationPersonalAutoUmbrellaDetailsSchemaFields = Object.assign({}, InsuranceInvestigationPersonalAutoUmbrellaDetailsSchema.default.fields);
            let InsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationSchemaFields = Object.assign({}, InsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationSchema.default.fields);

            const InsuranceInvestigationPersonalAutoANDORUmbrellaSchema = {
                ...GeneralInformationSchemaFields,
                ...InsuranceInvestigationPersonalAutoUmbrellaDetailsSchemaFields,
                ...InsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationSchemaFields,
                insuranceInvestigationPersonalAutoUmbrellaDriversDetails: Yup.object().when('insuranceInvestigationPersonalAutoUmbrellaDetails.ownerIsDriver', (val) => {
                    if (val === "No") {
                        return (
                            Yup.object().shape({
                                driversInsuranceCompany: Yup.string()
                                    .required('Required'),
                            })
                        );
                    } else {
                        Yup.object().shape({})
                    }
                }),
            }

            return InsuranceInvestigationPersonalAutoANDORUmbrellaSchema;
        case "b03f65f3-f6c5-42b6-af5b-c34a958bc6ec":
            const InsuranceInvestigationPersonalHomeownersRentersDetailsSchema = require('../../../validation-schemas/insurance-investigation-personal-homeowners-renters-details-schema');
            const InsuranceInvestigationPersonalHomeownersRentersAdditionalInformationSchema = require('../../../validation-schemas/insurance-investigation-personal-homeowners-renters-additional-information-schema');

            let InsuranceInvestigationPersonalHomeownersRentersDetailsSchemaFields = Object.assign({}, InsuranceInvestigationPersonalHomeownersRentersDetailsSchema.default.fields);
            let InsuranceInvestigationPersonalHomeownersRentersAdditionalInformationSchemaFields = Object.assign({}, InsuranceInvestigationPersonalHomeownersRentersAdditionalInformationSchema.default.fields);

            const InsuranceInvestigationPersonalHomeownersORRentersSchema = {
                ...GeneralInformationSchemaFields,
                ...InsuranceInvestigationPersonalHomeownersRentersDetailsSchemaFields,
                ...InsuranceInvestigationPersonalHomeownersRentersAdditionalInformationSchemaFields,
            }

            return InsuranceInvestigationPersonalHomeownersORRentersSchema;
        case "77cf5024-fffe-48ac-ade7-a4ca318b1290":
            const SceneInvestigationDetailsSchema = require('../../../validation-schemas/scene-investigation-details-schema');

            let SceneInvestigationDetailsSchemaFields = Object.assign({}, SceneInvestigationDetailsSchema.default.fields);

            const SceneInvestigationSchema = {
                ...GeneralInformationSchemaFields,
                ...SceneInvestigationDetailsSchemaFields
            }

            return SceneInvestigationSchema;
        case "8327fb74-d3ff-4561-b189-8e80fde1b3af":
            const SkipTraceDetailsSchema = require('../../../validation-schemas/skip-trace-details-schema');

            let SkipTraceDetailsSchemaFields = Object.assign({}, SkipTraceDetailsSchema.default.fields);

            const SkipTraceSchema = {
                ...GeneralInformationSchemaFields,
                ...SkipTraceDetailsSchemaFields
            }

            return SkipTraceSchema;
        case "918a0ae1-4e91-49a2-a595-190f6b87e8dc":
            const SubpoenaProcessServingDetailsSchema = require('../../../validation-schemas/subpoena-process-serving-details-schema');

            let SubpoenaProcessServingDetailsSchemaFields = Object.assign({}, SubpoenaProcessServingDetailsSchema.default.fields);

            const SubpoenaProcessServingSchema = {
                ...GeneralInformationSchemaFields,
                ...SubpoenaProcessServingDetailsSchemaFields
            }

            return SubpoenaProcessServingSchema;
        case "26a9c1ea-45a1-4181-8665-9cdc33721f71":
            const TrafficCollisionsReportRetrievalDetailsSchema = require('../../../validation-schemas/traffic-collisions-report-retrieval-details-schema');

            let TrafficCollisionsReportRetrievalDetailsSchemaFields = Object.assign({}, TrafficCollisionsReportRetrievalDetailsSchema.default.fields);

            const TrafficCollisionsReportRetrievalSchema = {
                ...GeneralInformationSchemaFields,
                ...TrafficCollisionsReportRetrievalDetailsSchemaFields
            }

            return TrafficCollisionsReportRetrievalSchema;
        case "3a25f588-0e8f-451d-978d-711502131631":
            const WitnessDefendantStatementDetailsSchema = require('../../../validation-schemas/witness-defendant-statement-defendant-details');
            const WitnessDefendantStatementDefendantDetailsSchema = require('../../../validation-schemas/witness-defendant-statement-defendant-details');
            const WitnessDefendantStatementWitnessDetailsSchema = require('../../../validation-schemas/witness-defendant-statement-witness-details');

            let WitnessDefendantStatementDetailsSchemaFields = Object.assign({}, WitnessDefendantStatementDetailsSchema.default.fields);
            let WitnessDefendantStatementDefendantDetailsSchemaFields = Object.assign({}, WitnessDefendantStatementDefendantDetailsSchema.default.fields);
            let WitnessDefendantStatementWitnessDetailsSchemaFields = Object.assign({}, WitnessDefendantStatementWitnessDetailsSchema.default.fields);

            const WitnessDefendantStatementSchema = {
                ...GeneralInformationSchemaFields,
                ...WitnessDefendantStatementDetailsSchemaFields,
                witnessDefendantStatementDefendantDetails: Yup.object().when('witnessDefendantStatementDetails.statementType', (val) => {
                    if (val === "Defendant" || val === "Both") {
                        return (
                            Yup.object().shape({
                                defendants: Yup.array()
                                    .of(
                                        Yup.object().shape({
                                            firstName: Yup.string()
                                                .required('Required'),
                                            lastName: Yup.string()
                                                .required('Required'),
                                            phone: Yup.string()
                                                .matches(phoneExpression, 'Invalid phone number')
                                                .required('Required'),
                                            directionOfTravel: Yup.string()
                                                .required('Required'),
                                            streetOfTravel: Yup.string()
                                                .required('Required'),
                                            defendantVehicleDescription: Yup.string()
                                                .required('Required'),
                                        })
                                    )
                                    .required('Must have at least one defendat')
                                    .min(1, 'Minimum of 1 defendant'),
                            })
                        );
                    } else {
                        Yup.object().shape({})
                    }
                }),
                witnessDefendantStatementWitnessDetails: Yup.object().when('witnessDefendantStatementDetails.statementType', (val) => {
                    if (val === "Witness" || val === "Both") {
                        return (
                            Yup.object().shape({
                                witnesses: Yup.array()
                                    .of(
                                        Yup.object().shape({
                                            firstName: Yup.string()
                                                .required('Required'),
                                            lastName: Yup.string()
                                                .required('Required'),
                                            phone: Yup.string()
                                                .matches(phoneExpression, 'Invalid phone number')
                                                .required('Required'),
                                        })
                                    )
                                    .required('Must have at least one witness')
                                    .min(1, 'Minimum of 1 witness'),
                            })
                        );
                    } else {
                        Yup.object().shape({})
                    }
                }),
            };

            return WitnessDefendantStatementSchema;
        default:
            const DefaultSchema = {
                ...GeneralInformationSchemaFields,
            };

            return DefaultSchema;
    }
};

export default BuildSchema;