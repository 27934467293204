import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../../../components/form-footer';
import IntakeInsuranceInvestigationPersonalAutoUmbrellaDriversDetailsFields from '../../../../../components/intake/insurance-investigation/personal/auto-umbrella/drivers-details-fields';
import InsuranceInvestigationPersonalAutoUmbrellaDriversDetailsSchema from '../../../../../validation-schemas/insurance-investigation-personal-auto-umbrella-driver-details-schema';
import { updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaDriversDetails, selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaDetails, selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaDriversDetails } from '../../../../../reducers/active-form-slice';


const IntakeInsuranceInvestigationPersonalAutoUmbrellaDriversDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const insuranceInvestigationPersonalAutoUmbrellaDriversDetails = useSelector(selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaDriversDetails);
    const hasValues = Object.keys(insuranceInvestigationPersonalAutoUmbrellaDriversDetails).length > 0 ? true : false;
    const { ownersName, ownersAddress, ownersAddress2, ownersCity, ownersCountry, ownersStateProvince, ownersZip, ownerIsDriver } = useSelector(selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaDetails);


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/insurance-investigation-personal-auto-umbrella/details");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaDriversDetails(values));
        navigate("/intake/insurance-investigation-personal-auto-umbrella/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        driversName: ownerIsDriver === 'Yes' ? ownersName : '',
        address: ownerIsDriver === 'Yes' ? ownersAddress : '',
        address2: ownerIsDriver === 'Yes' ? ownersAddress2 : '',
        city: ownerIsDriver === 'Yes' ? ownersCity : '',
        stateProvince: ownerIsDriver === 'Yes' ? ownersStateProvince : '',
        zip: ownerIsDriver === 'Yes' ? ownersZip : '',
        country: ownerIsDriver === 'Yes' ? ownersCountry : '',
        dob: '',
        driversLicensePlate: '',
        driversInsuranceCompany: '',
        policy: '',
        claim: '',
    };


    return (
        <div className="page intake">
            <h2>Driver's Details</h2>

            <Formik
                initialValues={hasValues ? insuranceInvestigationPersonalAutoUmbrellaDriversDetails : initialValues}
                validationSchema={InsuranceInvestigationPersonalAutoUmbrellaDriversDetailsSchema.fields.insuranceInvestigationPersonalAutoUmbrellaDriversDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeInsuranceInvestigationPersonalAutoUmbrellaDriversDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`50`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInsuranceInvestigationPersonalAutoUmbrellaDriversDetails;