import * as Yup from 'yup';
import { phoneExpression } from '../utility/phone-expression';


const WitnessDefendantStatementWitnessDetailsSchema = Yup.object().shape({
    witnessDefendantStatementWitnessDetails: Yup.object().shape({
        witnesses: Yup.array()
            .of(
                Yup.object().shape({
                    firstName: Yup.string()
                        .required('Required'),
                    lastName: Yup.string()
                        .required('Required'),
                    phone: Yup.string()
                        .matches(phoneExpression, 'Invalid phone number')
                        .required('Required'),
                })
            )
            .required('Must have at least one witness')
            .min(1, 'Minimum of 1 witness'),
    })
});


export default WitnessDefendantStatementWitnessDetailsSchema;