import * as Yup from 'yup';


const InsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationSchema = Yup.object().shape({
    insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation: Yup.object().shape({
        letterOfAcknowledgement: Yup.string()
            .required('Required'),
    })
});

export default InsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationSchema;