import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from "../../../components/form-footer";
import { selectActiveFormWitnessDefendantStatementDetails } from "../../../reducers/active-form-slice";


const IntakeWitnessDefendantStatementInfo = () => {
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const witnessDefendantStatementDetails = useSelector(selectActiveFormWitnessDefendantStatementDetails);


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/witness-defendant-statement/fault-info");
    };


    /**
     * update active form & continue
    */
    const handleSubmit = () => {
        navigate("/intake/additional-information");
    };


    return (
        <div className="page intake">
            <h2>Witness AND/OR Defendant Statement Details</h2>

            <Formik
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <div className="form-wrapper general-information">
                            <div className="content">
                                <h3>Statement Focus</h3>

                                <div className="form-row single-col">
                                    <strong>
                                        <p>Is this an all-inclusive statement (fault, insurance, course & scope)</p>
                                        <p>OR</p>
                                        <p>Is focus on insurance, and course & scope?</p>
                                    </strong>
                                </div>

                                <div className="form-row single-col">
                                    <strong>
                                        <p>Suggested questions for insurance, course & scope/work information:</p>
                                    </strong>

                                    <ol type="a">
                                        <li><span>Confirm Def driver's name, address, telephone number, and DOB (see above info.) If any missing info., please obtain.</span></li>
                                        <li><span>Def Driver's version of what happened.</span></li>
                                        <li><span>Where was he/she coming from/going? (from home to work?) What is his/her home address?</span></li>
                                        <li><span>Who his/her employer is, job title, job responsibilities: length of employment?</span></li>
                                        <li><span>If he/she ever uses car for work purposes. If so, to do what? How often? What part of his/her job requires him/her to use this vehicle?</span></li>
                                        <li><span>Was he/she working at time of incident or doing anything work related at all?</span></li>
                                        <li><span>Was he/she running an errand for employer or anyone else at the time of the accident?</span></li>
                                        <li><span>Does employer provide insurance policy for the car at all? If so, what is the insurance carrier?</span></li>
                                        <li><span>Does employer provide him/her with a work vehicle to perform any work obligations or does employer expect him/her to use their own personal vehicle?</span></li>
                                        <li><span>Who is the registered owner of the Defendant's vehicle?</span></li>
                                        <li><span>If Defendant does not confirm that they were also the registered owner of the vehicle, did they have permission from the registered owner to drive the vehicle?</span></li>
                                        <li><span>If Defendant driver is not the registered owner, please provide name and address of the registered owner(s). Do you know if they had separate insurance for their vehicle?</span></li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={false}
                            showNext={true}
                            progress={`40`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeWitnessDefendantStatementInfo;