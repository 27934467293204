import { Field } from "formik";
import { stateProvince } from "../../../utility/state-province";
import { DatePickerInput } from "../../datepicker-input";
import StateProvinceDropdown from "../../stateprovinceDropdown";


const IntakeDMVDetailsFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <h3>Subject</h3>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="firstName">First Name <span className="required">*</span></label>
                    <Field id="firstName" name={nested ? "dmvDetails.firstName" : "firstName"} />

                    {errors && errors.firstName && touched && touched.firstName ? (
                        <div className="form-error">{errors.firstName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastName">Last Name <span className="required">*</span></label>
                    <Field id="lastName" name={nested ? "dmvDetails.lastName" : "lastName"} />

                    {errors && errors.lastName && touched && touched.lastName ? (
                        <div className="form-error">{errors.lastName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="email">Email</label>
                    <Field id="email" name={nested ? "dmvDetails.email" : "email"} type="email" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="phone">Phone #</label>
                    <Field id="phone" name={nested ? "dmvDetails.phone" : "phone"} type="phone" />

                    {errors && errors.phone && touched && touched.phone ? (
                        <div className="form-error">{errors.phone}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress1">Last Known Address 1</label>
                    <Field id="lastKnownAddress1" name={nested ? "dmvDetails.lastKnownAddress1" : "lastKnownAddress1"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress2">Last Known Address 2</label>
                    <Field id="lastKnownAddress2" name={nested ? "dmvDetails.lastKnownAddress2" : "lastKnownAddress2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="city">City</label>
                    <Field id="city" name={nested ? "dmvDetails.city" : "city"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "dmvDetails.stateProvince" : "stateProvince"} id="stateProvince" disabled={false}/>

                <div className="form-field-wrapper">
                    <label htmlFor="zip">Zip</label>
                    <Field id="zip" name={nested ? "dmvDetails.zip" : "zip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="country">Country</label>
                    <Field id="country" name={nested ? "dmvDetails.country" : "country"} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="dob">Date of Birth</label>
                    <DatePickerInput name={nested ? "dmvDetails.dob" : "dob"} placeholderText="Select date" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="age">Age</label>
                    <Field id="age" name={nested ? "dmvDetails.age" : "age"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="vin">VIN</label>
                    <Field id="vin" name={nested ? "dmvDetails.vin" : "vin"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="licensePlate">License Plate</label>
                    <Field id="licensePlate" name={nested ? "dmvDetails.licensePlate" : "licensePlate"} />
                </div>
            </div>
        </div>
    );
}

export default IntakeDMVDetailsFields;