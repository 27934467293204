import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


const ArchiveModal = ({ showArchiveModal, handleCloseArchiveModal, handleArchiveButton }) => {
    const [disableButton, setDisableButton] = useState(true);

    const handleConfirm = e => {
        setDisableButton(!e.target.checked);
    };

    return (
        <Modal
            backdrop="static"
            show={showArchiveModal}
            onHide={handleCloseArchiveModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Archive all selected orders</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Label>
                    <Form.Check id="confirm" label="Confirm" onClick={handleConfirm} />
                </Form.Label>
            </Modal.Body>

            <Modal.Footer>
                <button disabled={disableButton} className="button tertiary" onClick={handleArchiveButton}>Archive</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ArchiveModal;