import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';


const AddNotesModal = ({ showAddNoteModal, handleHideAddNoteModal, handleAddNewNote }) => {
    /**
     * setup the initial values & validation
     */
    const initialValues = {
        note: '',
    };

    const validationSchema = Yup.object().shape({
        note: Yup.string()
            .required('Required'),
    });


    return (
        <Modal
            backdrop="static"
            show={showAddNoteModal}
            onHide={handleHideAddNoteModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Note</Modal.Title>
            </Modal.Header>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleAddNewNote}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <Modal.Body>
                            <div className="form-field-wrapper">
                                <label htmlFor="firstNameCaseManager">Note <span className="required">*</span></label>
                                <Field as="textarea" rows="5" id="note" name="note" />

                                {errors && errors.note && touched && touched.note ? (
                                    <div className="form-error">{errors.note}</div>
                                ) : null}
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <button type="submit" disabled={!((dirty) && isValid)} className="button tertiary">Add Note</button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal >
    );
};

export default AddNotesModal;