import * as Yup from 'yup';


const AssetLocateTypeSchema = Yup.object().shape({
    assetLocateType: Yup.object().shape({
        assetLocateType: Yup.string()
            .required('Required'),
    })
});

export default AssetLocateTypeSchema;