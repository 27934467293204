export const mapNamesToLabels = (name) => {
    switch (name) {
        case "orderNumber":
            return 'Order #';
        case "caseName":
            return 'Case Name';
        case "managerAssigned":
            return 'Manager Assigned';
        case "glucroftAssigned":
            return 'Glucroft Assigned';
        case "investigationType":
            return 'Investigation Type';
        case "status":
            return 'Status';
        case "address":
            return 'Address Line 1'
        case "address1":
            return 'Address Line 1'
        case "address2":
            return 'Address Line 2'
        case "city":
            return 'City'
        case "country":
            return 'Country'
        case "email":
            return 'Email Address'
        case "firstName":
            return 'First Name'
        case "lastDayToComplete":
            return 'Last Day to Complete'
        case "lastName":
            return 'Last Name'
        case "licensePlate":
            return 'License Plate'
        case "phone":
            return 'Phone Number'
        case "stateProvince":
            return 'State/Province'
        case "typeOfService":
            return 'Type of Service'
        case "vin":
            return 'VIN'
        case "zip":
            return 'Zip Code'
        case "insuranceClaimHistoryDetails":
            return 'Is your client the subject of the claim history?'
        case "businessName":
            return 'Business Name'
        case "ceoPresidentName":
            return "CEO or President's Name(s)"
        case "ssn":
            return 'Social Security #'
        case "fein":
            return 'FEIN'
        case "dob":
            return 'Date of Birth'
        case "emailPrimary":
            return 'Primary Email Address'
        case "emailSecondary":
            return 'Secondary Email Address'
        case "phonePrimary":
            return 'Primary Phone #'
        case "phoneSecondary":
            return 'Secondary Phone #'
        case "jobs":
            return 'Job(s)'
        case "age":
            return 'Age'
        case "informationType":
            return 'What kind of information are you looking for?'
        case "assetLocateRequest":
            return 'Asset Locate Request(s)'
        case "assetLocateType":
            return 'Asset Locate Type'
        case "socialMedia":
            return 'Do you want to include a social media search?'
        case "locations":
            return "Subject's Location (s)"
        case "connectionsAssociates":
            return "Subject's Connections/Associate(s)"
        case "connectionsAssociatesLocations":
            return "Subject's Connections/Associate(s) Location(s"
        case "username":
            return "Subject's Username(s)"
        case "keyword":
            return 'Keyword(s)'
        case "job":
            return 'Job'
        case "school":
            return 'School'
        case "dateOfIncident":
            return 'Date of Incident'
        case "ownersName":
            return 'Owners Name'
        case "businessAddress":
            return 'Business Address Line 1'
        case "businessAddress2":
            return 'Business Address Line 2'
        case "lastKnownAddress1":
            return 'Last Known Address Line 1'
        case "lastKnownAddress2":
            return 'Last Known Address Line 2'
        case "insuredType":
            return 'Insured Type'
        case "dateOfLoss":
            return 'Date of Loss'
        case "insuredName":
            return "Insured's Name"
        case "insuredAddress":
            return 'Address Line 1'
        case "insuredAddress2":
            return 'Address Line 2'
        case "insuredCity":
            return 'City'
        case "insuredStateProvince":
            return 'State/Province'
        case "insuredZip":
            return 'Zip Code'
        case "insuredCountry":
            return 'Country'
        case "insuredDob":
            return 'Date of Birth'
        case "insuredSsn":
            return 'Social Security #'
        case "insuredVin":
            return 'VIN'
        case "insuredLicensePlate":
            return 'License Plate'
        case "insuredLicenseNumber":
            return "Insured Driver's License #"
       case "ownersName":
            return "Owners's Name"
        case "ownersAddress":
            return 'Address Line 1'
        case "ownersAddress2":
            return 'Address Line 2'
        case "ownersCity":
            return 'City'
        case "ownersStateProvince":
            return 'State/Province'
        case "registeredOwnersStateProvince":
            return 'State/Province'
        case "ownersZip":
            return 'Zip Code'
        case "ownersCountry":
            return 'Country'
        case "ownerIsDriver":
            return 'Is the Owner also the Driver?'
        case "driversName":
            return "Driver's Name"
        case "driversInsuranceCompany":
            return "Driver's Insurance Company"
        case "driversLicenseNumber":
            return "Driver's Driver's License #"
        case "driversLicensePlate":
            return "Driver's License Plate"
        case "policy":
            return 'Policy #'
        case "claim":
            return 'Claim #'
        case "letterOfAcknowledgement":
            return 'Do you have a Letter of Acknowledgment?'
        case "policyLimits":
            return 'Do you want policy limits?'
        case "registeredOwnersName":
            return "Registered Owners's Name"
        case "registeredOwnersAddress":
            return 'Address Line 1'
        case "registeredOwnersAddress2":
            return 'Address Line 2'
        case "registeredOwnersCity":
            return 'City'
        case "registeredOwnersZip":
            return 'Zip Code'
        case "registeredOwnersCountry":
            return 'Country'
        case "registeredOwnerIsDriver":
            return 'Is the Registered Owner also the Driver?'
        case "homeownerRentersName":
            return "Homeowner/Renter's Name"
        case "lossAddress":
            return 'Address of Loss Line 1'
        case "lossAddress2":
            return 'Address of Loss Line 2'
        case "lossCity":
            return 'City'
        case "lossState":
            return 'State/Province'
        case "lossZip":
            return 'Zip Code'
        case "lossCountry":
            return 'Country'
        case "timeOfLoss":
            return 'Time of Loss'
        case "surveillanceType":
            return 'Surveillance Type'
        case "investigationSubject":
            return 'Subject of Surveillance/ Investigation'
        case "investigationLocation":
            return 'Location of Surveillance/ Incident'
       case "subjectVehicleDescription":
            return 'Subject Vehicle Description'
        case "subjectVehiclelicensePlate":
            return 'License Plate'
        case "factsOfLoss":
            return 'Facts of Loss'
        case "location":
            return 'Location of Accident or Intersection'
        case "policeDept":
            return 'Police Dept/Agency'
        case "clientDob":
            return 'Client Date of Birth'
        case "clientDriversLicenseNumber":
            return 'Client Drivers License #'
        case "reportNumber":
            return 'Report #'
        case "lossLocation":
            return 'Loss Location'
        case "vehicle1":
            return 'Vehicle 1'
        case "licensePlate1":
            return 'License Plate'
        case "vehicle2":
            return 'Vehicle 2'
        case "licensePlate2":
            return 'License Plate'
        case "vehicle3":
            return 'Vehicle 3'
        case "licensePlate3":
            return 'License Plate'
        case "additionalInformation":
            return 'Additional Information'
        case "locationOfLoss":
            return 'Location of Loss'
        case "specificQuestions":
            return 'Specific Questions'
        case "directionOfTravel":
            return 'Direction of Travel'           
        case "streetOfTravel":
            return 'Street of Travel'
        case "vehicleDescription":
            return 'Vehicle Description'
        case "statemenetType":
            return 'Statement Type'
        case "defendants":
            return 'Defendants'
        case "witnesses":
            return 'Witnesses'
        case "acceptedLiability":
            return 'Accepted Liability'
        case 'managerAssignedEmail':
            return 'Email (Primary)'
        case 'managerAssignedEmail2':
            return 'Email (Secondary)'
        case 'managerAssignedPhone':
            return 'Phone (Primary)'
        case 'managerAssignedPhone2':
            return 'Phone (Secondary)'
        case 'lawFirm':
            return 'Law Firm Name'
        case 'lawFirmAddress1':
            return 'Address'
        case 'lawFirmAddress2':
            return 'Address 2'
        case 'lawFirmCity':
            return 'City'
        case 'lawFirmState':
            return 'State'
        case 'lawFirmZip':
            return 'Zip'
        case 'lawFirmCountry':
            return 'Country'
        case 'pedestrianIdentifiers':
            return 'Pedestrian Identifiers'
        case 'statementType':
            return 'Is this a Witness Statement, Defendant Statement, or Both?';
        case 'county':
            return 'Country';
        case 'dateOfBirth':
            return 'Date of Birth';
        case 'defendantVehicleDescription':
            return 'Vehicle Description';
        case 'socialMediaFirstName':
            return 'First Name';
        case 'socialMediaLastName':
            return 'Last Name';
        case 'socialMediaEmail':
            return 'Email';
        case 'socialMediaPhone':
            return 'Phone';
        case 'socialMediaAddress':
            return 'Address Line 1';
        case 'socialMediaAddress2':
            return 'Address Line 2';
        case 'socialMediaCity':
            return 'City';
        case 'socialMediaStateProvince':
            return 'State/Province';
        case 'socialMediaZip':
            return 'Zip';
        case 'socialMediaCountry':
            return 'Country';
        case 'socialMediaLocations':
            return 'Subject\'s Location (s)';
        case 'socialMediaConnectionsAssociates':
            return 'Subject\'s Connections/Associate(s)';
        case 'socialMediaConnectionsAssociatesLocations':
            return 'Subject\'s Connections/Associate(s) Location(s)';
        case 'socialMediaUsername':
            return 'Subject\'s Username(s)';
        case 'socialMediaVin':
            return 'VIN';
        case 'socialMediaLicensePlate':
            return 'License Plate';
        case 'socialMediaKeyword':
            return 'Keyword(s) Image and Word Based';
        case 'socialMediaJob':
            return 'Job';
        case 'socialMediaDob':
            return 'Date of Birth';
        case 'socialMediaAge':
            return 'Age';
        case 'socialMediaSchool':
            return 'School';
        case 'socialMediaDateOfIncident':
            return 'Date of Incident';
        case 'lastKnownAddress1Line1':
            return 'Last Known Address 1 - Line 1';
        case 'lastKnownAddress1Line2':
            return 'Last Known Address 1 - Line 2';
        case 'lastKnownAddress1City':
            return 'Last Known Address 1 - City';
        case 'lastKnownAddress1StateProvince':
            return 'Last Known Address 1 - State/Province';
        case 'lastKnownAddress1Zip':
            return 'Last Known Address 1 - Zip';
        case 'lastKnownAddress1Country':
            return 'Last Known Address 1 - Country';
        case 'lastKnownAddress2Line1':
            return 'Last Known Address 2 - Line 1';
        case 'lastKnownAddress2Line2':
            return 'Last Known Address 2 - Line 2';
        case 'lastKnownAddress2City':
            return 'Last Known Address - City';
        case 'lastKnownAddress2StateProvince':
            return 'Last Known Address 2 - State/Province';
        case 'lastKnownAddress2Zip':
            return 'Last Known Address 2 - Zip';
        case 'lastKnownAddress2Country':
            return 'Last Known Address 2 - Country';
        case 'stateProvinceRequested':
            return 'Asset Locate State'
        case 'locateAddress':
            return 'Address'
        case 'locateAddress2':
            return 'Address 2'
        case 'locateCity':
            return 'City'
        case 'locateStateProvince':
            return 'State/Province'
        case 'locateZip':
            return 'Zip'
        case 'locateCountry':
            return 'Country'
        case 'insuranceCompany':
            return 'Insurance Company'
        case 'type':
            return 'Type'
        
        default:
            return name;
    }
};