import { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import BeatLoader from 'react-spinners/BeatLoader';
import IcoArrow from '../assets/ico-arrow';
import OrderDetailsForm from '../components/order-details-form';
import OrderDetailsNotesTable from '../components/order-details-notes-table';
import OrderDetailsAttachmentsTable from '../components/order-details-attachments-table';
import OrderDetailsChangeLogTable from '../components/order-details-change-log-table';
import { fetchOrderById, resetStatus, selectOrder, submitToBilling, updateOrder} from '../reducers/order-details-slice';
import { fetchAllNotifications, selectAllNotifications, selectGetAllNotifications, postDeleteNotification, resetAllNotifications, postNewNotificationFromExisting } from '../reducers/notifications-slice';
import OrderServices from '../services/orders-service';
import { updateStatus, updateAssignedUser } from '../reducers/orders-slice';
import ArchiveModal from '../components/modals/archive';
import StatusModal from '../components/modals/status';
import AssignModal from '../components/modals/assign';
import SubmitToBillingModal from '../components/modals/submit-to-billing';
import NotesModal from '../components/modals/add-notes';
import AttachmentModal from '../components/modals/attachment';
import { selectActiveFeatures } from '../reducers/auth-slice';
import ReportService from '../services/report-service';
import BaseAPI from '../utility/fetch-wrapper';
import OrderDetailsInvestigationResults from '../components/order-details-investigation-results';
import ResultsModal from '../components/modals/results';
import React from 'react';
import { resetAttachments, resetAttachmentsStatus } from '../reducers/attachments-slice';


const OrderDetails = () => {
    const navigate = useNavigate();
    const [lockForm, setLockForm] = useState(true);
    const [detailsLoaded, setDetailsLoaded] = useState(false);
    const activeFeatures = useSelector(selectActiveFeatures);

    /**
     * get order details
     */
    let { orderId } = useParams();
    const dispatch = useDispatch();
    const order = useSelector(selectOrder);
    const notifications = useSelector(selectGetAllNotifications);

    const ordersStatus = useSelector(state => state.orderDetails.status);

    // fetch order details on each initial render
    useEffect(() => {
        if (ordersStatus === 'idle' && !detailsLoaded) {
            dispatch(fetchOrderById(orderId));
            dispatch(fetchAllNotifications(orderId));
            // call for egnyte here
        } else if (ordersStatus === 'succeeded' && !detailsLoaded) {
            // anything that needs to happen AFTER 
            // the data has loaded (e.g. removing loading indicator)
            setDetailsLoaded(true);
        }
    }, [order, dispatch, orderId, ordersStatus, detailsLoaded]);

    // set when order details have been loaded
    useEffect(() => {
        if (detailsLoaded) {
            dispatch(resetStatus());
        }
    }, [dispatch, detailsLoaded]);

    //setting useEffect for only when #notes is in url so it waits to scroll to notes and attachments table
    const notesAndAttachmentsTable = useRef(null);
    //using this to only run the use effect function once when the page is hit, so the load doesnt occur after a form edit or etc.
    const [scrollToNotifications, setScrollToNotifications] = useState(true);
  
    useEffect(() => {

    //checks the url for #notifications
      const notificationsUrlFlag = window.location.hash.includes('#notifications');
    
      //waits for order status to be succeeded and details to load and the flag to be in the url
      if (notificationsUrlFlag && ordersStatus === "succeeded" && detailsLoaded && scrollToNotifications) {
        // scrolls to the notes and attachments table element 
        notesAndAttachmentsTable.current.scrollIntoView({ behavior: 'smooth' });
        setScrollToNotifications(false)
      }
    }, [detailsLoaded, ordersStatus, scrollToNotifications]);


    /**
     * handle archive functionality
     */
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const handleCloseArchiveModal = () => setShowArchiveModal(false);
    const handleShowArchiveModal = () => setShowArchiveModal(true);
    const handleArchiveButton = (e) => {
        e.preventDefault();

        var orderIds = [];
        orderIds.push(orderId);
        dispatch(updateStatus({ orderIds, status: "Archived" }));

        handleCloseArchiveModal();
        setDetailsLoaded(false);
    };


    /**
     * handle status functionality
     */
    const [showStatusModal, setShowStatusModal] = useState(false);
    const handleCloseStatusModal = () => setShowStatusModal(false);
    const handleShowStatusModal = () => setShowStatusModal(true);

    const [status, setStatus] = useState({});
    const handleStatusChange = (e) => {
        e.preventDefault();
        setStatus(e.target.value);
    };

    const handleStatusButton = async (e) => {
        e.preventDefault();

        // update order status;
        const newOrder = { ...order, details: { ...order.details, status: status } };
        const values = {};
        await dispatch(updateStatus({ orderIds: [newOrder.id], status, values }));
        setDetailsLoaded(false);
        dispatch(resetStatus());
        handleCloseStatusModal();
    };


    /**
     * handle assign functionality
     */
    const [showAssignModal, setShowAssignModal] = useState(false);
    const handleCloseAssignModal = () => setShowAssignModal(false);
    const handleShowAssignModal = () => setShowAssignModal(true);
    // eslint-disable-next-line
    const [user, setUser] = useState("");
    const handleUserChange = (e) => {
        e.preventDefault();
        setUser(e.target.value);
    };

    const handleAssignButton = async (e) => {
        e.preventDefault();
        if (user != "Select a User") {

            // update order assigned user;
            const newOrder = { ...order, details: { ...order.details, glucroftAssignedId: user, orderId: "UPDATE ASSIGNED USER ONLY" } };
            const values = {};
            await dispatch(updateOrder({ values, order: newOrder }));
        }
        handleCloseAssignModal();
        setDetailsLoaded(false);
        dispatch(resetStatus());
    };


    /**
     * handle investigator summary pdf
     */
    const handleInvestigatorSummaryPDF = async () => {

        // await ReportService.GenerateSummaryReport(orderId)
        //     .then(r => {

        //         BaseAPI.downloadFile('report/downloadsummary/' + r.reportId);
        //     });

        await ReportService.GenerateSummaryReport(orderId, true, true)
            .then(r => {
                BaseAPI.downloadFile('report/downloadsummary/' + r.reportId);
            });
        dispatch(resetStatus());
        dispatch(fetchOrderById(order.id));

    };

    /**
     * handle the add new
     */
    const handleAddNew = () => {
        navigate("/intake/general-information");
    };

    /**
     * handle submit to billing
     */
    const [showSubmitToBillingModal, setShowSubmitToBillingModal] = useState(false);

    const handleShowSubmitToBillingModal = () => {
        setShowSubmitToBillingModal(true);
    };

    const handleHideSubmitToBillingModal = () => {
        setShowSubmitToBillingModal(false);
    };

    const handleSubmitButton = async (values) => {
        handleHideSubmitToBillingModal();

        var subjectLine = "Sent to Billing"
        var orderIds = [orderId];
        var orderCodes = values.orderCode;
        await dispatch(submitToBilling({ subjectLine, orderCodes, orderIds }));
        setDetailsLoaded(false);
        dispatch(resetStatus());
        navigate('/orders');
    };


    /**
     * handle editing order details
     */
    const handleOrderDetailsEdit = () => {
        setLockForm(!lockForm);
    };

    /**
     * handle adding new note
     */
    const [showAddNoteModal, setShowAddNoteModal] = useState(false);
    const [selectedNote, setSelectedNote] = useState(null);
    const [showNoteButton, setShowNoteButton] = useState(false);
    const [noteButtonTitle, setNoteButtonTitle] = useState("");

    const handleShowAddNoteModal = () => {
        setShowAddNoteModal(true);
    };

    const handleHideAddNoteModal = () => {
        setShowAddNoteModal(false);
    };

    const handleAddNewNote = async values => {
        handleHideAddNoteModal();
        await dispatch(updateOrder({ values, order }));
        setDetailsLoaded(false);
        dispatch(resetStatus());
    };

    const handleSelectedNoteRowsChange = ({ selectedRows }) => {
        
        setSelectedNote(selectedRows);

        if (selectedRows != null && selectedRows.length > 0)
        {
            //Check if the order is a Filevine order and if the tag is set to external. If it is we keep it disabled
            if (order.details.isFilevineOrder && selectedRows[0].tag)
            {
                setNoteButtonTitle("This is a Filevine order, unable to change the note status to internal.")
                setShowNoteButton(false);

                if(!notifications.find(x => x.noteID === selectedRows[0].id))
                {
                    setShowNoteRead(true);
                }
                
            }
            else
            {
                setNoteButtonTitle("");
                setShowNoteButton(true);
               
                if(!notifications.find(x => x.noteID === selectedRows[0].id))
                {
                    setShowNoteRead(true);
                }
            }
        }
        else {
            setShowNoteButton(false);
            setShowNoteRead(false);
        }


    };

    const handleChangeNoteType = async (e) => {
        e.preventDefault();
        const id = selectedNote[0].id;
        // change type
        setShowNoteButton(false);
        await OrderServices.ChangeNoteType(id);
        await dispatch(fetchOrderById(order.id))
        dispatch(resetStatus());
    };

    //Handle marking note as unread
    const [showNoteRead, setShowNoteRead] = useState(false);

    const handleSetNoteAsRead = async (e) => {
        e.preventDefault();

        const values = {
            type: "note",
            id: selectedNote[0].id,
            external: selectedNote[0].tag
        }

        await dispatch(postNewNotificationFromExisting({ values, order }));

        setShowNoteRead(false);
      
        await resetAllNotifications();

        setDetailsLoaded(false);
        dispatch(resetStatus());

    };

    //handle marking attachment as unread
    const [showAttachmentRead, setShowAttachmentRead] = useState(false);

    const handleSetAttachmentAsRead = async (e) => {
        e.preventDefault();

        const values = {
            type: "attachment",
            id: selectedFile[0].id,
            external: selectedFile[0].tag
        }

        await dispatch(postNewNotificationFromExisting({ values, order }));

        setShowAttachmentRead(false);
      
        await resetAllNotifications();

        setDetailsLoaded(false);
        dispatch(resetStatus());

    };


    /**
     * handle adding new attachment
     */
    const [showAddAttachmentModal, setShowAddAttachmentModal] = useState(false);
    const [showHandleFileTypeButton, setShowHandleFileTypeButton] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showAttachmentButton, setShowAttachmentButton] = useState(false);
    const [attachmentButtonTitle, setAttachmentButtonTitle] = useState("");


    const handleSelectedAttachmentsRowsChange = ({ selectedRows }) => {
        
        setSelectedFile(selectedRows);
        if (selectedRows != null && selectedRows.length > 0) 
        {
            //Check if the order is a Filevine order and if the tag is set to external. If it is we keep it disabled
            if (order.details.isFilevineOrder && selectedRows[0].tag && selectedRows[0].owner && selectedRows[0].owner == "Filevine")
            {
                setAttachmentButtonTitle("This attachment is owned by Filevine, unable to change the attachment status to internal.")
                setShowAttachmentButton(false);


                if(!notifications.find(x => x.docID === selectedRows[0].id))
                {
                    setShowAttachmentRead(true);
                }
                
            }
            else
            {
                setAttachmentButtonTitle("");
                setShowAttachmentButton(true);

                if(!notifications.find(x => x.docID === selectedRows[0].id))
                {
                    setShowAttachmentRead(true);
                }
            }
        }
        else {
            setShowAttachmentButton(false);
            setShowAttachmentRead(false);
        }
    };

    const handleChangeFileType = async (e) => {
        e.preventDefault();
        const id = selectedFile[0].id;
        const tag = selectedFile[0].tag;
        setShowAttachmentButton(false);
        // change to external
        let payload = {fileid:id, orderId:order.id}
        if (tag === false) {
            await OrderServices.ChangeAttachmentTypeExternal(payload);
        }
        else {
            await OrderServices.ChangeAttachmentTypeInternal(payload);
        }
        // dispatch to reload 
        dispatch(resetAttachmentsStatus);
        dispatch(resetAttachments);
        await dispatch(fetchOrderById(order.id))
        dispatch(resetStatus());
    };

    const handleShowAddAttachmentModal = () => {
        setShowAddAttachmentModal(true);
    };


    const handleHideAddAttachmentModal = () => {
        setShowAddAttachmentModal(false);
    };

    /**
     *  handle editing investigation results
     */
    const [showEditResultsModal, setShowEditResultsModal] = useState(false);

    const handleShowEditResultsModal = () => {
        setShowEditResultsModal(true);
    };

    const handleHideEditResultsModal = () => {
        setShowEditResultsModal(false);
    };

    const handleAddNewResult = async values => {
        handleHideEditResultsModal();
        var data = await dispatch(updateOrder({ values, order }));
        dispatch(resetStatus());
    };

    // add attachment
    const handleAddNewAttachment = async values => {
        handleHideAddAttachmentModal();
        await dispatch(fetchOrderById(order.id))
        dispatch(resetStatus());
    };

    const checkStatusForEdit = () => {

        // Check for admin user when status is "In Billing", "Complete", or "Cancelled"
        if ((order && order.details) && (
            order.details.status == "In Billing" ||
            order.details.status == "Complete" ||
            order.details.status == "Canceled")) {

            return activeFeatures.includes('f_EditClosedOrder');
        } else {
            return true;
        }

    }




    return (
        <Fragment>
            {(ordersStatus === 'loading') &&
                <div className="loader-container">
                    <BeatLoader color="#00818C" />
                </div>
            }

            <div className="page mb-4">
                <div className="page-header">
                    <div className="page-header-title">
                        <h1>ORDER #</h1>
                        <h2 className="mb-0"><span className="light">{(order && order.details) ? order.details.orderNumber : ''}</span></h2>
                    </div>

                    <div className="page-header-actions">
             
                        {(order && order.details && (order.details.investigationType.includes("Insurance Investigation") || order.details.investigationType.includes("Insurance Claim History"))) ? (activeFeatures.includes('f_InvestigatorSummary') && <button className="button primary" type="button" onClick={handleInvestigatorSummaryPDF}>Investigation Results</button>) : <></>}

                        {activeFeatures.includes('f_NewOrder') && <button className="button secondary" type="button" onClick={handleAddNew}>+ Add New Order</button>}

                        <Dropdown className="action" align="end">
                            <Dropdown.Toggle id="dropdown-action" className="button tertiary action">
                                Choose an Actions <IcoArrow />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {activeFeatures.includes('f_Archive') && <Dropdown.Item onClick={handleShowArchiveModal}>Archive</Dropdown.Item>}
                                <Dropdown.Item onClick={handleShowStatusModal}>Update Status</Dropdown.Item>
                                <Dropdown.Item onClick={handleShowAssignModal}>Assign</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {activeFeatures.includes('f_SubmitBilling') && <button className="button outlined secondary" type="button" onClick={handleShowSubmitToBillingModal}>Submit to Billing</button>}
                    </div>
                </div>
            </div>

            {/* order info section */}
            <div className="page mb-4">
                <div className="page-header mb-4">
                    <div className="page-header-title">
                        <h2 className="mb-0">ORDER DETAILS</h2>
                    </div>

                    <div className="page-header-actions">
                        {lockForm && checkStatusForEdit() && <button className="button outlined secondary" type="button" onClick={handleOrderDetailsEdit}>Edit</button>}
                    </div>
                </div>

                <div className="page-body">
                    {(order && order.details) ? <OrderDetailsForm details={order.details} order={order} lockForm={lockForm} setLockForm={setLockForm} handleOrderDetailsEdit={handleOrderDetailsEdit} detailsLoaded={detailsLoaded} /> : <div>no details found</div>}
                </div>
            </div>

            <div className="two-column-pages mb-4" ref={notesAndAttachmentsTable}>
                {/* notes section */}
                <div className="page"  id="notes">
                    <div className="page-header mb-4">
                        <div className="page-header-title">
                            <h2 className="mb-0">Notes</h2>
                        </div>

                        <div className="page-header-actions">
                            <button className="button secondary" title={noteButtonTitle} type="button" disabled={!showNoteButton} onClick={handleChangeNoteType}>Change To {showNoteButton == false ? "..." : (selectedNote[0].tag === false ? "External" : "Internal")}</button>
                            <button className="button secondary" type="button" disabled={!showNoteRead} onClick={handleSetNoteAsRead}>Mark Unread</button>
                            <button className="button secondary" type="button" onClick={handleShowAddNoteModal}>+ Add New</button>
                        </div>
                    </div>
                    <div className="page-body">
                        <OrderDetailsNotesTable
                            activeFeatures={activeFeatures}
                            orderId={order.id}
                            notes={order.notes}
                            notifications={notifications}
                            progressPending={ordersStatus === 'loading'}
                            handleSelectedRowsChange={handleSelectedNoteRowsChange}
                        />
                    </div>
                </div>

                {/* attachments section */}
                <div className="page">
                    <div className="page-header mb-4">
                        <div className="page-header-title">
                            <h2 className="mb-0">ATTACHMENTS</h2>
                        </div>

                        <div className="page-header-actions">
                            <button className="button secondary"  title={attachmentButtonTitle} type="button" disabled={!showAttachmentButton} onClick={handleChangeFileType}>Change To {showAttachmentButton == false ? "..." : (selectedFile[0].tag === false ? "External" : "Internal")}</button>
                            <button className="button secondary" type="button" disabled={!showAttachmentRead} onClick={handleSetAttachmentAsRead}>Mark Unread</button>
                            <button className="button secondary" type="button" onClick={handleShowAddAttachmentModal}>+ Add New</button>
                        </div>
                    </div>

                    <div className="page-body">
                        <OrderDetailsAttachmentsTable
                            activeFeatures={activeFeatures}
                            orderId={order.id}
                            attachments={order.attachments}
                            notifications={notifications}
                            progressPending={ordersStatus === 'loading'}
                            setSelectedFile={setSelectedFile}
                            handleSelectedRowsChange={handleSelectedAttachmentsRowsChange}
                        />
                    </div>
                </div>
            </div>

            {/* results section */}
            <div className="page mb-4">
                <div className="page-header mb-4">
                    <div className="page-header-title">
                        <h2 className="mb-0">INVESTIGATION RESULTS</h2>
                    </div>

                    <div className="page-header-actions">
                        <button className="button outlined secondary" type="button" onClick={handleShowEditResultsModal}>Edit</button>
                    </div>
                </div>

                <div className="page-body">
                    <OrderDetailsInvestigationResults
                        results={order.results}
                        progressPending={ordersStatus === 'loading'}
                    />
                </div>
            </div>

            {/* changelog section */}
            <div className="page">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <div className="page-header">
                            <div className="page-header-title">
                                <Accordion.Header>
                                    <h2 className="mb-0">CHANGE LOG</h2>
                                </Accordion.Header>
                            </div>
                        </div>
                        <Accordion.Body>
                            <div className="page-body mt-4">
                                <OrderDetailsChangeLogTable
                                    changeLog={order.changeLog}
                                    progressPending={ordersStatus === 'loading'}
                                />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            {showEditResultsModal &&
                <ResultsModal
                    showEditResultsModal={showEditResultsModal}
                    handleHideEditResultsModal={handleHideEditResultsModal}
                    handleAddNewResult={handleAddNewResult}
                    originalResults={order.results}
                />
            }


            {showAddAttachmentModal &&
                <AttachmentModal
                    showAddAttachmentModal={showAddAttachmentModal}
                    handleHideAddAttachmentModal={handleHideAddAttachmentModal}
                    handleAddNewAttachment={handleAddNewAttachment}
                    orderSummaryId={order.id}
                />
            }

            {showAddNoteModal &&
                <NotesModal
                    showAddNoteModal={showAddNoteModal}
                    handleHideAddNoteModal={handleHideAddNoteModal}
                    handleAddNewNote={handleAddNewNote}
                />
            }

            {showSubmitToBillingModal &&
                <SubmitToBillingModal
                    showSubmitToBillingModal={showSubmitToBillingModal}
                    handleHideSubmitToBillingModal={handleHideSubmitToBillingModal}
                    handleSubmitButton={handleSubmitButton}
                />
            }

            {showArchiveModal &&
                <ArchiveModal
                    showArchiveModal={showArchiveModal}
                    handleCloseArchiveModal={handleCloseArchiveModal}
                    handleArchiveButton={handleArchiveButton}
                />
            }

            {showStatusModal &&
                <StatusModal
                    showStatusModal={showStatusModal}
                    handleCloseStatusModal={handleCloseStatusModal}
                    handleStatusButton={handleStatusButton}
                    handleStatusChange={handleStatusChange}
                />
            }

            {showAssignModal &&
                <AssignModal
                    showAssignModal={showAssignModal}
                    handleCloseAssignModal={handleCloseAssignModal}
                    handleAssignButton={handleAssignButton}
                    handleUserChange={handleUserChange}
                    orderIds={[order.id]}
                />
            }
        </Fragment>
    );
}

export default OrderDetails;