import * as Yup from 'yup';


const InsuranceClaimHistoryDetailsSchema = Yup.object().shape({
    insuranceClaimHistoryDetails: Yup.object().shape({
        insuranceClaimHistoryDetails: Yup.string()
            .required('Required'),
    })
});

export default InsuranceClaimHistoryDetailsSchema;