import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import BeatLoader from 'react-spinners/BeatLoader';
import { fetchLawFirms, resetLawFirmsStatus, selectAllLawFirms } from "../../reducers/law-firms-slice";
import { fetchRoles } from "../../reducers/glucroft-user-details-slice";
import { fetchCaseManagerById, resetCaseManagerDetailsStatus, selectCaseManager, updateCaseManager, createCaseManager } from "../../reducers/case-manager-details-slice";
import { permissionLevels } from "../../utility/permission-levels-ids";
import { fetchCaseManagers, resetCaseManagersStatus } from "../../reducers/case-managers-slice";

const CaseManager = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [permissionOptions, setPermissionOptions] = useState([]);
    const [lawFirmOptions, setLawFirmOptions] = useState([]);


    /**
     * get case manager details
     */
    const { id } = useParams();
    const [detailsLoaded, setDetailsLoaded] = useState(false);
    const caseManager = useSelector(selectCaseManager);
    const caseManagerStatus = useSelector(state => state.caseManagerDetails.status);


    // fetch case manager details on each initial render
    useEffect(() => {
        const handleRoles = async () => {
            var roles = await dispatch(fetchRoles());
            var filteredRoles = filterRolesAvailable(roles.payload);

            const options = [];
            filteredRoles.forEach(element => {
                options.push(
                    <option key={element.id} value={element.id}>
                        {element.title}
                    </option>
                )
            });
            setPermissionOptions(options);
        }
        const handleLawFirms = async () => {
            var lawFirms = await dispatch(fetchLawFirms());
            const options = [<option value="" defaultValue>Make a selection</option>];
            lawFirms.payload.forEach(element => {
                options.push(
                    <option key={element.id} value={element.firm}>
                        {element.firm}
                    </option>
                )
            });

            setLawFirmOptions(options);
        }
        handleLawFirms();
        handleRoles();

        if (id) {
            if (caseManagerStatus === 'idle' && !detailsLoaded) {
                dispatch(fetchCaseManagerById(id));
            } else if (caseManagerStatus === 'succeeded' && !detailsLoaded) {
                // anything that needs to happen AFTER 
                // the data has loaded (e.g. removing loading indicator)
                setDetailsLoaded(true);
            } 
        }
    }, [dispatch, id, caseManagerStatus, detailsLoaded]);

    // set when case manager details have been loaded
    useEffect(() => {
        if (detailsLoaded) {
            dispatch(resetCaseManagerDetailsStatus());
        } else {
            dispatch(fetchCaseManagerById(id));
        }
    }, [dispatch, detailsLoaded]);

    const filterRolesAvailable = (roles) => {
        //does user permission matter for which level you can assign?
        return roles.filter(element => element.id == permissionLevels.CaseManagerLevel2 ||  element.id == permissionLevels.CaseManagerLevel1).sort(function (a, b) {
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
          })
    };


    /**
     * setup the initial values & validation
     */
    const initialValues = {
        id: '',
        firstName: '',
        lastName: '',
        lawFirm: '',
        email: '',
        phone: '',
        permissionLevel: '',
        status: '',
        notes: ''
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('Required'),
        lastName: Yup.string()
            .required('Required'),
        lawFirm: Yup.string()
            .required('Required'),
        permissionLevel: Yup.string()
            .required('Required'),
        email: Yup.string()
            .required('Required')
            .email('Invalid email'),
        status: Yup.string()
            .required('Required'),
    });


    /**
     * handle form submission
     */
    const handleSubmit = async (values) => {
        if (values.id.length === 0) {
            await dispatch(createCaseManager(values))
        }
        else {
            await dispatch(updateCaseManager(values));
        }
        dispatch(resetCaseManagerDetailsStatus());
        dispatch(fetchCaseManagers());
        navigate("/admin");
    };


    /**
     * handle canceling
     */
    const handleCancel = () => {
        navigate('/admin');
    };


    return (
        <Formik
            initialValues={detailsLoaded ? caseManager : initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ errors, dirty, isValid, touched, submitForm, values }) => (
                <Fragment>
                    <div className="page">
                        {(caseManagerStatus === 'loading') &&
                            <div className="loader-container">
                                <BeatLoader color="#00818C" />
                            </div>
                        }

                        <div className="page-header">
                            <div className="page-header-title">
                                <h1 className="mb-0">USER</h1>

                                {detailsLoaded && <div className="page-header-title-filters">{caseManager.firstName} {caseManager.lastName}</div>}
                            </div>

                            <div className="page-header-actions">
                                <button className="button tertiary" type="submit" onClick={submitForm} disabled={!((dirty || detailsLoaded) && isValid)}>Save</button>
                                <button className="button outlined secondary" type="button" onClick={handleCancel}>Cancel</button>
                            </div>
                        </div>

                        <div className="page-body mt-4">
                            <Form>
                                <div className="form-row">
                                    <div className="form-field-wrapper">
                                        <label htmlFor="firstName">First Name <span className="required">*</span></label>
                                        <Field id="firstName" name="firstName" />

                                        {errors && errors.firstName && touched && touched.firstName ? (
                                            <div className="form-error">{errors.firstName}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="lastName">Last Name <span className="required">*</span></label>
                                        <Field id="lastName" name="lastName" />

                                        {errors && errors.lastName && touched && touched.lastName ? (
                                            <div className="form-error">{errors.lastName}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="lawFirm">Law Firm <span className="required">*</span></label>
                                        <Field id="lawFirm" name="lawFirm" as="select">
                                            {/* <option value="" defaultValue>Make a selection</option> */}
                                            {lawFirmOptions}
                                        </Field>
                                        {errors && errors.lawFirm && touched && touched.lawFirm ? (
                                            <div className="form-error">{errors.lawFirm}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="permissionLevel">Role Type <span className="required">*</span></label>
                                        <Field id="permissionLevel" name="permissionLevel" as="select">
                                            <option value="" defaultValue>Select Role Type</option>
                                            {permissionOptions}
                                        </Field>
                                        {errors && errors.lawFirm && touched && touched.lawFirm ? (
                                            <div className="form-error">{errors.lawFirm}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="email">Email <span className="required">*</span></label>
                                        <Field id="email" name="email" />

                                        {errors && errors.email && touched && touched.email ? (
                                            <div className="form-error">{errors.email}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="phone">Phone <span className="required">*</span></label>
                                        <Field id="phone" name="phone" />

                                        {errors && errors.phone && touched && touched.phone ? (
                                            <div className="form-error">{errors.phone}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="status">Status <span className="required">*</span></label>
                                        <Field id="status" name="status" as="select">
                                            <option value="" defaultValue>Make a selection</option>
                                            <option value="Enabled">Enabled</option>
                                            <option value="Disabled">Disabled</option>
                                        </Field>

                                        {errors && errors.status && touched && touched.status ? (
                                            <div className="form-error">{errors.status}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="notes">Notes</label>
                                        <Field id="notes" name="notes" as="textarea" rows="5" />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Fragment>
            )}
        </Formik>
    );
};

export default CaseManager;