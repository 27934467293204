const IcoLogout = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.101" height="28.203" viewBox="0 0 23.101 28.203">
      <g id="Group_15911" data-name="Group 15911" transform="translate(1 -3.777)">
        <path id="Path_40" data-name="Path 40" d="M30.756,26.587H22.849a3.769,3.769,0,0,1-3.892-3.621V5.237a3.769,3.769,0,0,1,3.892-3.621h8.036a.836.836,0,0,0,.869-.808A.836.836,0,0,0,30.884,0H22.849a5.45,5.45,0,0,0-5.63,5.237V22.966a5.45,5.45,0,0,0,5.63,5.237h7.907a.81.81,0,1,0,0-1.616Z" transform="translate(-18.219 3.777)" fill="current" />
        <path id="Path_41" data-name="Path 41" d="M141.719,142.1l-5.52-5.52a.869.869,0,0,0-1.229,1.229l4.04,4.04H126.388a.869.869,0,0,0,0,1.737H139.01l-4.04,4.04a.872.872,0,0,0,.611,1.486.848.848,0,0,0,.611-.257l5.52-5.52A.868.868,0,0,0,141.719,142.1Z" transform="translate(-119.873 -124.841)" fill="current" />
      </g>
    </svg>
  );
}

export default IcoLogout;