import { Outlet } from "react-router-dom";
import LeftNav from '../left-nav';
import Header from '../header';
import Breadcrumbs from "../breadcrumbs";
import { useLocation } from "react-router-dom";


const DashboardLayout = () => {
    const location = useLocation();

    const showBreadcrumbs = (location.pathname !== '/orders' && location.pathname !== '/admin' && location.pathname !== '/portal') ? true : false;


    return (
        <div className='layout'>
            <LeftNav />

            <div className='layout-content'>
                <Header showUserName={true} />

                {showBreadcrumbs && <Breadcrumbs />}

                <div className={`layout-content-outlet ${!showBreadcrumbs ? 'noBreadcrumbs' : ''}`}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default DashboardLayout;