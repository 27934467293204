import { Field } from "formik";
import { DatePickerInput } from "../../datepicker-input";
import { stateProvince } from "../../../utility/state-province";
import StateProvinceDropdown from "../../stateprovinceDropdown";


const IntakeBackgroundScreeningDetailsFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <h3>Subject</h3>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="firstName">First Name <span className="required">*</span></label>
                    <Field id="firstName" name={nested ? "backgroundScreeningDetails.firstName" : "firstName"} />

                    {errors && errors.firstName && touched && touched.firstName ? (
                        <div className="form-error">{errors.firstName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastName">Last Name <span className="required">*</span></label>
                    <Field id="lastName" name={nested ? "backgroundScreeningDetails.lastName" : "lastName"} />

                    {errors && errors.lastName && touched && touched.lastName ? (
                        <div className="form-error">{errors.lastName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="emailPrimary">Email (Primary)</label>
                    <Field id="emailPrimary" name={nested ? "backgroundScreeningDetails.emailPrimary" : "emailPrimary"} type="email" />

                    {errors && errors.emailPrimary && touched && touched.emailPrimary ? (
                        <div className="form-error">{errors.emailPrimary}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="emailSecondary">Email (Secondary)</label>
                    <Field id="emailSecondary" name={nested ? "backgroundScreeningDetails.emailSecondary" : "emailSecondary"} type="email" />

                    {errors && errors.emailSecondary && touched && touched.emailSecondary ? (
                        <div className="form-error">{errors.emailSecondary}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="phonePrimary">Phone # (Primary)</label>
                    <Field id="phonePrimary" name={nested ? "backgroundScreeningDetails.phonePrimary" : "phonePrimary"} type="phone" />

                    {errors && errors.phonePrimary && touched && touched.phonePrimary ? (
                        <div className="form-error">{errors.phonePrimary}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="phoneSecondary">Phone # (Secondary)</label>
                    <Field id="phoneSecondary" name={nested ? "backgroundScreeningDetails.phoneSecondary" : "phoneSecondary"} type="phone" />

                    {errors && errors.phoneSecondary && touched && touched.phoneSecondary ? (
                        <div className="form-error">{errors.phoneSecondary}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address">Address <span className="required">*</span></label>
                    <Field id="address" name={nested ? "backgroundScreeningDetails.address" : "address"} />

                    {errors && errors.address && touched && touched.address ? (
                        <div className="form-error">{errors.address}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address2">Address 2</label>
                    <Field id="address2" name={nested ? "backgroundScreeningDetails.address2" : "address2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="city">City <span className="required">*</span></label>
                    <Field id="city" name={nested ? "backgroundScreeningDetails.city" : "city"} />

                    {errors && errors.city && touched && touched.city ? (
                        <div className="form-error">{errors.city}</div>
                    ) : null}
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "backgroundScreeningDetails.stateProvince" : "stateProvince"} id="stateProvince" disabled={false} required={true}>

                    {errors && errors.stateProvince && touched && touched.stateProvince ? (
                        <div className="form-error">{errors.stateProvince}</div>
                    ) : null}
                </StateProvinceDropdown>

                <div className="form-field-wrapper">
                    <label htmlFor="zip">Zip <span className="required">*</span></label>
                    <Field id="zip" name={nested ? "backgroundScreeningDetails.zip" : "zip"} />

                    {errors && errors.zip && touched && touched.zip ? (
                        <div className="form-error">{errors.zip}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="country">Country <span className="required">*</span></label>
                    <Field id="country" name={nested ? "backgroundScreeningDetails.country" : "country"} />

                    {errors && errors.country && touched && touched.country ? (
                        <div className="form-error">{errors.country}</div>
                    ) : null}
                </div>
            </div>

            <div className="form-row three-col no-border no-spacing">
                <div className="form-field-wrapper">
                    <label htmlFor="vin">VIN</label>
                    <Field id="vin" name={nested ? "backgroundScreeningDetails.vin" : "vin"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="licensePlate">License Plate</label>
                    <Field id="licensePlate" name={nested ? "backgroundScreeningDetails.licensePlate" : "licensePlate"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="jobs">Job(s)</label>
                    <Field id="jobs" name={nested ? "backgroundScreeningDetails.jobs" : "jobs"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="dob">Date of Birth</label>
                    <DatePickerInput name={nested ? "backgroundScreeningDetails.dob" : "dob"} placeholderText="Select date" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="age">Age</label>
                    <Field id="age" name={nested ? "backgroundScreeningDetails.age" : "age"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="informationType">What kind of information are you looking for? <small>(Ex: Criminal Records)</small></label>
                    <Field id="informationType" name={nested ? "backgroundScreeningDetails.informationType" : "informationType"} />
                </div>
            </div>

            <div className="form-row three-col-reversed">
                <div className="form-field-wrapper">
                    <label className="left-justified" htmlFor="socialMedia">Do you want to include a social media search? <span className="required">*</span></label>

                    <div role="group" aria-labelledby="socialMedia-radio-group" className="form-check-inline left-justified">
                        <div className="form-check">
                            <Field type="radio" name={nested ? "backgroundScreeningDetails.socialMedia" : "socialMedia"} value="Yes" id="Yes" />
                            <label htmlFor="Yes">Yes</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "backgroundScreeningDetails.socialMedia" : "socialMedia"} value="No" id="No" />
                            <label htmlFor="No">No</label>
                        </div>
                    </div>

                    {errors && errors.socialMedia && touched && touched.socialMedia ? (
                        <div className="form-error">{errors.socialMedia}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default IntakeBackgroundScreeningDetailsFields;