import { Field } from "formik";
import { DatePickerInput } from "../../datepicker-input";
import { stateProvince } from "../../../utility/state-province";
import StateProvinceDropdown from "../../stateprovinceDropdown";


const IntakeBackgroundScreeningSocialMediaFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <h3>Subject</h3>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaFirstName">First Name <span className="required">*</span></label>
                    <Field id="socialMediaFirstName" name={nested ? "backgroundScreeningSocialMedia.socialMediaFirstName" : "socialMediaFirstName"} />

                    {errors && errors.socialMediaFirstName && touched && touched.socialMediaFirstName ? (
                        <div className="form-error">{errors.socialMediaFirstName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaLastName">Last Name <span className="required">*</span></label>
                    <Field id="socialMediaLastName" name={nested ? "backgroundScreeningSocialMedia.socialMediaLastName" : "socialMediaLastName"} />

                    {errors && errors.socialMediaLastName && touched && touched.socialMediaLastName ? (
                        <div className="form-error">{errors.socialMediaLastName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaEmail">Email</label>
                    <Field id="socialMediaEmail" name={nested ? "backgroundScreeningSocialMedia.socialMediaEmail" : "socialMediaEmail"} type="email" />

                    {errors && errors.socialMediaEmail && touched && touched.socialMediaEmail ? (
                        <div className="form-error">{errors.socialMediaEmail}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaPhone">Phone #</label>
                    <Field id="socialMediaPhone" name={nested ? "backgroundScreeningSocialMedia.socialMediaPhone" : "socialMediaPhone"} type="phone" />

                    {errors && errors.socialMediaPhone && touched && touched.socialMediaPhone ? (
                        <div className="form-error">{errors.socialMediaPhone}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaAddress">Address</label>
                    <Field id="socialMediaAddress" name={nested ? "backgroundScreeningSocialMedia.socialMediaAddress" : "socialMediaAddress"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaAddress2">Address 2</label>
                    <Field id="socialMediaAddress2" name={nested ? "backgroundScreeningSocialMedia.socialMediaAddress2" : "socialMediaAddress2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaCity">City</label>
                    <Field id="socialMediaCity" name={nested ? "backgroundScreeningSocialMedia.socialMediaCity" : "socialMediaCity"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "backgroundScreeningSocialMedia.socialMediaStateProvince" : "socialMediaStateProvince"} id="socialMediaStateProvince" disabled={false} />


                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaZip">Zip</label>
                    <Field id="socialMediaZip" name={nested ? "backgroundScreeningSocialMedia.socialMediaZip" : "socialMediaZip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaCountry">Country</label>
                    <Field id="socialMediaCountry" name={nested ? "backgroundScreeningSocialMedia.socialMediaCountry" : "socialMediaCountry"} />
                </div>
            </div>

            <div className="form-row two-col">
                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaLocations">Subject's Location (s)</label>
                    <Field id="socialMediaLocations" name={nested ? "backgroundScreeningSocialMedia.socialMediaLocations" : "socialMediaLocations"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaConnectionsAssociates">Subject's Connections/Associate(s)</label>
                    <Field id="socialMediaConnectionsAssociates" name={nested ? "backgroundScreeningSocialMedia.socialMediaConnectionsAssociates" : "socialMediaConnectionsAssociates"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaConnectionsAssociatesLocations">Subject's Connections/Associate(s) Location(s)</label>
                    <Field id="socialMediaConnectionsAssociatesLocations" name={nested ? "backgroundScreeningSocialMedia.socialMediaConnectionsAssociatesLocations" : "socialMediaConnectionsAssociatesLocations"} />
                </div>
            </div>

            <div className="form-row three-col-reversed no-border no-spacing">
                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaUsername">Subject's Username(s) (Ex. Ethan 123, Facebook.com/Ethan123)</label>
                    <Field id="socialMediaUsername" name={nested ? "backgroundScreeningSocialMedia.socialMediaUsername" : "socialMediaUsername"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaVin">VIN</label>
                    <Field id="socialMediaVin" name={nested ? "backgroundScreeningSocialMedia.socialMediaVin" : "socialMediaVin"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaLicensePlate">License Plate</label>
                    <Field id="socialMediaLicensePlate" name={nested ? "backgroundScreeningSocialMedia.socialMediaLicensePlate" : "socialMediaLicensePlate"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaKeyword">Keyword(s) Image and Word Based</label>
                    <Field id="socialMediaKeyword" name={nested ? "backgroundScreeningSocialMedia.socialMediaKeyword" : "socialMediaKeyword"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaJob">Job</label>
                    <Field id="socialMediaJob" name={nested ? "backgroundScreeningSocialMedia.socialMediaJob" : "socialMediaJob"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaDob">Date of Birth</label>
                    <DatePickerInput name={nested ? "backgroundScreeningSocialMedia.socialMediaDob" : "socialMediaDob"} placeholderText="Select date" />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaAge">Age</label>
                    <Field id="socialMediaAge" name={nested ? "backgroundScreeningSocialMedia.socialMediaAge" : "socialMediaAge"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaSchool">School</label>
                    <Field id="socialMediaSchool" name={nested ? "backgroundScreeningSocialMedia.socialMediaSchool" : "socialMediaSchool"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="socialMediaDateOfIncident">Date of Incident</label>
                    <DatePickerInput name={nested ? "backgroundScreeningSocialMedia.socialMediaDateOfIncident" : "socialMediaDateOfIncident"} placeholderText="Select date" />
                </div>
            </div>
        </div>
    );
};

export default IntakeBackgroundScreeningSocialMediaFields;