import { PublicClientApplication, EventType } from '@azure/msal-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import AppRouterProvider from './app-router-provider';
import Store from './store';
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from './authConfig';
import './styles/main.scss';

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
  
  msalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {
        msalInstance.setActiveAccount(event.payload.account);
    }
  })

let logoutTimeout = 0;

window.addEventListener('gluinv-validUntil', () => {
    let expiresAtRaw = window.localStorage.getItem('gluinv-validUntil');
    if (expiresAtRaw) {
        clearTimeout(logoutTimeout);
        let expiresAt = new Date(expiresAtRaw);
        let expiresAtUtc = Date.UTC(expiresAt.getFullYear(), expiresAt.getMonth(), expiresAt.getDate(), expiresAt.getHours(), expiresAt.getMinutes(), expiresAt.getSeconds(), expiresAt.getMilliseconds());
        let now = new Date().getTime();

        let timeTilExpiration = expiresAtUtc - now;
        logoutTimeout = setTimeout(() => {
            let activeAccount = msalInstance.getActiveAccount();
            if (activeAccount) {
                msalInstance.logoutRedirect(activeAccount);    
            }
        }, timeTilExpiration) //setting interval to time API responds with to log out after inactivity       
    }

});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
    <MsalProvider instance={msalInstance}>
        <AppRouterProvider />        
    </MsalProvider>
  </Provider>
);
