import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '../services/auth-service';

export const fetchAuth = createAsyncThunk('auth/fetchAuth', async (payload) => {

  const data = await AuthService.GetAuth(payload)
  return data;      
});


export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    adUser: null,
    portalUser: {
        status: 'idle'
    }
  },
  reducers: {
    setADUser: (state, action) => {
        state.adUser = (({ accessToken, account }) => ({ accessToken, account }))(action.payload)
    }
  },

  extraReducers: {
    [fetchAuth.pending]: (state, action) => {
        state.status = 'loading';
    },
    [fetchAuth.fulfilled]: (state, action) => {
        state.status = 'succeeded';
        state.portalUser = action.payload;
    },
    [fetchAuth.rejected]: (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
    },
}
});


// ------------------------------------------------------------------------------------------
export const { setADUser } = authSlice.actions;


export const selectActiveFeatures = (state) => {
  return (state.auth && state.auth.portalUser && state.auth.portalUser.activeFeatures) ? state.auth.portalUser.activeFeatures : [];
}

export const selectPortalUser = (state) => {
  return (state.auth && state.auth.portalUser && state.auth.error != 'Rejected') ? state.auth.portalUser : {status: 'rejected'};
}

export const selectAdUser = (state) => {
    return state.auth;
}

// ------------------------------------------------------------------------------------------
export default authSlice.reducer;
