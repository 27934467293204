import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveFormAssetLocateType, selectActiveFormAssetLocateDetails, updateActiveFormAssetLocateDetails } from '../../../reducers/active-form-slice';
import FormFooter from '../../../components/form-footer';
import IntakeAssetLocateDetailsPersonFields from '../../../components/intake/asset-locate/details/person-fields';
import IntakeAssetLocateDetailsBusinessFields from '../../../components/intake/asset-locate/details/business-fields';
import AssetLocateDetailsPersonSchema from '../../../validation-schemas/asset-locate-details-person-schema';
import AssetLocateDetailsBusinessSchema from '../../../validation-schemas/asset-locate-details-business-schema';


const IntakeAssetLocateDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    /**
     * get current form values
     */
    const assetLocateType = useSelector(selectActiveFormAssetLocateType);
    const assetLocateDetails = useSelector(selectActiveFormAssetLocateDetails);
    const hasValues = Object.keys(assetLocateDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/asset-locate/type");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormAssetLocateDetails(values));
        navigate("/intake/asset-locate/request");
    };


    /**
     * setup the initial values
     */
    const initialValuesPerson = {
        firstName: '',
        lastName: '',
        address: '',
        address2: '',
        city: '',
        stateProvince: '',
        zip: '',
        country: '',
        ssn: '',
        dob: '',
    };

    const initialValuesBusiness = {
        businessName: '',
        fein: '',
        address: '',
        address2: '',
        city: '',
        stateProvince: '',
        zip: '',
        country: '',
        ceoPresidentName: '',
        ssn: '',
    };

    const initialValues = assetLocateType.assetLocateType === "Subject/Target" ? initialValuesPerson : initialValuesBusiness;

    const getFormFields = (errors, touched, values) => {
        if (assetLocateType.assetLocateType === "Subject/Target") {
            return <IntakeAssetLocateDetailsPersonFields errors={errors} touched={touched} values={values} assetLocateType={assetLocateType.assetLocateType} />;
        } else {
            return <IntakeAssetLocateDetailsBusinessFields errors={errors} touched={touched} values={values} assetLocateType={assetLocateType.assetLocateType} />;
        }
    };

    
    return (
        <div className="page intake">
            <h2>Asset Locate Details</h2>

            <Formik
                initialValues={hasValues ? assetLocateDetails : initialValues}
                validationSchema={assetLocateType.assetLocateType === "Subject/Target" ? AssetLocateDetailsPersonSchema.fields.assetLocateDetails : AssetLocateDetailsBusinessSchema.fields.assetLocateDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        {getFormFields(errors, touched, values)}

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`50`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeAssetLocateDetails;