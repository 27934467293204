import { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InvestigationTypeSchema from '../../validation-schemas/investigation-type-schema';
import FormFooter from '../../components/form-footer';
import IntakeInvestigationTypeFields from '../../components/intake/investigation-type-fields';
import { fetchInvestigationTypes, selectAllInvestigationTypes } from '../../reducers/investigation-type-slice';
import { fetchLawFirmId } from '../../reducers/law-firm-details-slice';
import { getFirmById, selectActiveFormInvestigationType, updateActiveFormInvestigationType, updateActiveFormClientInfo, selectActiveFormClientInfo } from '../../reducers/active-form-slice';
import { orderTypes } from '../../utility/order-type-ids';
import InvestigationNameFields from '../../components/intake/investigation-name-fields';

const IntakeInvestigationType = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [queryStringParams, setQueryStringParams] = useSearchParams();
    const firm = queryStringParams.get('firm');
    const [type, setType] = useState('');

    
    /**
     * handle the previous button
     */
     const handlePrevious = () => {
        navigate("/intake/general-information");
    };

    /**
     * decide which path to take based on the
     * chosen investigation type
     */
    const choosePath = (id) => {
    
        switch (id) {
            case orderTypes.AL:
                return "/intake/asset-locate/type";
            case orderTypes.BSMS:
                return "/intake/background-screening/details";
            case orderTypes.DD:
                return "/intake/business-due-diligence/details";
            case orderTypes.DMV:
                return "/intake/dmv/details";
            case orderTypes.ISO:
                return "/intake/insurance-claim-history/details";
            case orderTypes.PLB:
                return "/intake/insurance-investigation-business/details";
            case orderTypes.PLL:
                return "/intake/insurance-investigation-locate/details";
            case orderTypes.PLPAU:
                return "/intake/insurance-investigation-personal-auto-umbrella/details";
            case orderTypes.PLPHR:
                return "/intake/insurance-investigation-personal-homeowners-renters/details";
            case orderTypes.SI:
                return "/intake/scene-investigation/details";
            case orderTypes.ST:
               return "/intake/skip-trace/details";
            case orderTypes.SERVE:
                return "/intake/subpoena-process-serving/details";
            case orderTypes.SWITRS:
                return "/intake/switrs/details";
            case orderTypes.TCR:
                return "/intake/traffic-collisions-report-retrieval/details";
            case orderTypes.DWS:
                return "/intake/witness-defendant-statement/details";
            default:
                return "/intake/404";
        }
    };

    /**
     * get investigation types
     */
    const investigationTypes = useSelector(selectAllInvestigationTypes);
    const investigationTypesStatus = useSelector(state => state.investigationTypes.status);
    useEffect(() => {
        if (investigationTypesStatus === 'idle') {
            dispatch(fetchInvestigationTypes());
        }
    });

    /**
     * get current form values
     */
    const investigationType = useSelector(selectActiveFormInvestigationType);
    const clientInfo = useSelector(selectActiveFormClientInfo);
    const hasValues = Object.keys(investigationType).length > 0 ? true : false;

    /**
     * set general information values if querystring param specifies firm
     */
    useEffect(() => {
        if (firm) {
            dispatch(getFirmById(firm));
        }       

    }, queryStringParams);


    /**
     * update investigation type in active form
     */
    const handleSubmit = async (values) => {
        dispatch(updateActiveFormClientInfo(values));
        var flattenedInvestigationTypes = [];
        investigationTypes.forEach(element => {
            if (element.children.length > 0)
            {
                element.children.forEach(childElement => {
                    if (childElement.children.length > 0)
                    {
                        childElement.children.forEach(grandChildElement => {
                            flattenedInvestigationTypes.push({'id':grandChildElement.id, 'name':grandChildElement.name})
                        })
                    }
                    else
                    {
                        flattenedInvestigationTypes.push({'id':childElement.id, 'name':childElement.name})
                    }
                })
            }
            else
            {
                flattenedInvestigationTypes.push({'id':element.id, 'name':element.name})
            }
        });
        let orderType = flattenedInvestigationTypes.find(x => x.name == values.investigationType);
        let orderTypeObject = [values.investigationType, orderType.id]
        await dispatch(updateActiveFormInvestigationType(orderTypeObject));
        const path = choosePath(orderType.id);
        navigate(path)
    };
    

    /**
     * setup the initial values
     */
    const initialValues = {
        investigationType: '',
        firstNameClient: '',
        lastNameClient: '',
        fileNumberClient: ''
    };

    return (
        <div className="page intake">
            {(investigationTypesStatus === 'idle' || investigationTypesStatus === 'loading') &&
                <div className="loader-container">
                    <BeatLoader color="#00818C" />
                </div>
            }

            <h2>Select your single investigation type | <span className="light">Each order/investigation is to be entered one at a time.</span></h2>

            <Formik
                initialValues={hasValues ? clientInfo : initialValues}
                validationSchema={InvestigationTypeSchema.fields.investigation}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeInvestigationTypeFields

                            errors={errors}
                            touched={touched}
                            values={values}
                            investigationTypes={investigationTypes}
                        />

                        <InvestigationNameFields
                            errors={errors}
                            touched={touched}
                            values={values}
                            investigationTypes={investigationTypes}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`10`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInvestigationType;