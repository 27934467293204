import { Field } from "formik";
import FileInput from "../../file-input";


const IntakeWitnessDefendantStatementDetailsFaultInfoFields = ({ handleUpload, handleUploadedRemoval, uploadedFiles, uploadStatus, nested }) => {
    return (
        <div className="form-wrapper additional-information">
            <h3>Fault Information</h3>

            <div className="form-row single-col">
                <div className="form-field-wrapper">
                    <label htmlFor="acceptedLiability">Has the defendant driver, or their insurance carrier, accepted liability?</label>
                    <div role="group" aria-labelledby="acceptedLiability-radio-group" className="form-check-inline left-justified">
                        <div className="form-check">
                            <Field type="radio" name={nested ? "witnessDefendantStatementFaultInfo.acceptedLiability" : "acceptedLiability"} value="Yes" id="Yes" />
                            <label htmlFor="Yes">Yes</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "witnessDefendantStatementFaultInfo.acceptedLiability" : "acceptedLiability"} value="No" id="No" />
                            <label htmlFor="No">No</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-row single-col">
                <FileInput
                    uploadedFiles={uploadedFiles}
                    handleUpload={handleUpload}
                    handleUploadedRemoval={handleUploadedRemoval}
                    uploadStatus={uploadStatus}
                    isIntake={true}
                    intakeStep={'specific'}
                />

                <Field id="files" name={nested ? "witnessDefendantStatementFaultInfo.files" : "files"} type="hidden" />
            </div>
        </div>
    );
};

export default IntakeWitnessDefendantStatementDetailsFaultInfoFields;