import { Field } from "formik";
import { stateProvince } from "../../../../utility/state-province";
import { DatePickerInput } from "../../../datepicker-input";
import StateProvinceDropdown from "../../../stateprovinceDropdown";

const IntakeInsuranceInvestigationLocateDetailsFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <div className="form-row single-col no-spacing no-border">
                <div className="form-field-wrapper">
                    <label className="left-justified" htmlFor="insuredType">Type <span className="required">*</span></label>

                    <div role="group" aria-labelledby="insuredType-radio-group" className="form-check-inline">
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationLocateDetails.insuredType" : "insuredType"} value="Personal Auto" id="personalAuto" />
                            <label htmlFor="personalAuto">Personal Auto</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationLocateDetails.insuredType" : "insuredType"} value="Personal Umbrella" id="personalUmbrella" />
                            <label htmlFor="personalUmbrella">Personal Umbrella</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationLocateDetails.insuredType" : "insuredType"} value="Homeowners" id="homeowners" />
                            <label htmlFor="homeowners">Homeowners</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationLocateDetails.insuredType" : "insuredType"} value="Renters" id="renters" />
                            <label htmlFor="renters">Renters</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationLocateDetails.insuredType" : "insuredType"} value="Business Auto" id="businessAuto" />
                            <label htmlFor="businessAuto">Business Auto</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationLocateDetails.insuredType" : "insuredType"} value="Business General Liability" id="businessGeneralLiability" />
                            <label htmlFor="businessGeneralLiability">Business General<br />Liability</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationLocateDetails.insuredType" : "insuredType"} value="Business Excess Umbrella" id="businessExcessUmbrella" />
                            <label htmlFor="businessExcessUmbrella">Business Excess<br />(Umbrella)</label>
                        </div>
                    </div>

                    {errors && errors.type && touched && touched.type ? (
                        <div className="form-error">{errors.type}</div>
                    ) : null}
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label className="left-justified" htmlFor="policyLimits">Do you want policy limits? <span className="required">*</span></label>

                    <div role="group" aria-labelledby="policyLimits-radio-group" className="form-check-inline">
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationLocateDetails.policyLimits" : "policyLimits"} value="Yes" id="Yes" />
                            <label htmlFor="Yes">Yes</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationLocateDetails.policyLimits" : "policyLimits"} value="No" id="No" />
                            <label htmlFor="No">No</label>
                        </div>
                    </div>

                    {errors && errors.policyLimits && touched && touched.policyLimits ? (
                        <div className="form-error">{errors.policyLimits}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="dateOfLoss">Date of Loss <span className="required">*</span></label>
                    <DatePickerInput name={nested ? "insuranceInvestigationLocateDetails.dateOfLoss" : "dateOfLoss"} placeholderText="Select date" />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="insuredName">Insured's Name <span className="required">*</span></label>
                    <Field id="insuredName" name={nested ? "insuranceInvestigationLocateDetails.insuredName" : "insuredName"} />

                    {errors && errors.insuredName && touched && touched.insuredName ? (
                        <div className="form-error">{errors.insuredName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredAddress">Address</label>
                    <Field id="insuredAddress" name={nested ? "insuranceInvestigationLocateDetails.insuredAddress" : "insuredAddress"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredAddress2">Address 2</label>
                    <Field id="insuredAddress2" name={nested ? "insuranceInvestigationLocateDetails.insuredAddress2" : "insuredAddress2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredCity">City</label>
                    <Field id="insuredCity" name={nested ? "insuranceInvestigationLocateDetails.insuredCity" : "insuredCity"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "insuranceInvestigationLocateDetails.insuredStateProvince" : "insuredStateProvince"} id="insuredStateProvince" 
                disabled={false} className={`${(values.insuredStateProvince && (values.insuredStateProvince.length <= 0 || values.insuredStateProvince === 'Make a selection')) ? 'placeholder' : ''}`}/>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredZip">Zip</label>
                    <Field id="insuredZip" name={nested ? "insuranceInvestigationLocateDetails.insuredZip" : "insuredZip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredCountry">Country</label>
                    <Field id="insuredCountry" name={nested ? "insuranceInvestigationLocateDetails.insuredCountry" : "insuredCountry"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredDob">Date of Birth</label>
                    <DatePickerInput name={nested ? "insuranceInvestigationLocateDetails.insuredDob" : "insuredDob"} placeholderText="Select date" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredSsn">SSN</label>
                    <Field id="insuredSsn" name={nested ? "insuranceInvestigationLocateDetails.insuredSsn" : "insuredSsn"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredVin">VIN</label>
                    <Field id="insuredVin" name={nested ? "insuranceInvestigationLocateDetails.insuredVin" : "insuredVin"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="insuredLicensePlate">License Plate</label>
                    <Field id="insuredLicensePlate" name={nested ? "insuranceInvestigationLocateDetails.insuredLicensePlate" : "insuredLicensePlate"} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="registeredOwnersName">Registered Owners's Name</label>
                    <Field id="registeredOwnersName" name={nested ? "insuranceInvestigationLocateDetails.registeredOwnersName" : "registeredOwnersName"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="registeredOwnersAddress">Address</label>
                    <Field id="registeredOwnersAddress" name={nested ? "insuranceInvestigationLocateDetails.registeredOwnersAddress" : "registeredOwnersAddress"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="registeredOwnersAddress2">Address 2</label>
                    <Field id="registeredOwnersAddress2" name={nested ? "insuranceInvestigationLocateDetails.registeredOwnersAddress2" : "registeredOwnersAddress2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="registeredOwnersCity">City</label>
                    <Field id="registeredOwnersCity" name={nested ? "insuranceInvestigationLocateDetails.registeredOwnersCity" : "registeredOwnersCity"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "insuranceInvestigationLocateDetails.registeredOwnersStateProvince" : "registeredOwnersStateProvince"} id="registeredOwnersStateProvince" 
                disabled={false} className={`${(values.registeredOwnersStateProvince && (values.registeredOwnersStateProvince.length <= 0 || values.ownersStateProvince === 'Make a selection')) ? 'placeholder' : ''}`} htmlFor="ownersStateProvince"/>


                <div className="form-field-wrapper">
                    <label htmlFor="registeredOwnersZip">Zip</label>
                    <Field id="registeredOwnersZip" name={nested ? "insuranceInvestigationLocateDetails.registeredOwnersZip" : "registeredOwnersZip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="registeredOwnersCountry">Country</label>
                    <Field id="registeredOwnersCountry" name={nested ? "insuranceInvestigationLocateDetails.registeredOwnersCountry" : "registeredOwnersCountry"} />
                </div>

                <div className="form-field-wrapper">
                    <label className="left-justified" htmlFor="registeredOwnerIsDriver">Is the Registered Owner also the Driver?<span className="required">*</span></label>

                    <div role="group" aria-labelledby="registeredOwnerIsDriver-radio-group" className="form-check-inline left-justified">
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationLocateDetails.registeredOwnerIsDriver" : "registeredOwnerIsDriver"} value="Yes" id="registeredOwnerIsDriver-Yes" />
                            <label htmlFor="registeredOwnerIsDriver-Yes">Yes</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "insuranceInvestigationLocateDetails.registeredOwnerIsDriver" : "registeredOwnerIsDriver"} value="No" id="registeredOwnerIsDriver-No" />
                            <label htmlFor="registeredOwnerIsDriver-No">No</label>
                        </div>
                    </div>

                    {errors && errors.registeredOwnerIsDriver && touched && touched.registeredOwnerIsDriver ? (
                        <div className="form-error">{errors.registeredOwnerIsDriver}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default IntakeInsuranceInvestigationLocateDetailsFields;