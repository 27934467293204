import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import { BeatLoader } from 'react-spinners';
import FileInput from '../file-input';
import { selectPortalUser } from '../../reducers/auth-slice';
import { deleteAttachments, resetAttachmentsStatus, resetAttachments, selectAllAttchments, uploadAttachments } from '../../reducers/attachments-slice';


const AttachmentModal = ({ showAddAttachmentModal, handleHideAddAttachmentModal, handleAddNewAttachment, orderSummaryId }) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(true)

    /**
     * handle the file upload & store returned info in state
     */
    const attachments = useSelector(selectAllAttchments);
    const portalUser = useSelector(selectPortalUser);
    var portalUserId = portalUser.id;
    const attachmentsStatus = useSelector(state => state.attachments.status);
    const formRef = useRef();

    const handleUpload = async files => {
        await dispatch(uploadAttachments({files, orderSummaryId, portalUserId}));
    };

    const handleUploadedRemoval = async guid => {
        await dispatch(deleteAttachments(guid));
    };

    useEffect(() => {
        if (attachmentsStatus === "succeeded") {
            formRef.current.setFieldValue('attachments', attachments);
            dispatch(resetAttachmentsStatus());
        }
    }, [attachments, attachmentsStatus, dispatch]);


    /**
     * setup the initial values & validation
     */
    const initialValues = {
        attachments: [],
    };

    const validationSchema = Yup.object().shape({
        attachments: Yup.object().shape({
            attachments: Yup.array()
                .min(1, 'Required')
                .required('Required'),
        })
    });

    useEffect(() => {
        if (showAddAttachmentModal === true) {
            dispatch(resetAttachments());
        }
    }, [showAddAttachmentModal, formRef, dispatch]);


    return (
        <Modal
            backdrop="static"
            show={showAddAttachmentModal}
            onHide={handleHideAddAttachmentModal}
            centered
        >
            {(attachmentsStatus === 'loading') &&
                <div className="loader-container">
                    <BeatLoader color="#00818C" />
                </div>
            }

            <Modal.Header closeButton>
                <Modal.Title>Add Attachment</Modal.Title>
            </Modal.Header>

            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleAddNewAttachment}
                enableReinitialize={true}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                    {show &&
                        <Modal.Body className="no-padding">
                            <div className="form-row single-col">
                                <FileInput
                                    uploadedFiles={attachments.attachments}
                                    handleUpload={ async (files) => {
                                        await handleUpload(files);
                                        setShow(false)
                                        // await handleAddNewAttachment();
                                    }}
                                    handleUploadedRemoval={handleUploadedRemoval}
                                    uploadStatus={attachmentsStatus}
                                />

                                <Field id="attachments" name="attachments" type="hidden" />
                            </div>
                        </Modal.Body>
                    }

                        <Modal.Footer>
                            {!show && (
                            <div className=''>
                                <div className='form-footer'>Upload Success!</div>
                                <button type="submit" disabled={!((dirty) && isValid)} className="button tertiary attachments-footer"hidden={show}>Continue</button>
                            </div>
                            )}
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal >
    );
};

export default AttachmentModal;