import { Field } from "formik";
import FileInput from "../../file-input";
import { DatePickerInput } from "../../datepicker-input";


const IntakeTrafficCollisionsReportRetrievalDetailsFields = ({ errors, touched, handleUpload, handleUploadedRemoval, uploadedFiles, uploadStatus, nested, specificFiles }) => {
    return (
        <div className="form-wrapper additional-information">
            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="policeDept">Police Dept/Agency<span className="required">*</span></label>
                    <Field id="policeDept" name={nested ? "trafficCollisionsReportRetrievalDetails.policeDept" : "policeDept"} />

                    {errors && errors.policeDept && touched && touched.policeDept ? (
                        <div className="form-error">{errors.policeDept}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="dateOfLoss">Date of Loss <span className="required">*</span></label>
                    <DatePickerInput name={nested ? "trafficCollisionsReportRetrievalDetails.dateOfLoss" : "dateOfLoss"} placeholderText="Select date" />

                    {errors && errors.dateOfLoss && touched && touched.dateOfLoss ? (
                        <div className="form-error">{errors.dateOfLoss}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="timeOfLoss">Time of Loss <span className="required">*</span></label>
                    <DatePickerInput name={nested ? "trafficCollisionsReportRetrievalDetails.timeOfLoss" : "timeOfLoss"} showTimeSelect showTimeSelectOnly timeCaption="Time" dateFormat="h:mm aa" placeholderText="Select time" />

                    {errors && errors.timeOfLoss && touched && touched.timeOfLoss ? (
                        <div className="form-error">{errors.timeOfLoss}</div>
                    ) : null}
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="clientDob">Client Date of Birth</label>
                    <DatePickerInput name={nested ? "trafficCollisionsReportRetrievalDetails.clientDob" : "clientDob"} placeholderText="Select date" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="clientDriversLicenseNumber">Client Drivers License #</label>
                    <Field id="clientDriversLicenseNumber" name={nested ? "trafficCollisionsReportRetrievalDetails.clientDriversLicenseNumber" : "clientDriversLicenseNumber"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="reportNumber">Report #</label>
                    <Field id="reportNumber" name={nested ? "trafficCollisionsReportRetrievalDetails.reportNumber" : "reportNumber"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lossLocation">Loss Location<span className="required">*</span></label>
                    <Field id="lossLocation" name={nested ? "trafficCollisionsReportRetrievalDetails.lossLocation" : "lossLocation"} />

                    {errors && errors.lossLocation && touched && touched.lossLocation ? (
                        <div className="form-error">{errors.lossLocation}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="city">City</label>
                    <Field id="city" name={nested ? "trafficCollisionsReportRetrievalDetails.city" : "city"} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="vehicle1">Vehicle 1<small>(Year / Make / Model)</small> <span className="required">*</span></label>
                    <Field id="vehicle1" name={nested ? "trafficCollisionsReportRetrievalDetails.vehicle1" : "vehicle1"} />

                    {errors && errors.vehicle1 && touched && touched.vehicle1 ? (
                        <div className="form-error">{errors.vehicle1}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="licensePlate1">License Plate</label>
                    <Field id="licensePlate1" name={nested ? "trafficCollisionsReportRetrievalDetails.licensePlate1" : "licensePlate1"} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="vehicle2">Vehicle 2 <small>(Year / Make / Model)</small></label>
                    <Field id="vehicle2" name={nested ? "trafficCollisionsReportRetrievalDetails.vehicle2" : "vehicle2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="licensePlate2">License Plate</label>
                    <Field id="licensePlate2" name={nested ? "trafficCollisionsReportRetrievalDetails.licensePlate2" : "licensePlate2"} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="vehicle3">Vehicle 3 <small>(Year / Make / Model)</small></label>
                    <Field id="vehicle3" name={nested ? "trafficCollisionsReportRetrievalDetails.vehicle3" : "vehicle3"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="licensePlate3">License Plate</label>
                    <Field id="licensePlate3" name={nested ? "trafficCollisionsReportRetrievalDetails.licensePlate3" : "licensePlate3"} />
                </div>
            </div>

            <div className="form-row single-col">
                <div className="form-field-wrapper">
                    <label className="left-justified">Upload Power of Attorney <span className="required">*</span></label>

                    <FileInput
                        uploadedFiles={uploadedFiles}
                        handleUpload={handleUpload}
                        handleUploadedRemoval={handleUploadedRemoval}
                        uploadStatus={uploadStatus}
                        isIntake={true}
                        intakeStep={'specific'}
                    />

                    <Field id="files" name={nested ? "trafficCollisionsReportRetrievalDetails.files" : "files"} type="hidden" value={specificFiles}/>

                    {errors && errors.files && touched && touched.files ? (
                        <div className="form-error">{errors.files}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default IntakeTrafficCollisionsReportRetrievalDetailsFields;