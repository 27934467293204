import * as Yup from 'yup';


const InsuranceInvestigationLocateDriversDetailsSchema = Yup.object().shape({
    insuranceInvestigationLocateDriversDetails: Yup.object().shape({
        driversInsuranceCompany: Yup.string()
            .required('Required'),
    })
});

export default InsuranceInvestigationLocateDriversDetailsSchema;