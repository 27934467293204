import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormFooter from '../../../components/form-footer';
import { selectActiveFormAssetLocateRequest, updateActiveFormAssetLocateRequest } from '../../../reducers/active-form-slice';
import IntakeAssetLocateRequestFields from '../../../components/intake/asset-locate/request-fields';
import AssetLocateRequestSchema from '../../../validation-schemas/asset-locate-request-schema';

const IntakeAssetLocateRequest = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    /**
     * get current form values
     */
    const assetLocateRequest = useSelector(selectActiveFormAssetLocateRequest);
    const hasValues = Object.keys(assetLocateRequest).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/asset-locate/details");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormAssetLocateRequest(values));
        navigate("/intake/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        assetLocateRequest: [],
        stateProvinceRequested: ''
    };


    return (
        <div className="page intake">
            <h2>Asset Locate Details</h2>

            <Formik
                initialValues={hasValues ? assetLocateRequest : initialValues}
                validationSchema={AssetLocateRequestSchema.fields.assetLocateRequest}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, touched, isValid }) => (
                    <Form>
                        <IntakeAssetLocateRequestFields
                            errors={errors}
                            touched={touched}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`75`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeAssetLocateRequest;