import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectPortalUser } from '../reducers/auth-slice';
import BeatLoader from 'react-spinners/BeatLoader';

const Portal = () => {
    const navigate = useNavigate();
    const portalUser = useSelector(selectPortalUser);

    /**
     * handle the add new
     */
    const handleAddNew = () => {
        navigate("/intake/general-information");
    };

    const renderGlucroftUserMsg = () => {
        return (
            <div className="left">
                <h2>WELCOME, {portalUser.firstName}</h2>
            </div>            
        )
    }

    const renderPortalUserMsg = () => {
        return (
            <div className="left">
                <h2>WELCOME, {portalUser.firstName}</h2>
                <h2>|</h2>
                <h3>More functionality coming soon!</h3>
            </div>            
        )
    }

    const renderDisabledUserMsg = () => {
        return (
                <div className="left">
                <h3>Please contact Glucroft Investigations Office at <u>866-411-8646</u> regarding your account. Thank you.</h3>
            </div>                  
        )
    }

    return (
        <div className="page portal">
            {(portalUser.status === 'loading' || portalUser.status === 'idle') &&
                <div className="loader-container">
                    <BeatLoader color="#00818C" />
                </div>
            }
            {portalUser.status === 'rejected' ? renderDisabledUserMsg() : (portalUser.userType === "glucroft" ? renderGlucroftUserMsg() : renderPortalUserMsg())}
            {(portalUser.status != 'rejected') &&
                <div className="right">
                    <button className="button secondary" type="button" onClick={handleAddNew}>+ Add New Order</button>
                </div>
            }
        </div>
    );
};

export default Portal;