import { Field, Form, Formik, useFormikContext } from 'formik';
import { updateOrder, fetchOrderById, resetStatus, } from '../reducers/order-details-slice';
import { useDispatch } from 'react-redux';
import { mapNamesToLabels } from "../utility/map-fieldsNames-labels";
import { mapFieldIds } from "../utility/map-fieldsIds";
import { investigationTypes } from '../placeholder-data';




const OrderDetailsForm = ({ details, order, handleOrderDetailsEdit, lockForm, setLockForm, detailsLoaded }) => {
    const dispatch = useDispatch();
    const submitOrderDetailsEdit = async (values) => {
        var updatedGeneralDetails = Object.keys(values)
            .filter(key => updateProps.includes(key))
            .reduce((obj, key) => {
                obj[key] = values[key];
                return obj;
            }, {});

        var updatedSpecificOrderTypeDetails = Object.keys(values)
            .filter(key => !updateProps.includes(key))
            .reduce((obj, key) => {
                obj[key] = values[key];
                return obj;
            }, {});

        const specificOrderTypeDetails = JSON.stringify(updatedSpecificOrderTypeDetails);

        const updatedOrder = {
            ...updatedGeneralDetails,
            specificOrderTypeDetails
        };

        const newOrderObject = JSON.parse(JSON.stringify(order));
        newOrderObject.details = updatedOrder;
        order = newOrderObject;

        values = {};
        await dispatch(updateOrder({ values, order }));
        dispatch(resetStatus());
        dispatch(fetchOrderById(order.id))
        setLockForm(true);
    };
    
    const updateProps= ["orderId", "glucroftAssignedId", "caseId", "orderNumber", "caseNumber", "caseName", "investigationType", "managerAssignedId", "managerAssigned", "managerAssignedEmail", "managerAssignedEmail2", "managerAssignedPhone", "managerAssignedPhone2", "glucroftAssigned", "status", "lawFirmId", "lawFirm", "lawFirmAddress1", "lawFirmAddress2", "lawFirmCity", "lawFirmState", "lawFirmZip", "lawFirmCountry"]
    const caseManagerProps = ["orderId", "caseId", "orderNumber", "caseNumber", "caseName", "investigationType", "managerAssignedId", "managerAssigned", "managerAssignedEmail", "managerAssignedEmail2", "managerAssignedPhone", "managerAssignedPhone2"]
    const lawFirmProps = ["lawFirmId", "lawFirm", "lawFirmAddress1", "lawFirmAddress2", "lawFirmCity", "lawFirmState", "lawFirmZip", "lawFirmCountry"]
    const generalInsurancePropsAbove = ["insuredType", "policyLimits", "dateOfLoss", "insuredName", "insuredAddress", "insuredAddress2", "insuredCity", "insuredStateProvince", "insuredZip", "insuredCountry"]
    const generalInsurancePropsBelow = ["insuredDob", "insuredSsn", "insuredVin", "insuredLicensePlate", "insuredLicenseNumber", "ownersName", "ownersAddress", "ownersAddress2", "ownersCity", "ownersStateProvince", "ownersZip", "ownersCountry", "ownerIsDriver", "driversName", "address", "address2", "city", "stateProvince", "zip", "country", "dob", "driversLicensePlate", "driversLicenseNumber", "driversInsuranceCompany", "policy", "claim", "homeownerRentersName", "lossAddress", "lossAddress2", "lossCity", "lossState", "lossZip", "lossCountry", "insuranceCompany", "Policy", "Claim", "registeredOwnersName", "registeredOwnersAddress", "registeredOwnersAddress2", "registeredOwnersCity", "registeredOwnersStateProvince", "registeredOwnersZip", "registeredOwnersCountry", "registeredOwnerIsDriver"]
    const locateAddressProps = ["locateAddress", "locateAddress2", "locateCity", "locateStateProvince", "locateZip", "locateCountry"]
    const generalProps = ["glucroftAssigned", "glucroftAssignedId", "status"];
    var additionalDataProps = ["additionalInformation", "letterOfAcknowledgement", "billable"];
    const investigationSpecificDetails = JSON.parse(details.specificOrderTypeDetails);

    const generalDetails = Object.keys(details)
        .filter(key => generalProps.includes(key))
        .reduce((obj, key) => {
            obj[key] = details[key];
            return obj;
        }, {});
    
    const caseManagerDetails = Object.keys(details)
        .filter(key => caseManagerProps.includes(key))
        .reduce((obj, key) => {
            obj[key] = details[key];
            return obj;
        }, {});

    const lawFirmDetails = Object.keys(details)
        .filter(key => lawFirmProps.includes(key))
        .reduce((obj, key) => {
            obj[key] = details[key];
            return obj;
        }, {});

    let filteredInvestigationSpecificDetails, additionalData = {};

    if (investigationSpecificDetails) {
        filteredInvestigationSpecificDetails = Object.keys(investigationSpecificDetails)
            .filter(key => !additionalDataProps.includes(key))
            .reduce((obj, key) => {
                obj[key] = investigationSpecificDetails[key];
                return obj;
            }, {});
        additionalData = Object.keys(investigationSpecificDetails)
            .filter(key => additionalDataProps.includes(key))
            .reduce((obj, key) => {
                obj[key] = investigationSpecificDetails[key];
                return obj;
            }, {});
    }

    const generalInfoDetails = {
        ...generalDetails,
        ...filteredInvestigationSpecificDetails,
        ...additionalData
    }

    let completeDetails = {
        ...generalDetails,
        ...caseManagerDetails,
        ...lawFirmDetails,
        ...filteredInvestigationSpecificDetails,
        ...additionalData,
    };


    const subObjectFieldNames = (value) => {
        switch (value) {
            case "witnesses":
                return "Witness's";
            case "defendants":
                return "Defendant's"
        }
    };
    return (
        <Formik
            initialValues={completeDetails}
            onSubmit={submitOrderDetailsEdit}
            enableReinitialize={true}
        >
            {({ errors, dirty, isValid, touched, values, submitForm, resetForm }) => (
                <Form>
                    <fieldset disabled={lockForm}>
                        <div className="form-wrapper">
                            <div className='form-header'>LAW FIRM INFORMATION</div>
                                <div className="form-row three-col-equal" style={{borderBottom: 'none'}}>
                                        {Object.keys(lawFirmDetails).filter(x => !x.includes("Id")).map((result, index) => {
                                            var fieldId = mapFieldIds(result, lawFirmDetails, index);
                                            return (
                                                <div className="form-field-wrapper" key={`law-order-details-form-field-${result}`}>
                                                    <label htmlFor={result}>{mapNamesToLabels(result)}</label>
                                                    <Field id={fieldId} name={result} title={lawFirmDetails[result]} disabled={lawFirmProps.includes(result)} />
                                                    {/* if it's additionalInformation, make it textarea */}
                                                </div>
                                            );
                                        }
                                    )}
                            </div>
                            <div className='form-header'>CASE MANAGER INFORMATION</div>
                                <div className="form-row three-col-equal" style={{borderBottomWidth: '.2rem', borderColor: '#00818C' }}>
                                        {Object.keys(caseManagerDetails).filter(x => !x.includes("Id")).map((result, index) => { 
                                            var fieldId = mapFieldIds(result, caseManagerDetails, index);
                                            return (
                                                <div className="form-field-wrapper" key={`case-order-details-form-field-${result}`}>
                                                    <label htmlFor={result}>{mapNamesToLabels(result)}</label>
                                                    <Field id={fieldId} name={result} title={caseManagerDetails[result]} disabled={caseManagerProps.includes(result)} />
                                                    {/* if it's additionalInformation, make it textarea */}
                                                </div>
                                            );
                                    })}
                                </div>
                            {completeDetails.investigationType.includes("Insurance Investigation") ? (
                                    <div>
                                        <div className='form-header'>GENERAL INFORMATION</div>
                                            <div className="form-row three-col-equal" style={{borderBottom: 'none'}}>
                                            {Object.keys(generalDetails).filter(x => !x.includes("Id")).map((result, index) => {
                                                    var fieldId = mapFieldIds(result, generalDetails, index);
                                                        return (
                                                            <div className="form-field-wrapper" key={`general-details-form-field-${result}`}>
                                                                <label htmlFor={result}>{mapNamesToLabels(result)}</label>
                                                                <Field id={fieldId} name={result} title={generalDetails[result]} disabled={generalProps.includes(result)} />
                                                                {/* if it's additionalInformation, make it textarea */}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                                {Object.keys(filteredInvestigationSpecificDetails).filter(x => generalInsurancePropsAbove.includes(x)).map((result, index) => {
                                                    var fieldId = mapFieldIds(result, filteredInvestigationSpecificDetails, index);
                                                        return (
                                                            <div className="form-field-wrapper" key={`general-insurance-above-details-form-field-${result}`}>
                                                                <label htmlFor={result}>{mapNamesToLabels(result)}</label>
                                                                <Field id={fieldId} name={result} title={filteredInvestigationSpecificDetails[result]}/>
                                                                {/* if it's additionalInformation, make it textarea */}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        <div className='locate-address'>
                                            <div className='locate-address-header'>LOCATED ADDRESS</div>
                                                <div className="form-row three-col-equal" style={{borderBottom: 'none'}}>
                                                    {Object.keys(filteredInvestigationSpecificDetails).filter(x => locateAddressProps.includes(x)).map((result, index) => {
                                                        var fieldId = mapFieldIds(result, filteredInvestigationSpecificDetails, index);
                                                            return (
                                                                <div className="form-field-wrapper" key={`locate-address-details-form-field-${result}`}>
                                                                    <label htmlFor={result}>{mapNamesToLabels(result)}</label>
                                                                    <Field id={fieldId} name={result} title={filteredInvestigationSpecificDetails[result]}/>
                                                                    {/* if it's additionalInformation, make it textarea */}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                        </div>    
                                            <div className="form-row three-col-equal" style={{borderBottom: 'none'}}>
                                                {Object.keys(filteredInvestigationSpecificDetails).filter(x => generalInsurancePropsBelow.includes(x)).map((result, index) => {
                                                    var fieldId = mapFieldIds(result, filteredInvestigationSpecificDetails, index);
                                                        return (
                                                            <div className="form-field-wrapper" key={`general-insurance-below-details-form-field-${result}`}>
                                                                <label htmlFor={result}>{mapNamesToLabels(result)}</label>
                                                                <Field id={fieldId} name={result} title={filteredInvestigationSpecificDetails[result]} />
                                                                {/* if it's additionalInformation, make it textarea */}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            <div className="form-row three-col-equal">
                                                {Object.keys(additionalData).filter(x => !x.includes("billable")).map((result, index) => {
                                                    var fieldId = mapFieldIds(result, additionalData, index);
                                                        return (
                                                            <div className="form-field-wrapper" key={`addtional-data-details-form-field-${result}`}>
                                                                <label htmlFor={result}>{mapNamesToLabels(result)}</label>
                                                                <Field id={fieldId} name={result} title={additionalData[result]} as={result === "additionalInformation" ? "textarea" : ""} rows={result === "additionalInformation" ? "5" : ""} />
                                                                {/* if it's additionalInformation, make it textarea */}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        <div className="form-wrapper">
                                            <div className='form-header'>BILLABLE INVESTIGATIONS FOUND INFO</div>
                                                <div className="form-row three-col-equal" role="group" aria-labelledby="checkbox-group">
                                                    <div>
                                                        <label className="left-justified" htmlFor="billableAddress">Address</label>
                                                            <div className="form-check">
                                                                <Field type="checkbox" name="billable" value="billableAddressTrue" id="billableAddress" />
                                                            </div>
                                                    </div>
                                                    <div>
                                                        <label className="left-justified" htmlFor="billableAddressDob">Address + DOB (Name Only)</label>
                                                            <div className="form-check">
                                                                <Field type="checkbox" name="billable" value="billableAddressDobTrue" id="billableAddressDob" />
                                                            </div>
                                                    </div>
                                                    <div>
                                                        <label className="left-justified" htmlFor="billableInsuranceCompany">Insurance Company</label>
                                                            <div className="form-check">
                                                                <Field type="checkbox" name="billable" value="billableInsuranceCompanyTrue" id="billableInsuranceCompany"/>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                ) : (
                                    <div>
                                        <div className='form-header'>GENERAL INFORMATION</div>
                                            <div className="form-row three-col-equal">
                                                    {Object.keys(generalInfoDetails).filter(x => x.includes('pedestrianIdentifiers') || (!x.includes("Id") && !x.includes("billable"))).map((result, index) => {
                                                        if (result == 'defendants' || result == 'witnesses') {
                                                            return generalInfoDetails[result].map((x,i) => (
                                                                Object.keys(x).map((prop) => {
                                                                    var fieldId = `${result}-${mapFieldIds(prop, x, index)}`;
                                                                    return (
                                                                        <div className="form-field-wrapper" key={`complete-order-details-form-field-${result}-${prop}`}>
                                                                            <label htmlFor={`${result}-${prop}`}>{subObjectFieldNames(result)} {mapNamesToLabels(prop)}</label>
                                                                            <Field id={fieldId} name={`${result}[${i}].${prop}`} as={result === "additionalInformation" ? "textarea" : ""} rows={result === "additionalInformation" ? "5" : ""} />
                                                                            {/* if it's additionalInformation, make it textarea */}
                                                                        </div>
                                                                    );
                                                                })
                                                            ))
                                                        } else {
                                                        var fieldId = mapFieldIds(result, generalInfoDetails, index);
                                                        return (
                                                            <div className="form-field-wrapper" key={`complete-order-details-form-field-${result}`}>
                                                                <label htmlFor={result}>{mapNamesToLabels(result)}</label>
                                                                <Field id={fieldId} name={result} title={generalInfoDetails[result]} disabled={generalProps.includes(result)} as={result === "additionalInformation" ? "textarea" : ""} rows={result === "additionalInformation" ? "5" : ""} />
                                                                {/* if it's additionalInformation, make it textarea */}
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            </div> 
                                    </div> 
                                )
                            }
                        </div>
                    </fieldset>

                    {!lockForm &&
                        <div className="form-footer">
                            <div className="form-footer-left"></div>
                            <div className="form-footer-middle"></div>
                            <div className="form-footer-right">
                                <button className="form-submit" type="submit">Save</button>
                                <button className="button primary outlined" onClick={handleOrderDetailsEdit && (() => resetForm())} type="submit">Cancel</button>
                            </div>
                        </div>}
                </Form>
            )}
        </Formik>
    );
};


export default OrderDetailsForm;