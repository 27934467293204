import { Field, Form, Formik } from 'formik';
import Modal from 'react-bootstrap/Modal';

const ResultsModal = ({showEditResultsModal, handleHideEditResultsModal, handleAddNewResult, originalResults}) => {

    /**
     * setup the initial values 
     */
    const initialValues = {
        results: originalResults,
    };

    return (
        <Modal
            backdrop="static"
            show={showEditResultsModal}
            onHide={handleHideEditResultsModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Investigation Results</Modal.Title>
            </Modal.Header>

            <Formik
                initialValues={initialValues}
                onSubmit={handleAddNewResult}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <Modal.Body>
                            <div className="form-field-wrapper">
                                <Field as="textarea" rows="15" id="results" name="results"  />
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <div className="investigation-results-container-left">
                                <button type="submit" disabled={!((dirty) && isValid)} className="button outlined investigation-results-save">Save</button>
                            </div>
                            <div className="investigation-results-container-right">
                                <button className="button outlined secondary investigation-results" type="button" onClick={handleHideEditResultsModal}>Cancel</button>
                            </div>
                            
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal >  
    );
};

export default ResultsModal;