import Modal from 'react-bootstrap/Modal';
import { Formik, Form } from 'formik';

const NotificationModal = ({ showNotificationsModal, handleNotificationsModal, handleViewNotifications  }) => {
    return (
        <Modal
            backdrop="static"
            show={showNotificationsModal}
            onHide={handleNotificationsModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>You have new notifications.</Modal.Title>
            </Modal.Header>

            <Formik
                onSubmit={handleViewNotifications}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <Modal.Footer>
                            <button type="submit" className="button tertiary"> View </button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>

            
        </Modal>
    );
}

export default NotificationModal;