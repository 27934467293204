import AppRouter from './app-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { EventType, InteractionStatus } from '@azure/msal-browser';
import { b2cPolicies, protectedResources, loginRequest } from './authConfig';

import AuthService from './services/auth-service';
import { setADUser, selectAdUser, fetchAuth } from './reducers/auth-slice';
import BaseApi from './utility/fetch-wrapper';


const AppRouterProvider = () => {
    const { instance } = useMsal();    
    const dispatch = useDispatch();
    const sliceUserObject = useSelector(selectAdUser);
    const loggedin = instance.getActiveAccount();

    const tokenRequest = {
        scopes: protectedResources.apiHello.scopes,
        account: loggedin
    };

    const handleMsalResponse = async (event) => {
        if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload.account) {
            let payload = (({ accessToken, account, scopes }) => ({ accessToken, account, scopes }))(event.payload);
            dispatch(setADUser(payload));
            dispatch(fetchAuth(payload.accessToken));
            BaseApi.setToken(payload.accessToken);
        }

        if (event.eventType === EventType.LOGIN_FAILURE) {
            // Check for forgot password error
            // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
            if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                const resetPasswordRequest = {
                    authority: b2cPolicies.authorities.forgotPassword.authority,
                    scopes: [],
                };
                instance.loginRedirect(resetPasswordRequest);
            }
        }
    }

    if (!sliceUserObject.adUser) {
        if (loggedin) {
            if (!loggedin.accessToken) {

                instance.acquireTokenSilent(tokenRequest).catch(function (error) {

                    instance.acquireTokenRedirect(tokenRequest);
                });

            } else {
                dispatch(setADUser(loggedin));            
                dispatch(fetchAuth(loggedin.accessToken));
                BaseApi.setToken(loggedin.accessToken);
            }            
        } 
    }

    useEffect(() => {
        if (sliceUserObject.adUser && sliceUserObject.adUser.account && sliceUserObject.adUser.account.idTokenClaims) {
            let tokenClaims = sliceUserObject.adUser.account.idTokenClaims;
            let d = new Date(tokenClaims.exp*1000);
            //getting current time
            var currentTime = new Date().getTime();
            // console.log("current Time", currentTime);
            // console.log(tokenClaims.exp * 1000);

            //getting time in milliseconds between now and token expiration
            var tokenTimeToLive = (tokenClaims.exp * 1000) - new Date().getTime();
            // console.log("token time to live", tokenTimeToLive);
            // console.log("expriation time", d.toLocaleString());


            const fetchUserIntvl = setInterval(() => {
                instance.acquireTokenSilent(tokenRequest).catch(function (error) {
                    // console.log("getAccessToken error: ", error);
                    instance.acquireTokenRedirect(tokenRequest);
                });
            }, tokenTimeToLive - 10000) //setting interval to occur 10s before token expires

            return () => clearInterval(fetchUserIntvl);
        }
    }, [sliceUserObject])

    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    useEffect(() => {
        const callbackId = instance.addEventCallback(handleMsalResponse);

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    return  <AppRouter />;
};

export default AppRouterProvider;