import React, { useState } from 'react';
import { Field } from 'formik';
import { Form } from 'formik';
import { Formik } from 'formik';

const OrderDetailsInvestigationResults = ({results, progressPending}) => {

    return (
        !progressPending && <Formik>
            <Form>
                <fieldset disabled={true}>
                    <label>Details</label>
                    <br />
                    <div className="form-field-wrapper">
                        {/* <label>Details</label> */}
                        <Field id='results' name='results' as='textarea' rows='15' value={results} />
                    </div>
                </fieldset>
            </Form>
        </Formik>
    )
};

export default OrderDetailsInvestigationResults;