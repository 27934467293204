
import BaseApi from '../utility/fetch-wrapper';

class AuthService {

    static GetAuth = async (payload) => {
        const data = await BaseApi.getRequest(`authorization`, 'GET', payload);
        return data;
    };
}


export default AuthService;