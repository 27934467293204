import { Fragment } from "react";
import { Field, FieldArray } from "formik";
import { DatePickerInput } from "../../datepicker-input";
import { stateProvince } from "../../../utility/state-province";
import StateProvinceDropdown from "../../stateprovinceDropdown";

const IntakeWitnessDefendantStatementDefendantDetailsFields = ({ errors, touched, values, nested, initialValues }) => {
    /**
     * add another defendant to form
     */
    const handleAddNew = arrayHelpers => {
        arrayHelpers.push(initialValues);
    };


    /**
     * remove defendant
     */
    const handleRemove = (arrayHelpers, index) => {
        arrayHelpers.remove(index)
    };


    return (
        <div className="form-wrapper general-information">
            <h3>Defendant Information <small>* Fill with N/A if not applicable on required fields</small></h3>

            <FieldArray
                name={nested ? 'witnessDefendantStatementDefendantDetails.defendants' : 'defendants'}
                render={arrayHelpers => (
                    <Fragment>
                        {values.defendants.map((defendant, index) => (
                            <div key={`defendant-${index}`} className="repeater">
                                <div className="form-row">
                                    <div className="form-field-wrapper">
                                        <label htmlFor="firstName">First Name <span className="required">*</span></label>
                                        <Field id="firstName" name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.firstName` : `defendants.${index}.firstName`} />

                                        {errors && errors.defendants && errors.defendants[index] && errors.defendants[index].firstName && touched && touched.defendants && touched.defendants[index] && touched.defendants[index].firstName ? (
                                            <div className="form-error">{errors.defendants[index].firstName}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="lastName">Last Name <span className="required">*</span></label>
                                        <Field id="lastName" name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.lastName` : `defendants.${index}.lastName`} />

                                        {errors && errors.defendants && errors.defendants[index] && errors.defendants[index].lastName && touched && touched.defendants && touched.defendants[index] && touched.defendants[index].lastName ? (
                                            <div className="form-error">{errors.defendants[index].lastName}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="dateOfBirth">Date of Birth</label>
                                        <DatePickerInput name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.dateOfBirth` : `defendants.${index}.dateOfBirth`} placeholderText="Select date" />
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="phone">Phone # <span className="required">*</span></label>
                                        <Field id="phone" name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.phone` : `defendants.${index}.phone`} />

                                        {errors && errors.defendants && errors.defendants[index] && errors.defendants[index].phone && touched && touched.defendants && touched.defendants[index] && touched.defendants[index].phone ? (
                                            <div className="form-error">{errors.defendants[index].phone}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="address1">Address 1</label>
                                        <Field id="address1" name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.address1` : `defendants.${index}.address1`} />
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="address2">Address 2</label>
                                        <Field id="address2" name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.address2` : `defendants.${index}.address2`} />
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="city">City</label>
                                        <Field id="city" name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.city` : `defendants.${index}.city`} />
                                    </div>

                                    <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.stateProvince` : `defendants.${index}.stateProvince`} id="stateProvince" />

                                    <div className="form-field-wrapper">
                                        <label htmlFor="zip">Zip</label>
                                        <Field id="zip" name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.zip` : `defendants.${index}.zip`} />
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="county">Country</label>
                                        <Field id="county" name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.county` : `defendants.${index}.county`} />
                                    </div>
                                </div>

                                <div className="form-row three-col no-border">
                                    <div className="form-field-wrapper">
                                        <label htmlFor="directionOfTravel">Direction of Travel <span className="required">*</span></label>
                                        <Field id="directionOfTravel" name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.directionOfTravel` : `defendants.${index}.directionOfTravel`} />

                                        {errors && errors.defendants && errors.defendants[index] && errors.defendants[index].directionOfTravel && touched && touched.defendants && touched.defendants[index] && touched.defendants[index].directionOfTravel ? (
                                            <div className="form-error">{errors.defendants[index].directionOfTravel}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="streetOfTravel">Street of Travel <span className="required">*</span></label>
                                        <Field id="streetOfTravel" name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.streetOfTravel` : `defendants.${index}.streetOfTravel`} />

                                        {errors && errors.defendants && errors.defendants[index] && errors.defendants[index].streetOfTravel && touched && touched.defendants && touched.defendants[index] && touched.defendants[index].streetOfTravel ? (
                                            <div className="form-error">{errors.defendants[index].streetOfTravel}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="defendantVehicleDescription">Defendant Vehicle Description <small>(Make / Model / Year / Color)</small> <span className="required">*</span></label>
                                        <Field id="defendantVehicleDescription" name={nested ? `witnessDefendantStatementDefendantDetails.defendants.${index}.defendantVehicleDescription` : `defendants.${index}.defendantVehicleDescription`} />

                                        {errors && errors.defendants && errors.defendants[index] && errors.defendants[index].defendantVehicleDescription && touched && touched.defendants && touched.defendants[index] && touched.defendants[index].defendantVehicleDescription ? (
                                            <div className="form-error">{errors.defendants[index].defendantVehicleDescription}</div>
                                        ) : null}
                                    </div>
                                </div>

                                {index > 0 && <div className="buttons-right">
                                    <button
                                        type="button"
                                        className="button tertiary small"
                                        onClick={() => handleRemove(arrayHelpers, index)}
                                    >
                                        - Remove Defendant
                                    </button>
                                </div>}
                            </div>
                        ))}

                        <div className="buttons-centered">
                            <button
                                type="button"
                                className="button tertiary"
                                onClick={() => handleAddNew(arrayHelpers)}
                            >
                                + Add Another Defendant
                            </button>
                        </div>

                        {errors && errors.defendants && typeof errors.defendants === 'string' ? (
                            <div className="form-error">{errors.defendants}</div>
                        ) : null}
                    </Fragment>
                )}
            />
        </div>
    );
};

export default IntakeWitnessDefendantStatementDefendantDetailsFields;