import Header from "../header";
import PortalLogo from '../../assets/logo-portal.svg';
import Breadcrumbs from "../breadcrumbs";


const ErrorIntake = () => {
    return (
        <div className='layout intake'>
            <div className="left-rail">
                <img className="logo" src={PortalLogo} alt='portal' />
            </div>

            <div className='layout-content'>
                <Header showUserName={false} />

                <Breadcrumbs />

                <div className='layout-content-outlet'>
                    <h1 className="close-text">An error has occured.</h1>
                </div>
            </div>
        </div>
    );
}

export default ErrorIntake;