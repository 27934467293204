import { Field } from "formik";
import { stateProvince } from "../../../utility/state-province";
import { DatePickerInput } from "../../datepicker-input";
import StateProvinceDropdown from "../../stateprovinceDropdown";

const IntakeSkipTraceDetailsFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="firstName">First Name <span className="required">*</span></label>
                    <Field id="firstName" name={nested ? "skipTraceDetails.firstName" : "firstName"} />

                    {errors && errors.firstName && touched && touched.firstName ? (
                        <div className="form-error">{errors.firstName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastName">Last Name <span className="required">*</span></label>
                    <Field id="lastName" name={nested ? "skipTraceDetails.lastName" : "lastName"} />

                    {errors && errors.lastName && touched && touched.lastName ? (
                        <div className="form-error">{errors.lastName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="email">Email</label>
                    <Field id="email" name={nested ? "skipTraceDetails.email" : "email"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="phone">Phone #</label>
                    <Field id="phone" name={nested ? "skipTraceDetails.phone" : "phone"} />

                    {errors && errors.phone && touched && touched.phone ? (
                        <div className="form-error">{errors.phone}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress1Line1">Last Known Address 1 - Line 1</label>
                    <Field id="lastKnownAddress1Line1" name={nested ? "skipTraceDetails.lastKnownAddress1Line1" : "lastKnownAddress1Line1"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress1Line2">Last Known Address 1 - Line 2</label>
                    <Field id="lastKnownAddress1Line2" name={nested ? "skipTraceDetails.lastKnownAddress1Line2" : "lastKnownAddress1Line2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress1City">Last Known Address 1 - City</label>
                    <Field id="lastKnownAddress1City" name={nested ? "skipTraceDetails.lastKnownAddress1City" : "lastKnownAddress1City"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "skipTraceDetails.lastKnownAddress1StateProvince" : "lastKnownAddress1StateProvince"} id="lastKnownAddress1StateProvince" disabled={false} title="Last Known Address 1 - State/Province"/>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress1Zip">Last Known Address 1 - Zip</label>
                    <Field id="lastKnownAddress1Zip" name={nested ? "skipTraceDetails.lastKnownAddress1Zip" : "lastKnownAddress1Zip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress1Country">Last Known Address 1 - Country</label>
                    <Field id="lastKnownAddress1Country" name={nested ? "skipTraceDetails.lastKnownAddress1Country" : "lastKnownAddress1Country"} />
                </div>

                <div className="form-field-wrapper"></div>
                <div className="form-field-wrapper"></div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress2Line1">Last Known Address 2 - Line 1</label>
                    <Field id="lastKnownAddress2Line1" name={nested ? "skipTraceDetails.lastKnownAddress2Line1" : "lastKnownAddress2Line1"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress2Line2">Last Known Address 2 - Line 2</label>
                    <Field id="lastKnownAddress2Line2" name={nested ? "skipTraceDetails.lastKnownAddress2Line2" : "lastKnownAddress2Line2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress2City">Last Known Address 2 - City</label>
                    <Field id="lastKnownAddress2City" name={nested ? "skipTraceDetails.lastKnownAddress2City" : "lastKnownAddress2City"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "skipTraceDetails.lastKnownAddress2StateProvince" : "lastKnownAddress2StateProvince"} id="lastKnownAddress2StateProvince" disabled={false} className={`${(values.stateProvince && (values.stateProvince.length <= 0 || values.stateProvince === 'Make a selection')) ? 'placeholder' : ''}`} title="Last Known Address 2 - State/Province"/>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress2Zip">Last Known Address 2 - Zip</label>
                    <Field id="lastKnownAddress2Zip" name={nested ? "skipTraceDetails.lastKnownAddress2Zip" : "lastKnownAddress2Zip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastKnownAddress2Country">Last Known Address 2 - Country</label>
                    <Field id="lastKnownAddress2Country" name={nested ? "skipTraceDetails.lastKnownAddress2Country" : "lastKnownAddress2Country"} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="dob">Date of Birth</label>
                    <DatePickerInput name={nested ? "skipTraceDetails.dob" : "dob"} placeholderText="Select date" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="age">Age</label>
                    <Field id="age" name={nested ? "skipTraceDetails.age" : "age"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="vin">VIN</label>
                    <Field id="vin" name={nested ? "skipTraceDetails.vin" : "vin"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="licensePlate">License Plate</label>
                    <Field id="licensePlate" name={nested ? "skipTraceDetails.licensePlate" : "licensePlate"} />
                </div>
            </div>
        </div>
    );
};

export default IntakeSkipTraceDetailsFields;