import { Field } from "formik";


const IntakeInsuranceInvestigationPersonalHomeownersRentersAdditionalInformationFields = ({ errors, touched, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <h3>Do you have a Letter of Acknowledgment?</h3>

            <div className="asset-locate-type">
                <div role="group" aria-labelledby="letterOfAcknowledgement-radio-group" className="form-check-inline left-justified">
                    <div className="form-check">
                        <Field type="radio" name={nested ? "insuranceInvestigationPersonalHomeownersRentersAdditionalInformation.letterOfAcknowledgement" : "letterOfAcknowledgement"} value="Yes" id="letterOfAcknowledgement-Yes" />
                        <label htmlFor="letterOfAcknowledgement-Yes">Yes</label>
                    </div>
                    <div className="form-check">
                        <Field type="radio" name={nested ? "insuranceInvestigationPersonalHomeownersRentersAdditionalInformation.letterOfAcknowledgement" : "letterOfAcknowledgement"} value="No" id="letterOfAcknowledgement-No" />
                        <label htmlFor="letterOfAcknowledgement-No">No</label>
                    </div>
                </div>

                {errors && errors.letterOfAcknowledgement && touched && touched.letterOfAcknowledgement ? (
                    <div className="form-error">{errors.letterOfAcknowledgement}</div>
                ) : null}
            </div>
        </div>
    );
};

export default IntakeInsuranceInvestigationPersonalHomeownersRentersAdditionalInformationFields;