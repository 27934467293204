import * as Yup from 'yup';


const InsuranceInvestigationBusinessDriversDetailsSchema = Yup.object().shape({
    insuranceInvestigationBusinessDriversDetails: Yup.object().shape({
        driversInsuranceCompany: Yup.string()
            .required('Required'),
    })
});

export default InsuranceInvestigationBusinessDriversDetailsSchema;