import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import FormFooter from '../../../components/form-footer';
import IntakeInsuranceClaimHistorySubjectFields from '../../../components/intake/insurance-claim-history/subject-fields';
import InsuranceClaimHistorySubjectSchema from '../../../validation-schemas/insurance-claim-history-subject-schema';
import { updateActiveFormInsuranceClaimHistorySubject, selectActiveFormInsuranceClaimHistorySubject } from '../../../reducers/active-form-slice';


const IntakeInsuranceClaimHistorySubject = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const backgroundScreeningSubject = useSelector(selectActiveFormInsuranceClaimHistorySubject);
    /**
     * Get client firstname and lastname in case they select "yes"
     */
    
    const { firstNameClient, lastNameClient } = useSelector((state) => state.activeForm.generalInformation);
    const { insuranceClaimHistoryDetails } = useSelector((state) => state.activeForm.insuranceClaimHistoryDetails);

    const hasValues = Object.keys(backgroundScreeningSubject).length > 0 ? true : false;
    const hasInsuranceClaimHistoryDetails = insuranceClaimHistoryDetails === 'Yes';

    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/insurance-claim-history/details");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormInsuranceClaimHistorySubject(values));
        navigate("/intake/additional-information");
    };

    /**
     * setup the initial values
     */
    const initialValues = {
        firstName: insuranceClaimHistoryDetails === 'Yes' ? firstNameClient : '',
        lastName: insuranceClaimHistoryDetails === 'Yes' ? lastNameClient : '',
        email: '',
        phone: '',
        address: '',
        address2: '',
        city: '',
        stateProvince: '',
        zip: '',
        country: '',
        dob: '',
        ssn: '',
        vin: '',
        licensePlate: '',
    };


    return (
        <div className="page intake">
            <h2>Subject of Insurance Claim</h2>

            <Formik
                initialValues={hasValues ? backgroundScreeningSubject : initialValues}
                validationSchema={InsuranceClaimHistorySubjectSchema.fields.insuranceClaimHistorySubject}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <div className="form-wrapper general-information">
                            <h3>Subject</h3>
                            <IntakeInsuranceClaimHistorySubjectFields
                                errors={errors}
                                touched={touched}
                                values={values}
                            />
                        </div>

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues || hasInsuranceClaimHistoryDetails) && isValid)}
                            showNext={true}
                            progress={`70`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInsuranceClaimHistorySubject;