import { Field } from "formik";
import { stateProvince } from "../../../utility/state-province";
import { DatePickerInput } from "../../datepicker-input";
import StateProvinceDropdown from "../../stateprovinceDropdown";


const IntakeSubpoenaProcessServingDetailsFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="firstName">First Name <span className="required">*</span></label>
                    <Field id="firstName" name={nested ? "subpoenaProcessServingDetails.firstName" : "firstName"} />

                    {errors && errors.firstName && touched && touched.firstName ? (
                        <div className="form-error">{errors.firstName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastName">Last Name <span className="required">*</span></label>
                    <Field id="lastName" name={nested ? "subpoenaProcessServingDetails.lastName" : "lastName"} />

                    {errors && errors.lastName && touched && touched.lastName ? (
                        <div className="form-error">{errors.lastName}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="email">Email</label>
                    <Field id="email" name={nested ? "subpoenaProcessServingDetails.email" : "email"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="phone">Phone #</label>
                    <Field id="phone" name={nested ? "subpoenaProcessServingDetails.phone" : "phone"} />

                    {errors && errors.phone && touched && touched.phone ? (
                        <div className="form-error">{errors.phone}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address1">Address 1</label>
                    <Field id="address1" name={nested ? "subpoenaProcessServingDetails.address1" : "address1"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address2">Address 2</label>
                    <Field id="address2" name={nested ? "subpoenaProcessServingDetails.address2" : "address2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="city">City</label>
                    <Field id="city" name={nested ? "subpoenaProcessServingDetails.city" : "city"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "subpoenaProcessServingDetails.stateProvince" : "stateProvince"} id="stateProvince" />

                <div className="form-field-wrapper">
                    <label htmlFor="zip">Zip</label>
                    <Field id="zip" name={nested ? "subpoenaProcessServingDetails.zip" : "zip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="country">Country</label>
                    <Field id="country" name={nested ? "subpoenaProcessServingDetails.country" : "country"} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="typeOfService">Type of Service</label>
                    <Field id="typeOfService" name={nested ? "subpoenaProcessServingDetails.typeOfService" : "typeOfService"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastDayToComplete">Last Day to Complete</label>
                    <DatePickerInput name={nested ? "subpoenaProcessServingDetails.lastDayToComplete" : "lastDayToComplete"} placeholderText="Select date" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="vin">VIN</label>
                    <Field id="vin" name={nested ? "subpoenaProcessServingDetails.vin" : "vin"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="licensePlate">License Plate</label>
                    <Field id="licensePlate" name={nested ? "subpoenaProcessServingDetails.licensePlate" : "licensePlate"} />
                </div>
            </div>
        </div>
    );
};

export default IntakeSubpoenaProcessServingDetailsFields;