import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../components/form-footer';
import IntakeDMVDetailsFields from '../../../components/intake/dmv/details-fields';
import DmvDetailsSchema from '../../../validation-schemas/dmv-details-schema';
import { updateActiveFormDMVDetails, selectActiveFormDMVDetails } from '../../../reducers/active-form-slice';


const IntakeDMVDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const dmvDetails = useSelector(selectActiveFormDMVDetails);
    const hasValues = Object.keys(dmvDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormDMVDetails(values));
        navigate("/intake/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        lastKnownAddress1: '',
        lastKnownAddress2: '',
        city: '',
        stateProvince: '',
        zip: '',
        country: '',
        dob: '',
        age: '',
        vin: '',
        licensePlate: '',
    };


    return (
        <div className="page intake">
            <h2>DMV Details</h2>

            <Formik
                initialValues={hasValues ? dmvDetails : initialValues}
                validationSchema={DmvDetailsSchema.fields.dmvDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeDMVDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`50`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeDMVDetails;