import * as Yup from 'yup';


const InsuranceInvestigationBusinessAdditionalInformationSchema = Yup.object().shape({
    insuranceInvestigationBusinessAdditionalInformation: Yup.object().shape({
        letterOfAcknowledgement: Yup.string()
            .required('Required'),
    })
});

export default InsuranceInvestigationBusinessAdditionalInformationSchema;