import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LawFirmsServices from '../services/law-firms-service';


export const fetchLawFirmId = createAsyncThunk('lawFirmDetails/fetchLawFirmId', async (payload) => {
    const data = await LawFirmsServices.GetLawFirmById(payload);
    return data;
});

export const updateLawFirm = createAsyncThunk('lawFirmDetails/updateLawFirm', async (payload) => {
    const response = await LawFirmsServices.UpdateLawFirm(payload);
    return response;
});

export const createLawFirm = createAsyncThunk('lawFirmDetails/createLawFirm', async (payload) => {
    const response = await LawFirmsServices.CreateLawFirm(payload);
    return response;
});


export const lawFirmDetailsSlice = createSlice({
    name: 'lawFirmDetails',

    initialState: {
        lawFirmDetails: {},
        status: 'idle',
        error: null,
    },

    reducers: {
        resetLawFirmDetailsStatus: (state) => {
            state.status = 'idle';
        },
    },

    extraReducers: {
        [fetchLawFirmId.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchLawFirmId.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.lawFirmDetails = action.payload;
        },
        [fetchLawFirmId.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [updateLawFirm.pending]: (state, action) => {
            state.status = 'loading';
        },
        [updateLawFirm.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.lawFirmDetails = action.payload;
        },
        [updateLawFirm.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetLawFirmDetailsStatus,
} = lawFirmDetailsSlice.actions;

export default lawFirmDetailsSlice.reducer;


export const selectLawFirm = state => {
    return state.lawFirmDetails.lawFirmDetails;
};