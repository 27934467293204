import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../components/form-footer';
import IntakeBusinessDueDiligenceDetailsFields from '../../../components/intake/business-due-diligence/details-fields';
import BusinessDueDiligenceDetailsSchema from '../../../validation-schemas/business-due-diligence-details-schema';
import { updateActiveFormBusinessDueDiligenceDetails, selectActiveFormBusinessDueDiligenceDetails } from '../../../reducers/active-form-slice';

const IntakeBusinessDueDiligenceDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const businessDueDiligenceDetails = useSelector(selectActiveFormBusinessDueDiligenceDetails);
    const hasValues = Object.keys(businessDueDiligenceDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormBusinessDueDiligenceDetails(values));
        navigate("/intake/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        businessName: '',
        ownersName: '',
        businessAddress: '',
        businessAddress2: '',
        city: '',
        stateProvince: '',
        zip: '',
        country: '',
    };


    return (
        <div className="page intake">
            <h2>Business Due Diligence Details</h2>

            <Formik
                initialValues={hasValues ? businessDueDiligenceDetails : initialValues}
                validationSchema={BusinessDueDiligenceDetailsSchema.fields.businessDueDiligenceDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeBusinessDueDiligenceDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`50`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeBusinessDueDiligenceDetails;