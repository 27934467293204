import { Field } from "formik";
import { Fragment } from "react";
import InvestigationNameFields from '../investigation-name-fields';

const IntakeInsuranceClaimHistoryClientFields = ({ errors, touched, values, nested }) => {
    return (
        <Fragment>
            <div className="form-wrapper client-information" >
                <h3>Client Details</h3>
            </div>
            <div className="form-row three-col-equal">
                <div className="form-field-wrapper">
                    <label htmlFor="firstNameClient">First Name (Client) <span className="required">*</span></label>
                    <Field id="firstNameClient" name={nested ? 'generalInformation.firstNameClient' : 'firstNameClient'} />

                    {errors && errors.firstNameClient && touched && touched.firstNameClient ? (
                        <div className="form-error">{errors.firstNameClient}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="lastNameClient">Last Name (Client) <span className="required">*</span></label>
                    <Field id="lastNameClient" name={nested ? 'generalInformation.lastNameClient' : 'lastNameClient'} />

                    {errors && errors.lastNameClient && touched && touched.lastNameClient ? (
                        <div className="form-error">{errors.lastNameClient}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="fileNumberClient">File # (Client)</label>
                    <Field id="fileNumberClient" name={nested ? 'generalInformation.fileNumberClient' : 'fileNumberClient'} />
                </div>
            </div>
        </Fragment>
    );
};

export default IntakeInsuranceClaimHistoryClientFields;