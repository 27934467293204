import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormFooter from '../../../../../components/form-footer';
import IntakeInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationFields from '../../../../../components/intake/insurance-investigation/personal/auto-umbrella/additional-information-fields';
import InsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationSchema from '../../../../../validation-schemas/insurance-investigation-personal-auto-umbrella-addition-information-schema';
import { selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformation, updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformation, selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaDetails } from '../../../../../reducers/active-form-slice';


const IntakeInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    /**
     * get current form values
     */
    const insuranceInvestigationPersonalAutoUmbrellaDetails = useSelector(selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaDetails);
    const insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation = useSelector(selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformation);
    const hasValues = Object.keys(insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/insurance-investigation-personal-auto-umbrella/drivers-details");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformation(values));
        navigate("/intake/additional-information");
    };


    /**
     * setup the initial values & validation
     */
    const initialValues = {
        letterOfAcknowledgement: '',
    };


    return (
        <div className="page intake">
            <h2>Additional Information</h2>

            <Formik
                initialValues={hasValues ? insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation : initialValues}
                validationSchema={InsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationSchema.fields.insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, touched, isValid }) => (
                    <Form>
                        <IntakeInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationFields
                            errors={errors}
                            touched={touched}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`75`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformation;