import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OrdersServices from '../services/orders-service';


export const fetchNotifications = createAsyncThunk('orders/fetchNotifications', async () => {
    const data = await OrdersServices.GetNotifications()
    return data;     
});

export const fetchAllNotifications = createAsyncThunk('orders/fetchGetAllNotifications', async (payload) => {
    const data = await OrdersServices.GetAllNotifications(payload)
    return data
});

export const postNewNotificationFromExisting = createAsyncThunk('orders/postNewNotificationFromExisting', async (payload) => {
    const data = await OrdersServices.PostNewNotificationFromExisting(payload)
    return data
});

export const fetchUserNotified = createAsyncThunk('orders/fetchUserNotified', async (user) => {
    const data = await OrdersServices.GetNotified(user);
    return data;     
});

export const postDeleteNotification = createAsyncThunk('orders/postDeleteNotification', async (payload) => {
    const data = await OrdersServices.DeleteNotification(payload)
    // console.log("RETURN DATA: " + data);
    if (data === true) {
        // if the deletion worked, recalculate the amount of orders to show on the notifications dashboard
        var num = window.sessionStorage.getItem("numNotifications");
        if (num != null) {
            num = num - 1;
            window.sessionStorage.setItem("numNotifications", num);
        }
    
    }
    return data;
});

const initialState = {
    orders: [],
    notifications: [],
    status: 'idle',
    userNotifiedStatus: 'idle',
    error: null,
    notified: false
}

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        resetNotificationStatus: (state) => {
            state.status = 'idle';
            state.userNotifiedStatus = 'idle';
        },
        resetAllNotifications: (state) => {
            state.notifications = [];
            state.status = 'idle';
        },
        resetNotifications: () => initialState,
    },


    extraReducers: {
        [fetchNotifications.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchNotifications.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.orders = state.orders.concat(action.payload);
        },
        [fetchNotifications.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        
        [fetchUserNotified.fulfilled]: (state, action) => {
            state.userNotifiedStatus = 'succeeded';
            state.notified = action.payload;
        },
        [fetchUserNotified.pending]: (state, action) => {
            state.userNotifiedStatus = 'loading';
        },
        [fetchUserNotified.rejected]: (state, action) => {
            state.userNotifiedStatus = 'failed';
            state.error = action.error.message;
        },

        [fetchAllNotifications.fulfilled]: (state, action) => {
            state.userNotifiedStatus = 'succeeded';
            state.notifications = action.payload;
        },
        [fetchAllNotifications.pending]: (state, action) => {
            state.user = 'loading';
        },
        [fetchAllNotifications.rejected]: (state, action) => {
            state.userNotifiedStatus = 'failed';
            state.error = action.error.message;
        },
    }
});



export const {
    resetNotificationStatus,
    resetNotifications,
    resetAllNotifications
} = notificationsSlice.actions;

export default notificationsSlice.reducer;


export const selectAllNotifications = state => {
    return state.notifications.orders;
};

export const selectGetAllNotifications = state => {
    return state.notifications.notifications
}

export const selectUserNotified = state => {
    return state.notifications.notified;
}
