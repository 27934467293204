import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GlucroftUsersServices from '../services/glucroft-users-service';
import RoleServices from '../services/role-service';


export const fetchGlucroftById = createAsyncThunk('glucroftUserDetails/fetchGlucroftById', async (payload) => {
    const response = await GlucroftUsersServices.GetGlucroftUserById(payload);
    return response;
});

export const updateGlucroftUser = createAsyncThunk('glucroftUserDetails/updateGlucroftUser', async (payload) => {
    const response = await GlucroftUsersServices.UpdateGlucroftUser(payload);
    return response;
});

export const createGlucroftUser = createAsyncThunk('glucroftUserDetails/createGlucroftUser', async (payload) => {
    const data = await GlucroftUsersServices.CreateGlucroftUser(payload);
    return data;
});

export const fetchRoles = createAsyncThunk('glucroftUsers/fetchRoles', async (payload) => {
    const data = await RoleServices.GetRoles();
    return data;
});

export const updateUsersNotesAccess = createAsyncThunk('glucroftUsers/updateUsersNotesAccess', async (payload) => {
    const data = await RoleServices.UpdateNotePermissionLevel(payload);
    return data;
});


export const glucroftUserDetailsSlice = createSlice({
    name: 'glucroftUserDetails',

    initialState: {
        glucroftUserDetails: {},
        status: 'idle',
        error: null,
    },

    reducers: {
        resetGlucroftDetailsStatus: (state) => {
            state.status = 'idle';
        },
    },

    extraReducers: {
        [fetchGlucroftById.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchGlucroftById.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.glucroftUserDetails = action.payload;
        },
        [fetchGlucroftById.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [updateGlucroftUser.pending]: (state, action) => {
            state.status = 'loading';
        },
        [updateGlucroftUser.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.glucroftUserDetails = action.payload;
        },
        [updateGlucroftUser.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetGlucroftDetailsStatus,
} = glucroftUserDetailsSlice.actions;

export default glucroftUserDetailsSlice.reducer;


export const selectGlucroftUser = state => {
    return state.glucroftUserDetails.glucroftUserDetails;
};