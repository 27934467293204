import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormFooter from '../../../../components/form-footer';
import IntakeInsuranceInvestigationLocateAdditionalInformationFields from '../../../../components/intake/insurance-investigation/locate/additional-information-fields';
import InsuranceInvestigationLocateAdditionalInformationSchema from '../../../../validation-schemas/insurance-investigation-locate-additional-information-schema';
import { selectActiveFormInsuranceInvestigationLocateAdditionalInformation, updateActiveFormInsuranceInvestigationLocateAdditionalInformation, selectActiveFormInsuranceInvestigationLocateDetails } from '../../../../reducers/active-form-slice';


const IntakeInsuranceInvestigationLocateAdditionalInformation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    /**
     * get current form values
     */
    const insuranceInvestigationLocateDetails = useSelector(selectActiveFormInsuranceInvestigationLocateDetails);
    const insuranceInvestigationLocateAdditionalInformation = useSelector(selectActiveFormInsuranceInvestigationLocateAdditionalInformation);
    const hasValues = Object.keys(insuranceInvestigationLocateAdditionalInformation).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/insurance-investigation-locate/drivers-details");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormInsuranceInvestigationLocateAdditionalInformation(values));
        navigate("/intake/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        letterOfAcknowledgement: '',
    };


    return (
        <div className="page intake">
            <h2>Additional Information</h2>

            <Formik
                initialValues={hasValues ? insuranceInvestigationLocateAdditionalInformation : initialValues}
                validationSchema={InsuranceInvestigationLocateAdditionalInformationSchema.fields.insuranceInvestigationLocateAdditionalInformation}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, touched, isValid }) => (
                    <Form>
                        <IntakeInsuranceInvestigationLocateAdditionalInformationFields
                            errors={errors}
                            touched={touched}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`75`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInsuranceInvestigationLocateAdditionalInformation;