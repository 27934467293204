import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../components/form-footer';
import IntakeSkipTraceDetailsFields from '../../../components/intake/skip-trace/details-fields';
import SkipTraceDetailsSchema from '../../../validation-schemas/skip-trace-details-schema';
import { updateActiveFormSkipTraceDetails, selectActiveFormSkipTraceDetails } from '../../../reducers/active-form-slice';


const IntakeSkipTraceDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const skipTraceDetails = useSelector(selectActiveFormSkipTraceDetails);
    const hasValues = Object.keys(skipTraceDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormSkipTraceDetails(values));
        navigate("/intake/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        lastKnownAddress1Line1: '',
        lastKnownAddress1Line2: '',
        lastKnownAddress1City: '',
        lastKnownAddress1StateProvince: '',
        lastKnownAddress1Zip: '',
        lastKnownAddress1Country: '',
        lastKnownAddress2Line1: '',
        lastKnownAddress2Line2: '',
        lastKnownAddress2City: '',
        lastKnownAddress2StateProvince: '',
        lastKnownAddress2Zip: '',
        lastKnownAddress2Country: '',
        dob: '',
        age: '',
        vin: '',
        licensePlate: '',
    };


    return (
        <div className="page intake">
            <h2>Skip Trace Details</h2>

            <Formik
                initialValues={hasValues ? skipTraceDetails : initialValues}
                validationSchema={SkipTraceDetailsSchema.fields.skipTraceDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeSkipTraceDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`50`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeSkipTraceDetails;