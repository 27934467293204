import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../components/form-footer';
import IntakeGeneralInformationFields from '../../components/intake/general-information-fields';
import GeneralInformationSchema from '../../validation-schemas/general-information-schema';
import { updateActiveFormGeneralInformation, selectActiveFormGeneralInformation, selectActiveFormInvestigationType } from '../../reducers/active-form-slice';
import { orderTypes } from '../../utility/order-type-ids';

const IntakeGeneralInformation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const investigationType = useSelector(selectActiveFormInvestigationType);
    const generalInformation = useSelector(selectActiveFormGeneralInformation);
    const hasValues = Object.keys(generalInformation).length > 0 ? true : false;


    


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormGeneralInformation(values));

        navigate('/intake');
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        firstNameCaseManager: '',
        lastNameCaseManager: '',
        emailPrimary: '',
        emailSecondary: '',
        phonePrimary: '',
        phoneSecondary: '',
        lawFirmName: '',
        address: '',
        address2: '',
        city: '',
        stateProvince: '',
        zip: '',
        country: '',
    };


    return (
        <div className="page intake">
            <h2>General Information</h2>

            <Formik
                initialValues={hasValues ? generalInformation : initialValues}
                validationSchema={GeneralInformationSchema.fields.generalInformation}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeGeneralInformationFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            showPrevious={false}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`5`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeGeneralInformation;