import * as Yup from 'yup';


const InsuranceInvestigationLocateDetailsSchema = Yup.object().shape({
    insuranceInvestigationLocateDetails: Yup.object().shape({
        insuredType: Yup.string()
            .required('Required'),
        policyLimits: Yup.string()
            .required('Required'),
        insuredName: Yup.string()
            .required('Required'),
        registeredOwnerIsDriver: Yup.string()
            .required('Required'),
        dateOfLoss: Yup.string()
            .required('Required')
            .typeError('Required')
    })
});

export default InsuranceInvestigationLocateDetailsSchema;