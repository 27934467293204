import { useSelector } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FormService from '../services/form-service';
import LawFirmsServices from '../services/law-firms-service';
import FileUpload from '../services/files-upload-service';
import ReportService from '../services/report-service';


export const submitForm = createAsyncThunk('activeForm/SubmitForm', async (payload, {getState}) => {
    const state = getState();

    const response = await FormService.SubmitForm(payload);
    if (response && response.id) 
    {
        // create and attach investigation summary sheet
        if ((payload.investigationType[0].includes("Insurance Investigation")) || (payload.investigationType[0].includes("Insurance Claim History"))) {
            await ReportService.GenerateSummaryReport(response.id, "true", "false");
        }
        
        // post pending attachments
        var specificAttachments = state.attachments.intakeAttachments_specific.slice();
        var additionalAttachments = state.attachments.intakeAttachments_additional.slice();

        var attachments = [...specificAttachments, ...additionalAttachments];
        await FileUpload.Upload(attachments, response.id, state.auth.portalUser.id);

        const emailRequest =
        {
            emailRecipients: [payload.generalInformation.emailPrimary],
            subjectLine: "Glucroft Order Confirmation",
            emailBody: `Dear ${payload.generalInformation.firstNameCaseManager}, \n\nWe have received your submission (${response.orderNumber}) thank you! \n\nWe will turn around your order as quickly as possible. Please Note: Although we can investigate policy coverage for USAA, we are now unable to guarantee the result is consistent with the date of loss.`
        };
        await FormService.SendEmail(emailRequest);
    }
    return response;
});

export const getFirmById = createAsyncThunk('activeForm/firm', async (payload) => {
    const data = await LawFirmsServices.GetLawFirmById(payload);
    return data;
})


export const activeFormSlice = createSlice({
    name: 'activeForm',

    initialState: {
        investigationType: {},
        generalInformation: {},
        additionalInformation: {},
        assetLocateType: {},
        assetLocateDetails: {},
        assetLocateRequest: {},
        backgroundScreeningDetails: {},
        backgroundScreeningSocialMedia: {},
        businessDueDiligenceDetails: {},
        dmvDetails: {},
        insuranceClaimHistoryDetails: {},
        insuranceClaimHistorySubject: {},
        insuranceInvestigationBusinessDetails: {},
        insuranceInvestigationBusinessDriversDetails: {},
        insuranceInvestigationBusinessAdditionalInformation: {},
        insuranceInvestigationLocateDetails: {},
        insuranceInvestigationLocateDriversDetails: {},
        insuranceInvestigationLocateAdditionalInformation: {},
        insuranceInvestigationPersonalAutoUmbrellaDetails: {},
        insuranceInvestigationPersonalAutoUmbrellaDriversDetails: {},
        insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation: {},
        insuranceInvestigationPersonalHomeownersRentersDetails: {},
        insuranceInvestigationPersonalHomeownersRentersAdditionalInformation: {},
        sceneInvestigationDetails: {},
        skipTraceDetails: {},
        subpoenaProcessServingDetails: {},
        switrsDetails: {},
        trafficCollisionsReportRetrievalDetails: {},
        witnessDefendantStatementDetails: {},
        witnessDefendantStatementDefendantDetails: {},
        witnessDefendantStatementWitnessDetails: {},
        witnessDefendantStatementFaultInfo: {},
        persistGeneralInformation: false,
        status: 'idle',
        error: null,
    },

    reducers: {
        updateActiveFormInvestigationType: (state, action) => {
            state.investigationType = action.payload;
        },
        updateActiveFormGeneralInformation: (state, action) => {
            state.generalInformation = action.payload;
        },
        updateActiveFormClientInfo: (state, action) => {
            state.generalInformation.firstNameClient = action.payload.firstNameClient;
            state.generalInformation.lastNameClient = action.payload.lastNameClient;
            state.generalInformation.fileNumberClient = action.payload.fileNumberClient;
        },
        resetActiveFormClientInfo: (state, action) => {
            state.generalInformation.firstNameClient = null;
            state.generalInformation.lastNameClient = null;
            state.generalInformation.fileNumberClient = null;
        },
        updateActiveFormAdditionalInformation: (state, action) => {
            state.additionalInformation = action.payload;
        },
        updateActiveFormAssetLocateType: (state, action) => {
            state.assetLocateType = action.payload;
        },
        updateActiveFormAssetLocateDetails: (state, action) => {
            state.assetLocateDetails = action.payload;
        },
        updateActiveFormAssetLocateRequest: (state, action) => {
            state.assetLocateRequest = action.payload;
        },
        updateActiveFormBackgroundScreeningDetails: (state, action) => {
            state.backgroundScreeningDetails = action.payload;
        },
        updateActiveFormBackgroundScreeningSocialMedia: (state, action) => {
            state.backgroundScreeningSocialMedia = action.payload;
        },
        updateActiveFormBusinessDueDiligenceDetails: (state, action) => {
            state.businessDueDiligenceDetails = action.payload;
        },
        updateActiveFormDMVDetails: (state, action) => {
            state.dmvDetails = action.payload;
        },
        updateActiveFormInsuranceClaimHistoryDetails: (state, action) => {
            state.insuranceClaimHistoryDetails = action.payload;
        },
        updateActiveFormInsuranceClaimHistorySubject: (state, action) => {
            state.insuranceClaimHistorySubject = action.payload;
        },
        updateActiveFormInsuranceInvestigationBusinessDetails: (state, action) => {
            state.insuranceInvestigationBusinessDetails = action.payload;
        },
        updateActiveFormInsuranceInvestigationBusinessDriversDetails: (state, action) => {
            state.insuranceInvestigationBusinessDriversDetails = action.payload;
        },
        updateActiveFormInsuranceInvestigationBusinessAdditionalInformation: (state, action) => {
            state.insuranceInvestigationBusinessAdditionalInformation = action.payload;
        },
        updateActiveFormInsuranceInvestigationLocateDetails: (state, action) => {
            state.insuranceInvestigationLocateDetails = action.payload;
        },
        updateActiveFormInsuranceInvestigationLocateDriversDetails: (state, action) => {
            state.insuranceInvestigationLocateDriversDetails = action.payload;
        },
        updateActiveFormInsuranceInvestigationLocateAdditionalInformation: (state, action) => {
            state.insuranceInvestigationLocateAdditionalInformation = action.payload;
        },
        updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaDetails: (state, action) => {
            state.insuranceInvestigationPersonalAutoUmbrellaDetails = action.payload;
        },
        updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaDriversDetails: (state, action) => {
            state.insuranceInvestigationPersonalAutoUmbrellaDriversDetails = action.payload;
        },
        updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformation: (state, action) => {
            state.insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation = action.payload;
        },
        updateActiveFormInsuranceInvestigationPersonalHomeownersRentersDetails: (state, action) => {
            state.insuranceInvestigationPersonalHomeownersRentersDetails = action.payload;
        },
        updateActiveFormInsuranceInvestigationPersonalHomeownersRentersAdditionalInformation: (state, action) => {
            state.insuranceInvestigationPersonalHomeownersRentersAdditionalInformation = action.payload;
        },
        updateActiveFormSceneInvestigationDetails: (state, action) => {
            state.sceneInvestigationDetails = action.payload;
        },
        updateActiveFormSkipTraceDetails: (state, action) => {
            state.skipTraceDetails = action.payload;
        },
        updateActiveFormSubpoenaProcessServingDetails: (state, action) => {
            state.subpoenaProcessServingDetails = action.payload;
        },
        updateActiveFormSWITRSDetails: (state, action) => {
            state.switrsDetails = action.payload;
        },
        updateTrafficCollisionsReportRetrievalDetails: (state, action) => {
            state.trafficCollisionsReportRetrievalDetails = action.payload;
        },
        updateWitnessDefendantStatementDetails: (state, action) => {
            state.witnessDefendantStatementDetails = action.payload;
        },
        updateWitnessDefendantStatementDefendantDetails: (state, action) => {
            state.witnessDefendantStatementDefendantDetails = action.payload;
        },
        updateWitnessDefendantStatementWitnessDetails: (state, action) => {
            state.witnessDefendantStatementWitnessDetails = action.payload;
        },
        updateWitnessDefendantStatementFaultInfo: (state, action) => {
            state.witnessDefendantStatementFaultInfo = action.payload;
        },
        resetState: state => {
            state.investigationType = {};
            if (!state.persistGeneralInformation) //clear out all general information
                state.generalInformation = {};
            else { //clear out only the client first, client last and file number from general information
                state.generalInformation.firstNameClient = '';
                state.generalInformation.lastNameClient = '';
                state.fileNumberClient = '';
            }

            state.additionalInformation = {};
            state.assetLocateType = {};
            state.assetLocateDetails = {};
            state.assetLocateRequest = {};
            state.backgroundScreeningDetails = {};
            state.backgroundScreeningSocialMedia = {};
            state.businessDueDiligenceDetails = {};
            state.dmvDetails = {};
            state.insuranceClaimHistoryDetails = {};
            state.insuranceClaimHistorySubject = {};
            state.insuranceInvestigationBusinessDetails = {};
            state.insuranceInvestigationBusinessDriversDetails = {};
            state.insuranceInvestigationBusinessAdditionalInformation = {};
            state.insuranceInvestigationLocateDetails = {};
            state.insuranceInvestigationLocateDriversDetails = {};
            state.insuranceInvestigationLocateAdditionalInformation = {};
            state.insuranceInvestigationPersonalAutoUmbrellaDetails = {};
            state.insuranceInvestigationPersonalAutoUmbrellaDriversDetails = {};
            state.insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation = {};
            state.insuranceInvestigationPersonalHomeownersRentersDetails = {};
            state.insuranceInvestigationPersonalHomeownersRentersAdditionalInformation = {};
            state.sceneInvestigationDetails = {};
            state.skipTraceDetails = {};
            state.subpoenaProcessServingDetails = {};
            state.switrsDetails = {};
            state.trafficCollisionsReportRetrievalDetails = {};
            state.witnessDefendantStatement = {};
            state.witnessDefendantStatementDetails = {};
            state.witnessDefendantStatementDefendantDetails = {};
            state.witnessDefendantStatementWitnessDetails = {};
            state.witnessDefendantStatementFaultInfo = {};
            state.status = 'idle';
            state.error = null;
        },
    },

    extraReducers: {
        [submitForm.pending]: (state, action) => {
            state.status = 'loading';
        },
        [submitForm.fulfilled]: (state, action) => {
            state.status = 'succeeded';
        },
        [submitForm.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [getFirmById.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getFirmById.fulfilled]: (state, action) => {
            var payload = action.payload;

            let generalInfo = {
                firstNameCaseManager: payload.primaryName.split(" ")[0],
                lastNameCaseManager: payload.primaryName.split(" ")[1],
                emailPrimary: payload.primaryEmail,
                phonePrimary: payload.primaryPhone,
                lawFirmName: payload.firm,
                address: payload.addressLine1,
                address2: payload.addressLine2,
                city: payload.city,
                stateProvince: payload.state,
                zip: payload.zip,
                country: payload.country
            }
            state.generalInformation = generalInfo;
            state.persistGeneralInformation = true;
            state.status = 'idle';
        },
        [getFirmById.rejected]: (state, action) => {
            state.status = 'failed';
        }

    }
});

export const {
    updateActiveFormInvestigationType,
    updateActiveFormGeneralInformation,
    updateActiveFormClientInfo,
    resetActiveFormClientInfo,
    updateActiveFormAdditionalInformation,
    updateActiveFormAssetLocateType,
    updateActiveFormAssetLocateDetails,
    updateActiveFormAssetLocateRequest,
    updateActiveFormBackgroundScreeningDetails,
    updateActiveFormBackgroundScreeningSocialMedia,
    updateActiveFormBusinessDueDiligenceDetails,
    updateActiveFormDMVDetails,
    updateActiveFormInsuranceClaimHistoryDetails,
    updateActiveFormInsuranceClaimHistorySubject,
    updateActiveFormInsuranceInvestigationBusinessDetails,
    updateActiveFormInsuranceInvestigationBusinessDriversDetails,
    updateActiveFormInsuranceInvestigationBusinessAdditionalInformation,
    updateActiveFormInsuranceInvestigationLocateDetails,
    updateActiveFormInsuranceInvestigationLocateDriversDetails,
    updateActiveFormInsuranceInvestigationLocateAdditionalInformation,
    updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaDetails,
    updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaDriversDetails,
    updateActiveFormInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformation,
    updateActiveFormInsuranceInvestigationPersonalHomeownersRentersDetails,
    updateActiveFormInsuranceInvestigationPersonalHomeownersRentersAdditionalInformation,
    updateActiveFormSceneInvestigationDetails,
    updateActiveFormSkipTraceDetails,
    updateActiveFormSubpoenaProcessServingDetails,
    updateActiveFormSWITRSDetails,
    updateTrafficCollisionsReportRetrievalDetails,
    updateWitnessDefendantStatementDetails,
    updateWitnessDefendantStatementDefendantDetails,
    updateWitnessDefendantStatementWitnessDetails,
    updateWitnessDefendantStatementFaultInfo,
    resetState,
} = activeFormSlice.actions;

export default activeFormSlice.reducer;


export const selectAllActiveForm = state => {
    return state.activeForm;
};

export const selectActiveFormInvestigationType = state => {
    return state.activeForm.investigationType;
};

export const selectActiveFormClientInfo = state => {
    let returnStateClientInfo = {
        firstNameClient: state.activeForm.generalInformation.firstNameClient,
        lastNameClient: state.activeForm.generalInformation.lastNameClient,
        fileNumberClient: state.activeForm.generalInformation.fileNumberClient,
        investigationType: state.activeForm.investigationType[0]
    }
    return returnStateClientInfo
};

export const selectActiveFormGeneralInformation = state => {

    let returnStateGeneralInfo = state.activeForm.generalInformation;

    if (state.auth && state.auth.portalUser && state.auth.portalUser.organization)
    {
        let payload = state.auth.portalUser.organization
        returnStateGeneralInfo = {
            firstNameCaseManager: state.auth.portalUser.firstName,
            lastNameCaseManager: state.auth.portalUser.lastName,
            emailPrimary: state.auth.portalUser.email,
            phonePrimary: state.auth.portalUser.phone,
            lawFirmName: payload.firm,
            address: payload.addressLine1,
            address2: payload.addressLine2,
            city: payload.city,
            stateProvince: payload.state,
            zip: payload.zip,
            country: payload.country
        }
    }

    return returnStateGeneralInfo;
};

export const selectActiveFormAdditionalInformation = state => {
    return state.activeForm.additionalInformation;
};

export const selectActiveFormAssetLocateType = state => {
    return state.activeForm.assetLocateType;
};

export const selectActiveFormAssetLocateDetails = state => {
    return state.activeForm.assetLocateDetails;
};

export const selectActiveFormAssetLocateRequest = state => {
    return state.activeForm.assetLocateRequest;
};

export const selectActiveFormBackgroundScreeningDetails = state => {
    return state.activeForm.backgroundScreeningDetails;
};

export const selectActiveFormBackgroundScreeningSocialMedia = state => {
    return state.activeForm.backgroundScreeningSocialMedia;
};

export const selectActiveFormBusinessDueDiligenceDetails = state => {
    return state.activeForm.businessDueDiligenceDetails;
};

export const selectActiveFormDMVDetails = state => {
    return state.activeForm.dmvDetails;
};

export const selectActiveFormInsuranceClaimHistoryDetails = state => {
    return state.activeForm.insuranceClaimHistoryDetails;
};

export const selectActiveFormInsuranceClaimHistorySubject = state => {
    return state.activeForm.insuranceClaimHistorySubject;
};

export const selectActiveFormInsuranceInvestigationBusinessDetails = state => {
    return state.activeForm.insuranceInvestigationBusinessDetails;
};

export const selectActiveFormInsuranceInvestigationBusinessDriversDetails = state => {
    return state.activeForm.insuranceInvestigationBusinessDriversDetails;
};

export const selectActiveFormInsuranceInvestigationBusinessAdditionalInformation = state => {
    return state.activeForm.insuranceInvestigationBusinessAdditionalInformation;
};

export const selectActiveFormInsuranceInvestigationLocateDetails = state => {
    return state.activeForm.insuranceInvestigationLocateDetails;
};

export const selectActiveFormInsuranceInvestigationLocateDriversDetails = state => {
    return state.activeForm.insuranceInvestigationLocateDriversDetails;
};

export const selectActiveFormInsuranceInvestigationLocateAdditionalInformation = state => {
    return state.activeForm.insuranceInvestigationLocateAdditionalInformation;
};

export const selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaDetails = state => {
    return state.activeForm.insuranceInvestigationPersonalAutoUmbrellaDetails;
};

export const selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaDriversDetails = state => {
    return state.activeForm.insuranceInvestigationPersonalAutoUmbrellaDriversDetails;
};

export const selectActiveFormInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformation = state => {
    return state.activeForm.insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation;
};

export const selectActiveFormInsuranceInvestigationPersonalHomeownersRentersDetails = state => {
    return state.activeForm.insuranceInvestigationPersonalHomeownersRentersDetails;
};

export const selectActiveFormInsuranceInvestigationPersonalHomeownersRentersAdditionalInformation = state => {
    return state.activeForm.insuranceInvestigationPersonalHomeownersRentersAdditionalInformation;
};

export const selectActiveFormSceneInvestigationDetails = state => {
    return state.activeForm.sceneInvestigationDetails;
};

export const selectActiveFormSkipTraceDetails = state => {
    return state.activeForm.skipTraceDetails;
};

export const selectActiveFormSubpoenaProcessServingDetails = state => {
    return state.activeForm.subpoenaProcessServingDetails;
};

export const selectActiveFormSWITRSDetails = state => {
    return state.activeForm.switrsDetails;
};

export const selectActiveFormTrafficCollisionsReportRetrievalDetails = state => {
    return state.activeForm.trafficCollisionsReportRetrievalDetails;
};

export const selectActiveFormWitnessDefendantStatementDetails = state => {
    return state.activeForm.witnessDefendantStatementDetails;
};

export const selectActiveFormWitnessDefendantStatementDefendantDetails = state => {
    return state.activeForm.witnessDefendantStatementDefendantDetails;
};

export const selectActiveFormWitnessDefendantStatementWitnessDetails = state => {
    return state.activeForm.witnessDefendantStatementWitnessDetails;
};

export const selectActiveFormWitnessDefendantStatementFaultInfo = state => {
    return state.activeForm.witnessDefendantStatementFaultInfo;
};