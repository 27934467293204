import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../components/form-footer';
import IntakeSubpoenaProcessServingDetailsFields from '../../../components/intake/subpoena-process-serving/details-fields';
import SubpoenaProcessServingDetailsSchema from '../../../validation-schemas/subpoena-process-serving-details-schema';
import { updateActiveFormSubpoenaProcessServingDetails, selectActiveFormSubpoenaProcessServingDetails } from '../../../reducers/active-form-slice';


const IntakeSubpoenaProcessServingDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const subpoenaProcessServingDetails = useSelector(selectActiveFormSubpoenaProcessServingDetails);
    const hasValues = Object.keys(subpoenaProcessServingDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormSubpoenaProcessServingDetails(values));
        navigate("/intake/additional-information");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        stateProvince: '',
        zip: '',
        country: '',
        typeOfService: '',
        lastDayToComplete: '',
        vin: '',
        licensePlate: '',
    };


    return (
        <div className="page intake">
            <h2>Subpoena Details</h2>

            <Formik
                initialValues={hasValues ? subpoenaProcessServingDetails : initialValues}
                validationSchema={SubpoenaProcessServingDetailsSchema.fields.subpoenaProcessServingDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeSubpoenaProcessServingDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`50`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeSubpoenaProcessServingDetails;