import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import { billingCodes } from '../../utility/billing-codes';

const SubmitToBillingModal = ({ showSubmitToBillingModal, handleHideSubmitToBillingModal, handleSubmitButton }) => {
    /**
     * setup the initial values & validation
     */
    const initialValues = {
        orderCode: [],
    };

    const validationSchema = Yup.object().shape({
        orderCode: Yup.array()
            .min(1, "Required")
            .required('Required'),
    });


    return (
        <Modal
            backdrop="static"
            show={showSubmitToBillingModal}
            onHide={handleHideSubmitToBillingModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Submit to Billing</Modal.Title>
            </Modal.Header>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmitButton}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <Modal.Body>
                            <div role="group" aria-labelledby="orderCode-radio-group" className="form-check-list">
                                {billingCodes.map((item, index) => (
                                    <div key={`billing-code-${index}`} className="form-check">
                                        <Field type="checkbox" name="orderCode" value={`${item.code ? `${item.code} -` : ``} ${item.label}`} id={`billing-code-${index}`} />
                                        <label htmlFor={`billing-code-${index}`}>{`${item.code ? `${item.code} -` : ``} ${item.label}`}</label>
                                    </div>
                                ))}

                                {errors && errors.orderCode && touched && touched.orderCode ? (
                                    <div className="form-error">{errors.orderCode}</div>
                                ) : null}
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <button type="submit" className="button tertiary" disabled={!((dirty) && isValid)}>Submit</button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default SubmitToBillingModal;