import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFileUpload } from 'react-use-file-upload/dist/lib/useFileUpload';
import {setIntakeAttachments, resetIntakeAttachmentsAdded, resetIntakeAttachmentsDeleted, setIntakeAttachmentsDeleted} from '../reducers/attachments-slice';
import IcoTrash from '../assets/ico-trash.svg';
import IcoCursor from '../assets/ico-cursor.svg'; 


const FileInput = ({ uploadedFiles, handleUpload, handleUploadedRemoval, uploadStatus, isIntake = false, intakeStep = null }) => {
    const inputRef = useRef();
    const dispatch = useDispatch();

    const {
        files,
        fileNames,
        handleDragDropEvent,
        setFiles,
        removeFile,
        clearAllFiles,
    } = useFileUpload();

    const handleRemoveFile = (name) => {
        removeFile(name);
        if (isIntake) {
            dispatch(setIntakeAttachmentsDeleted({intakeStep}));
        }
    }

    // special logic for intake 
    const intakeAttachments = useSelector(state => state.attachments[`intakeAttachments_${intakeStep}`]);
    const intakeAttachmentsAdded = useSelector(state => state.attachments[`intakeAttachmentsAdded_${intakeStep}`]);
    const intakeAttachmentsDeleted = useSelector(state => state.attachments[`intakeAttachmentsDeleted_${intakeStep}`]);

    useEffect( () => {
        
        if(isIntake && intakeAttachments.length > 0) {
            const event = new CustomEvent('build');
            event.dataTransfer = {files: intakeAttachments};
    
            setFiles(event, 'w');
        }
    }, []);

    if (isIntake) {
        if (intakeAttachmentsAdded) {
                const event = new CustomEvent('build');
                event.dataTransfer = {files: intakeAttachments};
        
                setFiles(event, 'w');
                dispatch(resetIntakeAttachmentsAdded({intakeStep}));
        } else if (intakeAttachmentsDeleted) {
            dispatch(setIntakeAttachments({files, intakeStep}));
            dispatch(resetIntakeAttachmentsDeleted({intakeStep}));
        }
    }

    useEffect(() => {
        if (uploadStatus === "succeeded") {
            clearAllFiles();
        }
    }, [uploadStatus, clearAllFiles]);

    return (
        <div className="file-input">
            <div className="file-input-left">
                <button className="button secondary" type="button" onClick={() => inputRef.current.click()}>+ Add Attachment</button>

                <small>* i.e. photos, reports, TCR, LOA, POI, etc. if applicable.</small>
            </div>

            <div className="file-input-right">
                {/* Provide a drop zone and an alternative button inside it to upload files. */}
                <div
                    className="file-input-drag-drop"
                    onDragEnter={handleDragDropEvent}
                    onDragOver={handleDragDropEvent}
                    onDrop={(e) => {
                        handleDragDropEvent(e);
                        setFiles(e, 'a');
                        if (isIntake) {
                            dispatch(setIntakeAttachments({files: Array.from(e.dataTransfer.files), intakeStep, added: true}));
                        }
                    }}
                >
                    <div>
                        <img src={IcoCursor} alt="" />
                        Drag and Drop Attachments Here
                    </div>

                    {/* Hide the crappy looking default HTML input */}
                    <input
                        ref={inputRef}
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            //setFiles(e, 'a');
                            setFiles(e, 'a');
                            if (isIntake) {
                                dispatch(setIntakeAttachments({files: Array.from(e.currentTarget.files), intakeStep, added: true}));
                            }

                            inputRef.current.value = null;
                        }}
                    />
                </div>

                <div className="file-input-file-list">
                    {fileNames.length > 0 &&
                        <div className="ready-to-upload">
                            <strong>Ready to Upload:</strong>

                            <ul>
                                {fileNames.map((name, index) => (
                                    <li key={`${name}-${index}`}>
                                        <span>{name}</span>

                                        <span className="file-input-file-list-remove" onClick={() => handleRemoveFile(name)}>
                                            <img src={IcoTrash} alt="" />
                                        </span>
                                    </li>
                                ))}
                            </ul>

                           {
                            !isIntake && <div className="ready-to-upload-button">
                                    <button className="button secondary" type="button" onClick={() => {
                                        handleUpload(files);
                                        }}>Upload</button>
                                </div>
                            }
                        </div>
                    }


                {/* removed on Larry's request, leaving it here in case we need it again */}
                    {/* {(uploadedFiles && uploadedFiles.length > 0) && <div>
                        <strong>Attachments:</strong>

                        <ul>
                            {uploadedFiles.map((item, index) => (
                                <li key={`${item.name}-${index}`}>
                                    <span>{item.name}</span>

                                    <span className="file-input-file-list-remove" onClick={() => handleUploadedRemoval(item.guid)}>
                                        <img src={IcoTrash} alt="" />
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>} */}

                </div>
            </div>
        </div>
    );
};

export default FileInput;