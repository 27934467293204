import * as Yup from 'yup';


const AssetLocateDetailsBusinessSchema = Yup.object().shape({
    assetLocateDetails: Yup.object().shape({
        businessName: Yup.string()
            .required('Required'),
        address: Yup.string()
            .required('Required'),
        city: Yup.string()
            .required('Required'),
        stateProvince: Yup.string()
            .required('Required'),
        zip: Yup.string()
            .required('Required'),
        country: Yup.string()
            .required('Required'),
    })
});

export default AssetLocateDetailsBusinessSchema;