import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import CustomLoader from './custom-loader';
import Accordion from 'react-bootstrap/Accordion';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import IcoArrow from '../assets/ico-arrow';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FilterMethods from '../utility/filter-methods';

const GlucroftUsersTable = ({ glucroftUsers, progressPending, glucroftUsersAccordion }) => {
    const navigate = useNavigate();
    /**
     * setup glucroft users columns
     */
    const columns = [
        {
            name: <span className="custom-table-header" title="First Name">First Name</span>,
            selector: row => row.firstName,
            cell: row => <Link to={`/admin/glucroft-user/${row.id}`}>{row.firstName}</Link>,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Last Name">Last Name</span>,
            selector: row => row.lastName,
            cell: row => <Link to={`/admin/glucroft-user/${row.id}`}>{row.lastName}</Link>,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Employee #">Employee #</span>,
            selector: row => row.employeeNumber,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Employee ID">Employee ID</span>,
            selector: row => row.employeeId,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Email">Email</span>,
            selector: row => row.email,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Phone">Phone</span>,
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Permission Level">Role Type</span>,
            selector: row => row.permissionLevel,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="Status">Status</span>,
            selector: row => row.status,
            sortable: true,
        },
    ];

    const paginationComponentOptions = {
        noRowsPerPage: true,
    };

    // glucroft user
    const addNewGlucroftUser = () => {
        navigate('/admin/glucroft-user');
    };

    /**
     * handle filters functionality
     */
    const [appliedFilters, setApplyFilters] = useState([]);
    const [filters, setFilters] = useState([]);
    const [displayedFilters, setDisplayedFilters] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);

    const handleApplyFilters = (e) => {
        e.preventDefault();
        setDisplayedFilters(FilterMethods.concatFilters(filters));
        setApplyFilters(filters);
        setShowDropdown(false);
    }

    const handleClearFilters = (e) => {
        e.preventDefault();
        FilterMethods.clearFilters();
        setFilters([]);
        setApplyFilters([]);
        setDisplayedFilters("");
        setShowDropdown(false);
    };

    return (
        <Accordion.Item eventKey="2">
            <div className="page-header" as="div">
                <Accordion.Header>
                    <div className="page-header-title">
                        <h2 className={`mb-0 ${glucroftUsersAccordion ? 'active' : ''}`}>Glucroft Users</h2>
                    </div>
                </Accordion.Header>
                {glucroftUsersAccordion &&
                    <div className="page-header-title-filters">{displayedFilters}</div>
                }
                {glucroftUsersAccordion &&
                    <div className="page-header-actions">
                        <Dropdown className="filter" align="end" show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
                            <Dropdown.Toggle id="dropdown-filter" className="button primary filter">
                                Filter <IcoArrow />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Header className="center">
                                    <button className="button primary" onClick={handleApplyFilters}>Apply</button>
                                    <button className="button outlined primary" onClick={handleClearFilters}>Clear</button>
                                </Dropdown.Header>

                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Glucroft User Name</Accordion.Header>
                                        <Accordion.Body>
                                            {[...new Set(glucroftUsers.map(data => data.firstName))].map((firstName, i) => (
                                                <Form.Check type={'checkbox'} name={`firstName`} id={`lf${i}`} key={`lf${i}`}>
                                                    <Form.Check.Input type={'checkbox'} onClick={e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "firstName", filters))} />
                                                    <Form.Check.Label id={`lf${i}-label`}>{firstName}</Form.Check.Label>
                                                </Form.Check>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Permission Level</Accordion.Header>
                                        <Accordion.Body>
                                            {[...new Set(glucroftUsers.map(data => data.permissionLevel))].map((permissionLevel, i) => (
                                                <Form.Check type={'checkbox'} name={`permissionLevel`} id={`p${i}`} key={`p${i}`}>
                                                    <Form.Check.Input type={'checkbox'} onClick={e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "permissionLevel", filters))} />
                                                    <Form.Check.Label id={`p${i}-label`}>{permissionLevel}</Form.Check.Label>
                                                </Form.Check>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Status</Accordion.Header>
                                        <Accordion.Body>
                                            {[...new Set(glucroftUsers.map(data => data.status))].map((status, i) => (
                                                <Form.Check type={'checkbox'} name={`status`} id={`s${i}`} key={`s${i}`}>
                                                    <Form.Check.Input type={'checkbox'} onClick={e => setFilters(FilterMethods.assignSelectedFilter(e.target.id, "status", filters))} />
                                                    <Form.Check.Label id={`s${i}-label`}>{status}</Form.Check.Label>
                                                </Form.Check>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Dropdown.Menu>
                        </Dropdown>
                        <button className="button secondary" type="button" onClick={addNewGlucroftUser}>+ Add New</button>
                    </div>
                }
            </div>
            <Accordion.Body>
                <div className="page-body mt-4">
                    <div className="custom-table">
                        <DataTable
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            columns={columns}
                            data={FilterMethods.filteredData(glucroftUsers, appliedFilters)}
                            progressPending={progressPending}
                            progressComponent={<CustomLoader />}
                        />
                    </div>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
};

export default GlucroftUsersTable;