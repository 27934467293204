import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../../components/form-footer';
import IntakeInsuranceInvestigationLocateDetailsFields from '../../../../components/intake/insurance-investigation/locate/details-fields';
import InsuranceInvestigationLocateDetailsSchema from '../../../../validation-schemas/insurance-investigation-locate-details-schema';
import { updateActiveFormInsuranceInvestigationLocateDetails, selectActiveFormInsuranceInvestigationLocateDetails } from '../../../../reducers/active-form-slice';


const IntakeInsuranceInvestigationLocateDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const insuranceInvestigationLocateDetails = useSelector(selectActiveFormInsuranceInvestigationLocateDetails);
    const hasValues = Object.keys(insuranceInvestigationLocateDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormInsuranceInvestigationLocateDetails(values));
        navigate("/intake/insurance-investigation-locate/drivers-details");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        type: '',
        policyLimits: '',
        dateOfLoss: '',
        insuredName: '',
        insuredAddress: '',
        insuredAddress2: '',
        insuredCity: '',
        insuredStateProvince: '',
        insuredZip: '',
        insuredCountry: '',
        insuredDob: '',
        insuredSsn: '',
        insuredVin: '',
        insuredLicensePlate: '',
        registeredOwnersName: '',
        registeredOwnersAddress: '',
        registeredOwnersAddress2: '',
        registeredOwnersCity: '',
        registeredOwnersStateProvince: '',
        registeredOwnersZip: '',
        registeredOwnersCountry: '',
        registeredOwnerIsDriver: '',
        locateAddress: '',
        locateAddress2: '',
        locateCity: '',
        locateStateProvince: '',
        locateZip: '',
        locateCountry: ''
    };


    return (
        <div className="page intake">
            <h2>Insured's Details</h2>

            <Formik
                initialValues={hasValues ? insuranceInvestigationLocateDetails : initialValues}
                validationSchema={InsuranceInvestigationLocateDetailsSchema.fields.insuranceInvestigationLocateDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeInsuranceInvestigationLocateDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`30`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInsuranceInvestigationLocateDetails;