import * as Yup from 'yup';
import { phoneExpression } from '../utility/phone-expression';

const GeneralInformationSchema = Yup.object().shape({
    generalInformation: Yup.object().shape({
        firstNameCaseManager: Yup.string()
            .required('Required'),
        lastNameCaseManager: Yup.string()
            .required('Required'),
        emailPrimary: Yup.string()
            .email('Invalid email')
            .required('Required'),
        emailSecondary: Yup.string()
            .email('Invalid email'),
        phonePrimary: Yup.string()
            .matches(phoneExpression, 'Invalid phone number')
            .required('Required'),
        phoneSecondary: Yup.string()
            .matches(phoneExpression, 'Invalid phone number'),
        lawFirmName: Yup.string()
            .required('Required'),
    })
});

export default GeneralInformationSchema;