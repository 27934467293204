import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import BeatLoader from 'react-spinners/BeatLoader';
import { phoneExpression } from "../../utility/phone-expression";
import { fetchLawFirmId, resetLawFirmDetailsStatus, selectLawFirm, updateLawFirm, createLawFirm } from "../../reducers/law-firm-details-slice";
import { stateProvince } from "../../utility/state-province"
import StateProvinceDropdown from "../../components/stateprovinceDropdown";

const LawFirm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    /**
     * get law firm details
     */
    const { id } = useParams();
    const [detailsLoaded, setDetailsLoaded] = useState(false);
    const lawFirm = useSelector(selectLawFirm);
    const lawFirmDetailsStatus = useSelector(state => state.lawFirmDetails.status);

    // fetch case manager details on each initial render
    useEffect(() => {
        if (id) {
            if (lawFirmDetailsStatus === 'idle' && !detailsLoaded) {
                dispatch(fetchLawFirmId(id));
            } else if (lawFirmDetailsStatus === 'succeeded' && !detailsLoaded) {
                // anything that needs to happen AFTER 
                // the data has loaded (e.g. removing loading indicator)
                setDetailsLoaded(true);
            }
        }
    }, [dispatch, id, lawFirmDetailsStatus, detailsLoaded]);

    // set when case manager details have been loaded
    useEffect(() => {
        if (detailsLoaded) {
            dispatch(resetLawFirmDetailsStatus());
        } else {
            dispatch(fetchLawFirmId(id));
        }
    }, [dispatch, detailsLoaded]);


    /**
     * setup the initial values & validation
     */
    const initialValues = {
        id: '',
        firm: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone: '',
        primaryName: '',
        primaryEmail: '',
        primaryPhone: '',
        billingName: '',
        billingEmail: '',
        billingPhone: '',
        status: '',
        notes: ''
    };

    const validationSchema = Yup.object().shape({
        firm: Yup.string()
            .required('Required'),
        addressLine1: Yup.string()
            .required('Required'),
        city: Yup.string()
            .required('Required'),
        state: Yup.string()
            .required('Required'),
        zip: Yup.string()
            .required('Required'),
        country: Yup.string()
            .required('Required'),
        phone: Yup.string()
            .required('Required'),
        primaryName: Yup.string()
            .required('Required'),
        primaryEmail: Yup.string()
            .required('Required')
            .email('Invalid email'),
        primaryPhone: Yup.string()
            .required('Required')
            .matches(phoneExpression, 'Invalid phone number'),
        billingName: Yup.string()
            .required('Required'),
        billingEmail: Yup.string()
            .required('Required')
            .email('Invalid email'),
        billingPhone: Yup.string()
            .required('Required')
            .matches(phoneExpression, 'Invalid phone number'),
        status: Yup.string()
            .required('Required'),
    });

    /**
     * handle canceling
     */
    const handleCancel = () => {
        navigate('/admin');
    };


    return (
        <Formik
            initialValues={detailsLoaded ? lawFirm : initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                if (values.id.length === 0) {
                    await dispatch(createLawFirm(values))
                }
                else {
                    await dispatch(updateLawFirm(values));
                }
                navigate("/admin");
            }}
            enableReinitialize={true}
        >
            {({ errors, dirty, isValid, touched, submitForm, values }) => (
                <Fragment>
                    <div className="page">
                        {(lawFirmDetailsStatus === 'loading') &&
                            <div className="loader-container">
                                <BeatLoader color="#00818C" />
                            </div>
                        }

                        <div className="page-header">
                            <div className="page-header-title">
                                <h1 className="mb-0">FIRM</h1>

                                {detailsLoaded && <div className="page-header-title-filters">{lawFirm.firm}</div>}
                            </div>

                            <div className="page-header-actions">
                                <button className="button tertiary" type="submit" onClick={submitForm} disabled={!((dirty || detailsLoaded) && isValid)}>Save</button>
                                <button className="button outlined secondary" type="button" onClick={handleCancel}>Cancel</button>
                            </div>
                        </div>

                        <div className="page-body mt-4">
                            <Form>
                                <div className="form-row">
                                    <div className="form-field-wrapper">
                                        <label htmlFor="firm">Firm <span className="required">*</span></label>
                                        <Field id="firm" name="firm" />

                                        {errors && errors.firm && touched && touched.firm ? (
                                            <div className="form-error">{errors.firm}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="addressLine1">Address Line 1<span className="required">*</span></label>
                                        <Field id="addressLine1" name="addressLine1" />

                                        {errors && errors.addressLine1 && touched && touched.addressLine1 ? (
                                            <div className="form-error">{errors.addressLine1}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-field-wrapper">
                                        <label htmlFor="addressLine2">Address Line 2</label>
                                        <Field id="addressLine2" name="addressLine2" />
                                    </div>
                                    <div className="form-field-wrapper">
                                        <label htmlFor="city">City<span className="required">*</span></label>
                                        <Field id="city" name="city" />

                                        {errors && errors.city && touched && touched.city ? (
                                            <div className="form-error">{errors.city}</div>
                                        ) : null}
                                    </div>

                                    <StateProvinceDropdown nested={null} values={null} stateProvince={stateProvince} name={"state"} id="state" disabled={false} className={'state'} required={true}/>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="zip">Postal Code<span className="required">*</span></label>
                                        <Field id="zip" name="zip" />

                                        {errors && errors.zip && touched && touched.zip ? (
                                            <div className="form-error">{errors.zip}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-field-wrapper">
                                        <label htmlFor="country">Country<span className="required">*</span></label>
                                        <Field id="country" name="country" />

                                        {errors && errors.country && touched && touched.country ? (
                                            <div className="form-error">{errors.country}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-field-wrapper">
                                        <label htmlFor="phone">Phone <span className="required">*</span></label>
                                        <Field id="phone" name="phone" />

                                        {errors && errors.phone && touched && touched.phone ? (
                                            <div className="form-error">{errors.phone}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="primaryName">Primary Name <span className="required">*</span></label>
                                        <Field id="primaryName" name="primaryName" />

                                        {errors && errors.primaryName && touched && touched.primaryName ? (
                                            <div className="form-error">{errors.primaryName}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="primaryEmail">Primary Email <span className="required">*</span></label>
                                        <Field id="primaryEmail" name="primaryEmail" />

                                        {errors && errors.primaryEmail && touched && touched.primaryEmail ? (
                                            <div className="form-error">{errors.primaryEmail}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="primaryPhone">Primary Phone <span className="required">*</span></label>
                                        <Field id="primaryPhone" name="primaryPhone" />

                                        {errors && errors.primaryPhone && touched && touched.primaryPhone ? (
                                            <div className="form-error">{errors.primaryPhone}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="billingName">Billing Name <span className="required">*</span></label>
                                        <Field id="billingName" name="billingName" />

                                        {errors && errors.billingName && touched && touched.billingName ? (
                                            <div className="form-error">{errors.billingName}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="billingEmail">Billing Email <span className="required">*</span></label>
                                        <Field id="billingEmail" name="billingEmail" />

                                        {errors && errors.billingEmail && touched && touched.billingEmail ? (
                                            <div className="form-error">{errors.billingEmail}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="billingPhone">Billing Phone <span className="required">*</span></label>
                                        <Field id="billingPhone" name="billingPhone" />

                                        {errors && errors.billingPhone && touched && touched.billingPhone ? (
                                            <div className="form-error">{errors.billingPhone}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="referredBy">Referred By</label>
                                        <Field id="referredBy" name="referredBy" />
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="status">Status <span className="required">*</span></label>
                                        <Field id="status" name="status" as="select">
                                            <option value="" defaultValue>Make a selection</option>
                                            <option value="Enabled">Enabled</option>
                                            <option value="Disabled">Disabled</option>
                                            <option value="Inactive">Inactive</option>
                                            <option value="Suspended">Suspended</option>
                                            <option value="Cut Off">Cut Off</option>
                                        </Field>

                                        {errors && errors.status && touched && touched.status ? (
                                            <div className="form-error">{errors.status}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-field-wrapper">
                                        <label htmlFor="notes">Notes</label>
                                        <Field id="notes" name="notes" as="textarea" rows="5" />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Fragment>
            )}
        </Formik>
    );
};

export default LawFirm;