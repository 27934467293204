import DataTable from 'react-data-table-component';
import CustomLoader from './custom-loader';
import ParseOrderDetailsChangeLog from '../utility/order-details-change-log';
import { mapNamesToLabels } from '../utility/map-fieldsNames-labels';

const OrderDetailsChangeLogTable = ({ changeLog, progressPending }) => {
    /**
     * setup change log columns
     */
    const columns = [
        // {
        //     name: <span className="custom-table-header" title="#">#</span>,
        //     selector: row => row.orderNumber,
        //     sortable: true,
        // },
        {
            name: <span className="custom-table-header" title="AUTHOR">AUTHOR</span>,
            selector: row => row.modifiedBy,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="DATE EXECUTED">DATE EXECUTED</span>,
            selector: row => row.lastEdit,
            cell: row => <span>{new Date(row.lastEdit.endsWith('Z') ? row.lastEdit : row.lastEdit+"Z").toLocaleString()}</span>,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="EXEC. TYPE">EXEC. TYPE</span>,
            selector: row => row.modificationType,
            sortable: true,
        },
        {
            name: <span className="custom-table-header" title="DISPLAY RESULT">DISPLAY RESULT</span>,
            selector: row => row.modificationType != "Order Details - Edited" ? row.displayResult : 
                ParseOrderDetailsChangeLog.parsedOrderDetailsChangeLog(row.olderOrderDetails, row.updatedOrderDetails)
                .map(x => {return mapNamesToLabels(x)})
                .join(', '),
            sortable: true,
        },
        // {
        //     name: <span className="custom-table-header" title="LAW FIRM">LAW FIRM</span>,
        //     selector: row => row.firm,
        //     sortable: true,
        // },
        // {
        //     name: <span className="custom-table-header" title="CASE MANAGER">CASE MANAGER</span>,
        //     selector: row => row.assignedFirm,
        //     sortable: true,
        // },
        // {
        //     name: <span className="custom-table-header" title="CASE NAME">CASE NAME</span>,
        //     selector: row => row.caseName,
        //     sortable: true,
        // },
        // {
        //     name: <span className="custom-table-header" title="INVESTIGATION TYPE">INVESTIGATION TYPE</span>,
        //     selector: row => row.investigationType,
        //     sortable: true,
        // },
        // {
        //     name: <span className="custom-table-header" title="ASSIGNED GLUCROFT USER">ASSIGNED GLUCROFT USER</span>,
        //     selector: row => row.assignedGI,
        //     sortable: true,
        // },
        // {
        //     name: <span className="custom-table-header" title="STATUS">STATUS</span>,
        //     selector: row => row.status,
        //     sortable: true,
        // },
    ];

    const paginationComponentOptions = {
        noRowsPerPage: true,
    };

    return (
        <div className="custom-table">
            <DataTable
                pagination
                paginationComponentOptions={paginationComponentOptions}
                columns={columns}
                data={changeLog}
                progressPending={progressPending}
                progressComponent={<CustomLoader />}
            />
        </div>
    );
};

export default OrderDetailsChangeLogTable;