import { Field } from "formik";
import { DatePickerInput } from "../../datepicker-input";


const IntakeWitnessDefendantStatementDetailsFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <h3>Accident Information</h3>

            <div className="form-row three-col">
                <div className="form-field-wrapper">
                    <label htmlFor="dateOfLoss">Date of Loss <span className="required">*</span></label>
                    <DatePickerInput name={nested ? "witnessDefendantStatementDetails.dateOfLoss" : "dateOfLoss"} placeholderText="Select date" />

                    {errors && errors.dateOfLoss && touched && touched.dateOfLoss ? (
                        <div className="form-error">{errors.dateOfLoss}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="timeOfLoss">Time of Loss <span className="required">*</span></label>
                    <DatePickerInput name={nested ? "witnessDefendantStatementDetails.timeOfLoss" : "timeOfLoss"} showTimeSelect showTimeSelectOnly timeCaption="Time" dateFormat="h:mm aa" placeholderText="Select time" />

                    {errors && errors.timeOfLoss && touched && touched.timeOfLoss ? (
                        <div className="form-error">{errors.timeOfLoss}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="locationOfLoss">Location of Loss <span className="required">*</span></label>
                    <Field id="locationOfLoss" name={nested ? "witnessDefendantStatementDetails.locationOfLoss" : "locationOfLoss"} />

                    {errors && errors.locationOfLoss && touched && touched.locationOfLoss ? (
                        <div className="form-error">{errors.locationOfLoss}</div>
                    ) : null}
                </div>
            </div>

            <div className="form-row single-col">
                <div className="form-field-wrapper">
                    <label htmlFor="factsOfLoss">Facts of Loss <span className="required">*</span></label>
                    <Field id="factsOfLoss" name={nested ? "witnessDefendantStatementDetails.factsOfLoss" : "factsOfLoss"} as="textarea" rows="5" />

                    {errors && errors.factsOfLoss && touched && touched.factsOfLoss ? (
                        <div className="form-error">{errors.factsOfLoss}</div>
                    ) : null}
                </div>
            </div>

            <div className="form-row single-col">
                <div className="form-field-wrapper">
                    <label htmlFor="specificQuestions">Please include any specific questions that you would like us to ask. <span className="required">*</span></label>
                    <Field id="specificQuestions" name={nested ? "witnessDefendantStatementDetails.specificQuestions" : "specificQuestions"} as="textarea" rows="5" />

                    {errors && errors.specificQuestions && touched && touched.specificQuestions ? (
                        <div className="form-error">{errors.specificQuestions}</div>
                    ) : null}
                </div>
            </div>

            <div className="form-row three-col">
                <div className="form-field-wrapper">
                    <label htmlFor="directionOfTravel">Direction of Travel <span className="required">*</span></label>
                    <Field id="directionOfTravel" name={nested ? "witnessDefendantStatementDetails.directionOfTravel" : "directionOfTravel"} />

                    {errors && errors.directionOfTravel && touched && touched.directionOfTravel ? (
                        <div className="form-error">{errors.directionOfTravel}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="streetOfTravel">Street of Travel <span className="required">*</span></label>
                    <Field id="streetOfTravel" name={nested ? "witnessDefendantStatementDetails.streetOfTravel" : "streetOfTravel"} />

                    {errors && errors.streetOfTravel && touched && touched.streetOfTravel ? (
                        <div className="form-error">{errors.streetOfTravel}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="vehicleDescription">Vehicle Description <small>(Make / Model / Year / Color)</small> <span className="required">*</span></label>
                    <Field id="vehicleDescription" name={nested ? "witnessDefendantStatementDetails.vehicleDescription" : "vehicleDescription"} />

                    {errors && errors.vehicleDescription && touched && touched.vehicleDescription ? (
                        <div className="form-error">{errors.vehicleDescription}</div>
                    ) : null}
                </div>
            </div>

            <div className="form-row single-col">
                <div className="form-field-wrapper">
                    <label htmlFor="pedestrianIdentifiers">If Pedestrian: Please provide as many identifiers as you can. <small>(i.e.: Physical description, Gender, Clothing, etc.)</small> <span className="required">*</span></label>
                    <Field id="pedestrianIdentifiers" name={nested ? "witnessDefendantStatementDetails.pedestrianIdentifiers" : "pedestrianIdentifiers"} as="textarea" rows="5" />

                    {errors && errors.pedestrianIdentifiers && touched && touched.pedestrianIdentifiers ? (
                        <div className="form-error">{errors.pedestrianIdentifiers}</div>
                    ) : null}
                </div>
            </div>

            <div className="form-row three-col-reversed">
                <div className="form-field-wrapper">
                    <label className="left-justified" htmlFor="statementType">Is this a Witness Statement, Defendant Statement, or Both? <span className="required">*</span></label>

                    <div role="group" aria-labelledby="statementType-radio-group" className="form-check-inline left-justified">
                        <div className="form-check">
                            <Field type="radio" name={nested ? "witnessDefendantStatementDetails.statementType" : "statementType"} value="Defendant" id="Defendant" />
                            <label htmlFor="Defendant">Defendant</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "witnessDefendantStatementDetails.statementType" : "statementType"} value="Witness" id="Witness" />
                            <label htmlFor="Witness">Witness</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "witnessDefendantStatementDetails.statementType" : "statementType"} value="Both" id="Both" />
                            <label htmlFor="Both">Both</label>
                        </div>
                    </div>

                    {errors && errors.statementType && touched && touched.statementType ? (
                        <div className="form-error">{errors.statementType}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default IntakeWitnessDefendantStatementDetailsFields;