import { Fragment } from 'react';
import IntakeAssetLocateTypeFields from '../../../components/intake/asset-locate/type-fields';
import IntakeAssetLocateDetailsPersonFields from '../../../components/intake/asset-locate/details/person-fields';
import IntakeAssetLocateDetailsBusinessFields from '../../../components/intake/asset-locate/details/business-fields';
import IntakeAssetLocateRequest from '../../../components/intake/asset-locate/request-fields';
import IntakeBackgroundScreeningDetailsFields from '../../../components/intake/background-screening/details-fields';
import IntakeBackgroundScreeningSocialMediaFields from '../../../components/intake/background-screening/social-media-fields';
import IntakeBusinessDueDiligenceDetailsFields from '../../../components/intake/business-due-diligence/details-fields';
import IntakeDMVDetailsFields from '../../../components/intake/dmv/details-fields';
import IntakeInsuranceClaimHistoryDetailsFields from '../../../components/intake/insurance-claim-history/details-fields';
import IntakeInsuranceClaimHistorySubjectFields from '../../../components/intake/insurance-claim-history/subject-fields';
import IntakeInsuranceClaimHistoryClientFields from '../../../components/intake/insurance-claim-history/client-fields';
import IntakeInsuranceInvestigationBusinessDetailsFields from '../../../components/intake/insurance-investigation/business/details-fields';
import IntakeInsuranceInvestigationBusinessDriversDetailsFields from '../../../components/intake/insurance-investigation/business/drivers-details-fields';
import IntakeInsuranceInvestigationBusinessAdditionalInformationFields from '../../../components/intake/insurance-investigation/business/additional-information-fields';
import IntakeInsuranceInvestigationLocateDetailsFields from '../../../components/intake/insurance-investigation/locate/details-fields';
import IntakeInsuranceInvestigationLocateDriversDetailsFields from '../../../components/intake/insurance-investigation/locate/drivers-details-fields';
import IntakeInsuranceInvestigationLocateAdditionalInformationFields from '../../../components/intake/insurance-investigation/locate/additional-information-fields';
import IntakeInsuranceInvestigationPersonalAutoUmbrellaDetailsFields from '../../../components/intake/insurance-investigation/personal/auto-umbrella/details-fields';
import IntakeInsuranceInvestigationPersonalAutoUmbrellaDriversDetailsFields from '../../../components/intake/insurance-investigation/personal/auto-umbrella/drivers-details-fields';
import IntakeInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationFields from '../../../components/intake/insurance-investigation/personal/auto-umbrella/additional-information-fields';
import IntakeInsuranceInvestigationPersonalHomeownersRentersDetailsFields from '../../../components/intake/insurance-investigation/personal/homeowners-renters/details-fields';
import IntakeInsuranceInvestigationPersonalHomeownersRentersAdditionalInformationFields from '../../../components/intake/insurance-investigation/personal/homeowners-renters/additional-information-fields';
import IntakeSceneInvestigationDetailsFields from '../../../components/intake/scene-investigation/details-fields';
import IntakeSkipTraceDetailsFields from '../../../components/intake/skip-trace/details-fields';
import IntakeSubpoenaProcessServingDetailsFields from '../../../components/intake/subpoena-process-serving/details-fields';
import IntakeSWITRSDetailsFields from '../../../components/intake/switrs/details-fields';
import IntakeTrafficCollisionsReportRetrievalDetailsFields from '../../../components/intake/traffic-collisions-report-retrieval/details-fields';
import IntakeWitnessDefendantStatementDetailsFields from '../../../components/intake/witness-defendant-statement/details-fields';
import IntakeWitnessDefendantStatementDefendantDetailsFields from "../../../components/intake/witness-defendant-statement/defendant-info-fields";
import IntakeWitnessDefendantStatementWitnessDetailsFields from "../../../components/intake/witness-defendant-statement/witness-info-fields";
import IntakeWitnessDefendantStatementDetailsFaultInfoFields from '../../../components/intake/witness-defendant-statement/fault-info-fields';


const BuildForms = ({ errors, touched, values, investigationType, handleUpload, handleUploadedRemoval, uploadedFiles, uploadStatus }) => {

    switch (investigationType[1]) {
        case "6859dfec-df01-492f-8def-50bb130d2740":
            const IntakeAssetLocateDetails = values.assetLocateType.assetLocateType === "Subject/Target" ? IntakeAssetLocateDetailsPersonFields : IntakeAssetLocateDetailsBusinessFields;

            return (
                <Fragment>
                    <div className="form-wrapper general-information">
                        <h2>Asset Locate Details</h2>
                    </div>

                    <IntakeAssetLocateTypeFields
                        errors={errors.assetLocateType}
                        touched={touched.assetLocateType}
                        values={values.assetLocateType}
                        nested={true}
                    />

                    <IntakeAssetLocateDetails
                        errors={errors.assetLocateDetails}
                        touched={touched.assetLocateDetails}
                        values={values.assetLocateDetails}
                        nested={true}
                    />

                    <IntakeAssetLocateRequest
                        errors={errors.assetLocateRequest}
                        touched={touched.assetLocateRequest}
                        values={values.assetLocateRequest}
                        nested={true}
                    />
                </Fragment>
            );
        case "b7fdc4fd-0629-4d64-8751-65080436b4ed":
            if (values.backgroundScreeningDetails.socialMedia === "Yes") {
                return (
                    <Fragment>
                        <IntakeBackgroundScreeningDetailsFields
                            errors={errors.backgroundScreeningDetails}
                            touched={touched.backgroundScreeningDetails}
                            values={values.backgroundScreeningDetails}
                            nested={true}
                        />

                        <IntakeBackgroundScreeningSocialMediaFields
                            errors={errors.backgroundScreeningSocialMedia}
                            touched={touched.backgroundScreeningSocialMedia}
                            values={values.backgroundScreeningSocialMedia}
                            nested={true}
                        />
                    </Fragment>
                );
            } else {
                return (
                    <Fragment>
                        <IntakeBackgroundScreeningDetailsFields
                            errors={errors.backgroundScreeningDetails}
                            touched={touched.backgroundScreeningDetails}
                            values={values.backgroundScreeningDetails}
                            nested={true}
                        />
                    </Fragment>
                );
            }
        case "05a7fe17-8fb5-442b-aaa0-d9c99476228c":
            return (
                <Fragment>
                    <IntakeBusinessDueDiligenceDetailsFields
                        errors={errors.businessDueDiligenceDetails}
                        touched={touched.businessDueDiligenceDetails}
                        values={values.businessDueDiligenceDetails}
                        nested={true}
                    />
                </Fragment>
            );
        case "112f034e-c54b-4307-bc1d-ce68d3a69fe7":
            return (
                <Fragment>
                    <IntakeDMVDetailsFields
                        errors={errors.dmvDetails}
                        touched={touched.dmvDetails}
                        values={values.dmvDetails}
                        nested={true}
                    />
                </Fragment>
            );
        case "fc25e0ba-f2f1-41e9-afc8-2da980ed53fe":
            return (
                <Fragment>
                    <IntakeInsuranceClaimHistoryClientFields
                        errors={errors.generalInformation}
                        touched={touched.generalInformation}
                        values={values.generalInformation}
                        nested={true}
                    />

                    <IntakeInsuranceClaimHistoryDetailsFields
                        errors={errors.insuranceClaimHistoryDetails}
                        touched={touched.insuranceClaimHistoryDetails}
                        values={values.insuranceClaimHistoryDetails}
                        nested={true}
                    />

                    <IntakeInsuranceClaimHistorySubjectFields
                        errors={errors.insuranceClaimHistorySubject}
                        touched={touched.insuranceClaimHistorySubject}
                        values={values.insuranceClaimHistorySubject}
                        nested={true}
                    />
                </Fragment>
            );
        case "cbd61d9e-7c34-4bbb-af1f-2cbf84321034":
            return (
                <Fragment>
                    <IntakeInsuranceInvestigationBusinessDetailsFields
                        errors={errors.insuranceInvestigationBusinessDetails}
                        touched={touched.insuranceInvestigationBusinessDetails}
                        values={values.insuranceInvestigationBusinessDetails}
                        nested={true}
                    />

                    <IntakeInsuranceInvestigationBusinessDriversDetailsFields
                        errors={errors.insuranceInvestigationBusinessDriversDetails}
                        touched={touched.insuranceInvestigationBusinessDriversDetails}
                        values={values.insuranceInvestigationBusinessDriversDetails}
                        nested={true}
                    />
                        <IntakeInsuranceInvestigationBusinessAdditionalInformationFields
                        errors={errors.insuranceInvestigationBusinessAdditionalInformation}
                        touched={touched.insuranceInvestigationBusinessAdditionalInformation}
                        values={values.insuranceInvestigationBusinessAdditionalInformation}
                        nested={true}
                    />
                </Fragment>
            );

        case "dd7b99f9-5394-4d46-87f3-3a0804a8738a":
                return (
                    <Fragment>
                        <IntakeInsuranceInvestigationLocateDetailsFields
                            errors={errors.insuranceInvestigationLocateDetails}
                            touched={touched.insuranceInvestigationLocateDetails}
                            values={values.insuranceInvestigationLocateDetails}
                            nested={true}
                        />

                        <IntakeInsuranceInvestigationLocateDriversDetailsFields
                            errors={errors.insuranceInvestigationLocateDriversDetails}
                            touched={touched.insuranceInvestigationLocateDriversDetails}
                            values={values.insuranceInvestigationLocateDriversDetails}
                            nested={true}
                        />

                        <IntakeInsuranceInvestigationLocateAdditionalInformationFields
                            errors={errors.insuranceInvestigationLocateAdditionalInformation}
                            touched={touched.insuranceInvestigationLocateAdditionalInformation}
                            values={values.insuranceInvestigationLocateAdditionalInformation}
                            nested={true}
                        />
                    </Fragment>
                );
        case "3fe7eb42-7b28-4146-8fdd-f4012ce62bc5":
                return (
                    <Fragment>
                        <IntakeInsuranceInvestigationPersonalAutoUmbrellaDetailsFields
                            errors={errors.insuranceInvestigationPersonalAutoUmbrellaDetails}
                            touched={touched.insuranceInvestigationPersonalAutoUmbrellaDetails}
                            values={values.insuranceInvestigationPersonalAutoUmbrellaDetails}
                            nested={true}
                        />

                        <IntakeInsuranceInvestigationPersonalAutoUmbrellaDriversDetailsFields
                            errors={errors.insuranceInvestigationPersonalAutoUmbrellaDriversDetails}
                            touched={touched.insuranceInvestigationPersonalAutoUmbrellaDriversDetails}
                            values={values.insuranceInvestigationPersonalAutoUmbrellaDriversDetails}
                            nested={true}
                        />

                        <IntakeInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformationFields
                            errors={errors.insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation}
                            touched={touched.insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation}
                            values={values.insuranceInvestigationPersonalAutoUmbrellaAdditionalInformation}
                            nested={true}
                        />
                    </Fragment>
                );
        case "b03f65f3-f6c5-42b6-af5b-c34a958bc6ec":
            return (
                <Fragment>
                    <IntakeInsuranceInvestigationPersonalHomeownersRentersDetailsFields
                        errors={errors.insuranceInvestigationPersonalHomeownersRentersDetails}
                        touched={touched.insuranceInvestigationPersonalHomeownersRentersDetails}
                        values={values.insuranceInvestigationPersonalHomeownersRentersDetails}
                        nested={true}
                    />

                    <IntakeInsuranceInvestigationPersonalHomeownersRentersAdditionalInformationFields
                        errors={errors.insuranceInvestigationPersonalHomeownersRentersAdditionalInformation}
                        touched={touched.insuranceInvestigationPersonalHomeownersRentersAdditionalInformation}
                        values={values.insuranceInvestigationPersonalHomeownersRentersAdditionalInformation}
                        nested={true}
                    />
                </Fragment>
            );
        case "77cf5024-fffe-48ac-ade7-a4ca318b1290":
            return (
                <Fragment>
                    <IntakeSceneInvestigationDetailsFields
                        errors={errors.sceneInvestigationDetails}
                        touched={touched.sceneInvestigationDetails}
                        values={values.sceneInvestigationDetails}
                        nested={true}
                    />
                </Fragment>
            );
        case "8327fb74-d3ff-4561-b189-8e80fde1b3af":
            return (
                <Fragment>
                    <IntakeSkipTraceDetailsFields
                        errors={errors.skipTraceDetails}
                        touched={touched.skipTraceDetails}
                        values={values.skipTraceDetails}
                        nested={true}
                    />
                </Fragment>
            );
        case "918a0ae1-4e91-49a2-a595-190f6b87e8dc":
            return (
                <Fragment>
                    <IntakeSubpoenaProcessServingDetailsFields
                        errors={errors.subpoenaProcessServingDetails}
                        touched={touched.subpoenaProcessServingDetails}
                        values={values.subpoenaProcessServingDetails}
                        nested={true}
                    />
                </Fragment>
            );
        case "c7537487-e799-49f0-85b9-13c07170ba20":
            return (
                <Fragment>
                    <IntakeSWITRSDetailsFields
                        handleUpload={handleUpload}
                        handleUploadedRemoval={handleUploadedRemoval}
                        uploadedFiles={uploadedFiles}
                        uploadStatus={uploadStatus}
                        errors={errors.switrsDetails}
                        touched={touched.switrsDetails}
                        values={values.switrsDetails}
                        nested={true}
                    />
                </Fragment>
            );
        case "26a9c1ea-45a1-4181-8665-9cdc33721f71":
            return (
                <Fragment>
                    <IntakeTrafficCollisionsReportRetrievalDetailsFields
                        handleUpload={handleUpload}
                        handleUploadedRemoval={handleUploadedRemoval}
                        uploadedFiles={uploadedFiles}
                        uploadStatus={uploadStatus}
                        errors={errors.trafficCollisionsReportRetrievalDetails}
                        touched={touched.trafficCollisionsReportRetrievalDetails}
                        values={values.trafficCollisionsReportRetrievalDetails}
                        nested={true}
                    />
                </Fragment>
            );
        case "3a25f588-0e8f-451d-978d-711502131631":
            switch (values.witnessDefendantStatementDetails.statementType) {
                case "Defendant":
                    return (
                        <Fragment>
                            <IntakeWitnessDefendantStatementDetailsFields
                                errors={errors.witnessDefendantStatementDetails}
                                touched={touched.witnessDefendantStatementDetails}
                                values={values.witnessDefendantStatementDetails}
                                nested={true}
                            />
                            <IntakeWitnessDefendantStatementDefendantDetailsFields
                                errors={errors.witnessDefendantStatementDefendantDetails}
                                touched={touched.witnessDefendantStatementDefendantDetails}
                                values={values.witnessDefendantStatementDefendantDetails}
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    dateOfBirth: '',
                                    phone: '',
                                    address1: '',
                                    address2: '',
                                    city: '',
                                    stateProvince: '',
                                    zip: '',
                                    county: '',
                                    directionOfTravel: '',
                                    streetOfTravel: '',
                                    defendantVehicleDescription: '',
                                }}
                                nested={true}
                            />
                            <IntakeWitnessDefendantStatementDetailsFaultInfoFields
                                handleUpload={handleUpload}
                                handleUploadedRemoval={handleUploadedRemoval}
                                uploadedFiles={uploadedFiles}
                                uploadStatus={uploadStatus}
                                errors={errors.witnessDefendantStatementFaultInfo}
                                touched={touched.witnessDefendantStatementFaultInfo}
                                values={values.witnessDefendantStatementFaultInfo}
                                nested={true}
                            />
                        </Fragment>
                    );
                case "Witness":
                    return (
                        <Fragment>
                            <IntakeWitnessDefendantStatementDetailsFields
                                errors={errors.witnessDefendantStatementDetails}
                                touched={touched.witnessDefendantStatementDetails}
                                values={values.witnessDefendantStatementDetails}
                                nested={true}
                            />
                            <IntakeWitnessDefendantStatementWitnessDetailsFields
                                errors={errors.witnessDefendantStatementWitnessDetails}
                                touched={touched.witnessDefendantStatementWitnessDetails}
                                values={values.witnessDefendantStatementWitnessDetails}
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    dateOfBirth: '',
                                    phone: '',
                                    address1: '',
                                    address2: '',
                                    city: '',
                                    stateProvince: '',
                                    zip: '',
                                    county: '',
                                }}
                                nested={true}
                            />
                            <IntakeWitnessDefendantStatementDetailsFaultInfoFields
                                handleUpload={handleUpload}
                                handleUploadedRemoval={handleUploadedRemoval}
                                uploadedFiles={uploadedFiles}
                                uploadStatus={uploadStatus}
                                errors={errors.witnessDefendantStatementFaultInfo}
                                touched={touched.witnessDefendantStatementFaultInfo}
                                values={values.witnessDefendantStatementFaultInfo}
                                nested={true}
                            />
                        </Fragment>
                    );
                default:
                    // console.log('values in buildform', values.witnessDefendantStatementWitnessDetails);

                    return (
                        <Fragment>
                            <IntakeWitnessDefendantStatementDetailsFields
                                errors={errors.witnessDefendantStatementDetails}
                                touched={touched.witnessDefendantStatementDetails}
                                values={values.witnessDefendantStatementDetails}
                                nested={true}
                            />
                            <IntakeWitnessDefendantStatementDefendantDetailsFields
                                errors={errors.witnessDefendantStatementDefendantDetails}
                                touched={touched.witnessDefendantStatementDefendantDetails}
                                values={values.witnessDefendantStatementDefendantDetails}
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    dateOfBirth: '',
                                    phone: '',
                                    address1: '',
                                    address2: '',
                                    city: '',
                                    stateProvince: '',
                                    zip: '',
                                    county: '',
                                    directionOfTravel: '',
                                    streetOfTravel: '',
                                    defendantVehicleDescription: '',
                                }}
                                nested={true}
                            />
                            <IntakeWitnessDefendantStatementWitnessDetailsFields
                                errors={errors.witnessDefendantStatementWitnessDetails}
                                touched={touched.witnessDefendantStatementWitnessDetails}
                                values={values.witnessDefendantStatementWitnessDetails}
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    dateOfBirth: '',
                                    phone: '',
                                    address1: '',
                                    address2: '',
                                    city: '',
                                    stateProvince: '',
                                    zip: '',
                                    county: '',
                                }}
                                nested={true}
                            />
                            <IntakeWitnessDefendantStatementDetailsFaultInfoFields
                                handleUpload={handleUpload}
                                handleUploadedRemoval={handleUploadedRemoval}
                                uploadedFiles={uploadedFiles}
                                uploadStatus={uploadStatus}
                                errors={errors.witnessDefendantStatementFaultInfo}
                                touched={touched.witnessDefendantStatementFaultInfo}
                                values={values.witnessDefendantStatementFaultInfo}
                                nested={true}
                            />
                        </Fragment>
                    );
            }
        default:
            return (
                <div>Sorry, an error has occured.</div>
            );
    }
};

export default BuildForms;