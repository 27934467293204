import { Field } from "formik";


const IntakeInsuranceInvestigationLocateAdditionalInformationFields = ({ errors, touched, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <h3>Do you have a Letter of Acknowledgment?</h3>

            <div className="asset-locate-type">
                <div role="group" aria-labelledby="letterOfAcknowledgement-radio-group" className="form-check-inline left-justified">
                    <div className="form-check">
                        <Field type="radio" name={nested ? "insuranceInvestigationLocateAdditionalInformation.letterOfAcknowledgement" : "letterOfAcknowledgement"} value="Yes" id="Yes" />
                        <label htmlFor="Yes">Yes</label>
                    </div>
                    <div className="form-check">
                        <Field type="radio" name={nested ? "insuranceInvestigationLocateAdditionalInformation.letterOfAcknowledgement" : "letterOfAcknowledgement"} value="No" id="No" />
                        <label htmlFor="No">No</label>
                    </div>
                </div>

                {errors && errors.driversInsuranceCompany && touched && touched.driversInsuranceCompany ? (
                    <div className="form-error">{errors.driversInsuranceCompany}</div>
                ) : null}
            </div>
        </div>
    );
};

export default IntakeInsuranceInvestigationLocateAdditionalInformationFields;