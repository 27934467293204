import { configureStore } from '@reduxjs/toolkit';
import ordersSlice from './reducers/orders-slice';
import casesSlice from './reducers/cases-slice';
import orderDetailsSlice from './reducers/order-details-slice';
import caseDetailsSlice from './reducers/case-details-slice';
import caseManagersSlice from './reducers/case-managers-slice';
import caseManagerDetailsSlice from './reducers/case-manager-details-slice';
import lawFirmsSlice from './reducers/law-firms-slice';
import lawFirmDetailsSlice from './reducers/law-firm-details-slice';
import glucroftUsersSlice from './reducers/glucroft-users-slice';
import glucroftUsersToAssignSlice from './reducers/glucroft-users-to-assign-slice';
import glucroftUserDetailsSlice from './reducers/glucroft-user-details-slice';
import investigationTypeSlice from './reducers/investigation-type-slice';
import activeFormSlice from './reducers/active-form-slice';
import additionalInformationFilesSlice from './reducers/additional-information-files-slice';
import switrsDetailsFilesSlice from './reducers/switrs-details-files-slice';
import trafficCollisionsReportRetrievalFilesSlice from './reducers/traffic-collisions-report-retrieval-files-slice';
import linkInviteSlice from './reducers/link-invite-slice';
import reportSlice from './reducers/report-slice';
import attachmentsSlice from './reducers/attachments-slice';
import authSlice from './reducers/auth-slice';
import faultInfoFilesSlice from './reducers/fault-info-files-slice';
import rolesSlice from './reducers/roles-slice';
import notificationsSlice from './reducers/notifications-slice';


const Store = configureStore({
    reducer: {
        orders: ordersSlice,
        orderDetails: orderDetailsSlice,
        cases: casesSlice,
        caseDetails: caseDetailsSlice,
        caseManagers: caseManagersSlice,
        caseManagerDetails: caseManagerDetailsSlice,
        lawFirms: lawFirmsSlice,
        lawFirmDetails: lawFirmDetailsSlice,
        glucroftUsers: glucroftUsersSlice,
        glucroftUsersToAssign: glucroftUsersToAssignSlice,
        glucroftUserDetails: glucroftUserDetailsSlice,
        investigationTypes: investigationTypeSlice,
        activeForm: activeFormSlice,
        additionalInformationFiles: additionalInformationFilesSlice,
        switrsDetailsFiles: switrsDetailsFilesSlice,
        trafficCollisionsReportRetrievalFiles: trafficCollisionsReportRetrievalFilesSlice,
        linkInvite: linkInviteSlice,
        report: reportSlice,
        attachments: attachmentsSlice,
        auth: authSlice,
        faultInfoFiles: faultInfoFilesSlice,
        roles: rolesSlice,
        notifications: notificationsSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
        {
            serializableCheck: {
              // Ignore these action types
              ignoredActions: ['attachments/setIntakeAttachments'],
               // Ignore these paths in the state
                ignoredPaths: ['attachments.intakeAttachments_specific', 'attachments.intakeAttachments_additional'],
            },
          }
    )
});

export default Store;