import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormFooter from '../../../components/form-footer';
import { selectActiveFormAssetLocateType, updateActiveFormAssetLocateType } from '../../../reducers/active-form-slice';
import IntakeAssetLocateTypeFields from '../../../components/intake/asset-locate/type-fields';
import AssetLocateTypeSchema from '../../../validation-schemas/asset-locate-type-schema';


const IntakeAssetLocateType = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    /**
     * get current form values
     */
    const assetLocateType = useSelector(selectActiveFormAssetLocateType);
    const hasValues = Object.keys(assetLocateType).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormAssetLocateType(values));
        navigate("/intake/asset-locate/details");
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        assetLocateType: '',
    };


    return (
        <div className="page intake">
            <h2>Asset Locate Details</h2>

            <Formik
                initialValues={hasValues ? assetLocateType : initialValues}
                validationSchema={AssetLocateTypeSchema.fields.assetLocateType}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched }) => (
                    <Form>
                        <IntakeAssetLocateTypeFields
                            errors={errors}
                            touched={touched}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`25`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeAssetLocateType;