import { useMsal } from '@azure/msal-react';
import React from 'react';
import LogoGlugroft from '../assets/logo-glucroft.svg';
import { loginRequest } from '../authConfig';
import IcoMail from '../assets/ico-mail';
import { selectActiveFeatures } from '../reducers/auth-slice';
import { useDispatch, useSelector } from 'react-redux';

const Header = (props) => {

    const { showUserName } = props;
    const { instance, inProgress } = useMsal();
    const activeFeatures = useSelector(selectActiveFeatures);

    let activeAccount;
    if (instance) {
        activeAccount = instance.getActiveAccount();
    }
    const handleLoginRedirect = (e) => {
        e.preventDefault();
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    }

    const handleClick = (e) => {
        e.preventDefault();
        
    };

    return (
        <div className='header'>
            <div className='header-logo'>
                A product by
            <a href="/"><img src={LogoGlugroft} alt='glucroft investigations' /></a>
            </div>
            {
                showUserName && activeAccount ?
                    <>
                        
                        <div className='header-username'>
                            <div className='notification'>
                               {(activeFeatures.includes('f_FieldInvestigator_AssignRoles') || activeFeatures.includes('f_InternalInvestigator_AssignRoles')) ? <></> : <a className='button notification' href="/orders/notifications"><IcoMail /> &nbsp; &nbsp; New Notifications</a>}
                            </div>
                            {activeAccount.name}
                        </div>
                    </>
                    :
                    <a className='header-username' onClick={handleLoginRedirect}>Login</a>
            }
        </div>
    );
}

export default Header;