import * as Yup from 'yup';
import { phoneExpression } from '../utility/phone-expression';


const BackgroundScreeningDetailsSchema = Yup.object().shape({
    backgroundScreeningDetails: Yup.object().shape({
        firstName: Yup.string()
            .required('Required'),
        lastName: Yup.string()
            .required('Required'),
        emailPrimary: Yup.string()
            .email('Invalid email'),
        emailSecondary: Yup.string()
            .email('Invalid email'),
        phonePrimary: Yup.string()
            .matches(phoneExpression, 'Invalid phone number'),
        phoneSecondary: Yup.string()
            .matches(phoneExpression, 'Invalid phone number'),
        address: Yup.string()
            .required('Required'),
        city: Yup.string()
            .required('Required'),
        stateProvince: Yup.string()
            .required('Required'),
        zip: Yup.string()
            .required('Required'),
        country: Yup.string()
            .required('Required'),
        socialMedia: Yup.string()
            .required('Required'),
    })
});

export default BackgroundScreeningDetailsSchema;