import { Field } from "formik";


const IntakeInsuranceClaimHistoryDetailsFields = ({ errors, touched, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <h3>Is your client the subject of the claim history?</h3>

            <div className="asset-locate-type">
                <div role="group" aria-labelledby="insurance-claim-history-details-radio-group" className="form-check-inline left-justified">
                    <div className="form-check">
                        <Field type="radio" name={nested ? "insuranceClaimHistoryDetails.insuranceClaimHistoryDetails" : "insuranceClaimHistoryDetails"} value="Yes" id="Yes" />
                        <label htmlFor="Yes">Yes</label>
                    </div>
                    <div className="form-check">
                        <Field type="radio" name={nested ? "insuranceClaimHistoryDetails.insuranceClaimHistoryDetails" : "insuranceClaimHistoryDetails"} value="No" id="No" />
                        <label htmlFor="No">No</label>
                    </div>
                </div>
            </div>

            {errors && errors.insuranceClaimHistoryDetails && touched && touched.insuranceClaimHistoryDetails ? (
                <div className="form-error">{errors.insuranceClaimHistoryDetails}</div>
            ) : null}
        </div>
    );
};

export default IntakeInsuranceClaimHistoryDetailsFields;