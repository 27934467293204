import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import {getGlucroftUsersForAssignment, resetGlucroftUsersToAssignStatus, selectAllGlucroftUsersForAssigment } from '../../reducers/glucroft-users-to-assign-slice';


const AssignModal = ({ showAssignModal, handleCloseAssignModal, handleAssignButton, handleUserChange, orderIds }) => {
    const dispatch = useDispatch();
    const [glucroftUsersLoaded, setGlucroftUsersLoaded] = useState(false);
    const glucroftUsersForAssigment = useSelector(selectAllGlucroftUsersForAssigment);
    const glucroftUsersStatus = useSelector(state => state.glucroftUsersToAssign.status);

    useEffect(() => {
        if (glucroftUsersStatus === 'idle' && !glucroftUsersLoaded) {
            dispatch(getGlucroftUsersForAssignment(orderIds));
        } else if (glucroftUsersStatus === 'succeeded' && !glucroftUsersLoaded) {
            // anything that needs to happen AFTER 
            // the data has loaded (e.g. removing loading indicator)
            setGlucroftUsersLoaded(true);
        }
    }, [dispatch, glucroftUsersStatus, glucroftUsersLoaded]);

    // set when glucroft users details have been loaded
    useEffect(() => {
        if (glucroftUsersLoaded) {
            dispatch(resetGlucroftUsersToAssignStatus());
        }
    }, [dispatch, glucroftUsersLoaded]);

    return (
        <Modal
            backdrop="static"
            show={showAssignModal}
            onHide={handleCloseAssignModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Assign all selected orders to:</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {orderIds.length === 0 && <div>Please select at least one order to assign users to.</div>}
                <p><strong>User</strong></p>
                <Form.Select onChange={handleUserChange}>
                    <option>Select a User</option>
                    {glucroftUsersForAssigment.map(data => {
                        return <option key={data.id} value ={data.id}>{data.firstName} {data.lastName}</option>
                    })}
                </Form.Select>
            </Modal.Body>

            <Modal.Footer>
                <button className="button tertiary" onClick={handleAssignButton}>Assign</button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignModal;