import { Field } from "formik";
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';


const IntakeInvestigationTypeFields = ({ errors, touched, values, investigationTypes }) => {
    return (
        <div className="form-wrapper investigation-type">
            <label htmlFor="investigation-type">Investigation Type</label>

            <Dropdown>
                <Dropdown.Toggle id="investigation-type" className={`form-select ${values.investigationType !== '' ? 'hasValue' : ''}`}>
                    {values.investigationType !== '' ? values.investigationType : 'Select your investigation type '}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <ul role="group" aria-labelledby="investigation-type-radio-group" className='radio-group'>
                        {investigationTypes.map((investigationType, i) => {
                            if (investigationType.children.length > 0) {
                                return (
                                    <Accordion key={`accordion-${i}`}>
                                        <li className="form-check">
                                            <Accordion.Item eventKey={`accordion-item-${i}`}>
                                                <Accordion.Header>{investigationType.name}</Accordion.Header>

                                                <ul>
                                                    <Accordion.Body>
                                                        {investigationType.children.map((investigationTypeChild, i) => {
                                                            let investigationTypeName = investigationTypeChild.name;
                                                            if (investigationTypeChild.children.length > 0) {
                                                                return (
                                                                    <Accordion key={`sub-accordion-${i}`}>
                                                                        <li className="form-check">
                                                                            <Accordion.Item eventKey={`sub-accordion-item-${i}`}>
                                                                                <Accordion.Header>{investigationTypeChild.name}</Accordion.Header>

                                                                                <Accordion.Body>
                                                                                    <ul>
                                                                                        {investigationTypeChild.children.map((investigationTypeGrandChild, i) => {
                                                                                            let investigationTypeName = investigationTypeGrandChild.name.slice(34);
                                                                                            return (
                                                                                                <li key={`investigation-type-child-${i}`} className="form-check">
                                                                                                    <Field type="radio" name="investigationType" id={investigationTypeGrandChild.Id} value={`${investigationType.name}: ${investigationTypeChild.name} ${investigationTypeName}`} />
                                                                                                    <label htmlFor={investigationTypeName}>{investigationTypeName}</label>
                                                                                                </li>
                                                                                            );
                                                                                        })}
                                                                                    </ul>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        </li>
                                                                    </Accordion>
                                                                );
                                                            } else {
                                                                let slicedInvestigationTypeName = investigationTypeName.slice(25);
                                                                return (
                                                                    <li key={`investigation-type-child-${i}`} className="form-check">
                                                                        <Field type="radio" name="investigationType" id={investigationTypeChild.Id} value={`${investigationType.name}: ${slicedInvestigationTypeName}`} />
                                                                        <label htmlFor={slicedInvestigationTypeName}>{slicedInvestigationTypeName}</label>
                                                                    </li>
                                                                );
                                                            }
                                                        })}
                                                    </Accordion.Body>
                                                </ul>
                                            </Accordion.Item>
                                        </li>
                                    </Accordion>
                                );
                            } else {
                                let investigationTypeName = investigationType.name;

                                return (
                                    <li key={`investigation-type-${i}`} className="form-check">
                                        <Field type="radio" name="investigationType" id={investigationType.Id} value={investigationTypeName}/>
                                        <label htmlFor={investigationTypeName}>{investigationTypeName}</label>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </Dropdown.Menu>
            </Dropdown>

            {errors.investigationType && touched.investigationType ? (
                <div className="form-error">{errors.investigationType}</div>
            ) : null}
        </div>
    );
};

export default IntakeInvestigationTypeFields;