import { investigationTypes } from '../placeholder-data';
import BaseAPI from '../utility/fetch-wrapper';


class InvestigationTypesServices {
    static GetInvestigationTypes = async successFunc => {
        const data = await BaseAPI.getRequest(`misc/ordertypes`);
        return data;
    };
}


export default InvestigationTypesServices;