import BaseAPI from '../utility/fetch-wrapper';

class CaseServices {
    static GetCaseById = async payload => {
        const data = await BaseAPI.getRequest(`orders/case/${payload}`);
        return data;
    };

    static GetCasesByOrgId = async payload => {
        const data = await BaseAPI.getRequest(`cases?orgId=${payload}`);
        return data;
    };

    static GetAllCases = async payload => {
        const data = await BaseAPI.getRequest(`cases`);
        return data;
    };
}


export default CaseServices;