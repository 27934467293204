import * as Yup from 'yup';


const SceneInvestigationDetailsSchema = Yup.object().shape({
    sceneInvestigationDetails: Yup.object().shape({
        dateOfLoss: Yup.string()
            .required('Required')
            .typeError('Required'),
        timeOfLoss: Yup.string()
            .required('Required')
            .typeError('Required'),
    })
});

export default SceneInvestigationDetailsSchema;