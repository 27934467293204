import * as Yup from 'yup';


const InsuranceInvestigationPersonalAutoUmbrellaDriversDetailsSchema = Yup.object().shape({
    insuranceInvestigationPersonalAutoUmbrellaDriversDetails: Yup.object().shape({
        driversInsuranceCompany: Yup.string()
            .required('Required'),
    })
});

export default InsuranceInvestigationPersonalAutoUmbrellaDriversDetailsSchema;