import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OrdersServices from '../services/orders-service';


export const fetchOrderById = createAsyncThunk('orderDetails/fetchOrderById', async (payload) => {
    const response = await OrdersServices.GetOrderById(payload);
    return response;
});

export const updateOrder = createAsyncThunk('orderDetails/updateOrder', async (payload) => {

    const response = await OrdersServices.UpdateOrder(payload);
    return response;
});

export const submitToBilling = createAsyncThunk('orderDetails/submitToBilling', async (payload) => {
    const response = await OrdersServices.SubmitToBilling(payload);
    return response;
});

const initialState = {
    orderDetails: [],
    status: 'idle',
    error: null,
};

export const orderDetailsSlice = createSlice({
    name: 'orderDetails',
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.status = 'idle';
        },
        resetOrderDetails: (state) => initialState
    },

    extraReducers: {
        [fetchOrderById.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchOrderById.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.orderDetails = action.payload;
        },
        [fetchOrderById.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [submitToBilling.pending]: (state, action) => {
            state.status = 'loading';
        },
        [submitToBilling.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.orderDetails = action.payload;
        },
        [submitToBilling.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [updateOrder.pending]: (state, action) => {
            state.status = 'loading';
        },
        [updateOrder.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.orderDetails = action.payload;
        },
        [updateOrder.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetStatus,
    resetOrderDetails
} = orderDetailsSlice.actions;

export default orderDetailsSlice.reducer;


export const selectOrder = state => {
    return state.orderDetails.orderDetails;
};