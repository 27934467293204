import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from '../header';
import PortalLogo from '../../assets/logo-portal.svg';
import { selectActiveFormInvestigationType } from '../../reducers/active-form-slice';


const LayoutIntake = () => {
    let location = useLocation();
    const investigationType = useSelector(selectActiveFormInvestigationType);
    
    return (
        <div className='layout intake'>
            <div className="left-rail">
                <a href="/"><img className="logo" src={PortalLogo} alt='portal' /></a>
            </div>

            <div className='layout-content'>
                <Header showUserName={true} />

                <div className='layout-content-outlet'>
                    <h1>{(location.pathname === "/intake" || location.pathname === "/intake/") ? '' : investigationType[0]}</h1>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default LayoutIntake;