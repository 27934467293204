import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CaseServices from '../services/case-service';


export const fetchCaseById = createAsyncThunk('caseDetails/fetchCaseById', async (payload) => {
    try{
    const response = await CaseServices.GetCaseById(payload);
    return response;
    }
    catch(ex)
    {
        console.error(ex);
    }
});

const initialState = {
    caseDetails: [],
    status: 'idle',
    error: null,
}

export const caseDetailsSlice = createSlice({
    name: 'caseDetails',
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.status = 'idle';
        },
        resetCaseOrders: () => initialState
    },

    extraReducers: {
        [fetchCaseById.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCaseById.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.caseDetails = action.payload;
        },
        [fetchCaseById.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

export const {
    resetStatus,
    resetCaseOrders,
} = caseDetailsSlice.actions;

export default caseDetailsSlice.reducer;


export const selectCase = state => {
    return state.caseDetails.caseDetails;
};