import BaseAPI from '../utility/fetch-wrapper';

class ReportService {
    static GenerateReport = async payload => {
        const data = await BaseAPI.requestReport(`misc/reports`, payload)
        return data;
    };


    static GenerateSummaryReport = async (payload, saveToEgnyte="false", showResults="false") => {
        const data = await BaseAPI.getRequest(`report/generatesummary/${payload}?saveToEgnyte=${saveToEgnyte}&showResults=${showResults}`)
        return data;
    };

}


export default ReportService;