import { useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import FormFooter from '../../../components/form-footer';
import IntakeSWITRSDetailsFields from '../../../components/intake/switrs/details-fields';
import { selectActiveFormSWITRSDetails, updateActiveFormSWITRSDetails } from '../../../reducers/active-form-slice';
import { resetSWITRSDetailsFilesStatus, selectAllSWITRSDetailsFiles, uploadSWITRSDetailsFiles, deleteSWITRSDetailsFiles } from '../../../reducers/switrs-details-files-slice';


const IntakeSWITRSDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const switrsDetails = useSelector(selectActiveFormSWITRSDetails);
    const hasValues = Object.keys(switrsDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * handle the file upload & store returned info in state
     */
    const switrsDetailsFiles = useSelector(selectAllSWITRSDetailsFiles);
    const switrsDetailsFilesStatus = useSelector(state => state.switrsDetailsFiles.status);
    const formRef = useRef();

    const handleUpload = (files) => {
        dispatch(uploadSWITRSDetailsFiles(files));
    };

    const handleUploadedRemoval = (guid) => {
        dispatch(deleteSWITRSDetailsFiles(guid));
    };

    useEffect(() => {
        if (switrsDetailsFilesStatus === "succeeded") {
            formRef.current.setFieldValue('files', switrsDetailsFiles.switrsDetailsFiles);
            dispatch(resetSWITRSDetailsFilesStatus());
        }
    }, [switrsDetailsFiles, switrsDetailsFilesStatus, dispatch]);


    /**
     * submit form
     */
    const handleSubmit = (values) => {
        delete values.intakeAttachments;
        dispatch(updateActiveFormSWITRSDetails(values));
        navigate('/intake/additional-information');
    };


    /**
     * setup the initial values & validation
     */
    const initialValues = {
        location: '',
        files: [],
    };
    

    return (
        <div className="page intake">
            {(switrsDetailsFilesStatus === 'loading') &&
                <div className="loader-container">
                    <BeatLoader color="#00818C" />
                </div>
            }

            <h2>SWITRS Details</h2>

            <Formik
                innerRef={formRef}
                initialValues={hasValues ? switrsDetails : initialValues}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <IntakeSWITRSDetailsFields
                            handleUpload={handleUpload}
                            handleUploadedRemoval={handleUploadedRemoval}
                            uploadedFiles={switrsDetailsFiles.switrsDetailsFiles}
                            uploadStatus={switrsDetailsFilesStatus}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={false}
                            showNext={true}
                            progress={`50`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeSWITRSDetails;