export const orders = [
    {
        "id": "3452",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3453",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "In Review",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3454",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Waiting on Client",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3455",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "In Process",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3456",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Report Ordered",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3457",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Report Recieved",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3458",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Assigned",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3459",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "QA",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3460",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Sent to Client",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3461",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "In Billing",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3462",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Complete",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3463",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Canceled",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3464",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Complete",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3465",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3466",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Complete",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3467",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3468",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3469",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3470",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "In Review",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3471",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "In Review",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3472",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3473",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "In Review",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3474",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3475",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3476",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3477",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3478",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3479",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3480",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3481",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3482",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3483",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3484",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3485",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "New",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3486",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Complete",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3487",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Waiting on Client",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3488",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Complete",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    },
    {
        "id": "3489",
        "firm": "Firm Name",
        "caseName": "Case Name",
        "caseID": "123",
        "type": "Investigation Type",
        "assignedGI": "Name Assigned",
        "status": "Waiting on Client",
        "start": "July 04, 2020",
        "lastEdit": "July 04, 2020",
        "attention": "See Note"
    }
];

export const order3452 = {
    "details": {
        "number": "003452",
        "caseName": "some case name",
        "managerAssigned": "assigned manager",
        "glucroftAssigned": "assigned glucroft",
        "outsideInvestigatorAssigned": "assigned outside investigator",
        "investigationType": "investigation type",
        "status": "status",
        "unknown1": "unknown",
        "unknown2": "unknown",
        "unknown3": "unknown",
        "unknown4": "unknown",
        "unknown5": "unknown",
    },
    "notes": [
        {
            "id": "123",
            "from": "Name Name",
            "date": "July 04, 2020",
            "message": "Vulputate odio ut enim blandit volutpat maecenas. Leo a diam sollicitudin tempor id eu. Turpis massa sed elementum tempus egestas sed sed risus. Justo laoreet sit amet cursus. Nisi quis eleifend quam adipiscing. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Et tortor consequat id porta nibh venenatis cras. Sit amet volutpat consequat mauris. Diam maecenas ultricies mi eget mauris pharetra. Dolor morbi non arcu risus quis varius. Felis donec et odio pellentesque diam volutpat commodo. Egestas erat imperdiet sed euismod.",
        },
        {
            "id": "124",
            "from": "Name Name",
            "date": "July 04, 2020",
            "message": "Vulputate odio ut enim blandit volutpat maecenas. Leo a diam sollicitudin tempor id eu. Turpis massa sed elementum tempus egestas sed sed risus. Justo laoreet sit amet cursus. Nisi quis eleifend quam adipiscing. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Et tortor consequat id porta nibh venenatis cras. Sit amet volutpat consequat mauris. Diam maecenas ultricies mi eget mauris pharetra. Dolor morbi non arcu risus quis varius. Felis donec et odio pellentesque diam volutpat commodo. Egestas erat imperdiet sed euismod.",
        },
        {
            "id": "125",
            "from": "Name Name",
            "date": "July 04, 2020",
            "message": "Vulputate odio ut enim blandit volutpat maecenas. Leo a diam sollicitudin tempor id eu. Turpis massa sed elementum tempus egestas sed sed risus. Justo laoreet sit amet cursus. Nisi quis eleifend quam adipiscing. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Et tortor consequat id porta nibh venenatis cras. Sit amet volutpat consequat mauris. Diam maecenas ultricies mi eget mauris pharetra. Dolor morbi non arcu risus quis varius. Felis donec et odio pellentesque diam volutpat commodo. Egestas erat imperdiet sed euismod.",
        },
        {
            "id": "126",
            "from": "Name Name",
            "date": "July 04, 2020",
            "message": "Vulputate odio ut enim blandit volutpat maecenas. Leo a diam sollicitudin tempor id eu. Turpis massa sed elementum tempus egestas sed sed risus. Justo laoreet sit amet cursus. Nisi quis eleifend quam adipiscing. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Et tortor consequat id porta nibh venenatis cras. Sit amet volutpat consequat mauris. Diam maecenas ultricies mi eget mauris pharetra. Dolor morbi non arcu risus quis varius. Felis donec et odio pellentesque diam volutpat commodo. Egestas erat imperdiet sed euismod.",
        },
        {
            "id": "127",
            "from": "Name Name",
            "date": "July 04, 2020",
            "message": "Vulputate odio ut enim blandit volutpat maecenas. Leo a diam sollicitudin tempor id eu. Turpis massa sed elementum tempus egestas sed sed risus. Justo laoreet sit amet cursus. Nisi quis eleifend quam adipiscing. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Et tortor consequat id porta nibh venenatis cras. Sit amet volutpat consequat mauris. Diam maecenas ultricies mi eget mauris pharetra. Dolor morbi non arcu risus quis varius. Felis donec et odio pellentesque diam volutpat commodo. Egestas erat imperdiet sed euismod.",
        },
        {
            "id": "128",
            "from": "Name Name",
            "date": "July 04, 2020",
            "message": "Vulputate odio ut enim blandit volutpat maecenas. Leo a diam sollicitudin tempor id eu. Turpis massa sed elementum tempus egestas sed sed risus. Justo laoreet sit amet cursus. Nisi quis eleifend quam adipiscing. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Et tortor consequat id porta nibh venenatis cras. Sit amet volutpat consequat mauris. Diam maecenas ultricies mi eget mauris pharetra. Dolor morbi non arcu risus quis varius. Felis donec et odio pellentesque diam volutpat commodo. Egestas erat imperdiet sed euismod.",
        },
        {
            "id": "129",
            "from": "Name Name",
            "date": "July 04, 2020",
            "message": "Vulputate odio ut enim blandit volutpat maecenas. Leo a diam sollicitudin tempor id eu. Turpis massa sed elementum tempus egestas sed sed risus. Justo laoreet sit amet cursus. Nisi quis eleifend quam adipiscing. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Et tortor consequat id porta nibh venenatis cras. Sit amet volutpat consequat mauris. Diam maecenas ultricies mi eget mauris pharetra. Dolor morbi non arcu risus quis varius. Felis donec et odio pellentesque diam volutpat commodo. Egestas erat imperdiet sed euismod.",
        },
        {
            "id": "130",
            "from": "Name Name",
            "date": "July 04, 2020",
            "message": "Vulputate odio ut enim blandit volutpat maecenas. Leo a diam sollicitudin tempor id eu. Turpis massa sed elementum tempus egestas sed sed risus. Justo laoreet sit amet cursus. Nisi quis eleifend quam adipiscing. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Et tortor consequat id porta nibh venenatis cras. Sit amet volutpat consequat mauris. Diam maecenas ultricies mi eget mauris pharetra. Dolor morbi non arcu risus quis varius. Felis donec et odio pellentesque diam volutpat commodo. Egestas erat imperdiet sed euismod.",
        },
        {
            "id": "131",
            "from": "Name Name",
            "date": "July 04, 2020",
            "message": "Vulputate odio ut enim blandit volutpat maecenas. Leo a diam sollicitudin tempor id eu. Turpis massa sed elementum tempus egestas sed sed risus. Justo laoreet sit amet cursus. Nisi quis eleifend quam adipiscing. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Et tortor consequat id porta nibh venenatis cras. Sit amet volutpat consequat mauris. Diam maecenas ultricies mi eget mauris pharetra. Dolor morbi non arcu risus quis varius. Felis donec et odio pellentesque diam volutpat commodo. Egestas erat imperdiet sed euismod.",
        },
        {
            "id": "132",
            "from": "Name Name",
            "date": "July 04, 2020",
            "message": "Vulputate odio ut enim blandit volutpat maecenas. Leo a diam sollicitudin tempor id eu. Turpis massa sed elementum tempus egestas sed sed risus. Justo laoreet sit amet cursus. Nisi quis eleifend quam adipiscing. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Et tortor consequat id porta nibh venenatis cras. Sit amet volutpat consequat mauris. Diam maecenas ultricies mi eget mauris pharetra. Dolor morbi non arcu risus quis varius. Felis donec et odio pellentesque diam volutpat commodo. Egestas erat imperdiet sed euismod.",
        },
        {
            "id": "133",
            "from": "Name Name",
            "date": "July 04, 2020",
            "message": "Vulputate odio ut enim blandit volutpat maecenas. Leo a diam sollicitudin tempor id eu. Turpis massa sed elementum tempus egestas sed sed risus. Justo laoreet sit amet cursus. Nisi quis eleifend quam adipiscing. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Et tortor consequat id porta nibh venenatis cras. Sit amet volutpat consequat mauris. Diam maecenas ultricies mi eget mauris pharetra. Dolor morbi non arcu risus quis varius. Felis donec et odio pellentesque diam volutpat commodo. Egestas erat imperdiet sed euismod.",
        },
    ],
    "attachments": [
        {
            "id": "123",
            "type": "PDF",
            "date": "July 04, 2020",
            "fileName": "Lorem_Ipsum_Supporting_Documentation.pdf",
        },
        {
            "id": "124",
            "type": "PDF",
            "date": "July 04, 2020",
            "fileName": "Lorem_Ipsum_Supporting_Documentation.pdf",
        },
        {
            "id": "125",
            "type": "PDF",
            "date": "July 04, 2020",
            "fileName": "Lorem_Ipsum_Supporting_Documentation.pdf",
        },
        {
            "id": "126",
            "type": "PDF",
            "date": "July 04, 2020",
            "fileName": "Lorem_Ipsum_Supporting_Documentation.pdf",
        },
        {
            "id": "127",
            "type": "PDF",
            "date": "July 04, 2020",
            "fileName": "Lorem_Ipsum_Supporting_Documentation.pdf",
        },
        {
            "id": "128",
            "type": "PDF",
            "date": "July 04, 2020",
            "fileName": "Lorem_Ipsum_Supporting_Documentation.pdf",
        },
        {
            "id": "129",
            "type": "PDF",
            "date": "July 04, 2020",
            "fileName": "Lorem_Ipsum_Supporting_Documentation.pdf",
        },
        {
            "id": "130",
            "type": "PDF",
            "date": "July 04, 2020",
            "fileName": "Lorem_Ipsum_Supporting_Documentation.pdf",
        },
        {
            "id": "131",
            "type": "PDF",
            "date": "July 04, 2020",
            "fileName": "Lorem_Ipsum_Supporting_Documentation.pdf",
        },
    ],
    "changeLog": [
        {
            "number": "1",
            "id": "23",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "123445",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
        {
            "number": "2",
            "id": "24",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "123446",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
        {
            "number": "3",
            "id": "25",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "1234547",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
        {
            "number": "4",
            "id": "26",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "123448",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
        {
            "number": "5",
            "id": "27",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "123449",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
        {
            "number": "6",
            "id": "28",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "123450",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
        {
            "number": "7",
            "id": "29",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "123451",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
        {
            "number": "8",
            "id": "30",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "123452",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
        {
            "number": "9",
            "id": "31",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "123453",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
        {
            "number": "10",
            "id": "32",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "123454",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
        {
            "number": "11",
            "id": "33",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "123455",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
        {
            "number": "12",
            "id": "34",
            "author": "u_jondoe",
            "dateExecuted": "02-SEP-22 12.55.25.96583000 PM",
            "order": "123456",
            "execType": "EXECUTED",
            "description": "this admin user made this change to this field on this users account",
        },
    ],
};

export const case123 = {
    "name": "Jack v Jill",
    "orders": [
        {
            "id": "3452",
            "firm": "Firm Name",
            "caseName": "Case Name",
            "caseID": "123",
            "type": "Investigation Type",
            "assignedGI": "Name Assigned",
            "status": "New",
            "start": "July 04, 2020",
            "lastEdit": "July 04, 2020",
            "attention": "See Note"
        },
        {
            "id": "3453",
            "firm": "Firm Name",
            "caseName": "Case Name",
            "caseID": "123",
            "type": "Investigation Type",
            "assignedGI": "Name Assigned",
            "status": "In Review",
            "start": "July 04, 2020",
            "lastEdit": "July 04, 2020",
            "attention": "See Note"
        },
        {
            "id": "3454",
            "firm": "Firm Name",
            "caseName": "Case Name",
            "caseID": "123",
            "type": "Investigation Type",
            "assignedGI": "Name Assigned",
            "status": "Waiting on Client",
            "start": "July 04, 2020",
            "lastEdit": "July 04, 2020",
            "attention": "See Note"
        },
        {
            "id": "3455",
            "firm": "Firm Name",
            "caseName": "Case Name",
            "caseID": "123",
            "type": "Investigation Type",
            "assignedGI": "Name Assigned",
            "status": "In Process",
            "start": "July 04, 2020",
            "lastEdit": "July 04, 2020",
            "attention": "See Note"
        },
        {
            "id": "3456",
            "firm": "Firm Name",
            "caseName": "Case Name",
            "caseID": "123",
            "type": "Investigation Type",
            "assignedGI": "Name Assigned",
            "status": "Report Ordered",
            "start": "July 04, 2020",
            "lastEdit": "July 04, 2020",
            "attention": "See Note"
        },
        {
            "id": "3457",
            "firm": "Firm Name",
            "caseName": "Case Name",
            "caseID": "123",
            "type": "Investigation Type",
            "assignedGI": "Name Assigned",
            "status": "Report Recieved",
            "start": "July 04, 2020",
            "lastEdit": "July 04, 2020",
            "attention": "See Note"
        },
    ],
};

export const investigationTypes = [
    {
        "name": "SWITRS",
        "children": []
    },
    {
        "name": "Subpoena Process Serving",
        "children": []
    },
    {
        "name": "Insurance Claim History (ISO)",
        "children": []
    },
    {
        "name": "Asset Locate",
        "children": []
    },
    {
        "name": "Insurance Investigation",
        "children": [
            {
                "name": "Business",
                "children": []
            },
            {
                "name": "Locate",
                "children": []
            },
            {
                "name": "Personal",
                "children": [
                    {
                        "name": "Homeowners OR Renters",
                        "children": []
                    },
                    {
                        "name": "Auto AND/OR Umbrella",
                        "children": []
                    }
                ]
            }
        ]
    },
    {
        "name": "Background Screening",
        "children": []
    },
    {
        "name": "Skip Trace",
        "children": []
    },
    {
        "name": "Traffic Collisions Report Retrieval",
        "children": []
    },
    {
        "name": "Scene Investigation",
        "children": []
    },
    {
        "name": "DMV",
        "children": []
    },
    {
        "name": "Business Due Diligence",
        "children": []
    },
    {
        "name": "Witness & Defendant Statement",
        "children": []
    },
]

export const caseManagers = [
    {
        "id": "120",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Disabled",
    },
    {
        "id": "121",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "122",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "124",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "125",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "126",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "127",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "128",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "129",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "1231",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "1232",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "1233",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "1234",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "1235",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "1236",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "1237",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "1238",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "1239",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12310",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12312",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Disabled",
    },
    {
        "id": "12313",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12314",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12315",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12316",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12317",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12318",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12319",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12320",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12321",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12322",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12323",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12324",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12325",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12326",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12327",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12328",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12329",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12330",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12331",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Disabled",
    },
    {
        "id": "12332",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12333",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12334",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12335",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12336",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12337",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12338",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12339",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12340",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12341",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12342",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12343",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12344",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12345",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12346",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12347",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12348",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "12349",
        "firstName": "First Name",
        "lastName": "Last Name",
        "lawFirm": "Law Firm",
        "email": "jdoe@firmname.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
];

export const caseManager123 = {
    "id": "123",
    "firstName": "First Name",
    "lastName": "Last Name",
    "lawFirm": "Law Firm",
    "email": "jdoe@firmname.com",
    "phone": "+1 (555) 555-5555 EXT: 555",
    "permissionLevel": "Billing",
    "status": "Disabled",
};

export const lawFirms = [
    {
        "id": "11230",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Disabled",
    },
    {
        "id": "11231",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "11232",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "11233",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "11234",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "11235",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "11236",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "12371",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "11238",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "11239",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112310",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112311",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112312",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112313",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112314",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112315",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112316",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112317",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112318",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112319",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112320",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112321",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112322",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112323",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112324",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112325",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112326",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112327",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112328",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112329",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112330",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112331",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112332",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112333",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112334",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112335",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112336",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112337",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112338",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112339",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112340",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112341",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
    {
        "id": "112342",
        "firm": "Firm Name",
        "address": "123 Address Ave. Las Vegas, NV 12345",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "primaryName": "First Last Name",
        "primaryEmail": "jdoe@firmname.com",
        "primaryPhone": "+1 (555) 555-5555 EXT: 555",
        "billingName": "First Last Name",
        "billingEmail": "name@firmname.com",
        "billingPhone": "+1 (555) 555-5555 EXT: 555",
        "status": "Enabled",
    },
];

export const lawFirm123 = {
    "id": "1123",
    "firm": "Firm Name",
    "address": "123 Address Ave. Las Vegas, NV 12345",
    "phone": "+1 (555) 555-5555 EXT: 555",
    "primaryName": "First Last Name",
    "primaryEmail": "jdoe@firmname.com",
    "primaryPhone": "+1 (555) 555-5555 EXT: 555",
    "billingName": "First Last Name",
    "billingEmail": "name@firmname.com",
    "billingPhone": "+1 (555) 555-5555 EXT: 555",
    "status": "Disabled",
};

export const glucroftUsers = [
    {
        "id": "21230",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Disabled",
    },
    {
        "id": "21231",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "21232",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "21233",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "21234",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "21235",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "21236",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "21237",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "21238",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "21239",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212310",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212311",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212312",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212313",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212314",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212315",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212316",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212317",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212318",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212319",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212320",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212321",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212322",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212323",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212324",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212325",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212326",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212327",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212328",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212329",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212330",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212331",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212332",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212333",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212334",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212335",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212336",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212337",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212338",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212339",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212340",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212341",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212342",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212343",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212344",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212345",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212346",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212347",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212348",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212349",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
    {
        "id": "212350",
        "firstName": "First Name",
        "lastName": "Last Name",
        "employeeNumber": "12345",
        "employeeId": "12345",
        "email": "jdoe@email.com",
        "phone": "+1 (555) 555-5555 EXT: 555",
        "permissionLevel": "Permission level",
        "status": "Enabled",
    },
];

export const glucroftUser123 = {
    "id": "21231",
    "firstName": "First Name",
    "lastName": "Last Name",
    "employeeNumber": "12345",
    "employeeId": "12345",
    "email": "jdoe@email.com",
    "phone": "+1 (555) 555-5555 EXT: 555",
    "permissionLevel": "Permission level",
    "status": "Disabled",
};