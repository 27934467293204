import { Fragment, useEffect, useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import PortalLogo from '../assets/logo-portal.svg';
import PortalLogoMinimized from '../assets/logo-portal-minimized.svg';
import IcoArrow from '../assets/ico-arrow';
import IcoHome from '../assets/ico-home';
import IcoGear from '../assets/ico-gear';
import IcoDocument from '../assets/ico-document';
import IcoSend from '../assets/ico-send';
import IcoLogout from '../assets/ico-logout';
import LinkShare from './link-share';
import Reports from './reports';
import { useMsal } from '@azure/msal-react';
import { selectActiveFeatures } from '../reducers/auth-slice';
import { useSelector } from 'react-redux';


const LeftNav = () => {
    const [menuState, setMenuState] = useState('opened');
    const [showLinkShare, setShowLinkShare] = useState(false);
    const [showReports, setShowReports] = useState(false);
    const activeStyle = 'active';
    const activeFeatures = useSelector(selectActiveFeatures);

    const toggleMenu = () => {
        if (menuState === 'opened') {
            setMenuState('closed');
        } else {
            setMenuState('opened');
        }
    };

    const LogoSrc = menuState === 'opened' ? PortalLogo : PortalLogoMinimized;

    const handleShowLinkShare = () => {
        setShowLinkShare(!showLinkShare);
        setShowReports(false);
    };

    const handleShowReports = () => {
        setShowReports(!showReports);
        setShowLinkShare(false);
    };

    const handleCloseFlyout = () => {
        setShowReports(false);
        setShowLinkShare(false);
    };

    const location = useLocation();
    useEffect(() => {
        setShowLinkShare(false);
        setShowReports(false);
    }, [location]);

    const { instance } = useMsal();
    const handleLogout = (e) => {
        e.preventDefault();
        let activeAccount = instance.getActiveAccount();
        if (activeAccount) {
            instance.logout(activeAccount);
        }
    }

    /**
     * TODO:
     * 
     * figure out how to show/hide menu options
     * based on user roles
     */
    return (
        <Fragment>
            <div className={`left-nav ${menuState}`}>
                <a href="/"><img className="logo" src={LogoSrc} alt='portal' /></a>

                <button onClick={toggleMenu} className={`left-nav-toggle button ${menuState}`}>
                    <IcoArrow />
                </button>

                <ul>
                    {activeFeatures.includes('f_HomeOrders') ?
                        <li>
                            <NavLink
                                to='/orders'
                                className={({ isActive }) => (isActive && !showLinkShare && !showReports) ? activeStyle : undefined}
                            >
                                <IcoHome />
                                <span>Home</span>
                            </NavLink>
                        </li>
                        :
                        <li>
                            <NavLink
                                to='/portal'
                                className={({ isActive }) => (isActive && !showLinkShare && !showReports) ? activeStyle : undefined}
                            >
                                <IcoHome />
                                <span>Home</span>
                            </NavLink>
                        </li>
                    }
                    {activeFeatures.includes('f_Admin') &&
                        <li>
                            <NavLink
                                to='/admin'
                                className={({ isActive }) => (isActive && !showLinkShare && !showReports) ? activeStyle : undefined}
                            >
                                <IcoGear />
                                <span>Admin</span>
                            </NavLink>
                        </li>
                    }
                    {activeFeatures.includes('f_Reports') &&
                        <li>
                            <button onClick={handleShowReports} className={showReports ? 'active' : ''}>
                                <IcoDocument />
                                <span>Reports</span>
                            </button>
                        </li>
                    }
                    {activeFeatures.includes('f_SendInvite') &&
                        <li>
                            <button onClick={handleShowLinkShare} className={showLinkShare ? 'active' : ''}>
                                <IcoSend />
                                <span>Link<br />Invitation</span>
                            </button>
                        </li>
                    }
                    <li>
                        <a className='logout button tertiary' onClick={handleLogout} >
                            <IcoLogout />
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>

                {showLinkShare && <LinkShare closeFlyout={handleCloseFlyout} />}
                {showReports && <Reports closeFlyout={handleCloseFlyout} />}
            </div>

            {(showLinkShare || showReports) && <div onClick={handleCloseFlyout} className="flyout-background"></div>}
        </Fragment>
    );
}

export default LeftNav;