const IcoSend = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="32.414" viewBox="0 0 32.414 32.414">
      <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(-2 -1.586)">
        <path id="Path_10393" data-name="Path 10393" d="M33,3,16.5,19.5" fill="none" stroke="current" strokeLinecap="round" strokeWidth="2" />
        <path id="Path_10394" data-name="Path 10394" d="M33,3,22.5,33l-6-13.5L3,13.5Z" fill="none" stroke="current" strokeLinecap="round" strokeWidth="2" />
      </g>
    </svg>
  );
}

export default IcoSend;