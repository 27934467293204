import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import { selectCase } from '../reducers/case-details-slice';
import { selectOrder } from '../reducers/order-details-slice';
import { selectCaseManager } from "../reducers/case-manager-details-slice";
import { selectLawFirm } from "../reducers/law-firm-details-slice";
import { selectGlucroftUser } from "../reducers/glucroft-user-details-slice";


const Breadcrumbs = () => {
    const GetCaseName = () => {
        const caseDetails = useSelector(selectCase);

        return (
            <span>{caseDetails[0]?.caseName}</span>
        );
    };

    const GetOrderName = () => {
        const order = useSelector(selectOrder);

        return (
            <span>Order: {order?.details?.orderNumber}</span>
        );
    };

    const GetCaseManagerName = () => {
        const caseManager = useSelector(selectCaseManager);

        return (
            <span>{caseManager.firstName} {caseManager.lastName}</span>
        );
    };

    const GetLawFirmName = () => {
        const lawFirm = useSelector(selectLawFirm);

        return (
            <span>{lawFirm.firm}</span>
        );
    };

    const GetGlucroftUserName = () => {
        const glucroftUser = useSelector(selectGlucroftUser);

        return (
            <span>{glucroftUser.firstName} {glucroftUser.lastName}</span>
        );
    };

    const routes = [
        { path: "/orders/case-details/:caseNumber", breadcrumb: GetCaseName },
        { path: "/orders/order-details/:orderId", breadcrumb: GetOrderName },
        { path: "/admin/case-manager/:id", breadcrumb: GetCaseManagerName },
        { path: "/admin/law-firm/:id", breadcrumb: GetLawFirmName },
        { path: "/admin/glucroft-user/:id", breadcrumb: GetGlucroftUserName },
    ];

    const breadcrumbs = useBreadcrumbs(routes);

    return (
        <div className="breadcrumbs">
            {breadcrumbs.map(({ match, breadcrumb }) => {
                if (match.pathname.endsWith('case-manager') || match.pathname.endsWith('law-firm') || match.pathname.endsWith('glucroft-user') || match.pathname.endsWith('order-details') || match.pathname.endsWith('case-details')) {
                    return (
                        <span key={match.pathname}>{breadcrumb}</span>
                    );
                } else if (match.pathname === '/') {
                    return false;
                } else {
                    return (
                        <NavLink key={match.pathname} to={match.pathname}>
                            {breadcrumb}
                        </NavLink>
                    );
                }
            })}
        </div>
    );
};

export default Breadcrumbs;