import { Field } from "formik";
import { DatePickerInput } from "../../datepicker-input";


const IntakeSceneInvestigationDetailsFields = ({ errors, touched, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="dateOfLoss">Date of Loss <span className="required">*</span></label>
                    <DatePickerInput name={nested ? "sceneInvestigationDetails.dateOfLoss" : "dateOfLoss"} placeholderText="Select date" />

                    {errors && errors.dateOfLoss && touched && touched.dateOfLoss ? (
                        <div className="form-error">{errors.dateOfLoss}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="timeOfLoss">Time of Loss <span className="required">*</span></label>
                    <DatePickerInput name={nested ? "sceneInvestigationDetails.timeOfLoss" : "timeOfLoss"} showTimeSelect showTimeSelectOnly timeCaption="Time" dateFormat="h:mm aa" placeholderText="Select time" />

                    {errors && errors.timeOfLoss && touched && touched.timeOfLoss ? (
                        <div className="form-error">{errors.timeOfLoss}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label className="left-justified" htmlFor="surveillanceType">Surveillance Type</label>

                    <div role="group" aria-labelledby="surveillanceType-radio-group" className="form-check-inline single-col">
                        <div className="form-check">
                            <Field type="radio" name={nested ? "sceneInvestigationDetails.surveillanceType" : "surveillanceType"} value="stationary" id="stationary" />
                            <label htmlFor="stationary">Stationary</label>
                        </div>
                        <div className="form-check">
                            <Field type="radio" name={nested ? "sceneInvestigationDetails.surveillanceType" : "surveillanceType"} value="mobile" id="mobile" />
                            <label htmlFor="mobile">Mobile</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="investigationSubject">Subject of Surveillance/ Investigation</label>
                    <Field id="investigationSubject" name={nested ? "sceneInvestigationDetails.investigationSubject" : "investigationSubject"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="investigationLocation">Location of Surveillance/ Incident</label>
                    <Field id="investigationLocation" name={nested ? "sceneInvestigationDetails.investigationLocation" : "investigationLocation"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="subjectVehicleDescription">Subject Vehicle Description</label>
                    <Field id="subjectVehicleDescription" name={nested ? "sceneInvestigationDetails.subjectVehicleDescription" : "subjectVehicleDescription"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="subjectVehiclelicensePlate">License Plate</label>
                    <Field id="subjectVehiclelicensePlate" name={nested ? "sceneInvestigationDetails.subjectVehiclelicensePlate" : "subjectVehiclelicensePlate"} />
                </div>
            </div>

            <div className="form-row single-col">
                <div className="form-field-wrapper">
                    <label htmlFor="factsOfLoss">Facts of Loss</label>
                    <Field id="factsOfLoss" name={nested ? "sceneInvestigationDetails.factsOfLoss" : "factsOfLoss"} as="textarea" rows="5" />
                </div>
            </div>
        </div>
    );
};

export default IntakeSceneInvestigationDetailsFields;