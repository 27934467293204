import { Field } from "formik";
import { stateProvince } from "../../../../utility/state-province";
import { DatePickerInput } from "../../../datepicker-input";
import StateProvinceDropdown from "../../../stateprovinceDropdown";

const IntakeInsuranceInvestigationLocateDriversDetailsFields = ({ errors, touched, values, nested }) => {
    return (
        <div className="form-wrapper general-information">
            <div className="form-row">
                <div className="form-field-wrapper">
                    <label htmlFor="driversName">Driver's Name</label>
                    <Field id="driversName" name={nested ? "insuranceInvestigationLocateDriversDetails.driversName" : "driversName"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address">Address</label>
                    <Field id="address" name={nested ? "insuranceInvestigationLocateDriversDetails.address" : "address"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="address2">Address 2</label>
                    <Field id="address2" name={nested ? "insuranceInvestigationLocateDriversDetails.address2" : "address2"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="city">City</label>
                    <Field id="city" name={nested ? "insuranceInvestigationLocateDriversDetails.city" : "city"} />
                </div>

                <StateProvinceDropdown nested={nested} values={values} stateProvince={stateProvince} name={nested ? "insuranceInvestigationLocateDriversDetails.stateProvince" : "stateProvince"} id="stateProvince" disabled={false}/>

                <div className="form-field-wrapper">
                    <label htmlFor="zip">Zip</label>
                    <Field id="zip" name={nested ? "insuranceInvestigationLocateDriversDetails.zip" : "zip"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="country">Country</label>
                    <Field id="country" name={nested ? "insuranceInvestigationLocateDriversDetails.country" : "country"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="dob">Date of Birth</label>
                    <DatePickerInput name={nested ? "insuranceInvestigationLocateDriversDetails.dob" : "dob"} placeholderText="Select date" />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="driversLicensePlate">License Plate</label>
                    <Field id="driversLicensePlate" name={nested ? "insuranceInvestigationLocateDriversDetails.driversLicensePlate" : "driversLicensePlate"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="driversInsuranceCompany">Driver's Insurance Company <span className="required">*</span></label>
                    <Field id="driversInsuranceCompany" name={nested ? "insuranceInvestigationLocateDriversDetails.driversInsuranceCompany" : "driversInsuranceCompany"} />

                    {errors && errors.driversInsuranceCompany && touched && touched.driversInsuranceCompany ? (
                        <div className="form-error">{errors.driversInsuranceCompany}</div>
                    ) : null}
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="policy">Policy #</label>
                    <Field id="policy" name={nested ? "insuranceInvestigationLocateDriversDetails.policy" : "policy"} />
                </div>

                <div className="form-field-wrapper">
                    <label htmlFor="claim">Claim #</label>
                    <Field id="claim" name={nested ? "insuranceInvestigationLocateDriversDetails.claim" : "claim"} />
                </div>
            </div>
        </div>
    );
};

export default IntakeInsuranceInvestigationLocateDriversDetailsFields;