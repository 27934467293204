import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../../components/form-footer';
import IntakeInsuranceInvestigationBusinessDetailsFields from '../../../../components/intake/insurance-investigation/business/details-fields';
import InsuranceInvestigationBusinessDetailsSchema from '../../../../validation-schemas/insurance-investigation-business-details-schema';
import { updateActiveFormInsuranceInvestigationBusinessDetails, selectActiveFormInsuranceInvestigationBusinessDetails } from '../../../../reducers/active-form-slice';


const IntakeInsuranceInvestigationBusinessDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const insuranceInvestigationBusinessDetails = useSelector(selectActiveFormInsuranceInvestigationBusinessDetails);
    const hasValues = Object.keys(insuranceInvestigationBusinessDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormInsuranceInvestigationBusinessDetails(values));
        navigate("/intake/insurance-investigation-business/drivers-details");
    };


    /**
     * setup the initial values
     */

    const initialValues = {
        insuredType: '',
        dateOfLoss: '',
        insuredName: '',
        insuredAddress: '',
        insuredAddress2: '',
        insuredCity: '',
        insuredStateProvince: '',
        insuredZip: '',
        insuredCountry: '',
        insuredDob: '',
        insuredSsn: '',
        insuredVin: '',
        insuredLicensePlate: '',
        insuredLicenseNumber: '',
        ownersName: '',
        ownersAddress: '',
        ownersAddress2: '',
        ownersCity: '',
        ownersStateProvince: '',
        ownersZip: '',
        ownersCountry: '',
        ownerIsDriver: '',
        locateAddress: '',
        locateAddress2: '',
        locateCity: '',
        locateStateProvince: '',
        locateZip: '',
        locateCountry: ''
    };


    return (
        <div className="page intake">
            <h2>Insured's Details</h2>

            <Formik
                initialValues={hasValues ? insuranceInvestigationBusinessDetails : initialValues}
                validationSchema={InsuranceInvestigationBusinessDetailsSchema.fields.insuranceInvestigationBusinessDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeInsuranceInvestigationBusinessDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`40`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeInsuranceInvestigationBusinessDetails;