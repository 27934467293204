import { caseManagers, caseManager123 } from '../placeholder-data';
import BaseAPI from '../utility/fetch-wrapper';


class CaseManagersServices {
    static GetCaseManagers = async successFunc => {
        const data = await BaseAPI.getRequest(`users/organizationusers`);
        return data;
    };

    static GetCaseManagersByFirm = () => {
        return new Promise((resolve) =>
            setTimeout(() => resolve({ data: caseManagers }), 1000)
        );
    };

    static GetCaseManagerById = async payload => {
        const data = await BaseAPI.getRequest(`users/organizationusers/${payload}`);
        return data;
    };

    static UpdateCaseManager = async (payload) => {
        const data = await BaseAPI.putRequest(`users/organizationusers/${payload.id}`, payload)
        return data;
    };

    static CreateCaseManager = async payload => {
        const data = await BaseAPI.postRequest(`users/organizationusers`, payload);
        return data;
    };
}


export default CaseManagersServices;