class FilterMethods {

    static filteredData = (orders, appliedFilters) => {
        var data = orders.filter((order) => {
            if (appliedFilters.length === 0)
            {
                return order;
            }
            else
            {
                var meetsFilterCriteriaArray = [];
                var filterTypeArrays = appliedFilters.map(filter => filter.filterType);
                appliedFilters.forEach(filter => {
                    var filterTypeMatch = filter.filterType;
                    if (order[filterTypeMatch] == filter.filterName)
                    {   
                        meetsFilterCriteriaArray.push(filterTypeMatch);    
                    }
                })
                var meetsAllCriteriaArray = [];
                filterTypeArrays.forEach(filterTypeMatch => {
                    if (meetsFilterCriteriaArray.includes(filterTypeMatch))
                    {
                        meetsAllCriteriaArray.push(true);
                    }
                    else
                    {
                        meetsAllCriteriaArray.push(false);
                    }
                })
                if (!meetsAllCriteriaArray.includes(false))
                {
                    return order;
                }
            }
        })
        var records = data.map((d, i) => ({...d, key: d.id + '-' + i}));
        return records;
    };

    static assignSelectedFilter = (id, filterType, filters) => {
        var filterId = id + "-label";
        var filterName = document.getElementById(filterId).innerText;
        switch(filterName)
        {
            case "Cleared":
                filterName = "0";
                break;
            case "Aged Out":
                filterName = "1";
                break;
            case "Waiting on Client":
                if (filterType === "colorCode") {
                    filterName = "2";
                }
                break;
        }
        var filterObject = {filterId, filterName, filterType};
        var filterIdArray = filters.map(item => item.filterId);
        filterIdArray.includes(filterId) ? filters.splice(filterIdArray.indexOf(filterId), 1) : filters.push(filterObject);
        return filters;
    };

    static clearFilters = () => {
        var checkboxes = document.getElementsByClassName("form-check-input");
        for (var i = 0; i < checkboxes.length; i++) {
            document.getElementById(checkboxes[i].id);
            checkboxes[i].checked = false;
        }
    };
    
    //derived from the clearfilters function. this function takes in the session storage variable with active filters and foreach filter option
    //checks to see if its id is in the array and if it does sets the checkbox to true
    static applyLoadedFilters = async (loadedFilters) => {
        const checkboxes = document.getElementsByClassName("form-check-input");
        // convert checkboxes collection into array
        const checkboxesArray = Array.from(checkboxes);
        //iterates over each available checkbox element and matches its id to loadedFilters if there
        checkboxesArray.forEach(checkbox => {
            const checkboxIdLabel = checkbox.id + "-label";
            const loadedFilter = loadedFilters.find(x => x.filterId === checkboxIdLabel); 
            //if loaded filter is true then checkbox gets marked
            if (loadedFilter) {
                checkbox.checked = true;
            }
        });
    }

    

    static concatFilters = (filters)  => {
        var formatedFilters = "";
        filters.forEach((item, i) => {
            let filterName = ""
            switch(item.filterName)
            {
                case "0":
                    filterName = "Cleared";
                    break;
                case "1":
                    filterName = "Aged Out";
                    break;
                case "2":
                    filterName = "Waiting on Client";
                    break;
                default:
                    filterName = item.filterName
            }            
            if (i === 0)
            {
                formatedFilters = filterName;
            }
            else
            {
                formatedFilters = formatedFilters.concat(", ", filterName);
            };
        });
        return formatedFilters;
    }

    static filteredOrderNotes = (notes, useExternalNotes, useInternalNotes) => {
        if (!useExternalNotes)
        {
            var filteredOrderNotes = notes.filter((note) => !note.tag);
            notes = filteredOrderNotes;
        }

        if (!useInternalNotes)
        {
            var filteredOrderNotes = notes.filter((note) => note.tag);
            notes = filteredOrderNotes;
        }
        return notes;
    }

    static filteredOrderAttachments = (attachments, useExternalAttachments, useInternalAttachments) => {
        if (!useExternalAttachments)
        {
            var filteredOrderAttachments = attachments.filter((attachments) => !attachments.tag);
            attachments = filteredOrderAttachments;
        }

        if (!useInternalAttachments)
        {
            var filteredOrderAttachments = attachments.filter((attachments) => attachments.tag);
            attachments = filteredOrderAttachments;
        }
        return attachments;
    }
};

export default FilterMethods;