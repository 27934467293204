import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { selectPortalUser, selectActiveFeatures } from '../reducers/auth-slice';
import { useSelector } from 'react-redux';
import { loginRequest } from '../authConfig';


const RequireAuth = ({ children, userType, featureType }) => {

    const { instance, inProgress } = useMsal();    
    const portalUser = useSelector(selectPortalUser);
    const activeFeatures = useSelector(selectActiveFeatures);

    let loggedin = instance.getActiveAccount();


    if (!loggedin && inProgress === InteractionStatus.None) {
        
        // Force login
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));       

    } else {

        // Check for feature flag when provided
        var featureFound = !featureType || activeFeatures.includes(featureType) ;

        // Check for userType match when provided
        var userTypeMatch = !userType || (portalUser && portalUser.userType === userType);


        return (userTypeMatch && featureFound) ? children : null;
    }


    return null;
    
}

export default RequireAuth;