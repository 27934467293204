import { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { BeatLoader } from 'react-spinners';
import BuildForms from './build-forms';
import BuildSchema from "./build-schema";
import FormFooter from "../../../components/form-footer";
import IntakeGeneralInformationFields from "../../../components/intake/general-information-fields";
import IntakeAdditionalInformationFields from "../../../components/intake/additional-information-fields";
import { selectAllActiveForm, selectActiveFormInvestigationType, resetState, submitForm } from "../../../reducers/active-form-slice";
import { resetAdditionalInformationFilesStatus, selectAllAdditionalInformationFiles, uploadAdditionalInformationFiles, deleteAdditionalInformationFiles, resetAdditionalInformationFiles } from '../../../reducers/additional-information-files-slice';
import { resetSWITRSDetailsFilesStatus, selectAllSWITRSDetailsFiles, uploadSWITRSDetailsFiles, deleteSWITRSDetailsFiles, resetSWITRSDetailsFiles } from '../../../reducers/switrs-details-files-slice';
import { resetTrafficCollisionsReportRetrievalFilesStatus, selectAllTrafficCollisionsReportRetrievalFiles, uploadTrafficCollisionsReportRetrievalFiles, deleteTrafficCollisionsReportRetrievalFiles, resetTrafficCollisionsReportRetrievalFiles } from '../../../reducers/traffic-collisions-report-retrieval-files-slice';
import { deleteFaultInfoFiles, resetFaultInfoFilesStatus, selectAllFaultInfoFiles, uploadFaultInfoFiles } from '../../../reducers/fault-info-files-slice';
import SubmissionFailureModal from '../../../components/modals/submission-failure';
import { resetAttachmentsAfterSubmission } from "../../../reducers/attachments-slice";


const IntakeConfirmation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formRef = useRef();
    const [submitType, setSubmitType] = useState();


    /**
     * get current form values
     */
    const allActiveFormValues = useSelector(selectAllActiveForm);
    const investigationType = useSelector(selectActiveFormInvestigationType);


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/additional-information");
    };

    /**
     * handle the next button
     */
    const handleNext = async (e) => {
        e.preventDefault();
        await dispatch(resetState());
        await dispatch(resetSWITRSDetailsFiles());
        await dispatch(resetAdditionalInformationFiles());
        await dispatch(resetTrafficCollisionsReportRetrievalFiles());
        await dispatch(resetFaultInfoFilesStatus());
        await dispatch(resetAttachmentsAfterSubmission());
        navigate("/portal");
    };

    /**
     * update active form & continue or fail
     */
    const activeFormStatus = useSelector(state => state.activeForm.status);
    const activeFormError = useSelector(state => state.activeForm.error);

    const [showSubmissionFailureModal, setShowSubmissionFailureModal] = useState(false);
    const handleHideSubmissionFailureModal = () => {
        setShowSubmissionFailureModal(false);
    };

    const handleSubmit = async (values) => {
        await dispatch(submitForm(values));
    };

    useEffect(() => {
        if (activeFormStatus === "succeeded") {
            (async () => {                
                await dispatch(resetState());
                await dispatch(resetSWITRSDetailsFiles());
                await dispatch(resetAdditionalInformationFiles());
                await dispatch(resetTrafficCollisionsReportRetrievalFiles());
                await dispatch(resetFaultInfoFilesStatus());
                await dispatch(resetAttachmentsAfterSubmission());

                if (submitType === 'submitContinue') {
                    navigate('/intake/success');
                } else {
                    navigate('/intake');
                }
            })();
        } else if (activeFormStatus === "failed") {
            setShowSubmissionFailureModal(!showSubmissionFailureModal);
        }
    }, [activeFormStatus, submitType, navigate, dispatch]);


    /**
     * setup the validation
     */
    const schema = BuildSchema(investigationType);
    const validationSchema = Yup.object().shape({
        ...schema
    });


    /**
     * handle the file upload for additional information
     */
    const additionalInformationFiles = useSelector(selectAllAdditionalInformationFiles);
    const additionalInformationFilesStatus = useSelector(state => state.additionalInformationFiles.status);

    const handleAdditionalFilesUpload = async files => {
        await dispatch(uploadAdditionalInformationFiles(files));
    };

    const handleUploadedAdditionalFilesRemoval = async guid => {
        await dispatch(deleteAdditionalInformationFiles(guid));
    };

    useEffect(() => {
        if (additionalInformationFilesStatus === "succeeded") {
            formRef.current.setFieldValue('additionalInformation.files', additionalInformationFiles.additionalInformationFiles);

            (async () => {
                await dispatch(resetAdditionalInformationFilesStatus());
            })();
        }
    }, [additionalInformationFiles, additionalInformationFilesStatus, dispatch]);


    /**
     * handle the file upload for SWITRS
     */
    const switrsDetailsFiles = useSelector(selectAllSWITRSDetailsFiles);
    const switrsDetailsFilesStatus = useSelector(state => state.switrsDetailsFiles.status);

    const handleSWITRSUpload = async files => {
        await dispatch(uploadSWITRSDetailsFiles(files));
    };

    const handleSWITRSUploadedRemoval = async guid => {
        await dispatch(deleteSWITRSDetailsFiles(guid));
    };

    useEffect(() => {
        if (switrsDetailsFilesStatus === "succeeded") {
            formRef.current.setFieldValue('switrsDetails.files', switrsDetailsFiles.switrsDetailsFiles);

            (async () => {
                await dispatch(resetSWITRSDetailsFilesStatus());
            })();
        }
    }, [switrsDetailsFiles, switrsDetailsFilesStatus, dispatch]);


    /**
     * handle the file upload for Traffic Collisions Report Retrieval
     */
    const trafficCollisionsReportRetrievalFiles = useSelector(selectAllTrafficCollisionsReportRetrievalFiles);
    const trafficCollisionsReportRetrievalFilesStatus = useSelector(state => state.trafficCollisionsReportRetrievalFiles.status);

    const handleTrafficCollisionsReportRetrievalUpload = async files => {
        await dispatch(uploadTrafficCollisionsReportRetrievalFiles(files));
    };

    const handleTrafficCollisionsReportRetrievalUploadedRemoval = async guid => {
        await dispatch(deleteTrafficCollisionsReportRetrievalFiles(guid));
    };

    useEffect(() => {
        if (trafficCollisionsReportRetrievalFilesStatus === "succeeded") {
            formRef.current.setFieldValue('trafficCollisionsReportRetrievalDetails.files', trafficCollisionsReportRetrievalFiles.trafficCollisionsReportRetrievalFiles);

            (async () => {
                await dispatch(resetTrafficCollisionsReportRetrievalFilesStatus());
            })();
        }
    }, [trafficCollisionsReportRetrievalFiles, trafficCollisionsReportRetrievalFilesStatus, dispatch]);


    /**
     * handle the file upload for witness & defendant statement
     */
    const faultInfoFiles = useSelector(selectAllFaultInfoFiles);
    const faultInfoFilesStatus = useSelector(state => state.faultInfoFiles.status);

    const handleWitnessDefendantStatementUpload = (files) => {
        dispatch(uploadFaultInfoFiles(files));
    };

    const handleWitnessDefendantStatementUploadedRemoval = (guid) => {
        dispatch(deleteFaultInfoFiles(guid));
    };

    useEffect(() => {
        if (faultInfoFilesStatus === "succeeded") {
            formRef.current.setFieldValue('witnessDefendantStatementFaultInfo.files', faultInfoFiles.faultInfoFiles);

            (async () => {
                await dispatch(resetFaultInfoFilesStatus());
            })();
        }
    }, [faultInfoFiles, faultInfoFilesStatus, dispatch]);


    return (
        <div className="page confirmation">
            {(activeFormStatus === "loading" || additionalInformationFilesStatus === 'loading' || switrsDetailsFilesStatus === 'loading' || trafficCollisionsReportRetrievalFilesStatus === 'loading' || faultInfoFilesStatus === 'loading') &&
                <div className="loader-container">
                    <BeatLoader color="#00818C" />
                </div>
            }

            <h2>Please confirm that your information is correct.</h2>

            <Formik
                innerRef={formRef}
                initialValues={allActiveFormValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, isValid, touched, values }) => (
                    <Form>
                        <IntakeGeneralInformationFields
                            errors={errors.generalInformation}
                            touched={touched.generalInformation}
                            values={values.generalInformation}
                            nested={true}
                        />


                        <BuildForms
                            errors={errors}
                            touched={touched}
                            values={values}
                            investigationType={investigationType}

                            handleUpload={
                                investigationType[1] === "c7537487-e799-49f0-85b9-13c07170ba20" ?
                                    handleSWITRSUpload : investigationType[1] === "26a9c1ea-45a1-4181-8665-9cdc33721f71" ?
                                        handleTrafficCollisionsReportRetrievalUpload : handleWitnessDefendantStatementUpload
                            }
                            handleUploadedReAdditionalFilesmoval={
                                investigationType[1] === "c7537487-e799-49f0-85b9-13c07170ba20" ?
                                    handleSWITRSUploadedRemoval : investigationType[1] === "26a9c1ea-45a1-4181-8665-9cdc33721f71" ?
                                        handleTrafficCollisionsReportRetrievalUploadedRemoval : handleWitnessDefendantStatementUploadedRemoval
                            }
                            uploadedFiles={
                                investigationType[1] === "c7537487-e799-49f0-85b9-13c07170ba20" ?
                                    values.switrsDetails.files : investigationType[1] === "26a9c1ea-45a1-4181-8665-9cdc33721f71" ?
                                        values.trafficCollisionsReportRetrievalDetails.files : values.witnessDefendantStatementFaultInfo.files
                            }
                            uploadStatus={
                                investigationType[1] === "c7537487-e799-49f0-85b9-13c07170ba20" ?
                                    switrsDetailsFilesStatus : investigationType[1] === "26a9c1ea-45a1-4181-8665-9cdc33721f71" ?
                                        trafficCollisionsReportRetrievalFilesStatus : faultInfoFilesStatus
                            }
                        />

                        <IntakeAdditionalInformationFields
                            handleUpload={handleAdditionalFilesUpload}
                            handleUploadedRemoval={handleUploadedAdditionalFilesRemoval}
                            uploadedFiles={values.additionalInformation.files}
                            uploadStatus={additionalInformationFilesStatus}
                            nested={true}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={true}
                            showNext={false}
                            progress={`100`}
                        />

                        <div className="form-footer-sub">
                            <button type="submit" className="form-submit" disabled={!isValid} onClick={() => setSubmitType('submitContinue')}>
                                Submit
                            </button>

                            <button type="submit" className="form-previous" disabled={!isValid} onClick={() => setSubmitType('submitCreateNew')}>
                                + Submit &amp; Create Another Order
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            {showSubmissionFailureModal &&
                <SubmissionFailureModal
                    showSubmissionFailureModal={showSubmissionFailureModal}
                    handleHideSubmissionFailureModal={handleHideSubmissionFailureModal}
                    failureMessage={activeFormError}
                    continueOrder = {handleNext}
                />
            }
        </div>
    );
};

export default IntakeConfirmation;