import Modal from 'react-bootstrap/Modal';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { postDeleteNotification } from '../../reducers/notifications-slice';


const ShowNoteModal = ({ showNoteModal, handleHideNoteModal, note }) => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(postDeleteNotification(note.id));
    }, [showNoteModal]);

    return (
        <Modal
            backdrop="static"
            show={showNoteModal}
            onHide={handleHideNoteModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Note from {note.from} on {new Date(note.date.endsWith('Z') ? note.date : note.date+"Z").toLocaleString()}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="form-field-wrapper">
                    {note.message}
                </div>
            </Modal.Body>

            <Modal.Footer></Modal.Footer>
        </Modal>
    );
};

export default ShowNoteModal;