import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CaseServices from '../services/case-service';


export const fetchCases = createAsyncThunk('cases/fetchCases', async () => {
        const data = await CaseServices.GetAllCases()
        return data;      
    });

const initialState = {
    cases: [],
    status: 'idle',
    error: null  
}

export const casesSlice = createSlice({
    name: 'cases',
    initialState,
    reducers: {
        resetCasesStatus: (state) => {
            state.status = 'idle';
        },
        resetCases: () => initialState
    },


    extraReducers: {
        [fetchCases.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCases.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.cases = state.cases.concat(action.payload);
        },
        [fetchCases.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    }
});

export const {
    resetCasesStatus,
    resetCases,
} = casesSlice.actions;

export default casesSlice.reducer;


export const selectAllCases = state => {
    return state.cases.cases;
};