import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from '../../../components/form-footer';
import IntakeBackgroundScreeningDetailsFields from '../../../components/intake/background-screening/details-fields';
import { updateActiveFormBackgroundScreeningDetails, selectActiveFormBackgroundScreeningDetails } from '../../../reducers/active-form-slice';
import BackgroundScreeningDetailsSchema from '../../../validation-schemas/background-screening-details-schema';


const IntakeBackgroundScreeningDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const backgroundScreeningDetails = useSelector(selectActiveFormBackgroundScreeningDetails);
    const hasValues = Object.keys(backgroundScreeningDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateActiveFormBackgroundScreeningDetails(values));

        if (values.socialMedia === "Yes") {
            navigate("/intake/background-screening/social-media");
        } else {
            navigate("/intake/additional-information");
        }
    };


    /**
     * setup the initial values
     */
    const initialValues = {
        firstName: '',
        lastName: '',
        emailPrimary: '',
        emailSecondary: '',
        phonePrimary: '',
        phoneSecondary: '',
        address: '',
        address2: '',
        city: '',
        stateProvince: '',
        zip: '',
        country: '',
        vin: '',
        licensePlate: '',
        jobs: '',
        dob: '',
        age: '',
        informationType: '',
        socialMedia: '',
    };


    return (
        <div className="page intake">
            <h2>Background Social Media Screening Details</h2>

            <Formik
                initialValues={hasValues ? backgroundScreeningDetails : initialValues}
                validationSchema={BackgroundScreeningDetailsSchema.fields.backgroundScreeningDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeBackgroundScreeningDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`40`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeBackgroundScreeningDetails;