import { createBrowserRouter } from 'react-router-dom';
import { RouterProvider } from "react-router-dom";
import DashboardLayout from './components/layouts/dashboard';
import LayoutIntake from './components/layouts/intake';
import NotFound from './components/layouts/not-found';
import Error from './components/layouts/error';
import ErrorIntake from './components/layouts/error-intake';
import Orders from './screens/orders';
import OrderDetails from './screens/orders-details';
import CaseDetails from './screens/case-details';
import Admin from './screens/admin/admin';
import LawFirm from './screens/admin/law-firm';
import CaseManager from './screens/admin/case-manager';
import GlucroftUser from './screens/admin/glucroft-user';
import IntakeInvestigationType from './screens/intake/investigation-type';
import IntakeGeneralInformation from './screens/intake/general-information';
import IntakeAdditionalInformation from './screens/intake/additional-information';
import IntakeConfirmation from './screens/intake/confirmation/confirmation';
import IntakeSuccess from './screens/intake/success';
import IntakeAssetLocateType from './screens/intake/asset-locate/type';
import IntakeAssetLocateDetails from './screens/intake/asset-locate/details';
import IntakeAssetLocateRequest from './screens/intake/asset-locate/request';
import IntakeBackgroundScreeningDetails from './screens/intake/background-screening/details';
import IntakeBackgroundScreeningSocialMedia from './screens/intake/background-screening/social-media';
import IntakeBusinessDueDiligenceDetails from './screens/intake/business-due-diligence/details';
import IntakeDMVDetails from './screens/intake/dmv/details';
import IntakeInsuranceClaimHistoryDetails from './screens/intake/insurance-claim-history/details';
import IntakeInsuranceClaimHistorySubject from './screens/intake/insurance-claim-history/subject';
import IntakeInsuranceInvestigationBusinessDetails from './screens/intake/insurance-investigation/business/details';
import IntakeInsuranceInvestigationBusinessDriversDetails from './screens/intake/insurance-investigation/business/drivers-details';
import IntakeInsuranceInvestigationBusinessAdditionalInformation from './screens/intake/insurance-investigation/business/additional-information';
import IntakeInsuranceInvestigationLocateDetails from './screens/intake/insurance-investigation/locate/details';
import IntakeInsuranceInvestigationLocateDriversDetails from './screens/intake/insurance-investigation/locate/drivers-details';
import IntakeInsuranceInvestigationLocateAdditionalInformation from './screens/intake/insurance-investigation/locate/additional-information';
import IntakeInsuranceInvestigationPersonalAutoUmbrellaDetails from './screens/intake/insurance-investigation/personal/auto-umbrella/details';
import IntakeInsuranceInvestigationPersonalAutoUmbrellaDriversDetails from './screens/intake/insurance-investigation/personal/auto-umbrella/drivers-details';
import IntakeInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformation from './screens/intake/insurance-investigation/personal/auto-umbrella/additional-information';
import IntakeInsuranceInvestigationPersonalHomeownersRentersDetails from './screens/intake/insurance-investigation/personal/homeowners-renters/details';
import IntakeInsuranceInvestigationPersonalHomeownersRentersAdditionalInformation from './screens/intake/insurance-investigation/personal/homeowners-renters/additional-information';
import IntakeSceneInvestigationDetails from './screens/intake/scene-investigation/details';
import IntakeSkipTraceDetails from './screens/intake/skip-trace/details';
import IntakeSubpoenaProcessServingDetails from './screens/intake/subpoena-process-serving/details';
import IntakeSWITRSDetails from './screens/intake/switrs/details';
import IntakeTrafficCollisionsReportRetrievalDetails from './screens/intake/traffic-collisions-report-retrieval/details';
import IntakeWitnessDefendantStatementDetails from './screens/intake/witness-defendant-statement/details';
import IntakeWitnessDefendantStatementDefendantInfo from './screens/intake/witness-defendant-statement/defendant-info';
import IntakeWitnessDefendantStatementWitnessInfo from './screens/intake/witness-defendant-statement/witness-info';
import IntakeWitnessDefendantStatementFaultInfo from './screens/intake/witness-defendant-statement/fault-info';
import IntakeWitnessDefendantStatementInfo from './screens/intake/witness-defendant-statement/info';
import Portal from './screens/portal';
import RequireAuth from './utility/require-auth';
import LoginRedirector from './utility/login-redirector';
import OrdersWithNotifications from './screens/orders-with-notifications';


const AppRouter = () => {
    /**
     * TODOs
     * 
     * 1. replace with whatever needs to be first
     * 2. redirect user to /intake if investigation type is empty
     * 3. check that all form errors are showing on front end
     */
    const router = createBrowserRouter([
        /**
         * TODOs
         * 
         * 1. replace with whatever needs to be first
         * 2. redirect user to /intake if investigation type is empty
         * 3. check that all form errors are showing on front end
         */
        {
            path: "/",
            element: <DashboardLayout />,
            errorElement: <Error />,
            children: [
                {
                    index: true,
                    element: <LoginRedirector />
                },
                {
                    path: "*",
                    element: <NotFound />,
                },
                {
                    path: "orders",
                    element: <RequireAuth userType="glucroft"><Orders /></RequireAuth>,
                },
                {
                    path: "orders/order-details/:orderId",
                    element: <RequireAuth userType="glucroft"><OrderDetails /></RequireAuth>,
                },
                {
                    path: "orders/case-details/:caseNumber",
                    element: <RequireAuth userType="glucroft"><CaseDetails /></RequireAuth>,
                },
                {
                    path: "orders/notifications",
                    element: <RequireAuth userType="glucroft"><OrdersWithNotifications /></RequireAuth>,
                },
                {
                    path: "admin",
                    children: [
                        {
                            path: "",
                            element: <RequireAuth userType="glucroft"><Admin /></RequireAuth>,
                        },
                        {
                            path: "law-firm",
                            element: <RequireAuth userType="glucroft" featureType="f_Admin_Firm"><LawFirm /></RequireAuth>,
                            children: [
                                {
                                    path: ":id",
                                    element: <RequireAuth userType="glucroft" featureType="f_Admin_Firm"><LawFirm /></RequireAuth>,
                                }
                            ],
                        },
                        {
                            path: "case-manager",
                            element: <RequireAuth userType="glucroft" featureType="f_Admin_CaseMgr"><CaseManager /></RequireAuth>,
                            children: [
                                {
                                    path: ":id",
                                    element: <RequireAuth userType="glucroft" featureType="f_Admin_CaseMgr"><CaseManager /></RequireAuth>,
                                }
                            ],
                        },
                        {
                            path: "glucroft-user",
                            element: <RequireAuth userType="glucroft" featureType="f_Admin_Users"><GlucroftUser /></RequireAuth>,
                            children: [
                                {
                                    path: ":id",
                                    element: <RequireAuth userType="glucroft" featureType="f_Admin_Users"><GlucroftUser /></RequireAuth>,
                                }
                            ],
                        },
                    ],
                }
            ],
        },
        {
            path: "/portal",
            element: <DashboardLayout />,
            errorElement: <Error />,
            children: [
                {
                    index: true,
                    element: <RequireAuth><Portal /></RequireAuth>
                }
            ]
        },
        {
            path: "/intake",
            element: <LayoutIntake />,
            errorElement: <ErrorIntake />,
            children: [
                {
                    path: "*",
                    element: <NotFound />,
                },
                {
                    path: "",
                    element: <IntakeInvestigationType />,
                },
                {
                    path: "investigation-type",
                    element: <IntakeInvestigationType />,
                },
                {
                    path: "general-information",
                    element: <IntakeGeneralInformation />,
                },
                {
                    path: "additional-information",
                    element: <IntakeAdditionalInformation />,
                },
                {
                    path: "confirmation",
                    element: <IntakeConfirmation />,
                },
                {
                    path: "success",
                    element: <IntakeSuccess />,
                },
                {
                    path: "asset-locate",
                    children: [
                        {
                            path: "type",
                            element: <IntakeAssetLocateType />,
                        },
                        {
                            path: "details",
                            element: <IntakeAssetLocateDetails />,
                        },
                        {
                            path: "request",
                            element: <IntakeAssetLocateRequest />,
                        },
                    ],
                },
                {
                    path: "background-screening",
                    children: [
                        {
                            path: "details",
                            element: <IntakeBackgroundScreeningDetails />,
                        },
                        {
                            path: "social-media",
                            element: <IntakeBackgroundScreeningSocialMedia />,
                        },
                    ],
                },
                {
                    path: "business-due-diligence",
                    children: [
                        {
                            path: "details",
                            element: <IntakeBusinessDueDiligenceDetails />,
                        },
                    ],
                },
                {
                    path: "dmv",
                    children: [
                        {
                            path: "details",
                            element: <IntakeDMVDetails />,
                        },
                    ],
                },
                {
                    path: "insurance-claim-history",
                    children: [
                        {
                            path: "details",
                            element: <IntakeInsuranceClaimHistoryDetails />,
                        },
                        {
                            path: "subject",
                            element: <IntakeInsuranceClaimHistorySubject />,
                        },
                    ],
                },
                {
                    path: "insurance-investigation-business",
                    children: [
                        {
                            path: "details",
                            element: <IntakeInsuranceInvestigationBusinessDetails />,
                        },
                        {
                            path: "drivers-details",
                            element: <IntakeInsuranceInvestigationBusinessDriversDetails />,
                        },
                        {
                            path: "additional-information",
                            element: <IntakeInsuranceInvestigationBusinessAdditionalInformation />,
                        },
                    ],
                },
                {
                    path: "insurance-investigation-locate",
                    children: [
                        {
                            path: "details",
                            element: <IntakeInsuranceInvestigationLocateDetails />,
                        },
                        {
                            path: "drivers-details",
                            element: <IntakeInsuranceInvestigationLocateDriversDetails />,
                        },
                        {
                            path: "additional-information",
                            element: <IntakeInsuranceInvestigationLocateAdditionalInformation />,
                        },
                    ],
                },
                {
                    path: "insurance-investigation-personal-auto-umbrella",
                    children: [
                        {
                            path: "details",
                            element: <IntakeInsuranceInvestigationPersonalAutoUmbrellaDetails />,
                        },
                        {
                            path: "drivers-details",
                            element: <IntakeInsuranceInvestigationPersonalAutoUmbrellaDriversDetails />,
                        },
                        {
                            path: "additional-information",
                            element: <IntakeInsuranceInvestigationPersonalAutoUmbrellaAdditionalInformation />,
                        },
                    ],
                },
                {
                    path: "insurance-investigation-personal-homeowners-renters",
                    children: [
                        {
                            path: "details",
                            element: <IntakeInsuranceInvestigationPersonalHomeownersRentersDetails />,
                        },
                        {
                            path: "additional-information",
                            element: <IntakeInsuranceInvestigationPersonalHomeownersRentersAdditionalInformation />,
                        },
                    ],
                },
                {
                    path: "scene-investigation",
                    children: [
                        {
                            path: "details",
                            element: <IntakeSceneInvestigationDetails />,
                        },
                    ],
                },
                {
                    path: "skip-trace",
                    children: [
                        {
                            path: "details",
                            element: <IntakeSkipTraceDetails />,
                        },
                    ],
                },
                {
                    path: "subpoena-process-serving",
                    children: [
                        {
                            path: "details",
                            element: <IntakeSubpoenaProcessServingDetails />,
                        },
                    ],
                },
                {
                    path: "switrs",
                    children: [
                        {
                            path: "details",
                            element: <IntakeSWITRSDetails />,
                        },
                    ],
                },
                {
                    path: "traffic-collisions-report-retrieval",
                    children: [
                        {
                            path: "details",
                            element: <IntakeTrafficCollisionsReportRetrievalDetails />,
                        },
                    ],
                },
                {
                    path: "witness-defendant-statement",
                    children: [
                        {
                            path: "details",
                            element: <IntakeWitnessDefendantStatementDetails />,
                        },
                        {
                            path: "defendant-info",
                            element: <IntakeWitnessDefendantStatementDefendantInfo />,
                        },
                        {
                            path: "witness-info",
                            element: <IntakeWitnessDefendantStatementWitnessInfo />,
                        },
                        {
                            path: "fault-info",
                            element: <IntakeWitnessDefendantStatementFaultInfo />,
                        },
                        {
                            path: "info",
                            element: <IntakeWitnessDefendantStatementInfo />,
                        },
                    ],
                },
            ],
        },
    ]);

    return (
        <RouterProvider router={router} />
    )
}

export default AppRouter;