import React, { useState } from "react";
import { Field } from "formik";

// replaces all the dropdowns with a "stateProvince" id
const StateProvinceDropdown = ( {nested, values, stateProvince, name, id, disabled=false, required=false, className=null, htmlFor=null, title="State/Province", children }) => {
    if (className === null) {
        className = (( values && values.stateProvince && (values.stateProvince.length <= 0 || values.stateProvince === 'Make a selection')) ? 'placeholder' : '');
    }
    if (htmlFor === null) {
        htmlFor = id;
    }

    return (
        <div className="form-field-wrapper">
            {(required) ? <label htmlFor={htmlFor}>{title}<span className="required">*</span></label> : <label htmlFor={htmlFor}>{title}</label>}
            <Field id={id} name={name} as="select" className={className} disabled={disabled}>
                <option value="" defaultValue>Make a selection</option>
                {stateProvince.map((item, i) => {
                    // disables key if below is true
                    if (item==="United States" || item==="Canada") {
                        return (
                            <option key={`stateProvince-${i}`} disabled={true} value={item}>{item}</option>
                        );
                    }
                    return (
                        <option key={`stateProvince-${i}`}  value={item}>{item}</option>
                    );
                })}
            </Field>
            {children}
        </div>
    );
};

export default StateProvinceDropdown;

