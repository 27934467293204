import { Form, Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFooter from "../../../components/form-footer";
import { selectActiveFormWitnessDefendantStatementDetails, selectActiveFormWitnessDefendantStatementDefendantDetails, updateWitnessDefendantStatementDefendantDetails } from "../../../reducers/active-form-slice";
import WitnessDefendantStatementDefendantDetailsSchema from "../../../validation-schemas/witness-defendant-statement-defendant-details";
import IntakeWitnessDefendantStatementDefendantDetailsFields from "../../../components/intake/witness-defendant-statement/defendant-info-fields";


const IntakeWitnessDefendantStatementDefendantInfo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     * get current form values
     */
    const witnessDefendantStatementDetails = useSelector(selectActiveFormWitnessDefendantStatementDetails);
    const witnessDefendantStatementDefendantDetails = useSelector(selectActiveFormWitnessDefendantStatementDefendantDetails);
    const hasValues = Object.keys(witnessDefendantStatementDefendantDetails).length > 0 ? true : false;


    /**
     * handle the previous button
     */
    const handlePrevious = () => {
        navigate("/intake/witness-defendant-statement/details");
    };


    /**
     * update active form & continue
     */
    const handleSubmit = (values) => {
        dispatch(updateWitnessDefendantStatementDefendantDetails(values));

        switch (witnessDefendantStatementDetails.statementType) {
            case "Both":
                navigate("/intake/witness-defendant-statement/witness-info");
                break;
            default:
                navigate("/intake/witness-defendant-statement/fault-info");
                break;
        }
    };


    /**
     * setup the initial values
     */
    const defendantsInitialValue = {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        stateProvince: '',
        zip: '',
        county: '',
        directionOfTravel: '',
        streetOfTravel: '',
        defendantVehicleDescription: '',
    };

    const initialValues = {
        defendants: [
            defendantsInitialValue
        ],
    };


    return (
        <div className="page intake">
            <h2>Witness AND/OR Defendant Statement Details</h2>

            <Formik
                initialValues={hasValues ? witnessDefendantStatementDefendantDetails : initialValues}
                validationSchema={WitnessDefendantStatementDefendantDetailsSchema.fields.witnessDefendantStatementDefendantDetails}
                onSubmit={handleSubmit}
            >
                {({ errors, dirty, isValid, touched, values }) => (
                    <Form>
                        <IntakeWitnessDefendantStatementDefendantDetailsFields
                            errors={errors}
                            touched={touched}
                            values={values}
                            initialValues={defendantsInitialValue}
                        />

                        <FormFooter
                            handlePrevious={handlePrevious}
                            showPrevious={true}
                            disableNext={!((dirty || hasValues) && isValid)}
                            showNext={true}
                            progress={`40`}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeWitnessDefendantStatementDefendantInfo;